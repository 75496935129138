import {Component, OnInit, EventEmitter} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {FormGroup, FormControl, Validators} from '@angular/forms';
import {ServicesListService} from '../../../services/services.service';
import {ToastrService} from 'ngx-toastr';
import {combineLatest, concat, merge, Subject} from 'rxjs';
import {OfficeService} from '../../../services/office.service';
import {ModulesService} from 'src/app/services/modules.service';
import {concatAll, map} from 'rxjs/operators';
import {flatten} from 'lodash';

@Component({
  selector: 'app-addservice',
  templateUrl: './add-service.component.html',
  styleUrls: ['./add-service.component.css']
})
export class AddServiceComponent implements OnInit {

  title = 'New Service';
  selectCallType: string;
  closeBtnName = 'Close';
  addService: FormGroup;
  feeField = true;
  serviceName: string;
  durationHour: string;
  durationMinutes: string;
  servicePrice: string;
  currency: string;
  formValid = true;
  priceChangeObserver: Subject<string> = new Subject<string>();
  modules: any[] = [];
  selectedFee = 'With Fee';
  public event: EventEmitter<any> = new EventEmitter();
  selectedModule: any;
  selectedOffices: any[] = [];
  officeList: any[] = [];

  constructor(
    private toast: ToastrService,
    private _service: ServicesListService,
    public bsModalRef: BsModalRef,
    private _officeService: OfficeService,
    private _moduleService: ModulesService
  ) {}

  showFeeField(field): void {
    this.selectedFee = field;
    this.feeField = this.feeField !== false ? false : true;
    this.priceChangeObserver.next(this.selectedFee.toString());
  }

  ngOnInit(): void {
    this.addService = new FormGroup({
      office: new FormControl('', [Validators.required]),
      module: new FormControl('', [Validators.required]),
      serviceText: new FormControl('', [Validators.required]),
      durationHour: new FormControl(),
      durationMinutes: new FormControl(),
      servicePrice: new FormControl('', [Validators.required]),
      currency: new FormControl(''),
    });

    const servicePrice = this.addService.get('servicePrice');

    this.priceChangeObserver.subscribe(feeField => {
      this.servicePrice = '';
      this.currency = '';
      if (feeField === 'With Fee') {
        servicePrice.setValidators([Validators.required]);
      } else {
        servicePrice.setValidators(null);
      }
    });

    this.getOfficeList();
  }

  createService(): void {
    for (const inner in this.addService.controls) {
      this.addService.get(inner).markAsTouched();
      this.addService.get(inner).updateValueAndValidity();
    }

    if (!this.addService.valid) {
      this.formValid = false;
      this.toast.warning('Fill the required fields.', 'Warning!');
      return;
    }

    const service: any = {};
    service.ModuleSoid = this.selectedModule.Soid;
    service.ModuleName = this.selectedModule.Name;
    service.Category = this.serviceName;
    service.Item = this.serviceName;
    service.Description = this.serviceName;
    service.UnitOfMeasure = 'Each';
    if (this.selectedFee === 'With Fee') {
      service.UnitPrice = parseFloat(this.servicePrice == null || this.servicePrice === '' ? '0' : this.servicePrice);
    } else {
      service.UnitPrice = 0;
    }
    service.DurationInHours = parseInt(this.durationHour == null ? '0' : this.durationHour);
    service.DurationInMinutes = parseInt(this.durationMinutes == null ? '0' : this.durationMinutes);
    service.PriceType = this.selectedFee;

    this._service.createService({service, officeSoid: this.selectedOffices.map(o => o.Soid)}).subscribe((res: any) => {
      if (res) {
        this.toast.success('Service created successfully.', 'Success!');
        this.triggerEvent();
        this.bsModalRef.hide();
      } else {
        this.toast.warning('Error Creating service', 'Error!');
      }
    });
  }

  triggerEvent(): void {
    this.event.emit();
  }

  private getOfficeList(): void {
    this._officeService.getOfficeListByUser(localStorage.getItem('UserSoid')).subscribe((res: any) => {
      if (res) {
        this.officeList = res.Office;
      } else {
        this.officeList = [];
      }
    });
  }

  public officeChange(): void {
    if (this.selectedOffices.length) {
      combineLatest(this.selectedOffices.map((office) => this._moduleService.getAllOfficeModules(office.Soid)))
        .subscribe({
          next: (modules: any[]) => {
            const officeModules = flatten(modules);
            officeModules.forEach(module => {
              if (!this.modules.find(m => m.Soid === module.Soid && m.Name === module.Name) &&
                officeModules.filter(m => m.Soid === module.Soid && m.Name === module.Name).length === this.selectedOffices.length) {
                this.modules.push(module);
              }
            });
          },
        });
    }
  }
}

