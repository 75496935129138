import { FormBuilder, FormGroup } from '@angular/forms';
import { Component, OnInit, TemplateRef } from '@angular/core';
import {FormControl, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ActivatedRoute, Router } from '@angular/router';
import { XeroService } from '../../../services/xero.service';
import { DatePipe } from '@angular/common';
import {ToastrService} from 'ngx-toastr';
import {PageEvent} from '@angular/material/paginator'; 
import * as _ from 'underscore';
import { XeroTenantsComponent } from '../../modal/xero-tenants/xero-tenants.component';

@Component({
  selector: 'app-xero-code',
  templateUrl: './xero-code.component.html',
  styleUrls: ['./xero-code.component.css']
})
export class XeroCodeComponent implements OnInit {

  myForm:FormGroup;
  clientSubmitForm: FormGroup;
  disabled = false;
  ShowFilter = false;
  limitSelection = false;
  selectedItems: any = [];
  searchText: string;
  dropdownSettings: any = {};
  lowValue:number = 0;
  highValue:number = 5; 
  clientRequest: any = {};
  firstName:string;
  lastName:string;
  formValid:boolean = true;
  subscriptionServices: any = [];
  agelimit:Date;
  sortBy:string = "";


  countries: string[] = [
    'Canada', 'Mexico', 'United States'
  ];

  invoices: any = [];

  xeroResponse: any = {};

  // MatPaginator Inputs
  length = Number;
  pageIndex:number = 0;
  pageSize = 5;
  pageSizeOptions: number[] = [5, 10, 25, 100];  

  modalRef: BsModalRef;
  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);
  constructor(private xeroService: XeroService, private toast: ToastrService,
     private datePipe: DatePipe, private modalService: BsModalService,
     private activatedRoute: ActivatedRoute,
      private _router: Router, private fb: FormBuilder, ) {}

  ngOnInit() {

    this.xeroService.generateToken(localStorage.getItem("XeroRequestOfficeID"), this.activatedRoute.snapshot.queryParams.code).subscribe(e => {
      this.xeroResponse = e;
      if (this.xeroResponse && this.xeroResponse.Status == "SUCCESS"  ) {
        if (this.xeroResponse.TenantInfo && this.xeroResponse.TenantInfo.length > 0) {
          this.showTenants();
        } else {
          this._router.navigateByUrl("/office/" + localStorage.getItem("XeroRequestOfficeID"));
        }
      }

    });
  }

  showTenants() {
    const initialState = {
      header: "Xero Tenant",
      xeroTenaants: this.xeroResponse.TenantInfo,
      backdrop: true,
      ignoreBackdropClick: true
    };
    this.modalRef = this.modalService.show(XeroTenantsComponent, { initialState });
    this.modalRef.content.event.subscribe(data => {
     
    });
  }
}
