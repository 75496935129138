import { Injectable } from '@angular/core';
import { Api } from '../services/api';

@Injectable({
  providedIn: 'root'
})
export class EventsService {

  constructor(public api: Api) { }

  createEvent(event) {
    //  var sortField = 'getStaffList' + sortBy != null ? '?sortBy=' + sortBy : '';
      let seq = this.api.post('Appointment/Create', event);
      return seq;
    }



    getEvents() {
     // var sortField = 'getClientList' + sortBy != null ? '?sortBy=' + sortBy : '';
     var userSoid =  localStorage.getItem("UserSoid");
      let seq = this.api.get('Appointment/' + userSoid + '/Participant');
      return seq;
    }

    getUpComingEvents() {
      // var sortField = 'getClientList' + sortBy != null ? '?sortBy=' + sortBy : '';
      var userSoid =  localStorage.getItem("UserSoid");
       let seq = this.api.post('Appointment/' + userSoid + '/UpComingEvents', null);
       return seq;
     }

    getEventsBySoid(soid) {
       let seq = this.api.get('Appointment/' + soid + '/Participant');
       return seq;
     }

    getEventsByStaffId(data) {
      // var sortField = 'getClientList' + sortBy != null ? '?sortBy=' + sortBy : '';
       let seq = this.api.post('Appointment/Participants', data);
       return seq;
     }

     assignStaff(appointmentId, participantSoid, data) {
      // var sortField = 'getClientList' + sortBy != null ? '?sortBy=' + sortBy : '';
       let seq = this.api.patch('Appointment/' + appointmentId + '/ChangeStaff/' + participantSoid, data);
       return seq;
     }

     reSchedule(appointmentId, data) {
      // var sortField = 'getClientList' + sortBy != null ? '?sortBy=' + sortBy : '';
       let seq = this.api.patch('Appointment/' + appointmentId + '/ReSchedule', data);
       return seq;
     }

     getEventById(eventId) {
      var userSoid =  localStorage.getItem("UserSoid");
      let seq = this.api.get('Appointment/' + eventId + '/Show/'+ userSoid);
      return seq;
     }

     cancelAppointment(eventId, data) {
      // var sortField = 'getClientList' + sortBy != null ? '?sortBy=' + sortBy : '';
       let seq = this.api.post('Appointment/'+ eventId + '/Cancel', data);
       return seq;
     }

     completeAppointment(eventId, data) {
      // var sortField = 'getClientList' + sortBy != null ? '?sortBy=' + sortBy : '';
       let seq = this.api.post('Appointment/'+ eventId + '/Complete', data);
       return seq;
     }
     notifyUser(appointmetId, userId) {
        return this.api.post(`Appointment/${appointmetId}/NotifyUser/${userId}`, {});
     }
}
