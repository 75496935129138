import { Pipe, PipeTransform } from '@angular/core';

import * as lodash from 'lodash';

@Pipe({ name: 'simpleSearch' })
export class SimpleSearchPipe implements PipeTransform {

  public transform(value, keys: string, term: string) {

    if (!term) return value;
    return (value || [])
      .filter(item =>
        keys.split(',').some(key => {
          const val = lodash.get(item, key, undefined);
          return val !== undefined && new RegExp(term, 'gi').test(val);
        })
      );
  }
}