import { Injectable } from '@angular/core';
import { Api } from '../services/api';

@Injectable({
  providedIn: 'root'
})

export class ServicesListService {

  constructor(public api: Api) { }

  
  getServicesList(officeSoid) {
    let seq = this.api.get('Office/' + officeSoid + '/ServiceList');
    return seq;
  }

  createService(service) {
    let seq = this.api.post('Office/AddService', service);
    return seq;
  }

  
  updateService(serviceSoid, data) { 
    let seq = this.api.post('Office/Service/'+ serviceSoid, data);
    return seq;
  }
  getService(officeSoid, serviceSoid) { 
    
    let seq = this.api.get('Office/' + officeSoid + '/Service/'+ serviceSoid);
    return seq;
  }

}

