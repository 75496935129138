<div class="section-container">
  <div class="subheader">
      <h2>Staff</h2>
      <!-- <h5>Plans and events</h5> -->
  </div>
  <div class="white-bg rounded border-0">
    <div class="row top-menu bg-light bg-grey no-gutters">
      <div class="col-md-6">
          <div class="top-menu-container">
              <button *ngIf="isAdmin || isSuperAdmin" mat-flat-button  (click)="openModal(template)" color="primary">Add New </button>
              <mat-menu #menu="matMenu" class="top-menu-container-dd">
                <button mat-menu-item (click)="openModal(template)">Staff</button>
                <button mat-menu-item>New Event</button>
              </mat-menu>
              <!--<a (click)="openModal(template)" class="btn btn-danger">Add New Client</a>-->
          <!--    <a (click)="addNewClient" class="btn btn-secondary">Invite via email</a>
              <a (click)="addNewClient" class="btn btn-secondary">Select</a>
              <a (click)="addNewClient" class="btn btn-secondary">Import</a>-->
            </div>
      </div>
      <div class="col-md-6">
          <form class="search-card">
              <div class="card-body row no-gutters align-items-center">
                  <div class="col search-card-field">
                      <i class="fas fa-search icon-serach"></i>
                      <input [(ngModel)]="searchText" name="searchText" class="form-control form-control-borderless" type="search" placeholder="Search By Name, PhoneNumber, Email..">
                  </div>
                 
              </div>
          </form>
      </div>
    </div>
    <div class="row form-page-header">
      <div class="col-md-4">
        <form class="form-inline">
          <label for="inlineFormInputName2" class="mrg-rt-5">Sort By</label>
          <!-- <input type="text" class="form-control mb-2 mr-sm-2" /> -->
          <mat-form-field>
             <!--  <mat-label>Cars</mat-label> -->
              <select matNativeControl required  (change)="sortFieldChange($event)">
                  <option value="NameFirst">Name</option>
                <option value="CreatedOn">Created On</option>
                <option value="EmailAddress">Email</option>
              </select>
            </mat-form-field>
        </form>
      </div>

      <div class="form-row" *ngIf="officeList && officeList.length > 0">
        <mat-form-field>
          <select  matNativeControl placeholder="Office" (change)="getSelectedStaffList($event)"  [(ngModel)]="selectedOffice">
            <option value="All">All</option>
            <!--<mat-option>None</mat-option>-->
            <option *ngFor="let service of officeList" [value]="service.Soid">{{service.CompanyName}} </option>
            <!--<mat-option value="Custom">Custom</mat-option>-->
          </select>
        </mat-form-field>
      </div>

      <div class="col-md-8 text-right">
        <mat-form-field class="search-results">
          <select matNativeControl required (change)="filterStaffList($event)"  [(ngModel)]="filterBy">
            <option value="All">All</option>
            <option value="Active">Active</option>
            <option value="InActive">InActive</option>
          </select>
        </mat-form-field>
      </div>
    </div>
    <div class="client-lists">
      <div class="row">
          <div class="col-md-12">
              <ul class="list-unstyled">
                     <li class="media" *ngFor="let staff of staffList | 
                    simpleSearch : 'NameFirst,NameLast,PhoneNumber,EmployeeNumber,EmailAddress': searchText | slice: lowValue : highValue" (click)=editClient(staff.PersonSoid)> 
                    
                        
                        <ngx-avatar *ngIf="!staff.ProfilePhoto" size="40" name="{{staff.NameFirst}} {{staff.NameLast}}"></ngx-avatar>
                        <ngx-avatar *ngIf="staff.ProfilePhoto" size="40" src="{{staff.ProfilePhoto}}"></ngx-avatar>
                        <!-- <div class="rounded mr-3 bg-success initial-text">{{getShortName(client.firstName) | uppercase}}{{getShortName(client.lastName) | uppercase}}</div> -->
                        <div class="media-body">
                            <div class="row no-gutters">
                                <div class="client-list-left col-md-4">
                                  <h6 class="mt-0 mb-1">{{staff.NameFirst | titlecase}} {{staff.NameLast | titlecase}}</h6>
                                  <span style="font-size:11px"><i class="fas fa-at"></i> {{staff.EmailAddress}}</span>
                                  <div><span style="font-size:11px"><i class="fas fa-mobile-alt"></i> {{staff.PhoneNumber}}</span></div>
                                </div>
                                <div class="col-md-4 result-date text-right">
                                  <span class="lable-tag">{{staff.EmployeeNumber}}</span>
                                </div>
                                <div class="col-md-4 result-date text-right">
                                 <span> {{staff.CreatedOn | date:"dd MMM yyyy"}}</span>
                                </div>
                            </div>
                        </div>
                      </li>
                </ul>
          </div>
      </div>
      <div class="row">
        <div class="col-md-4 offset-md-8">
        <mat-paginator class="pagination" [length]="length"
            [pageSize]="pageSize"
            [pageSizeOptions]="pageSizeOptions" (page)="getPaginatorData($event)">
        </mat-paginator>
        </div>
      </div>
    </div>
    <!-- Modal window: Add new Client -->
    <ng-template #template>
     <!--<div class="modal" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-lg" role="document"> -->
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Add New Staff</h5>
              <button type="button" class="close" aria-label="Close" (click)="modalRef.hide()">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
                <form class="client-modal" [formGroup]="clientSubmitForm">
                    <div class="form-row">
                      <div class="form-group col-md-6">
                        <!-- <label for="inputEmail4">Email</label> -->
                        <!-- <input type="email" class="form-control" id="inputEmail4" placeholder="Email"> -->
                        <mat-form-field>
                            <input type="email"  formControlName="email" matInput placeholder="Email">
                            <mat-error *ngIf="clientSubmitForm.controls.email.errors != null && 
                            clientSubmitForm.controls.email.errors.email != null">
                              Please enter a valid email address
                            </mat-error>
                            <mat-error *ngIf="clientSubmitForm.controls.email.errors != null && 
                            clientSubmitForm.controls.email.errors.required != null">
                              Please enter email address
                            </mat-error>
                        </mat-form-field>
                      </div>
                      <div class="form-group col-md-6">
                        <mat-form-field>
                          <ngx-mat-intl-tel-input (countryChanged)="countryChange($event)" (focusout)="phoneNumberFieldFocusOut($event)" 
                          [preferredCountries]="['us', 've']" [enablePlaceholder]="true"
                                    [enableSearch]="true" name="phone" format="national"
                                   formControlName="phone">
                                  </ngx-mat-intl-tel-input>
                                  <mat-error *ngIf="clientSubmitForm.controls.phone.errors?.required">Please enter valid mobile number</mat-error>
                                  <mat-error *ngIf="clientSubmitForm.controls.phone.errors?.validatePhoneNumber">Please enter valid mobile number</mat-error>
                                  <mat-error *ngIf="clientSubmitForm.controls.phone.errors?.maxlength">Please enter valid mobile number</mat-error>
                        </mat-form-field>
                      </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-md-6">
                            <mat-form-field>
                                <input type="text" formControlName="firstName" matInput placeholder="First Name">
                                <mat-error *ngIf="clientSubmitForm.controls.firstName.errors != null && !formValid">
                                  Please enter first name
                                </mat-error>
                            </mat-form-field>
                           
                        </div>
                        <div class="form-group col-md-6">
                            <mat-form-field>
                                <input type="text" formControlName="lastName" matInput placeholder="Last Name">
                                <mat-error *ngIf="clientSubmitForm.controls.lastName.errors != null && !formValid">
                                    Please enter last name
                                  </mat-error>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="form-row">
                      <div class="form-group col-md-6">
                        <mat-form-field>
                          <mat-label>Role</mat-label>
                          <mat-select formControlName="roleName">
                            <mat-option *ngFor="let role of staffRole" [value]="role.code">{{role.name}}</mat-option>
                          </mat-select>
                        <mat-error *ngIf="clientSubmitForm.controls.roleName.errors != null && !formValid">
                          Please select role
                        </mat-error>
                      </mat-form-field>
                      </div>
                      <div class="form-group col-md-6">
                        <mat-form-field>
                          <mat-label>Position</mat-label>
                          <mat-select formControlName="position">
                            <mat-option *ngFor="let position of staffPosition" [value]="position.code">{{position.name}}</mat-option>
                          </mat-select>
                        <mat-error *ngIf="clientSubmitForm.controls.position.errors != null && !formValid">
                          Please select position
                        </mat-error>
                      </mat-form-field>
                      </div>
                  </div>
                  <div class="form-row" *ngIf="officeList && officeList.length > 0">
                    <mat-form-field>
                      <mat-select placeholder="Office"  [formControl]="toppings" multiple>
                        <mat-select-trigger>
                          {{toppings.value && toppings.value[0] ? toppings.value[0].CompanyName : ''}}
                          <span *ngIf="toppings.value?.length > 1" class="example-additional-selection">
                            (+{{toppings.value.length - 1}} others)
                          </span>
                        </mat-select-trigger>
                        <mat-option *ngFor="let topping of officeList" [value]="topping">{{topping.CompanyName}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  
                  
                </form>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" (click)="modalRef.hide()">Close</button>
              <button type="button" class="btn btn-primary" (click)="saveStaff()">Create Staff</button>
            </div>
          </div>
         <!-- </div>
     </div> -->
    </ng-template>
      <!-- End Modal: Add new client -->
  </div>
</div>