<div class="modal-header">
  <h4 class="modal-title pull-left">{{ title }}</h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form class="client-modal" [formGroup]="addService">
    <div class="form-row">
      <div class="col-md-4">
        <mat-form-field>
          <mat-label>Office</mat-label>
          <mat-select [(ngModel)]="selectedOffices" formControlName="office" (selectionChange)="officeChange()"
                      multiple>
            <mat-option *ngFor="let office of officeList" [value]="office">
              {{ office.CompanyName }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="addService.controls.office.errors != null && !formValid">
            Please select office
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-4">
        <mat-form-field>
          <mat-label>Module</mat-label>
          <mat-select [(ngModel)]="selectedModule" formControlName="module">
            <mat-option *ngFor="let module of modules" [value]="module">
              {{ module.Name }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="addService.controls.module.errors != null && !formValid">
            Please select module
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-4">
        <mat-form-field>
          <input type="input" autocomplete="off" [(ngModel)]="serviceName" formControlName="serviceText" matInput
                 placeholder="Service Name">
          <mat-error *ngIf="addService.controls.serviceText.errors != null && !formValid">
            Please enter service name
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <h6>Duration</h6>
    <div class="form-row">
      <div class="col-md-3">
        <mat-form-field>
          <mat-select placeholder="Hours" [(ngModel)]="durationHour" formControlName="durationHour">
            <mat-option value="0">None</mat-option>
            <mat-option value="1">1 Hour</mat-option>
            <mat-option value="2">2 Hour</mat-option>
            <mat-option value="3">3 Hour</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-3">
        <mat-form-field>
          <mat-select placeholder="Minutes" [(ngModel)]="durationMinutes" formControlName="durationMinutes">
            <mat-option value="0">None</mat-option>
            <mat-option value="15">15 minutes</mat-option>
            <mat-option value="30">30 minutes</mat-option>
            <mat-option value="45">45 minutes</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <h6>Choose Fee Type</h6>
    <div class="form-row">
      <div class="col-md-3">
        <button class="btn btn-outline-info btn-location" (click)="showFeeField('With Fee')"
                [ngClass]="selectedFee == 'With Fee' ? 'active' : ''">
          <i class="fas fa-money-check"></i>
          With Fee
        </button>
      </div>
      <div class="col-md-3">
        <button class="btn btn-outline-info btn-location" (click)="showFeeField('No Fee')"
                [ngClass]="selectedFee == 'No Fee' ? 'active' : ''">
          No Fee
        </button>
      </div>
      <div class="col-md-3">
        <button class="btn btn-outline-info btn-location" (click)="showFeeField('Flexi Fee')"
                [ngClass]="selectedFee == 'Flexi Fee' ? 'active' : ''">
          Flexi Fee
        </button>
      </div>


    </div>
    <div class="form-row" *ngIf="selectedFee == 'With Fee'">
      <div class="col-md-4">
        <mat-form-field>
          <input [(ngModel)]="servicePrice" autocomplete="off" formControlName="servicePrice"
                 type="number" matInput min="0" value="0"
                 placeholder="Service Price($)" step=".01">
          <mat-error *ngIf="addService.controls.servicePrice.errors != null && !formValid">
            Please enter service price
          </mat-error>
        </mat-form-field>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">{{ closeBtnName }}</button>
  <button type="button" class="btn btn-primary" (click)="createService()">Create</button>
</div>
