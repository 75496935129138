import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-reshedule-appoinment-info',
  templateUrl: './reshedule-appoinment-info.component.html',
  styleUrls: ['./reshedule-appoinment-info.component.css']
})
export class ResheduleAppoinmentInfoComponent implements OnInit {
  public eventId : Number;
  closeBtnName: string
  header: string;
  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit() {}

}