<div class="modal-content">
  <div *ngIf="confirmTitle" class="modal-header">
    <h5 class="modal-title">{{confirmTitle}}</h5>
    <button type="button" class="close" aria-label="Close" (click)="triggerEvent(false)">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    {{confirmMessage}}
  </div>
  <div class="modal-footer">
    <button class="btn btn-danger" (click)="triggerEvent(false)">No</button>
    <button class="btn btn-info" (click)="triggerEvent(true)" >Yes</button>
  </div>
</div>