<div class="modal-header">
  <h4 class="modal-title pull-left">{{header}}</h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form class="client-modal" [formGroup]="addDependentForm">
    <div class="form-row">
      <div class="form-group col-md-6">
        <mat-form-field>                                                                                            
          <mat-label>Relationship</mat-label>
          <mat-select (selectionChange)="updateValidators()" formControlName="relationShip"  [(ngModel)]="relationShip">
            <mat-option *ngFor="let relation of dependentRelationship" [value]="relation.code">{{relation.name}}</mat-option>
          </mat-select>
        <mat-error *ngIf="addDependentForm.controls.relationShip.errors != null && !formValid">
          Please select relationship
        </mat-error>
      </mat-form-field>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-md-6">
        <!-- <label for="inputEmail4">Email</label> -->
        <!-- <input type="email" class="form-control" id="inputEmail4" placeholder="Email"> -->
        <mat-form-field>
            <input autocomplete="off" type="email"  formControlName="email" matInput placeholder="Email">
            <mat-error *ngIf="addDependentForm.controls.email.errors != null && 
            addDependentForm.controls.email.errors.email != null">
              Please enter a valid email address
            </mat-error>
            <mat-error *ngIf="addDependentForm.controls.email.errors != null && 
            addDependentForm.controls.email.errors.required != null">
              Please enter email address
            </mat-error>
        </mat-form-field>
      </div>
      <div class="form-group col-md-6">
        <mat-form-field>
          <ngx-mat-intl-tel-input (countryChanged)="countryChange($event)"   (focusout)="phoneNumberFieldFocusOut($event)"
          [preferredCountries]="['us', 've']" [enablePlaceholder]="true" [enableSearch]="true" name="phone" format="national"
                   formControlName="phone">
                  </ngx-mat-intl-tel-input>
                  <mat-error *ngIf="addDependentForm.controls.phone.errors?.required">Please enter valid mobile number</mat-error>
                  <mat-error *ngIf="addDependentForm.controls.phone.errors?.validatePhoneNumber">Please enter valid mobile number</mat-error>
                  <mat-error *ngIf="addDependentForm.controls.phone.errors?.maxlength">Please enter valid mobile number</mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="form-row">
        <div class="form-group col-md-6">
            <mat-form-field>
                <input autocomplete="off" type="text" formControlName="firstName" matInput placeholder="First Name">
                <mat-error *ngIf="addDependentForm.controls.firstName.errors != null && !formValid">
                  Please enter first name
                </mat-error>
            </mat-form-field>
           
        </div>
        <div class="form-group col-md-6">
            <mat-form-field>
                <input autocomplete="off" type="text" formControlName="lastName" matInput placeholder="Last Name">
                <mat-error *ngIf="addDependentForm.controls.lastName.errors != null && !formValid">
                    Please enter last name
                  </mat-error>
            </mat-form-field>
        </div>
    </div>
    <div class="form-row">
      <div class="form-group col-md-6">
        <mat-form-field>
          <input  matInput autocomplete="off" readonly formControlName="birthday"
             [(ngModel)]="birthDate" name="DateOfBirth"
            id="DateOfBirth" [matDatepicker]="picker" placeholder="DOB">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
          <mat-error *ngIf="addDependentForm.controls.birthday.errors != null && !formValid">
            Please enter date of birth
          </mat-error>
        </mat-form-field>
      </div>
      <div class="form-group col-md-6">
        <mat-form-field>
          <mat-label>Gender</mat-label>
          <mat-select formControlName="gender" >
            <mat-option *ngFor="let g of gender" [value]="g.code">{{g.name}}
            </mat-option>
            
          </mat-select>
          <mat-error *ngIf="addDependentForm.controls.gender.errors != null && !formValid">
            Please select gender
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="form-row" >
      <div class="form-group col-md-6">
        <mat-form-field>                                                                                            
          <mat-label>ID Type</mat-label>
          <mat-select formControlName="idType" (selectionChange)="updateIDValidators()" [(ngModel)]="dependentIDTypeVal">
            <mat-option *ngFor="let idType of dependentIDType" [value]="idType.code">{{idType.name}}</mat-option>
          </mat-select>
        <mat-error *ngIf="addDependentForm.controls.idType.errors != null && !formValid">
          Please select ID Type
        </mat-error>
      </mat-form-field>
      </div>
      
      <div class="form-group col-md-6" *ngIf="dependentIDTypeVal == 'SSN'">
        <mat-form-field>
          <input type="text" maxlength="11" [(ngModel)]="hiddenSSN" [hiddenInput]="true" mask="XXX-XX-0000"
            autocomplete="off" formControlName="ssn" matInput placeholder="SSN">

          <input type="hidden" [(ngModel)]="hiddenSSN" formControlName="actualSSN">
          <mat-error *ngIf="addDependentForm.controls.ssn.errors != null && 
          addDependentForm.controls.ssn.errors.mask != null">
            Please enter valid SSN
          </mat-error>

        </mat-form-field>
      </div>

      <div class="form-group col-md-6" *ngIf="dependentIDTypeVal == 'ITIN Number'">
        <mat-form-field>
          <input type="text" prefix="9" mask="00-00-0000" [showMaskTyped]="true" autocomplete="off" maxlength="14" formControlName="itinNumber"
            matInput placeholder="ITIN Number">
          <mat-error *ngIf="addDependentForm.controls.itinNumber.errors != null && 
          addDependentForm.controls.itinNumber.errors.mask != null">
            Please enter valid ITIN Number
          </mat-error>
          <mat-error *ngIf="addDependentForm.controls.itinNumber.errors != null && 
          addDependentForm.controls.itinNumber.errors.required != null">
            Please enter ITIN Number
          </mat-error>
        </mat-form-field>
      </div>
    
    </div>
    <div class="form-row" >
      <div class="form-group col-md-6" *ngIf="relationShip === 'Spouse'">
        <mat-form-field>
          <input readonly matInput formControlName="marriageAnniversaryDate" [matDatepicker]="annivDate"
           placeholder="Marriage Anniversary Date">
          <mat-datepicker-toggle matSuffix [for]="annivDate"></mat-datepicker-toggle>
          <mat-datepicker #annivDate></mat-datepicker>
        </mat-form-field>
      </div>
     
    </div>

    
</form>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">{{closeBtnName}}</button>
  <button type="button" class="btn btn-primary" (click)="addDependent()">Create</button>
</div>