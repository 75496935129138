import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../services/auth.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ForgotPasswordComponent } from '../feature/modal/forgot-password/forgot-password.component';

@Component({
  selector: 'lost-password',
  templateUrl: './lost-password.component.html',
  styleUrls: ['../login/login.component.css', './lost-password.component.css']
})
export class LostPasswordComponent implements OnInit {
  lostPassForm: FormGroup;
  isSubmitted = false;
  modalRef: BsModalRef;
  email: string = null;

  constructor(private toast: ToastrService, private _authService: AuthService, private router: Router,
    private formBuilder: FormBuilder,private modalService: BsModalService) { }

  ngOnInit() {
    this.lostPassForm = this.formBuilder.group({
      emailId: ['', Validators.required]
    });
  }

  forgotPassword() {
    if (!!this.email) {
      this._authService.forgotPassword(this.email).subscribe((res: any) => {
        if (!!res) {
          this.modalRef = this.modalService.show(ForgotPasswordComponent, { });
          this.modalRef.content.event.subscribe(data => {
            this.backToLogin();
          });
        } else {
          this.toast.warning("Error Changing Password", "Error!");
        }
      }, (err) => {
        this.toast.error(err);
      });
    } else {
      this.isSubmitted = true;
      this.toast.warning("Enter your Email address first, please!", "Error!");
    }
  }

  backToLogin() {
    this.router.navigateByUrl("login");
  }
}
