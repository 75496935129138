import { Component, OnInit, ViewChild, AfterViewInit, TemplateRef } from '@angular/core';
import { TaskService } from 'src/app/services/tasks.service';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { TaskAddComponent } from './add/taskadd.component';
import { ToastrService } from 'ngx-toastr';
import { StaffService } from 'src/app/services/staff.service';
import { OfficeService } from 'src/app/services/office.service';


@Component({
    selector: 'app-tasks',
    templateUrl: './tasks.component.html',
    styleUrls: ['./tasks.component.css'],

    animations: [
        trigger('detailExpand', [
            state('*', style({ height: '0px', minHeight: '0' })),
            state('collapsed', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
            state('expanded', style({ height: '*', visibility: 'visible' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
        ]),
    ]
})
export class TaskComponent implements OnInit {
    taskList: any = [];
    public displayedColumns: string[] = ['Edit', 'Delete', 'OfficeName', 'OriginatorName', 'AssigneeName', 'Status', 'Priority', 'DueOn', 'Category', 'Subject', 'DueStatus'];
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort,{static: false}) sort: MatSort;
    public dataSource;
    expandedElement: any | null = null;
    showData: boolean = false;
    role: string;
    modalRef: BsModalRef;
    modalTask: any;
    viewAll: boolean = false;
    employees: any[] = [];
    officeList: any = [];

    selectedOffice: string;

    constructor(private _TaskService: TaskService, private _officeService: OfficeService, private _router: Router, private modalService: BsModalService, private toast: ToastrService, private _staffService: StaffService) { }

    ngOnInit() {
        this.getStaffList();
        this.role = localStorage.getItem('Role');
        this.getTasksList();
        this.getOfficeList();
        this.selectedOffice = localStorage.getItem("OfficeSoid");
    }


    private getOfficeList() {    
        this._officeService.getAccessOfficeList(localStorage.getItem("OfficeSoid")).subscribe((res: any) => {
          if (res) {
            this.officeList = res.Office;        
          }
        });
      }

    private getStaffList() {
        this._staffService.getActiveStaffs().subscribe((res: any) => {
          if (res) {    
            this.employees = res;
          }
        });
      }

    applyFilter(filterValue: string) {
        filterValue = filterValue.trim();
        filterValue = filterValue.toLowerCase();
        this.dataSource.filter = filterValue;
    }

    private async getTasksList() {
        await this._TaskService.getUserTasksList(localStorage.getItem("UserSoid")).subscribe((res: any) => {
            if (res) {
                this.taskList = res;
                var fields = Object.assign([], this.taskList);
                var mapResult = fields.map(el => {
                    return { ...el, Priority: this.getPrValue(el.Priority), DueStatus: this.isOverDue(el) }
                });
                this.dataSource = new MatTableDataSource<any>(mapResult);  
                setTimeout(() => {
                    this.dataSource.sort = this.sort;
                }, 500);             
                
                this.dataSource.paginator = this.paginator;
                this.showData = true;
            } else {

            }
        });
    }

    public OnEditButtonClick(task: any) {
        this._router.navigate(['task/' + task.Soid]);
    }

    openModal(template: TemplateRef<any>, item: any) {
        this.modalRef = this.modalService.show(template);
        this.modalTask = item;
    }

    public async OnOkDeleteButtonClick() {
        await this.deleteTask(this.modalTask);
        this.modalRef.hide()
    }

    public deleteTask(task: any) {
        this._TaskService.deleteTask(task.Soid).subscribe((res: any) => {
            this.getTasksList();
            this.toast.success("Deleted Successfully.", "Success!");
        });
    }

    public toggleRow(element: any): void {
        this.expandedElement = this.expandedElement === element ? null : element;
    }

    public getPrValue(priority: Number): string {
        if (priority == 0) {
            return "Low";
        }
        else if (priority == 1) {
            return "Normal";
        }
        else if (priority == 2) {
            return "High";
        }
    }

    public OnViewAllButtonClick() {
        this.viewAll = true;
        this._TaskService.getAllTasks().subscribe((res: any) => {
            if (res) {
                this.taskList = res;
                var fields = Object.assign([], this.taskList);
                var mapResult = fields.map(el => {
                    return { ...el, Priority: this.getPrValue(el.Priority), DueStatus: this.isOverDue(el) }
                });
                this.dataSource = new MatTableDataSource<any>(mapResult);
                setTimeout(() => {
                    this.dataSource.sort = this.sort;
                }, 500);  
                this.dataSource.paginator = this.paginator;
                this.showData = true;
            } else {

            }
        });
    }


    

    public OnAddTaskButtonClick() {
        const initialState = {
            title: "Add Task",
            backdrop: true,
            ignoreBackdropClick: true,
            closeBtnName: "Close",
            employees: this.employees
        };

        this.modalRef = this.modalService.show(TaskAddComponent, { initialState, class: 'modal-lg' });
        this.modalRef.onHide.subscribe(() => {
            this.getTasksList();
        });
    }

    public setColor(item: any): string {
        let currentDate = new Date();
        let appoinmentDate = new Date(item.DueOn);

        if (currentDate > appoinmentDate && (item.Status == "New" || item.Status == "In Progress")) {
            return "overDueTask";
        } else if (item.Status == "Rejected") {
            return "overDueTask";
        } else if (item.Status == "Complete") {
            return "complete";
        }
    }

    public setPriorityColor(item): string {
        if (item.Priority == "Low") {
            return "low-priority";
        } else if (item.Priority == "Normal") {
            return "normal-priority";
        } else if (item.Priority == "High") {
            return "high-priority";
        }
    }

    public OnBackButtonClick() {
        this.viewAll = false;
        this.getTasksList();
    }

    public isOverDue(item): string {
        let currentDate = new Date();
        let appoinmentDate = new Date(item.DueOn);

        if (currentDate > appoinmentDate && (item.Status == "New" || item.Status == "In Progress")) {
            return "OverDue";
        }
    }

    // public getDuration(item) {
    //     var inProgressDate = item.Changes.filter(c => c.OldValue == "New" && c.NewValue == "In Progress")[0].CreatedOn;
    //     console.log(inProgressDate);
    //     var startDate = new Date(inProgressDate);
    //     console.log(startDate);
    //     var currentDate = new Date();
    //     var seconds = Math.floor((currentDate.getTime() - (startDate.getTime())) / 1000);
    //     var minutes = Math.floor(seconds / 60);
    //     var hours = Math.floor(minutes / 60);
    //     var days = Math.floor(hours / 24);

    //     hours = hours - (days * 24);
    //     minutes = minutes - (days * 24 * 60) - (hours * 60);
    //     seconds = seconds - (days * 24 * 60 * 60) - (hours * 60 * 60) - (minutes * 60);

    //     console.log(hours);
    //     console.log(minutes);
    //     console.log(seconds);
    // }

    // public getTotalDuration(item){
    //     //1:11:17
    //     var inProgressDate = item.Changes.filter(c => c.OldValue == "New" && c.NewValue == "In Progress")[0].CreatedOn;
    //     var startDate = new Date(inProgressDate);
    //     var completedDate = item.Changes.filter(c => c.OldValue == "In Progress" && c.NewValue == "Complete")[0].CreatedOn;
    //     var endDate = new Date(completedDate);
    //     var seconds = Math.floor((completedDate - inProgressDate) / 1000);
    //     var minutes = Math.floor(seconds / 60);
    //     var hours = Math.floor(minutes / 60);
    //     var days = Math.floor(hours / 24);

    //     hours = hours - (days * 24);
    //     minutes = minutes - (days * 24 * 60) - (hours * 60);
    //     seconds = seconds - (days * 24 * 60 * 60) - (hours * 60 * 60) - (minutes * 60);

    //     console.log(hours);
    //     console.log(minutes);
    //     console.log(seconds);
    // }
}