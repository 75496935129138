<div class="section-container">
    <div class="subheader">
        <h2>{{serviceInfo.Item}}</h2>
    </div>
    <div class="white-bg rounded border-0">
    <div class="row top-menu bg-light bg-grey no-gutters">
        <div class="col-md-6"> 
            <div class="top-menu-container">
              <a class="btn-link btn-icon-back" (click)="goBack()"><i class="fa fa-chevron-left"></i></a>

              <a (click)="createService()" class="btn btn-primary">Save</a>
              <a (click)="getServiceInfo()" class="btn btn-secondary">Discard Changes</a>
            </div>
        </div>
    </div>
      <div class="container emp-profile">
        <form class="client-modal" [formGroup]="addService">
          <div class="form-row">
            <div class="col-md-4"> 
              <mat-form-field>
                <mat-label>Office</mat-label>
                <mat-select [(ngModel)]="selectedOffices" formControlName="office" (selectionChange)="officeChange()" multiple>
                  <mat-option *ngFor="let office of officeList" [value]="office.Soid">
                    {{office.CompanyName}}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="addService.controls.office.errors != null && !formValid">
                  Please select office
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field>
                <mat-label>Module</mat-label>
                <mat-select [(ngModel)]="selectedModule" formControlName="module">
                  <mat-option *ngFor="let module of modules" [value]="module">
                    {{module.Name}}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="addService.controls.module.errors != null && !formValid">
                  Please select module
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field>
                <input type="input" [(ngModel)]="serviceName" formControlName="serviceText" matInput placeholder="Service Name" >
                <mat-error *ngIf="addService.controls.serviceText.errors != null && !formValid">
                  Please enter service name
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <h6>Duration</h6>
          <div class="form-row">
            <div class="col-md-3">
              <mat-form-field>
                <mat-select placeholder="Hours" [(ngModel)]="durationHour" formControlName="durationHour">
                    <mat-option value="0">None</mat-option>
                  <mat-option value="1">1 Hour</mat-option>
                  <mat-option value="2">2 Hour</mat-option>
                  <mat-option value="3">3 Hour</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-3">
                <mat-form-field>
                  <mat-select placeholder="Minutes" [(ngModel)]="durationMinutes" formControlName="durationMinutes">
                      <mat-option value="0">None</mat-option>
                    <mat-option value="15">15 minutes</mat-option>
                    <mat-option value="30">30 minutes</mat-option>
                    <mat-option value="45">45 minutes</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
          </div>
          <h6>Choose Fee Type</h6>
          <div class="form-row">
            <div class="col-md-3">
              <button class="btn btn-outline-info btn-location" (click)="showFeeField('With Fee')" [ngClass]="selectedFee == 'With Fee' ? 'active' : ''">
                <i class="fas fa-money-check"></i>
                With Fee
              </button>
            </div>
            <div class="col-md-3">
                <button class="btn btn-outline-info btn-location" (click)="showFeeField('No Fee')" [ngClass]="selectedFee == 'No Fee' ? 'active' : ''">
                  No Fee
                </button>
              </div>
              <div class="col-md-3">
                <button class="btn btn-outline-info btn-location" (click)="showFeeField('Flexi Fee')" [ngClass]="selectedFee == 'Flexi Fee' ? 'active' : ''">
                  Flexi Fee
                </button>
              </div>
      
          </div>
          <div class="form-row" *ngIf="selectedFee == 'With Fee'">
            <div class="col-md-4">
              <mat-form-field>
                  <input [(ngModel)]="servicePrice" formControlName="servicePrice" type="text" matInput placeholder="Service Price($)">
                  <mat-error *ngIf="addService.controls.servicePrice.errors != null && !formValid">
                      Please enter service price
                  </mat-error>
              </mat-form-field>
            </div>
          </div>
        </form>
               
      </div>
    </div>
  </div>
  