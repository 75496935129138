import { Component, OnInit, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ServicesListService } from '../../../services/services.service';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject } from 'rxjs';
import { StaffService } from '../../../services/staff.service';
import { DocumentsService } from '../../../services/documents.service';

@Component({
  selector: 'app-add-client-documents',
  templateUrl: './add-client-documents.component.html',
  styleUrls: ['./add-client-documents.component.css']
})
export class AddClientDocumentsComponent implements OnInit {


  title: string = "Add Client Document";
  selectCallType: string;
  closeBtnName: string = "Close";
  selectedTab: string = "tab1";
  addClientDocumentForm: FormGroup;
  feeField: boolean = true;
  serviceName: string;
  uploadType: string = "MY DOCUMENTS";
  phoneType: string;
  phoneValue: string;
  onlineType: string;
  onlineValue: string;
  faceType: string;
  faceValue: string;
  durationHour: string;
  durationMinutes: string;
  servicePrice: string;
  currency: string;
  module: string;
  formValid: boolean = true;
  locationModeObserver: Subject<string> = new Subject<string>();
  priceChangeObserver: Subject<string> = new Subject<string>();
  subscriptionServices: any = [];
  public event: EventEmitter<any> = new EventEmitter();
  public clientInfo: any = [];
  staffList: any = [];
  myDocumentsList: any = [];
  @ViewChild('labelImport')
  labelImport: ElementRef;
  staffAssigned: string;

  formImport: FormGroup;
  fileToUpload: File = null;



  onFileChange(files: FileList) {
    this.labelImport.nativeElement.innerText = Array.from(files)
      .map(f => f.name)
      .join(', ');
    this.fileToUpload = files.item(0);
  }


  constructor(private toast: ToastrService, private _service: ServicesListService,
    private _documentService: DocumentsService,
    public bsModalRef: BsModalRef, sb: FormBuilder,
    private _staffService: StaffService,) {
  }

  ngOnInit() {
    this.getStaffList();
    this.getMyDocumentList();

    this.addClientDocumentForm = new FormGroup({
      staffSelection: new FormControl('', []),
      documentTitle: new FormControl(''),
      documentDescription: new FormControl(''),
      myDocumentFiles: new FormControl('', [Validators.required]),
      importFile: new FormControl(),
      addToMyDocuments: new FormControl(),
    });

    this.staffAssigned = localStorage.getItem("UserSoid");

  }

  private readBase64(file): Promise<any> {
    const reader = new FileReader();
    const future = new Promise((resolve, reject) => {
      reader.addEventListener('load', function () {
        resolve(reader.result);
      }, false);
      reader.addEventListener('error', function (event) {
        reject(event);
      }, false);

      reader.readAsDataURL(file);
    });
    return future;
    }


  createNewDocument() {
    for (let inner in this.addClientDocumentForm.controls) {
      this.addClientDocumentForm.get(inner).markAsTouched();
      this.addClientDocumentForm.get(inner).updateValueAndValidity();
    }
    if (!this.addClientDocumentForm.valid) {
      this.formValid = false;
      this.toast.warning("Fill the required fields.", "Warning!");
      return;
    }

    if (this.uploadType === "MY DOCUMENTS") {
      this.createDocument();
    } else {
    this.readBase64(this.fileToUpload)
    .then((data) => {
      this.createDocument(data);
    });
  }


  }

  private createDocument(file?) {
    const testData = new FormData();
    testData.append('UploadType', this.uploadType);

    if (this.uploadType === "MY DOCUMENTS") {
      testData.append('MyDocumentsSoid', this.addClientDocumentForm.controls.myDocumentFiles.value);
      testData.append('AddToMyDocuments', "false");

      var myDocument = this.myDocumentsList.filter(m => m.Soid == this.addClientDocumentForm.controls.myDocumentFiles.value);
      if (myDocument && myDocument[0] != null) {
        selectedStaff = myDocument[0];
        testData.append("DocumentTitle", myDocument[0].DocumentTitle);
        testData.append("DocumentDescription", myDocument[0].DocumentDescription ? myDocument[0].DocumentDescription : "");
      }

    } else {
      testData.append('FileContent', file);
      testData.append('FileName', this.fileToUpload.name);
      testData.append('Extension', this.fileToUpload.type);
      testData.append('Size', this.formatBytes(this.fileToUpload.size));
      testData.append('AddToMyDocuments', this.addClientDocumentForm.controls.addToMyDocuments.value);
      testData.append("DocumentTitle", this.addClientDocumentForm.controls.documentTitle.value);
    testData.append("DocumentDescription", this.addClientDocumentForm.controls.documentDescription.value
     ? this.addClientDocumentForm.controls.documentDescription.value : "");
    }


    let userFullName = localStorage.getItem("NameFirst") + " " + localStorage.getItem("NameLast");
    var selectedStaff : any = {};

    var staff = this.staffList.filter(staffInfo => staffInfo.PersonSoid == this.staffAssigned);
    if (staff && staff[0] != null) {
      selectedStaff = staff[0];
    }

   // let selectedStaff = this.addClientDocumentForm.controls.staffSelection.value;

    testData.append("UserName", userFullName);
    testData.append("UserSoid", localStorage.getItem("UserSoid"));
    testData.append("OwnerSoid", this.clientInfo.Soid);
    testData.append("OwnerName", this.clientInfo.NameFirst + " " + this.clientInfo.NameLast);
    testData.append("StaffSoid", selectedStaff.Soid);
    testData.append("StaffName", selectedStaff.NameFirst + " " + selectedStaff.NameLast);
    testData.append("OwnerType", "Client");
    testData.append("DocumentType", "CLIENT");
    testData.append("OfficeSoid", localStorage.getItem("OfficeSoid"));

    this._documentService.createNewDocument(testData).subscribe((res: any) => {
      if (res) {
        this.toast.success("Document created successfully.", "Success!");
        this.triggerEvent();
        this.bsModalRef.hide();
      } else {
        this.toast.warning("Error Creating service", "Error!");
      }
    });
  }

  triggerEvent() {
    this.event.emit();
  }

  private getStaffList() {
    this._staffService.getStaffOptions().subscribe((res: any) => {
      if (res) {
        this.staffList = res;
      } else {
        this.staffList = [];
      }
    });
  }

  private getMyDocumentList() {
    this._documentService.myDocumentSelectList().subscribe((res: any) => {
      if (res) {
        this.myDocumentsList = res;
      }
    }, (error) => console.log(error));
  }

  updateValidators() {
    const documentTitle = this.addClientDocumentForm.controls.documentTitle;
    const myDocumentFiles = this.addClientDocumentForm.controls.myDocumentFiles;
    const importFile = this.addClientDocumentForm.controls.importFile;



    documentTitle.setValidators(null);
    myDocumentFiles.setValidators(null);
    importFile.setValidators(null);
    if (this.uploadType === 'MY DOCUMENTS') {
      myDocumentFiles.setValidators([Validators.required]);
    } else  {
      documentTitle.setValidators([Validators.required]);
      importFile.setValidators([Validators.required]);
    }
  }

  chosenTab(tab, locationMode) {
    this.locationModeObserver.next(locationMode);
    this.uploadType = locationMode;
    this.updateValidators();
    return this.selectedTab = tab;
  }

  formatBytes(bytes) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const decimals = 2;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}
}
