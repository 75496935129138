import { Component, Input, OnInit } from "@angular/core";

@Component({
    selector: 'task-view',
    templateUrl: './taskview.component.html',
    styleUrls: ['./taskview.component.css']
})
export class TaskViewComponent implements OnInit {
    @Input() data: any;
    nextStepBy: string;

    ngOnInit() {
        if (localStorage.getItem('Employees')) {
            var employees = JSON.parse(localStorage.getItem('Employees'));
        this.nextStepBy = employees.find(x => x.PersonSoid == this.data.NextStepBy).PersonName;
        }
        
     }

     public setPriorityColor(item): string{
        if(item.Priority == "Low"){
            return "low-priority";
        }else if(item.Priority == "Normal"){
            return "normal-priority";
        }else if(item.Priority == "High"){
            return "high-priority";
        }
    }

}