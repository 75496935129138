import { Injectable } from '@angular/core';
import { Api } from './api';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class DocumentsService {

  constructor(public api: Api) { }

  getDocumentsList(officeSoid) {

    let seq = this.api.get('FileUpload/Office/' + officeSoid );
    return seq;
  }

  getDocumentsByCustomer(customerId) {
    let seq = this.api.get('FileUpload/' + customerId + '/GetByCustomer' );
    return seq;
  }

  getMyDocumentsList() {
    let seq = this.api.get('FileUpload/MyDocuments');
    return seq;
  }

  myDocumentSelectList() {
    let seq = this.api.get('FileUpload/ListMyDocuments');
    return seq;
  }

  getDocumentBySoid(fileUploadSoid) {
    let seq = this.api.get('FileUpload/' + fileUploadSoid);
    return seq;
  }

  createNewDocument(data) {
    //let seq = this.api.postFormdata('Ajax/UploadCustomerFile', data);
    let seq = this.api.postFormdata('Ajax/UploadCustomerFile', data);
    return seq;
  }

  downloadFile(data) {
    //let seq = this.api.postFormdata('Ajax/UploadCustomerFile', data);
    let seq = this.api.ajaxPost('Ajax/DownloadUploadedFile', data);
    return seq;
  }

  updateFileUpload(data) {
    let seq = this.api.patch('FileUpload/Update', data);
    return seq;
  }

  inactiveFileUpload(fileUploadSoid) {
    let seq = this.api.delete('FileUpload/InActive/'+ fileUploadSoid);
    return seq;
  }

  sendCustomerDocumentByEmail(documentSoid: string): Observable<any> {
    return this.api.post(`FileUpload/SendCustomerDocument/${documentSoid}`, {});
  }
}

