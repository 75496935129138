
<div class="modal-header">
  <h4 class="modal-title pull-left">{{header}}</h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form class="client-modal" [formGroup]="clientForm">
    <div class="form-row">
      <div style="float: left; width: 50px; height:50px;">
          <ngx-avatar size="40" name="{{clientInfo.firstName}} {{clientInfo.lastName}}"></ngx-avatar>  
      </div>
      <div style="float: left; width: 80%;">
          <div class="client-list-left">
            <h6 class="mt-0 mb-1">{{clientInfo.firstName}} {{clientInfo.lastName}}</h6>
            <span><i class="fas fa-at"></i>{{clientInfo.Email}}</span>
          </div>
      </div>
    </div>
    <br/>
    <div class="form-row">
        <h6>Details</h6>
        <mat-form-field>
            <input type="text" formControlName="appoinmentTitle" matInput placeholder="Appoinment title*">
        </mat-form-field>
    </div>
    <div class="form-row">
        <div class="col-md-6">
            <mat-form-field>
              <mat-select placeholder="Type" (selectionChange)="typeChange()" formControlName="type" [(value)]="selectedDetailType">              
                <mat-option value="Phone - client calls you">Phone - client calls you</mat-option>
                <mat-option value="In Person - at your location">In Person - at your location</mat-option>
                <mat-option value="Phone - you call the client">Phone - you call the client</mat-option>
                <mat-option value="In Person- at Client's location">In Person- at Client's location</mat-option>
              </mat-select>
              <!-- <mat-error *ngIf="eventForm.controls.detailsType.errors != null && !formValid">
                  Please select type
                </mat-error> -->
            </mat-form-field>
        </div>
        <div class="col-md-6">
          <div *ngIf="showAddress;else meeting_location">
              <app-google-places adressType="address"></app-google-places>
          </div>
          <ng-template #meeting_location>
              <mat-form-field class="example-full-width">
                  <span matPrefix>+1 &nbsp;</span>            
                  <input type="input" [(ngModel)]="meetingLocation" #meetingLoc  formControlName="meetingLocation" matInput placeholder="Where" [disabled]="isEditable">
                  
                  <!-- <mat-error *ngIf="eventForm.controls.meetingLocation.errors != null && !formValid">
                      Please enter contact number
                    </mat-error> -->
              </mat-form-field>
          </ng-template>
        </div>
    </div>
   </form> 
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary" (click)="save()">{{closeBtnName}}</button>
</div>