import { Component, OnInit, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'confirm-dialog',
  templateUrl: 'confirmation-dialog.html',
})
export class ConfirmationDialog implements OnInit {


  formValid: boolean = true;
  message:string;
  public eventId : Number;
  public status : string;
  header: string;
  closeBtnName: string;

  public event: EventEmitter<any> = new EventEmitter();

  constructor(public bsModalRef: BsModalRef) { }


  public confirmTitle:string;
  public confirmMessage:string;

  ngOnInit() {
   
  }

  triggerEvent(status) {
    this.event.emit(status);
    this.bsModalRef.hide();
  }
}