import { Component, OnInit, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ServicesListService } from '../../../services/services.service';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject } from 'rxjs';
import { StaffService } from '../../../services/staff.service';
import { DocumentsService } from '../../../services/documents.service';
import { InvoiceService } from '../../../services/invoice.service';
import { Router } from '@angular/router';
import { ClientService } from '../../../services/client.service';
import { ConfirmationDialog } from '../confirmation-dialog/confirmation-dialog.component';
import _ from 'lodash';

@Component({
  selector: 'app-service-estimate',
  templateUrl: './service-estimate.component.html',
  styleUrls: ['./service-estimate.component.css']
})
export class ServiceEstimateComponent implements OnInit {

  billingToList: any = [];
  title: string = "Estimate";
  selectCallType: string;
  closeBtnName: string = "Close";
  selectedTab: string = "tab1"; //Initial tab active
  paymentTypes: Array<string> = ['USD', 'POUNDS', 'TINAR', 'BHAT'];
  serviceEstimateForm: FormGroup;
  feeField: boolean = true;
  serviceName: string;
  uploadType: string = "MY DOCUMENTS";
  phoneType: string;
  phoneValue: string;
  onlineType: string;
  onlineValue: string;
  faceType: string;
  faceValue: string;
  durationHour: string;
  durationMinutes: string;
  billingToSelected: any = {};
  servicePrice: string;
  currency: string;
  module: string;
  formValid: boolean = true;
  locationModeObserver: Subject<string> = new Subject<string>();
  priceChangeObserver: Subject<string> = new Subject<string>();
  subscriptionServices: any = [];
  public event: EventEmitter<any> = new EventEmitter();
  public clientInfo: any = [];
  staffList: any = [];
  myDocumentsList: any = [];
  @ViewChild('labelImport')
  labelImport: ElementRef;
  agelimit: number = 10;
  invoicePreListItems: any = {};
  formImport: FormGroup;
  fileToUpload: File = null;
  InvoicePriceListItemQuantity: number  = 0;
  invoiceServiceItems: any = [];
  invoiceSubTotal: number = 0.00;
  invoiceTotal: number = 0.00;
  invoiceQuantity: number = 1;
  serviceList: any = [];
  invoiceServiceItem: any = {};  
  invoicePrice: number;
  changeUnitPrice: boolean = false;
  billToName: string;
  officeName: string;
  billingFromAddress: string;
  billingToAddress: string;
  estimateNo: string;
  clientBusiness: any = {};
  clientDependent: any = {};
  totalTax: number = 0.00;
  subTotal: number = 0.00;
  estimateQuantity: number;
  modalRef: BsModalRef;  
  taxRates: any = [];
  taxServiceItem: any = {};

  onFileChange(files: FileList) {
    this.labelImport.nativeElement.innerText = Array.from(files)
      .map(f => f.name)
      .join(', ');
    this.fileToUpload = files.item(0);
  }


  constructor(private toast: ToastrService, private _service: ServicesListService,
    private _documentService: DocumentsService,
    private _invoiceService: InvoiceService,private modalService: BsModalService,
    private _router: Router,
    private _serviceList: ServicesListService,    
    private _clientService: ClientService,
    public bsModalRef: BsModalRef, sb: FormBuilder,
    private _staffService: StaffService,) {
  }

  ngOnInit() {

    //this.getStaffList();
    this.getServiceList();
    this.getEstimateNumber();

    this.taxRates = JSON.parse(localStorage.getItem("XeroTaxRates"));

    this.taxServiceItem = this.getDefaultTaxRates();

    this.officeName = localStorage.getItem("OfficeName");
    this.billingFromAddress = localStorage.getItem("BillStreet") + ', ' + localStorage.getItem("BillingState") + ', ' +
       localStorage.getItem("BillingCountry") + ', ' + localStorage.getItem("BillingZip");

    this._clientService.getClientInfo(this.clientInfo.Soid).subscribe((res: any) => {
      if (res) {
        this.clientInfo = res;
        this.billingToAddress = "Address: " + (this.clientInfo.HomeAddress ? this.clientInfo.HomeAddress + ', ' : '')
          + (this.clientInfo.HomeAddressCity ? this.clientInfo.HomeAddressCity + ', ' : '')
          + (this.clientInfo.HomeAddressState ? this.clientInfo.HomeAddressState + ', ' : '')
          + (this.clientInfo.ZipCode ? this.clientInfo.ZipCode + ', ' : '')         
          + this.clientInfo.HomeAddressCountry;
        this.billToName = this.clientInfo.NameFirst + ' ' + this.clientInfo.NameLast; 

        var billTo: any = {};
        billTo.code = "Client";
        billTo.name = this.clientInfo.NameFirst + ' ' + this.clientInfo.NameLast + ' (Client)';
        this.billingToSelected.code = "Client";
        this.billingToList.push(billTo);
      

        // if (this.clientInfo.SpouseFirstName && this.clientInfo.SpouseLastName != "") {
        //   billTo = {};
        //   billTo.code = "Spouse";
        //   billTo.name = this.clientInfo.SpouseFirstName + ' ' + this.clientInfo.SpouseLastName + ' (Spouse)';
        //   this.billingToList.push(billTo);
        // }
        this.clientBusiness = this.clientInfo.BusinessInfo.filter(w => w.Status == "Active");
        
        this.clientBusiness.forEach(element => {
          billTo = {};
          billTo.code = "Business";
          billTo.name = element.CompanyName + ' (Business)';
          billTo.Soid = element.Soid;
          this.billingToList.push(billTo);
         // element.MarriageAnniversaryDate =  element.MarriageAnniversaryDate ? new Date(element.MarriageAnniversaryDate) : null;
        });

        
      }
    });

    this.serviceEstimateForm = new FormGroup({
      estimateLabel: new FormControl('', [Validators.required]),
      //currency: new FormControl('',[Validators.required]),
      estimateNumber: new FormControl('', [Validators.required]),
      purchaseOrder: new FormControl(),
      estimateDate: new FormControl('', [Validators.required]),
      expirationDate: new FormControl('', [Validators.required]),
      notes: new FormControl(),
      serviceItem: new FormControl(),      
      taxItem: new FormControl(),
      estimateQuantity: new FormControl(),
      serviceUnitPrice: new FormControl(),
      servicePrice: new FormControl(),
      billingToAddress: new FormControl(),
      billingFromAddress: new FormControl(),
      billingToSelect: new FormControl(),
    });

  }


  createNewDocument() {
    for (let inner in this.serviceEstimateForm.controls) {
      this.serviceEstimateForm.get(inner).markAsTouched();
      this.serviceEstimateForm.get(inner).updateValueAndValidity();
    }
    if (!this.serviceEstimateForm.valid) {
      this.formValid = false;
      this.toast.warning("Fill the required fields.", "Warning!");
      return;
    }

    var requestInfo: any = {};
    requestInfo.StaffSoid = localStorage.getItem("UserSoid");
    requestInfo.StaffName = localStorage.getItem("NameFirst") + " " + localStorage.getItem("NameLast");
    requestInfo.Label = this.serviceEstimateForm.controls.estimateLabel.value;
    requestInfo.EstimateNumber = this.serviceEstimateForm.controls.estimateNumber.value;
    requestInfo.EstimateDate = this.serviceEstimateForm.controls.estimateDate.value;
    requestInfo.ExpirationDate = this.serviceEstimateForm.controls.expirationDate.value;
    requestInfo.Notes = this.serviceEstimateForm.controls.notes.value;
    requestInfo.Items = this.invoiceServiceItems;

    requestInfo.BillingFromAddress = localStorage.getItem("BillStreet");
    requestInfo.BillingFromState = localStorage.getItem("BillingState");
    requestInfo.BillingFromZip = localStorage.getItem("BillingZip");
    requestInfo.BillingFromCountry = localStorage.getItem("BillingCountry");    

    //requestInfo.BusinessName = this.clientInfo.CompanyName;

    
    requestInfo.BillingToEmail = this.clientInfo.Email;
    requestInfo.BillingFromFirstName = localStorage.getItem("OfficeName");
    requestInfo.BillingFromLastName = "";
    requestInfo.BillingFromEmail = "";
    requestInfo.Status = "ISSUED";
    requestInfo.BillingToSoid = this.clientInfo.Soid;


     if (this.billingToSelected.code == "Client") {
      requestInfo.BillingToFirstName = this.clientInfo.NameFirst;
      requestInfo.BillingToLastName = this.clientInfo.NameLast;

      requestInfo.BillingToAddress = this.clientInfo.HomeAddress;
    requestInfo.BillingToState = this.clientInfo.HomeAddressState;
    requestInfo.BillingToZip = this.clientInfo.ZipCode;
    requestInfo.BillingToCountry = this.clientInfo.HomeAddressCountry;
    requestInfo.BillingToCity = this.clientInfo.HomeAddressCity;
    } else if (this.billingToSelected.code == "Business") {
      let billingTo = this.clientBusiness.filter(w => w.Soid == this.billingToSelected.Soid);
      requestInfo.BillingToFirstName = this.clientInfo.NameFirst;
      requestInfo.BillingToLastName = this.clientInfo.NameLast;
      requestInfo.BusinessName = billingTo[0].CompanyName;

      requestInfo.BillingToAddress = billingTo[0].Address;
      requestInfo.BillingToState = billingTo[0].State;
      requestInfo.BillingToZip = billingTo[0].ZipCode;
      requestInfo.BillingToCountry = billingTo[0].Country;
      requestInfo.BillingToCity = billingTo[0].City;

    }
    requestInfo.Taxes = this.totalTax;
    
    this._invoiceService.createEstimates(this.clientInfo.Soid, requestInfo).subscribe((res: any) => {
      if (res) {
        this.toast.success("Estimate created successfully.", "Success!");
        this._router.navigate(['/client/'+ this.clientInfo.Soid + '/estimate/' + res]);
        // if (res.Invoices.length > 0) {
        //   var invoiceLength = res.Estimates.length;
        //   var newInvoice = res.Estimates[invoiceLength - 1];
        //   this._router.navigate(['/client/'+ this.clientInfo.Soid + '/estimate/' + newInvoice.Soid]);
        // }
        //this.triggerEvent();
        
        this.bsModalRef.hide();
      } else {
        this.toast.warning("Error Creating service", "Error!");
      }
    });

  }

  triggerEvent() {
    this.event.emit();
  }

  private getStaffList() {
    this._staffService.getActiveStaffs().subscribe((res: any) => {
      if (res) {
        this.staffList = res;
      } else {
        this.staffList = [];
      }
    });
  }

  private getEstimateNumber() {
    this._invoiceService.getEstimateNumber().subscribe((res: any) => {
      if (res) {
        this.estimateNo = res;
      } 
    });
  }


  private getServiceList() {
    this._serviceList.getServicesList(localStorage.getItem("OfficeSoid")).subscribe((res: any) => {
      if (res) {
        this.serviceList = res;
      }
    });
  } 

  
  unitPriceUpdate(quantity, lineItem) {
  

    this.invoiceServiceItems
    .filter(item => {
      if (item.ListItemSoid == lineItem.ListItemSoid) {
        item.Quantity = quantity;
        item.Price = parseFloat(item.UnitPrice) * parseFloat(item.Quantity);
        if (item.TaxPercentage > 0) {
          item.TaxAmount = Number(Number(item.Price * (item.TaxPercentage/100)).toFixed(2));
        } else {
          item.TaxAmount = 0;
        }
          return true; // Include the modified object in the new array
      }
      return true; // Include all other objects in the new array without modification
  });
  this.calculateSubTotal();

  }

  serviceChange() {
    this.invoicePrice = this.invoiceServiceItem.UnitPrice * this.invoiceQuantity;
    this.changeUnitPrice = this.invoiceServiceItem.PriceType == 'Flexi Fee';
  }

  addInvoiceItem() {
    if (this.invoiceServiceItem.UnitPrice == undefined) {
      return;
    }


    this.invoicePreListItems = this.serviceEstimateForm.controls.serviceItem.value;
    if (this.invoicePreListItems != null && this.invoicePreListItems == "") return;

    var isInvoiceExists = false;
    this.invoiceServiceItems
    .filter(item => {
      // Check if the current object's ID matches the ID of the object to update
      if (item.ListItemSoid == this.invoicePreListItems.Soid) {
        item.Quantity = item.Quantity + this.serviceEstimateForm.controls.estimateQuantity.value;
        item.Price = parseFloat(item.UnitPrice) * parseFloat(item.Quantity);
        isInvoiceExists = true;
          return true; // Include the modified object in the new array
      }
      return true; // Include all other objects in the new array without modification
  });
    if (!isInvoiceExists) {
      var invoiceItem: any = {};
      invoiceItem.Item = this.invoicePreListItems.Item;
      invoiceItem.UnitPrice = this.invoicePreListItems.UnitPrice;
      invoiceItem.Quantity = this.serviceEstimateForm.controls.estimateQuantity.value;
      invoiceItem.Price = parseFloat(invoiceItem.UnitPrice) * parseFloat(invoiceItem.Quantity);
      invoiceItem.ModuleName = this.invoicePreListItems.ModuleName;
      invoiceItem.ListItemSoid = this.invoicePreListItems.Soid;
      invoiceItem.TaxType = this.taxServiceItem.TaxType;

      invoiceItem.TaxType = this.taxServiceItem.TaxType;
      if (this.taxServiceItem.EffectiveRate > 0) {
        invoiceItem.TaxAmount = Number(Number(invoiceItem.Price * (this.taxServiceItem.EffectiveRate/100)).toFixed(2));
      } else {
        invoiceItem.TaxAmount = 0;
      }
      

      this.invoiceServiceItems.push(invoiceItem);
    }
    
    this.calculateSubTotal();
    this.invoiceQuantity = 1;
    this.invoiceServiceItem = {};
    this.invoicePrice = null;
    
    this.taxServiceItem = this.getDefaultTaxRates();
  }

  deleteConfirm(item) {
    const initialState = {
      confirmMessage: "Are you sure to delete the invoice item?",
      backdrop: true,
      ignoreBackdropClick: true
    };
    this.modalRef = this.modalService.show(ConfirmationDialog, { initialState });
    this.modalRef.content.event.subscribe(data => {
      if (data) {
        this.deleteItem(item);
      }
    });

  }

  deleteItem(item) {
    const indexToRemove = this.invoiceServiceItems.findIndex(item => item.ListItemSoid === item.ListItemSoid);

    if (indexToRemove !== -1) {
        this.invoiceServiceItems.splice(indexToRemove, 1);
    }
    this.calculateSubTotal();
  }


  calculateSubTotal() {
    var subTotal = 0;
    this.invoiceServiceItems.forEach(element => {
      if (subTotal == 0) {
        subTotal = element.Price;
      } else {
        subTotal += element.Price;
      }
    });
    this.subTotal = subTotal;
    this.totalTax = _.sumBy(this.invoiceServiceItems, 'TaxAmount');
    this.invoiceTotal = this.totalTax + this.subTotal;
  }

  chosenTab(tab, locationMode) {
    this.locationModeObserver.next(locationMode);
    this.uploadType = locationMode;
    return this.selectedTab = tab;
  }

  formatBytes(bytes) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const decimals = 2;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

changeBillingTo() {

  if (this.billingToSelected.code == "Spouse") {
    this.billToName = this.clientInfo.SpouseFirstName + ' ' + this.clientInfo.SpouseLastName;      
    this.billingToAddress = "Address: " + (this.clientInfo.HomeAddress ? this.clientInfo.HomeAddress + ', ' : '')
        + (this.clientInfo.HomeAddressCity ? this.clientInfo.HomeAddressCity + ', ' : '')
        + (this.clientInfo.HomeAddressState ? this.clientInfo.HomeAddressState + ', ' : '')
        + (this.clientInfo.ZipCode ? this.clientInfo.ZipCode + ', ' : '')         
        + this.clientInfo.HomeAddressCountry;
  } else if (this.billingToSelected.code == "Client") {
    this.billToName = this.clientInfo.NameFirst + ' ' + this.clientInfo.NameLast; 
    this.billingToAddress = "Address: " + (this.clientInfo.HomeAddress ? this.clientInfo.HomeAddress + ', ' : '')
        + (this.clientInfo.HomeAddressCity ? this.clientInfo.HomeAddressCity + ', ' : '')
        + (this.clientInfo.HomeAddressState ? this.clientInfo.HomeAddressState + ', ' : '')
        + (this.clientInfo.ZipCode ? this.clientInfo.ZipCode + ', ' : '')         
        + this.clientInfo.HomeAddressCountry;
  } else if (this.billingToSelected.code == "Business") {
    this.billToName = this.clientInfo.CompanyName;
    this.billingToAddress = "Address: " + (this.clientInfo.BusinessAddress ? this.clientInfo.BusinessAddress + ', ' : '')
        + (this.clientInfo.BusinessCity ? this.clientInfo.BusinessCity + ', ' : '')
        + (this.clientInfo.BusinessState ? this.clientInfo.BusinessState + ', ' : '')
        + (this.clientInfo.BusinessZipCode ? this.clientInfo.BusinessZipCode + ', ' : '')         
        + this.clientInfo.BusinessAddressCountry;
  }

}

getDefaultTaxRates () {
  var taxItem = this.taxRates.filter(e => e.TaxType == 'NONE');

  if (taxItem && taxItem[0]) {
    return taxItem[0]
  } else {
    return this.taxRates[0];
  }
}
}
