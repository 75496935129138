<div class="modal-header">
  <h4 class="modal-title pull-left">{{title}}</h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <div class="custom-file">
    <label for="office-logo" class="custom-file-label">
      {{ officeLogoName || 'Office Logo' }}
    </label>
    <input
      id="office-logo"
      class="custom-file-input"
      type="file"
      accept="image/png, image/jpg, image/jpeg"
      (change)="handleOfficeLogoChange($event)"
    >
  </div>
  <form class="client-modal" [formGroup]="addOfficeForm">


    <div class="form-row">
      <div class="col-md-12">
        <mat-form-field>
          <input type="input" autocomplete="off" [(ngModel)]="companyName" formControlName="companyName" matInput
            placeholder="Company Name">
          <mat-error *ngIf="addOfficeForm.controls.companyName.errors != null && !formValid">
            Please enter company name
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="form-row">
      <div class="col-md-12">
        <mat-form-field>
          <input type="input" autocomplete="off" [(ngModel)]="contactName" formControlName="contactName" matInput
            placeholder="Contact Name">
          <mat-error *ngIf="addOfficeForm.controls.contactName.errors != null && !formValid">
            Please enter company name
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-md-6">
        <!-- <label for="inputEmail4">Email</label> -->
        <!-- <input type="email" class="form-control" id="inputEmail4" placeholder="Email"> -->
        <mat-form-field>
          <input type="email" autocomplete="off" [(ngModel)]="emailAddress" formControlName="emailAddress" matInput
            placeholder="Email">
          <mat-error *ngIf="addOfficeForm.controls.emailAddress.errors != null &&
            addOfficeForm.controls.emailAddress.errors.email != null">
            Please enter a valid email address
          </mat-error>
          <mat-error *ngIf="addOfficeForm.controls.emailAddress.errors != null &&
            addOfficeForm.controls.emailAddress.errors.required != null">
            Please enter email address
          </mat-error>
        </mat-form-field>
      </div>
      <div class="form-group col-md-6">
        <mat-form-field>
          <ngx-mat-intl-tel-input (countryChanged)="countryChange($event)" [preferredCountries]="['us', 've']"
            [enablePlaceholder]="true" [enableSearch]="true" (focusout)="phoneNumberFieldFocusOut($event)"
            format="national" formControlName="phoneNumber" placeholder="Mobile Number">
          </ngx-mat-intl-tel-input>
          <mat-error *ngIf="addOfficeForm.controls.phoneNumber.errors?.required">Please enter valid mobile
            number</mat-error>
          <mat-error *ngIf="addOfficeForm.controls.phoneNumber.errors?.validatePhoneNumber">Please enter valid mobile
            number</mat-error>
        </mat-form-field>
      </div>
      <div class="form-group col-md-6">
        <mat-form-field floatLabel="always" appearance="none">
          <mat-label>Parent Office</mat-label>
          <mat-slide-toggle ngDefaultControl [ngModelOptions]="{standalone: true}" [(ngModel)]="isParentOffice"></mat-slide-toggle>
          <textarea matInput hidden></textarea>
        </mat-form-field>


      </div>
      <div class="form-group col-md-6" *ngIf="isParentOffice">
        <mat-form-field>
          <mat-select placeholder="Office"  [formControl]="toppings" multiple>
            <mat-select-trigger>
              {{toppings.value && toppings.value[0] ? toppings.value[0].CompanyName : ''}}
              <span *ngIf="toppings.value?.length > 1" class="example-additional-selection">
                (+{{toppings.value.length - 1}} others)
              </span>
            </mat-select-trigger>
            <mat-option *ngFor="let topping of officeList" [value]="topping">{{topping.CompanyName}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>


    </div>
  </form>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">{{closeBtnName}}</button>
  <button type="button" class="btn btn-primary" (click)="createService()">Create</button>
</div>
