

<div class="section-container" >

  <div class="row" style="margin-right: 10px;">

    <div class="col-lg-6">
      <div class="subheader">
        <h2>Payments</h2>
    
        <!-- <h5>Plans and events</h5> -->
      </div>
      <div class="white-bg rounded border-0">
        <div class="row top-menu bg-light bg-grey no-gutters">
          <div class="col-md-2">
            <div class="top-menu-container">
              <button mat-flat-button [matMenuTriggerFor]="menu" color="primary">New <i class="fa fa-plus"></i></button>
              <mat-menu #menu="matMenu" class="top-menu-container-dd">            
                <button mat-menu-item (click)="loadClientSearch('RecordPayment')">Record Payment</button>   
                <button mat-menu-item (click)="loadClientSearch('Invoice')">Invoice</button>         
                <button mat-menu-item (click)="loadClientSearch('Estimate')">Estimate</button>
              </mat-menu>
            </div>
          </div>
          <div class="col-md-6 top-menu-container" *ngIf="officeList && officeList.length > 0">
            <mat-form-field>
              <select  matNativeControl placeholder="Office" (change)="getInvoiceList()"  [(ngModel)]="selectedOffice">
                <option value="All">All</option>
                <!--<mat-option>None</mat-option>-->
                <option *ngFor="let service of officeList" [value]="service.Soid">{{service.CompanyName}} </option>
                <!--<mat-option value="Custom">Custom</mat-option>-->
              </select>
            </mat-form-field>
          </div>
          <div class="col-md-4">
            <form class="search-card">
              <div class="card-body row no-gutters align-items-center">
                <div class="col search-card-field">
                  <i class="fas fa-search icon-serach"></i>
                  <input autocomplete="off" [(ngModel)]="searchText" name="searchText"
                    class="form-control  form-control-borderless" type="search"
                    placeholder="Search...">
                </div>
    
              </div>
            </form>
          </div>
        </div>
        <br>
        <div class="container">
          <div class="row">
            <div class="col" (click)="showFilter('OverDue')">
              <mat-icon matListIcon>timer</mat-icon>
              <h3 class="ml-2 d-inline-block" matLine>All Payments</h3>
              <p matLine>
                <span class="demo-2">${{overDueAmount}} USD</span>
              </p>
            </div>
            <div class="col" (click)="showFilter('Due')">
              <mat-icon matListIcon>schedule</mat-icon>
              <h3 class="ml-2 d-inline-block" matLine>Paid</h3>
              <p matLine>
                <span class="demo-2">${{dueAmount}} USD</span>
              </p>
            </div>
            <div class="col" (click)="showFilter('All')">
              <mat-icon matListIcon>payments</mat-icon>
              <h3 class="ml-2 d-inline-block" matLine>Cancelled And Refunded</h3>
              <p matLine>
                <span class="demo-2">${{filteredTotal ? filteredTotal : '0'}} USD</span>
              </p>
            </div>
          </div>
        </div>
        <div class="row form-page-header">
          <div class="col-md-4">
            <form class="form-inline">
              <label for="inlineFormInputName2" class="mrg-rt-5">Sort By</label>
              <mat-form-field>
                <select matNativeControl required (change)="sortFieldChange($event)">
                  <option value="NameFirst">Name</option>
                  <option value="CreatedOn">Created On</option>
                  <option value="EmailAddress">Email</option>
                </select>
              </mat-form-field>
            </form>
          </div>
          <div class="col-md-8 text-right">
            <span class="mrg-rt-5 search-results">Showing Payment(filtered)</span>
          </div>
        </div>
        <div class="client-lists">
          <div class="row">
            <div class="col-md-12">
              <ul class="list-unstyled">
                <li class="media"  [ngClass]="(transaction.Soid == selectedReceiptId )   ? 'selected-row' : ''"
                  *ngFor="let transaction of transactionList | 
                        simpleSearch : 'BillingToFirstName,BillingToLastName': searchText | slice: lowValue : highValue"
                  (click)="showReceipt(transaction.Soid, transaction.CustomerSoid, transaction.InvoiceSoid, transaction)">
                  <ngx-avatar size="40" name="{{transaction.BillingToFirstName}} {{transaction.BillingToLastName}}"></ngx-avatar>
         
                  <!-- <div class="rounded mr-3 bg-success initial-text">{{getShortName(client.firstName) | uppercase}}{{getShortName(client.lastName) | uppercase}}</div> -->
                  <div class="media-body">
                    <div class="row no-gutters">
                      <div class="client-list-left col-md-4">
                        <h6 class="mt-0 mb-1">{{transaction.BusinessName ? transaction.BusinessName : '' | titlecase}}{{transaction.BusinessName ? ' / ' : '' | titlecase}}{{transaction.BillingToFirstName | titlecase}} {{transaction.BillingToLastName | titlecase}}</h6>
                        <span> {{transaction.Label}} #{{transaction.InvoiceNumber}}</span>
                        <span>{{transaction.XeroRecieptReference}}</span>
                        <div><span><i class="fas fa-mobile-alt"></i> {{transaction.StaffName}}</span></div>
                      </div>
                      <div class="col-md-5 result-date text-right" >
                       
                      </div>
                      <div class="col-md-3 result-date text-right">
                        <span>{{ '$' + transaction.Amount + ' (out of $' + transaction.InvoiceTotal + ')' }} USD</span>
                        <br>
                        <span> Paid On {{transaction.PaidOn | date:"dd MMM yyyy"}}</span>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4 offset-md-8">
              <mat-paginator class="pagination" [length]="invoicesLength" [pageSize]="invoicePageSize"
                [pageSizeOptions]="invoicePageSizeOptions" (page)="getinvoicePaginatorData($event)">
              </mat-paginator>
            </div>
          </div>
        </div>
      </div>


    </div>
    <div class="col" style="    overflow-y: auto;    overflow-x: hidden; " [ngClass]="transactionList.length == 0 ? 'd-none' : ''">
      <app-view-reciept #viewReciept ></app-view-reciept>
   </div>
  </div>
</div>