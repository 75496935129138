<div class="section-container">
  <div class="subheader">
    <h2>Document Info</h2>
    <!-- <h5>Plans and events</h5> -->
  </div>
  <div class="white-bg rounded border-0">
    <div class="row top-menu bg-light bg-grey no-gutters">
      <div class="col-md-6">
        <div class="top-menu-container">
          <a class="btn-link btn-icon-back" (click)="goBack()"><i class="fa fa-chevron-left"></i></a>
        </div>
      </div>
      <div class="col-md-6">
        <div class="top-menu-container d-flex flex-row-reverse">
          <a class="btn btn-primary" (click)="downloadFile()">Download</a>
          <button class="btn" [matMenuTriggerFor]="menu" >
            <i class="fas fa-ellipsis-v"></i>
          </button>
          <mat-menu #menu>
            <button mat-menu-item (click)="viewFile()">
              <mat-icon>print</mat-icon>
              <span>Print</span>
            </button>
            <button mat-menu-item (click)="openConfirmationDialog()">
              <mat-icon>delete</mat-icon>
              <span>Delete</span>
            </button>
            <button mat-menu-item (click)="showSendDocumentConfirmationPopup(template)">
              <mat-icon>mail</mat-icon>
              <span>Email</span>
            </button>
          </mat-menu>
        </div>
      </div>

    </div>
    <div class="padding-10">
      <!-- <div class="container"> -->
      <div class="row">
        <div class="col-md-9">
          <div class="row">
            <div class="date-container">
              <div class="date"><i class="far fa-file-image fa-5x"></i>
                <!--
                      <span class="binds"></span>
                      <span class="month">{{documentInfo.Time.Start | date:"EE" | uppercase}}</span>
                      <h1 class="day">{{documentInfo.Time.Start | date:"dd"}}
                        <div class="month-text">{{documentInfo.Time.Start | date:"MMM"}}</div>
                      </h1>
                     -->
              </div>
            </div>
            <div class="col-md-9">

              <h2>{{documentInfo.DocumentTitle | titlecase}}</h2>
              <ul class="list-group list-group-flush list-no-borders">
                <li class="list-group-item"><i class="fa fa-user-tie"></i> {{documentInfo.OwnerName | titlecase}}</li>
                <li class="list-group-item"><i class="fa fa-file-alt"></i> {{documentInfo.Size}}</li>
              </ul>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="card">
                <div class="card-header">
                  More Info.
                </div>
                <div class="card-body card-info">
                  <label>Document Description</label>
                  <!-- <button>{{documentInfo.staffFName}} {{documentInfo.staffLName}} <button *ngIf="eventStatus == 'Scheduled'" (click)="assignStaff()" class="btn btn-link">Assign</button></p> -->
                  <p>{{documentInfo.DocumentDescription | titlecase}} </p>
                  <label>Staff</label>
                  <!-- <button>{{documentInfo.staffFName}} {{documentInfo.staffLName}} <button *ngIf="eventStatus == 'Scheduled'" (click)="assignStaff()" class="btn btn-link">Assign</button></p> -->
                  <p>{{documentInfo.StaffName | titlecase}} </p>
                  <label>File Name</label>
                  <p>{{documentInfo.FileName | titlecase}} </p>
                  <label>Size</label>
                  <p>{{documentInfo.Size | titlecase}} </p>
                  <label>Created On</label>
                  <p>{{documentInfo.CreatedOn | date:"dd MMM yyyy"}} </p>
                  <label>Last Download On</label>
                  <p>{{documentInfo.CreatedOn | date:"dd MMM yyyy"}} </p>
                </div>
              </div>

            </div>
            <div class="col-md-6">

              <br />

            </div>

          </div>
        </div>

        <!-- end- right column  -->
    </div>

    <!-- </div> -->
  </div>
</div>
</div>

<ng-template #template>
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title">Send document by email</h5>
      <button type="button" class="close" aria-label="Close" (click)="sendDocumentConfirmationPopup.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      Do you want to send <b>{{ documentInfo.DocumentTitle }}</b> document by email?
    </div>
    <div class="modal-footer">
      <button class="btn btn-danger" (click)="sendDocumentConfirmationPopup.hide()">Cancel</button>
      <button class="btn btn-info" (click)="sendDocumentByEmail()" >Send</button>
    </div>
  </div>
</ng-template>
