import { Component, OnInit, ViewChild, AfterViewInit, TemplateRef } from '@angular/core';
import { ModulesService } from 'src/app/services/modules.service';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { StaffService } from 'src/app/services/staff.service';
import { AddModulesComponent } from '../modal/add-modules/add-modules.component';
import { ConfirmationDialog } from '../modal/confirmation-dialog/confirmation-dialog.component';
import { OfficeService } from '../../services/office.service';
import { ThrowStmt } from '@angular/compiler';

@Component({
    selector: 'app-modules-list',
    templateUrl: './modules-list.component.html',
    styleUrls: ['./modules-list.component.css'],

    animations: [
        trigger('detailExpand', [
            state('*', style({ height: '0px', minHeight: '0' })),
            state('collapsed', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
            state('expanded', style({ height: '*', visibility: 'visible' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
        ]),
    ]
})
export class ModulesListComponent implements OnInit {
    taskList: any = [];
    officeList: any = [];
    public displayedColumns: string[] = ['Edit', 'Delete', 'Name', 'Status', 'CreatedBy', 'CreatedOn'];
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort,{static: false}) sort: MatSort;
    public dataSource;
    expandedElement: any | null = null;
    showData: boolean = false;
    role: string;
    modalRef: BsModalRef;
    modalTask: any;
    viewAll: boolean = false;
    employees: any[] = [];
    filterBy: string = "Active";
    moduleList: any = [];
    selectedOffice: string;

    constructor(private moduleService: ModulesService,private _officeService: OfficeService, private _router: Router, private modalService: BsModalService, private toast: ToastrService, private _staffService: StaffService) { }

    ngOnInit() {
       // this.getStaffList();
        this.role = localStorage.getItem('Role');
        this.selectedOffice = localStorage.getItem("OfficeSoid")
        this.getAllModules(this.selectedOffice);
        this.getOfficeList();
    }


    public getOfficeFilter(event) {
        this.getAllModules(this.selectedOffice);
        
      }

     

    applyFilter(filterValue: string) {
        filterValue = filterValue.trim();
        filterValue = filterValue.toLowerCase();
        this.dataSource.filter = filterValue;
    }

    private getOfficeList() {    
        this._officeService.getAccessOfficeList(localStorage.getItem("OfficeSoid")).subscribe((res: any) => {
          if (res) {
            var office = [];
            this.officeList = res.Office;        
          }
        });
      }

    private async getAllModules(officeSoid) {
        this.moduleService.getAllOfficeModules(officeSoid).subscribe((res: any) => {
           // this.activeModules = res;
           this.moduleList = res;
           
           this.taskList = res.filter(w => w.Status == this.filterBy);
           var fields = Object.assign([], this.taskList);


           var mapResult = fields.map(el => {
               return { ...el, Priority: this.getPrValue(el.Priority), DueStatus: this.isOverDue(el) }
           });
           this.dataSource = new MatTableDataSource<any>(mapResult);  
           setTimeout(() => {
               this.dataSource.sort = this.sort;
           }, 500);             
           
           this.dataSource.paginator = this.paginator;
           this.showData = true;
          });
    }

    public OnEditButtonClick(module: any) {
        const initialState = {
            header: "Edit Module",
            backdrop: true,
            ignoreBackdropClick: true,
            closeBtnName: "Close",
            editModule: module
        };

        this.modalRef = this.modalService.show(AddModulesComponent, { initialState });
        this.modalRef.onHide.subscribe(() => {
            this.getAllModules(this.selectedOffice);
        });
    }

    openConfirmationDialog(module) {
        const initialState = {
          confirmMessage: "Are you sure to delete the module?",
          backdrop: true,
          ignoreBackdropClick: true
        };
        this.modalRef = this.modalService.show(ConfirmationDialog, {initialState});
        this.modalRef.content.event.subscribe(data => {
          if (data) {
            var requestInfo: any = {};
            requestInfo.FieldName = "Status";
            requestInfo.Data = "InActive";
            requestInfo.UserName = localStorage.getItem("UserName");
            requestInfo.UserSoid = localStorage.getItem("UserSoid");
            this.moduleService.updateModules(module.Soid, requestInfo).subscribe((res: any) => {
                if (res) {
                    this.toast.success("Deleted module successfully.", "Success!");
                    this.getAllModules(this.selectedOffice);
                } else {
                this.toast.warning("Error Updating Module", "Error!");
                }
            }, (err) => {
                this.toast.error(err);
            });
          }
       });
    
      }

      activeteModule(module) {
        const initialState = {
          confirmMessage: "Are you sure to activate the module?",
          backdrop: true,
          ignoreBackdropClick: true
        };
        this.modalRef = this.modalService.show(ConfirmationDialog, {initialState});
        this.modalRef.content.event.subscribe(data => {
          if (data) {
            var requestInfo: any = {};
            requestInfo.FieldName = "Status";
            requestInfo.Data = "Active";
            requestInfo.UserName = localStorage.getItem("UserName");
            requestInfo.UserSoid = localStorage.getItem("UserSoid");
            this.moduleService.updateModules(module.Soid, requestInfo).subscribe((res: any) => {
                if (res) {
                this.toast.success("Activated module successfully.", "Success!");
                this.getAllModules(this.selectedOffice);
                } else {
                this.toast.warning("Error Updating Module", "Error!");
                }
            }, (err) => {
                this.toast.error(err);
            });
          }
       });
    
      }

    openModal(template: TemplateRef<any>, item: any) {
        this.modalRef = this.modalService.show(template);
        this.modalTask = item;
    }

    public async OnOkDeleteButtonClick() {
        await this.deleteTask(this.modalTask);
        this.modalRef.hide()
    }

    public deleteTask(task: any) {
        // this._TaskService.deleteTask(task.Soid).subscribe((res: any) => {
        //     this.getTasksList();
        //     this.toast.success("Deleted Successfully.", "Success!");
        // });
    }

    public toggleRow(element: any): void {
        this.expandedElement = this.expandedElement === element ? null : element;
    }

    public getPrValue(priority: Number): string {
        if (priority == 0) {
            return "Low";
        }
        else if (priority == 1) {
            return "Normal";
        }
        else if (priority == 2) {
            return "High";
        }
    }

    public OnViewAllButtonClick() {
        this.viewAll = true;
        
    }

    public OnAddTaskButtonClick() {
        const initialState = {
            header: "Add Module",
            backdrop: true,
            ignoreBackdropClick: true,
            closeBtnName: "Close"
        };

        this.modalRef = this.modalService.show(AddModulesComponent, { initialState });
        this.modalRef.onHide.subscribe(() => {
            this.getAllModules(this.selectedOffice);
        });
    }

    
    filterModules() {
        if (this.filterBy == "All") {
            this.taskList = this.moduleList
        } else {
            this.taskList = this.moduleList.filter(w => w.Status == this.filterBy);
        }
        var fields = Object.assign([], this.taskList);
        var mapResult = fields.map(el => {
            return { ...el, Priority: this.getPrValue(el.Priority), DueStatus: this.isOverDue(el) }
        });
        this.dataSource = new MatTableDataSource<any>(mapResult);  
        setTimeout(() => {
            this.dataSource.sort = this.sort;
        }, 500);             
        
        this.dataSource.paginator = this.paginator;
        this.showData = true;
    }
   

    public OnBackButtonClick() {
        this.viewAll = false;
        this.getAllModules(this.selectedOffice);
    }

    public isOverDue(item): string {
        let currentDate = new Date();
        let appoinmentDate = new Date(item.DueOn);

        if (currentDate > appoinmentDate && (item.Status == "New" || item.Status == "In Progress")) {
            return "OverDue";
        }
    }

    // public getDuration(item) {
    //     var inProgressDate = item.Changes.filter(c => c.OldValue == "New" && c.NewValue == "In Progress")[0].CreatedOn;
    //     console.log(inProgressDate);
    //     var startDate = new Date(inProgressDate);
    //     console.log(startDate);
    //     var currentDate = new Date();
    //     var seconds = Math.floor((currentDate.getTime() - (startDate.getTime())) / 1000);
    //     var minutes = Math.floor(seconds / 60);
    //     var hours = Math.floor(minutes / 60);
    //     var days = Math.floor(hours / 24);

    //     hours = hours - (days * 24);
    //     minutes = minutes - (days * 24 * 60) - (hours * 60);
    //     seconds = seconds - (days * 24 * 60 * 60) - (hours * 60 * 60) - (minutes * 60);

    //     console.log(hours);
    //     console.log(minutes);
    //     console.log(seconds);
    // }

    // public getTotalDuration(item){
    //     //1:11:17
    //     var inProgressDate = item.Changes.filter(c => c.OldValue == "New" && c.NewValue == "In Progress")[0].CreatedOn;
    //     var startDate = new Date(inProgressDate);
    //     var completedDate = item.Changes.filter(c => c.OldValue == "In Progress" && c.NewValue == "Complete")[0].CreatedOn;
    //     var endDate = new Date(completedDate);
    //     var seconds = Math.floor((completedDate - inProgressDate) / 1000);
    //     var minutes = Math.floor(seconds / 60);
    //     var hours = Math.floor(minutes / 60);
    //     var days = Math.floor(hours / 24);

    //     hours = hours - (days * 24);
    //     minutes = minutes - (days * 24 * 60) - (hours * 60);
    //     seconds = seconds - (days * 24 * 60 * 60) - (hours * 60 * 60) - (minutes * 60);

    //     console.log(hours);
    //     console.log(minutes);
    //     console.log(seconds);
    // }
}