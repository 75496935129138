import {Injectable} from '@angular/core';
import {Api} from './api';
import {combineLatest, Observable} from 'rxjs';
import {flatten} from 'lodash';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ModulesService {

  constructor(public api: Api) { }

  getAllModules() {
    let seq = this.api.get('Module');
    return seq;
  }

  getAllOfficeModules(officeId: string): Observable<any> {
    return this.api.get('Module/' + officeId + '/List');
  }

  saveModules(module) {
    let seq = this.api.post('Module', module);
    return seq;
  }

  updateModules(soid, module) {
    let seq = this.api.patch('Module/' + soid, module);
    return seq;
  }

  getOfficesModules(officesSoid: string[]): Observable<any> {
    return combineLatest(officesSoid.map((office: any) => this.getAllOfficeModules(office.Soid)))
      .pipe(map((modules: any[]) => {
        const res = [];
        const officeModules = flatten(modules);
        officeModules.forEach(module => {
          if (!res.find(m => m.Soid === module.Soid && m.Name === module.Name) &&
            officeModules.filter(m => m.Soid === module.Soid && m.Name === module.Name).length === officesSoid.length) {
            res.push(module);
          }
        });
      }));
  }
}
