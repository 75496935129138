<div class="section-container">
  <div class="subheader">
    <h2>Documents</h2>
  </div>
  <div class="white-bg rounded border-0">
    <div class="row top-menu bg-light bg-grey no-gutters">
      <div class="col-md-6">
        <div class="top-menu-container">
          <a (click)="openClientSearch()" class="btn btn-primary">New Client Document</a>
        </div>
      </div>

    </div>
    <ng-sidebar-container style="height: 700px;">
      <div  ng-sidebar-content>
        <!-- <button (click)="_toggleSidebar()" style="position: relative;  left: 90%; top: 300px;">Toggle sidebar</button> -->
        <div (click)="_toggleSidebar()" class="btn-collapse right"><i class="fas fa-caret-left"></i></div>

      <div class="row form-page-header">
        <div class="col-md-4">
          <form class="form-inline">
            <label for="inlineFormInputName2" class="mrg-rt-5">Sort By</label>
            <mat-form-field>
              <select matNativeControl required (change)="sortFieldChange($event)">
                <option value="CreatedOn">Default</option>
                <option value="OwnerName">Name</option>
                <option value="DocumentTitle">Document Title</option>
              </select>
            </mat-form-field>
          </form>
        </div>
        <div class="col-md-4" *ngIf="officeList && officeList.length > 0">
          <mat-form-field>
            <select  matNativeControl placeholder="Office" (change)="getOfficeFilter($event)" [(ngModel)]="selectedOffice">
              <!--<mat-option>None</mat-option>-->
              <option value="All">All</option>
              <option *ngFor="let service of officeList" [value]="service.Soid">{{service.CompanyName}} </option>
              <!--<mat-option value="Custom">Custom</mat-option>-->
            </select>
          </mat-form-field>
        </div>
        <div class="col-md-4 text-right">
          <form class="search-card">
            <div class="card-body row no-gutters align-items-center">
              <div class="col search-card-field">
                <i class="fas fa-search icon-serach"></i>
                <input [(ngModel)]="searchText" name="searchText"
                  class="form-control form-control-borderless" type="search"
                  placeholder="Search by Document Title,File Name,Staff Name...">
              </div>
  
            </div>
          </form>
        </div>
      </div>
      <div class="client-lists">
        <div class="row">
          <div class="col-md-12">
            <ul class="list-unstyled">
              <li class="media"
                *ngFor="let document of documentList | 
                    simpleSearch : 'OwnerName,StaffName,DocumentTitle,FileName': searchText | slice: lowValue : highValue"
                (click)=editClient(document.Soid)>
             
                <ngx-avatar size="40" name="{{document.OwnerName}}"></ngx-avatar>
                <!-- <div class="rounded mr-3 bg-success initial-text">{{getShortName(client.firstName) | uppercase}}{{getShortName(client.lastName) | uppercase}}</div> -->
                <div class="media-body">
                  <div class="row no-gutters">
                    <div class="client-list-left col-md-4">
                      <h6 class="mt-0 mb-1">{{document.OwnerName | titlecase}}</h6>
                      <span> {{document.DocumentTitle}}</span><br>
                      <span><i class="fas fa-file-alt"></i> {{document.FileName}}</span>
                      <!--<div><span><i class="fas fa-mobile-alt"></i> {{client.country}} {{client.PhoneNumber}}</span></div>-->
                    </div>

                    <div class="col-md-5 result-date text-right"  >
                      <span class="lable-tag"><i class="fas fa fa-user"></i> {{document.StaffName}}</span>
                    </div>

                    <div class="col-md-3 result-date text-right">
                      <span> {{document.CreatedOn | date:"dd MMM yyyy"}}</span>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
            <div *ngIf="showListLoader" class="position-absolute w-100 d-flex flex-column align-items-center bg-white justify-content-center">
              Loading...
              <div class="spinner-border" role="status">
<span class="sr-only">Loading...</span>
</div>
          </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4 offset-md-8">
            <mat-paginator class="pagination" [length]="documentLength" [pageSize]="documentPageSize"
              [pageSizeOptions]="documentPageSizeOptions" (page)="getClientPaginatorData($event)">
            </mat-paginator>
          </div>
        </div>
      </div>
    </div>
    <ng-sidebar [(opened)]="_opened" position="right"  mode="slide">
      <mat-tab-group>
        
        <mat-tab label="My Documents"> 
          <div class="client-lists check-all">
          <div class="container">
            <div class="row">
                <div class="col-md-12">
                  <div class="col-md-12"><span>Document you wish to store and re-use with your clients</span>    </div>  
                    
                    <div class="col-md-12 text-right">
                      <button  class="btn btn-primary"(click)="myDocumentModal()"><i class="fas fa-plus text-warning"></i>Upload to My Documents</button>
                    </div>

                    <br />
                    <ul class="list-unstyled">
                          <li class="media" *ngFor="let document of myDocumentsList">
                                                           
                               
                              <div class="media-body" >
                                  <div class="row no-gutters">
                                      <div class="client-list-left col-md-10">
                                        <h6 class="mt-0 mb-1">{{document.DocumentTitle | titlecase}}</h6>
                                      </div>
                                      <div class="col-md-2 text-right">
                                       <!-- <span class="mr-2" (click)="editMyDocument(document)"><i class="far fa-edit"></i></span> -->
                                        <button mat-icon-button (click)="editMyDocument(document)" >
                                          <mat-icon>edit</mat-icon>
                                        </button>                                         
                                            <button mat-icon-button [matMenuTriggerFor]="menu" >
                                              <mat-icon>more_vert</mat-icon>
                                            </button>
                                            <mat-menu #menu="matMenu">
                                              <button mat-menu-item (click)="viewFile(document)">
                                                <mat-icon>preview</mat-icon>
                                                <span>View</span>
                                              </button>
                                              <button mat-menu-item (click)="inActiveDocument(document.Soid)">
                                                <mat-icon>delete</mat-icon>
                                                <span>Delete</span>
                                              </button>
                                              
                                            </mat-menu>
                                      </div>
                                  </div>
                              </div>
                            </li>
                      </ul>
                </div>
          </div>
        </div>
        </div> </mat-tab>
      </mat-tab-group>
   </ng-sidebar>
    </ng-sidebar-container>
    <!-- End Modal: Add new client -->
  </div>
</div>