<div class="section-container">
  <div class="subheader">
    <h2>Appointment and Event</h2>
    <!-- <h5>Plans and events</h5> -->
  </div>
  <div class="white-bg rounded border-0 m-10" style="width: 90%; height: 100%">
    <ng-sidebar-container style="height: 700px;" >
      <div ng-sidebar-content>
        <!-- <button (click)="_toggleSidebar()" style="position: relative;  left: 90%; top: 300px;">Toggle sidebar</button> -->
        <div (click)="_toggleSidebar()" class="btn-collapse right"><i class="fas fa-caret-left"></i></div>
        <full-calendar [options]="calendarOptions"></full-calendar>
      </div>
      <!-- Everything you see below is just for the demo, you don't need to include it in your app -->

      <!-- A sidebar -->
      <ng-sidebar [(opened)]="_opened" position="right" mode="slide">
        <!-- <button (click)="_toggleSidebar()">Open sidebar</button> -->
        <!-- <div (click)="_toggleSidebar()" class="btn-collapse"><i class="fas fa-caret-right"></i></div> -->
        <mat-tab-group>
          <mat-tab label="Service">
            <div class="client-lists">
              <div class="container">
                <div class="row">
                  <div class="col-md-6">
                    <h6 class="hd-service-card">Your service</h6>
                  </div>
                  <div class="col-md-6 text-right">
                    <button (click)="openServiceModal()" class="btn hd-btn-link"><i
                        class="fas fa-plus text-warning"></i></button>
                    <button (click)="viewServiceList()" class="btn hd-btn-link"><i
                        class="fas fa-edit text-primary"></i></button>
                    <button (click)="openSearchForClient()" class="btn hd-btn-link"><i
                        class="fas fa-plus text-warning"></i></button>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <ul class="list-unstyled">
                      <li class="media" *ngFor="let service of serviceList">
                        <div class="media-body">
                          <div class="row no-gutters">
                            <div (click)="selectedService(service.Soid)" class="client-list-left col-md-10">
                              <span class="mt-0 mb-1">{{service.Item}}</span>
                            </div>
                            <div class="col-md-2 result-date text-right">
                              <div dropdown container="body" placement="bottom left">
                                <i class="fas fa-ellipsis-v" dropdownToggle aria-controls="dropdown-container"></i>
                                <ul id="dropdown-container" *dropdownMenu class="dropdown-menu" role="menu"
                                  aria-labelledby="button-container">
                                  <li role="menuitem"><a class="dropdown-item" href="#">Action</a></li>
                                  <li role="menuitem"><a class="dropdown-item" href="#">Another action</a></li>
                                  <li role="menuitem"><a class="dropdown-item" href="#">Something else here</a></li>
                                  <li class="divider dropdown-divider"></li>
                                  <li role="menuitem"><a class="dropdown-item" href="#">Separated link</a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>

                </div>
              </div>
            </div>
          </mat-tab>
          <mat-tab label="Staff">
            <div class="client-lists check-all">
              <div class="container">
                <div class="row">
                  <div class="col-md-12">
                    <h6>Your Staff member</h6>
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" id="gridCheck" [(ngModel)]="isAllChecked"
                        (change)="checkUncheckAll()">
                      <label class="form-check-label mrg-lt-5" for="gridCheck">
                        Check All
                      </label>
                    </div>
                    <br />
                    <ul class="list-unstyled">
                      <li class="media" *ngFor="let staff of staffList">
                        <div class="form-check">
                          <!-- <input class="form-check-input" (change)="staffSelection($event)" type="checkbox" value="{{staff.loginId}}" id="defaultCheck1"> -->
                          <input class="form-check-input" type="checkbox" [(ngModel)]="staff.isSelected"
                            (change)="isAllSelected(staff.PersonSoid)" value="{{staff.PersonSoid}}" id="defaultCheck1">
                        </div>

                        <ngx-avatar size="40" name="{{staff.NameFirst}} {{staff.NameLast}}"></ngx-avatar>
                        <div class="media-body" (click)="selectedStaff(staff.PersonSoid)">
                          <div class="row no-gutters">
                            <div class="client-list-left col-md-10">
                              <h6 class="mt-0 mb-1">{{staff.NameFirst | titlecase}} {{staff.NameLast | titlecase}}</h6>
                            </div>
                            <div class="col-md-2 result-date text-right">
                              <span><i class="fas fa-ellipsis-v"></i></span>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </mat-tab>
        </mat-tab-group>
      </ng-sidebar>


    </ng-sidebar-container>
  </div>

</div>

<ng-template #modalContent let-close="close">
  <div class="modal-header">
    <h5 class="modal-title">Event action occurred</h5>
    <button type="button" class="close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div>
      Action:
      <pre>{{ modalData?.action }}</pre>
    </div>
    <div>
      Event:
      <pre>{{ modalData?.event | json }}</pre>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="close()">
      OK
    </button>
  </div>
</ng-template>