<div class="section-container" style="padding-left: 1rem;">
  <div class="subheader">
    <h2>{{officeInfo.CompanyName}}</h2>

    
    <!-- <h5>Plans and events</h5> -->
  </div>
  <div class="white-bg rounded border-0">
    <div class="row top-menu bg-light bg-grey no-gutters">

    </div>
    <div class="container emp-profile">

      <form method="post">
        <div class="row edit-profile-tab">
          <div class="col-md-12">
            <div class="container">
              <div class="row">
                <div class="col-md-12 mb-1">
                  <div class="card">
                    <div class="card-header">
                      Office Details
                    </div>
                    <div class="card-body">
                      <form class="client-modal" [formGroup]="clientForm">
                        <div class="form-row">
                          <div class="col-md-2 d-flex flex-column justify-content-center">
                            <h6 class="text-center">Logo</h6>
                            <label for="office-logo-input" class="office-logo-label d-flex justify-content-center" >
                              <ngx-avatar *ngIf="officeInfo.Logo; else NoLogo" size="50" [src]="officeInfo.Logo" > </ngx-avatar>
                              <input
                                id="office-logo-input"
                                type="file"
                                class="office-logo-input"
                                (change)="handleOfficeLogoChange($event)"
                                accept="image/png, image/jpg, image/jpeg"
                              >
                            </label>
                            <ng-template #NoLogo>
                              <ngx-avatar size="50" name="{{officeInfo.CompanyName}}"></ngx-avatar>
                            </ng-template>
                            <button *ngIf="officeInfo.Logo" class="btn btn-link" (click)="deleteOfficeLogo()">Delete</button>
                          </div>
                          <div class="col">
                            <div class="form-row">
                              <div class="form-group col-md-6">
                                <mat-form-field>
                                  <input formControlName="email" name="CompanyName" id="CompanyName" (focusout)="onInputFocusOut($event)"
                                         [(ngModel)]="officeInfo.CompanyName" type="email" matInput placeholder="Office Name">
                                </mat-form-field>
                              </div>
                              <div class="form-group col-md-6">
                                <mat-form-field>
                                  <input type="text" (focus)="inputFocus($event)" (focusout)="onInputFocusOut($event)"
                                         formControlName="firstName" name="ContactName" id="ContactName"
                                         [(ngModel)]="officeInfo.ContactName" matInput placeholder="Contact Name">
                                </mat-form-field>
                              </div>
                            </div>
                            <div class="form-row">
                              <div class="form-group col-md-6">
                                <mat-form-field>
                                  <input type="text" formControlName="emailAddress" (focus)="inputFocus($event)"
                                         (focusout)="onInputFocusOut($event)" name="EmailAddress" id="EmailAddress"
                                         [(ngModel)]="officeInfo.EmailAddress" matInput placeholder="Email Address">
                                </mat-form-field>
                              </div>
                              <div class="form-group col-md-6">
                                <mat-form-field>
                                  <ngx-mat-intl-tel-input (countryChanged)="countryChange($event)"
                                                          (focus)="inputFocus($event)" (focusout)="phoneNumberFieldFocusOut($event,'PhoneNumber')"
                                                          [(ngModel)]="officeInfo.PhoneNumber" [preferredCountries]="['us', 've']"
                                                          [enablePlaceholder]="true" placeholder="Phone Number" [enableSearch]="true"
                                                          format="national" formControlName="phoneNumber">
                                  </ngx-mat-intl-tel-input>
                                  <mat-error *ngIf="clientForm.controls.phoneNumber.errors?.required">Please enter valid
                                    mobile number</mat-error>
                                  <mat-error *ngIf="clientForm.controls.phoneNumber.errors?.validatePhoneNumber">Please
                                    enter valid mobile number</mat-error>
                                  <mat-error *ngIf="clientForm.controls.phoneNumber.errors?.maxlength">Please enter valid
                                    mobile number</mat-error>

                                </mat-form-field>
                              </div>
                            </div>
                          </div>

                          <div class="form-group col-md-4">

                            <mat-form-field>
                              <mat-select (selectionChange)="onSelect($event)" (openedChange)="changeTag($event)"
                                [formControl]="toppingsControl" placeholder="Modules" [(ngModel)]="selectedItems"
                                multiple>
                                <mat-select-trigger *ngIf="toppingsControl && toppingsControl.value">
                                  {{toppingsControl.value && toppingsControl.value[0] ? toppingsControl.value[0].Name :
                                  ''}}
                                  <span *ngIf="toppingsControl.value?.length > 1" class="example-additional-selection">
                                    (+{{toppingsControl.value.length - 1}} others)
                                  </span>
                                </mat-select-trigger>
                                <mat-option *ngFor="let topping of activeModules"
                                  [value]="topping">{{topping.Name}}</mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                        </div>

                      </form>
                    </div>
                  </div>
                </div>
                <div class="col-md-12 mb-1">
                  <div class="card">
                    <div class="card-header">
                      Status
                    </div>
                    <div class="card-body">
                      <form class="client-modal" [formGroup]="statusForm">
                        <div class="form-row">
                          <div class="form-group col-md-4">
                            <mat-form-field>
                              <mat-label>Status</mat-label>
                              <mat-select [(ngModel)]="officeStatus" Name="Active" id="Active"
                                (focus)="onSelectFocus($event)" (selectionChange)="onSelectFocusOut($event)"
                                formControlName="status">
                                <mat-option value="true">Active</mat-option>
                                <mat-option value="false">InActive</mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>

                          <div class="form-group col-md-2">
                            <mat-form-field floatLabel="always" appearance="none">
                              <mat-label>Parent Office</mat-label>
                              <mat-slide-toggle ngDefaultControl [ngModelOptions]="{standalone: true}"
                                (change)="onFocusOut($event)" [(ngModel)]="isParentOffice"></mat-slide-toggle>
                              <textarea matInput hidden></textarea>
                            </mat-form-field>




                          </div>
                          <div class="form-group col-md-6" *ngIf="isParentOffice">
                            <mat-form-field>
                              <mat-select (selectionChange)="onSelect($event)"
                                (openedChange)="updateBranchOffice($event)" [formControl]="toppings"
                                placeholder="Office" [(ngModel)]="branchOffice" multiple>



                                <mat-select-trigger *ngIf="toppings && toppings.value">
                                  {{toppings.value && toppings.value[0] ? toppings.value[0].CompanyName : ''}}
                                  <span *ngIf="toppings.value?.length > 1" class="example-additional-selection">
                                    (+{{toppings.value.length - 1}} others)
                                  </span>
                                </mat-select-trigger>
                                <mat-option *ngFor="let branches of officeList"
                                  [value]="branches">{{branches.CompanyName}}</mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                        </div>

                      </form>
                    </div>
                  </div>
                </div>
                
                <div class="col-md-12">
                  <div class="card">
                    <div class="card-header">
                      Contact Information
                    </div>
                    <div class="card-body">
                      <form class="client-modal" [formGroup]="contactForm">
                        <div class="form-row">
                          <div class="form-group col-md-6">
                            <mat-form-field>
                              <input type="text" autocomplete="off" (focus)="inputFocus($event)"
                                (focusout)="onInputFocusOut($event)" id="BillStreet" name="BillStreet"
                                formControlName="addressLine1" [(ngModel)]="officeInfo.BillStreet" matInput
                                placeholder="Address">
                            </mat-form-field>
                          </div>
                          <div class="form-group col-md-6">
                            <mat-form-field>
                              <input (focus)="inputFocus($event)" (focusout)="onInputFocusOut($event)" type="text"
                                name="BillCity" id="BillCity" autocomplete="off" [(ngModel)]="officeInfo.BillCity"
                                [ngModelOptions]="{standalone: true}" matInput placeholder="City">
                            </mat-form-field>
                          </div>


                        </div>
                        <div class="form-row">
                          <div class="form-group col-md-4">
                            <mat-select-country
                              (onCountrySelected)="onCountrySelected($event, 'HomeAddressCountry', 'Home')"
                              autocomplete="off" appearance="legacy" placeHolder="Country" label="Country"
                              name="HomeAddressCountry" id="HomeAddressCountry" [value]="defaultValue"
                              formControlName="country">
                            </mat-select-country>
                          </div>
                          <div class="form-group col-md-4" *ngIf="defaultValue && defaultValue.alpha2Code != 'US'">
                            <mat-form-field>
                              <input type="text" (focus)="inputFocus($event)" (focusout)="onInputFocusOut($event)"
                                id="BillState" name="BillState" formControlName="state"
                                [(ngModel)]="officeInfo.BillState" matInput placeholder="State">
                              <mat-error *ngIf="contactForm.controls.state.errors != null">
                                Please enter state
                              </mat-error>

                            </mat-form-field>
                          </div>
                          <div class="form-group col-md-4" *ngIf="defaultValue && defaultValue.alpha2Code == 'US'">
                            <mat-form-field>
                              <mat-label>Billing Address State</mat-label>
                              <mat-select name="BillState" id="BillState" (focus)="onSelectFocus($event)"
                                (selectionChange)="onSelectFocusOut($event)" formControlName="state"
                                [(ngModel)]="officeInfo.BillState">
                                <mat-option *ngFor="let states of usStates"
                                  [value]="states.abbreviation">{{states.name}}</mat-option>
                              </mat-select>
                              <mat-error *ngIf="contactForm.controls.state.errors != null">
                                Please select state
                              </mat-error>
                            </mat-form-field>
                          </div>
                          <div class="form-group col-md-4">
                            <mat-form-field>
                              <input type="text" autocomplete="off" (focus)="inputFocus($event)"
                                (focusout)="onInputFocusOut($event)" id="BillZipCode" name="BillZipCode"
                                formControlName="zipCode" [(ngModel)]="officeInfo.BillZipCode" matInput
                                placeholder="Zip Code">
                            </mat-form-field>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="card">
                    <div class="card-header">
                      Xero Information
                    </div>
                    <div class="card-body">
                      <form class="client-modal" [formGroup]="xeroForm">
                        <div class="form-row">
                          <div class="form-group col-md-4">
                            <mat-form-field>
                              <input type="text" autocomplete="off" (focus)="inputFocus($event)"  style="text-transform: none;"
                                (focusout)="onXeroInputFocusOut($event)" id="ClientId" name="ClientId"
                                formControlName="clientId" [(ngModel)]="clientId" matInput
                                placeholder="Client ID">
                            </mat-form-field>
                          </div>
                          <div class="form-group col-md-5">
                            <mat-form-field>
                              <input type="text" autocomplete="off" (focus)="inputFocus($event)" style="text-transform: none;"
                              (focusout)="onXeroInputFocusOut($event)"  id="ClientSecret" name="ClientSecret"
                                formControlName="clientSecret" [(ngModel)]="clientSecret" matInput
                                placeholder="Client Secret">
                            </mat-form-field>
                          </div>

                          <div class="form-group col-md-3">
                            <a *ngIf="xeroURL" href="{{xeroURL}}" class="btn btn-primary m-3">Generate Xero Token</a>
                            <!-- <a  (click)="refreshToken()" class="m-3">Refresh Token</a> -->
                          </div>

                          
                          <div class="form-group col-md-6">
                            <mat-form-field>
                              <input type="text" autocomplete="off"  id="Organization" name="Organization"
                                formControlName="organization" [(ngModel)]="organization" matInput disabled
                                placeholder="Organization">
                            </mat-form-field>
                          </div>
                        </div>

                      </form>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
