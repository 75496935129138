import { Component, OnInit, EventEmitter } from '@angular/core';
import { FormBuilder, FormControl, Validators, FormGroup } from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { EventsService } from '../../../services/events.service';

@Component({
  selector: 'app-new-time-for-appoinment',
  templateUrl: './new-time-for-appoinment.component.html',
  styleUrls: ['./new-time-for-appoinment.component.css']
})
export class NewTimeForAppoinmentComponent implements OnInit {
  eventInfo: any = {};
  newTimeForAppoinmentForm: FormGroup;
  formValid: boolean = true;
  startTimeList: any = [];
  endTimeList: any = [];
  startDateTime: string = "";
  endDateTime: string;
  header: string;
  closeBtnName: string;
  startDates: Date;
  startEventDate: Date;
  endEventDate: Date;
  public event: EventEmitter<any> = new EventEmitter();
    
  constructor(private datePipe: DatePipe, private _router: Router, private fb: FormBuilder,
    private _eventsService: EventsService,
     private toast: ToastrService,
      public bsModalRef: BsModalRef) { }

  ngOnInit() {
    this.startTimeList = this.getHoursList(0);
    this.endTimeList = this.getHoursList(0);
    this.newTimeForAppoinmentForm = this.fb.group({
      message: new FormControl('', Validators.compose([
        Validators.required
      ])),
      startEventDate: new FormControl('', Validators.compose([
        Validators.required
      ])),
      startDateTime: new FormControl('', Validators.compose([
        Validators.required
      ])),
      endEventDate: new FormControl('', [Validators.required]),
      endDateTime: new FormControl('', [Validators.required]),
    });

    this.startEventDate = new Date(this.datePipe.transform(this.eventInfo.Time.Start, 'MM/dd/yyyy'));
    this.endEventDate = new Date(this.datePipe.transform(this.eventInfo.Time.End, 'MM/dd/yyyy'));
    this.startDateTime = this.datePipe.transform(this.eventInfo.Time.Start, 'HH:mm');
    this.startDateTimeChange();
    this.endDateTime = this.datePipe.transform(this.eventInfo.Time.End, 'HH:mm');
  }

  startDateTimeChange(){
    //if (this.startEventDate === this.endEventDate){
      this.endDateTime = "";
      var eventTime: any = [];
      eventTime = this.startDateTime.split(":");
      if (eventTime[1] === "30") {
        this.endTimeList = this.getHoursList((eventTime[0] * 60) + 60);
      } else {
        this.endTimeList = this.getHoursList((eventTime[0] * 60) + 30);
      }
   // } else {
   //   this.endTimeList = this.getHoursList((eventTime[0] * 60) + 30);
   // }
  }

  getHoursList(startTime){
    var x = 30; //minutes interval
    var times = []; // time array
    var tt = startTime; // start time
    var ap = ['AM', 'PM']; // AM-PM
    
    //loop to increment the time and push results in array
    for (var i=0;tt<24*60; i++) {
      var hh = Math.floor(tt/60); // getting hours of day in 0-24 format
      var mm = (tt%60); // getting minutes of the hour in 0-55 format
      times[i] = ("0" + hh ).slice(-2) + ':' + ("0" + mm).slice(-2) ;
      tt = tt + x;
    }
    
    return times;
  }

  save() {

    if (!this.newTimeForAppoinmentForm.valid){
      for (let inner in this.newTimeForAppoinmentForm.controls) {
        this.newTimeForAppoinmentForm.get(inner).markAsTouched();
        this.newTimeForAppoinmentForm.get(inner).updateValueAndValidity();
      }
      this.formValid = false;
      this.toast.warning("Fill the required fields.", "Warning!");
      return;
    }
    var eventData:any = {};
    eventData.comments = this.newTimeForAppoinmentForm.controls.message.value;
    eventData.Start = this.transformDateFormat(this.startEventDate, this.startDateTime);
    eventData.End = this.transformDateFormat(this.endEventDate, this.endDateTime);
    eventData.UserName = localStorage.getItem("UserName");
    eventData.UserSoid =  localStorage.getItem("UserSoid");
    eventData.AuthorName =  localStorage.getItem("NameFirst") + ' ' + localStorage.getItem("NameLast");
    eventData.WholeDay =  false;

    this._eventsService.reSchedule(this.eventInfo.Soid, eventData).subscribe((res: any) => {
      if (res) {
        this.bsModalRef.hide();
        
        this.triggerEvent();
        this.toast.success("Schedule is successfully updated.", "Success!");
      } else {
        this.toast.success("Error updating, Please try again later.", "Success!");
      }
    });

  }

  transformDateFormat(dateValue, time) {
    var eventTime = time.split(":");
    let date = new Date(dateValue);
    date.setHours(eventTime[0]);
    date.setMinutes(eventTime[1]);
    return date;
  }

  triggerEvent() {
    this.event.emit();
  }

}
