import { Component, OnInit, EventEmitter } from '@angular/core';
import { FormBuilder, FormControl, Validators, FormGroup } from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { EventsService } from '../../../services/events.service';

@Component({
  selector: 'app-cancel-appoinment',
  templateUrl: './cancel-appoinment.component.html',
  styleUrls: ['./cancel-appoinment.component.css']
})
export class CancelAppoinmentComponent implements OnInit {

  cancelAppoinmentForm: FormGroup;
  formValid: boolean = true;
  message:string;
  public eventId : Number;
  public status : string;
  header: string;
  closeBtnName: string;
  
  public event: EventEmitter<any> = new EventEmitter();
  constructor(private _eventsService: EventsService, private fb: FormBuilder, private toast: ToastrService,
     public bsModalRef: BsModalRef) { }

  ngOnInit() {
    this.cancelAppoinmentForm = this.fb.group({
      message: new FormControl('', Validators.compose([
        Validators.required
      ]))
    });
  }

  save() {
    if (!this.cancelAppoinmentForm.valid){
      for (let inner in this.cancelAppoinmentForm.controls) {
        this.cancelAppoinmentForm.get(inner).markAsTouched();
        this.cancelAppoinmentForm.get(inner).updateValueAndValidity();
      }
      this.formValid = false;
      this.toast.warning("Fill the required fields.", "Warning!");
      return;
    }
    var data : any = {};
    data.UserName = localStorage.getItem("UserName");
    data.UserSoid =  localStorage.getItem("UserSoid");
    data.AuthorName =  localStorage.getItem("NameFirst") + ' ' + localStorage.getItem("NameLast");
    data.Comments = this.message;
    if (this.status === 'Cancel') {
      this._eventsService.cancelAppointment(this.eventId, data).subscribe((res: any) => {
        if (res) {
          this.bsModalRef.hide();
        this.triggerEvent();
          this.toast.success("Successfully cancelled.", "Success!");
        } else {
          this.toast.success("Error Cancelling, Please try again later.", "Success!");
        }
      });
  } else {
    this._eventsService.completeAppointment(this.eventId, data).subscribe((res: any) => {
      if (res) {
        this.bsModalRef.hide();
        
        
        this.triggerEvent();
        this.toast.success("Successfully Completed.", "Success!");
      } else {
        this.toast.success("Error updating, Please try again later.", "Success!");
      }
    });
  }
  }

  
  
  triggerEvent() {
    this.event.emit();
  }


}
