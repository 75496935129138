<div class="modal-header">
  <h4 class="modal-title pull-left">{{header}}</h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form class="client-modal" [formGroup]="notesForm">

    <div class="form-row">
      <div class="form-group col-md-6">
        <mat-form-field>
          <input type="text"  matInput [(ngModel)]="clientNote"
             placeholder="Module Name" autocomplete="off" maxlength="25"
             formControlName="module">
          <mat-error *ngIf="notesForm.controls.module.errors != null">
            Please enter Module Name
          </mat-error>
        </mat-form-field> 
      </div>
     
    </div>
    <div class="form-row  col-md-8" *ngIf="officeList && officeList.length > 0">
      <mat-form-field>
        <mat-select  placeholder="Office" formControlName="childOffice" [(ngModel)]="officeIds" multiple>
          <mat-option *ngFor="let service of officeList" [value]="service.Soid">{{service.CompanyName}} </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">{{closeBtnName}}</button>
  <button type="button" class="btn btn-primary" (click)="createNewDocument()">{{editModule && editModule.Soid ?  'Save' : 'Create'}}</button>
</div>