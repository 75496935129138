<div class="modal-header">
  <h4 class="modal-title pull-left">{{title}}</h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form class="client-modal" [formGroup]="addClientDocumentForm">
    <div class="form-row">
      <div style="float: left; width: 50px; height:50px;">
        <ngx-avatar size="40" name="{{clientInfo.NameFirst}} {{clientInfo.NameLast}}"></ngx-avatar>
      </div>
      <div style="float: left; width: 80%;">
        <div class="client-list-left">
          <h6 class="mt-0 mb-1">{{clientInfo.NameFirst | titlecase}} {{clientInfo.NameLast | titlecase}}</h6>
          <span><i class="fas fa-at"></i>{{clientInfo.Email}}</span>
        </div>
      </div>
    </div>
    <h6>Select from 'my documents' or upload new</h6>
    <div class="form-row">
      <div class="col-md-6">
        <button class="btn btn-outline-info btn-location" (click)="chosenTab('tab1', 'MY DOCUMENTS')"
          [ngClass]="selectedTab === 'tab1' ? 'active': ''">
          <i class="fas fa-file-alt"></i>
          From 'MY DOCUMENTS'
        </button>
      </div>
      <div class="col-md-6">
        <button class="btn btn-outline-info btn-location" (click)="chosenTab('tab2', 'UPLOAD NEW')"
          [ngClass]="selectedTab === 'tab2' ? 'active': ''">
          <i class="fas fa-upload"></i>
          UPLOAD NEW
        </button>
      </div>
    </div>
    <br />
    <!-- <span>{{selectedTab}}</span> -->
    <div *ngIf="selectedTab === 'tab1'">
      <div class="form-row">
        <div class="form-group selectType col-md-12">
            <mat-form-field>
              <mat-label>Select your files</mat-label>
              <mat-select  formControlName="myDocumentFiles">
                <mat-option *ngFor="let myDocument of myDocumentsList" [value]="myDocument.Soid">
                  {{myDocument.FileName}} 
                </mat-option>
              </mat-select>
              <mat-error *ngIf="addClientDocumentForm.controls.myDocumentFiles.errors != null && !formValid">
                Please select files
              </mat-error>
            </mat-form-field>
          </div>
        </div>
    </div>
    <div *ngIf="selectedTab === 'tab2'">
      <div class="form-row">
        

        <div class="form-group selectType col-md-10">
          <div class="custom-file">
            <input type="file" class="custom-file-input" multiple formControlName="importFile" id="importFile" (change)="onFileChange($event.target.files)">
                          <label class="custom-file-label" #labelImport for="importFile"><i class="fas fa-search"></i> Choose file</label>
                          <mat-error *ngIf="addClientDocumentForm.controls.importFile.errors != null && !formValid">
                            Please upload file
                          </mat-error>
          </div>

          <mat-form-field>
            <input type="text" autocomplete="off" [(ngModel)]="documentTitle" formControlName="documentTitle" matInput placeholder="Document Title">
            <mat-error *ngIf="addClientDocumentForm.controls.documentTitle.errors != null && !formValid">
              Please enter document title
            </mat-error>
          
          </mat-form-field>
          <mat-form-field>
            <input type="text" autocomplete="off" [(ngModel)]="documentDescription" formControlName="documentDescription" matInput placeholder="Document Description (Optional)">
       </mat-form-field>
        
       
          <div class="card-payment-checkbox">
            <mat-checkbox formControlName="addToMyDocuments" >Also Add to 'My Documents'</mat-checkbox>
          </div>
        
      </div>
        
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">{{closeBtnName}}</button>
  <button type="button" class="btn btn-primary" (click)="createNewDocument()">Create</button>
</div>