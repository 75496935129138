import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from  '@angular/router';
import { ChangePasswordComponent } from 'src/app/feature/modal/change-password/change-password.component';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { DatePipe, Location } from '@angular/common'; 
import { ToastrService } from 'ngx-toastr';
import { OfficeService } from 'src/app/services/office.service';
import { XeroService } from 'src/app/services/xero.service';

import { Subscription, interval } from 'rxjs';
import { takeWhile } from 'rxjs/operators';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.css']
})
export class LayoutComponent implements OnInit {

  userName:string;
  officeName: string;
  profilePhoto:string;
  isAdmin:boolean = false;
  isSuperAdmin:boolean = false;
  modalRef: BsModalRef;
  role: string;
  offices: any = [];
  officeInfo: string;
  private intervalSubscription: Subscription;
  private alive = true;
  isXeroTokenExpired = false;

  constructor(private _authService: AuthService, private location: Location, private router: Router, private modalService: BsModalService,private _officeService: OfficeService,
    private _xeroService: XeroService,
    private _router: Router, private toast: ToastrService) { }

  ngOnInit() {
    this.userName = localStorage.getItem("NameFirst");
    this.officeName = localStorage.getItem("OfficeName");

    this.officeInfo = localStorage.getItem("OfficeSoid");
    this.offices = localStorage.getItem("Offices") != null ? JSON.parse(localStorage.getItem("Offices")) : [];
    this._authService.getUserRole().subscribe( (res: string) => {
      if (res) {
        const permissions = this._authService.getPermissions(res);
        this.role = permissions.role;
        this.isAdmin = permissions.isAdmin;
        this.isSuperAdmin = permissions.isSuperAdmin;
      }
    }, (err) => {
      this.toast.error(err.message)
    });
    if (localStorage.getItem("isXeroTokenExpired") && localStorage.getItem("isXeroTokenExpired") == 'true') {
      this.isXeroTokenExpired = true
    }
    this.profilePhoto = localStorage.getItem("ProfilePhoto") != 'null' && localStorage.getItem("ProfilePhoto") ? localStorage.getItem("ProfilePhoto") : "assets/images/avatar.png";
    this.isTokenExpired();
    this.intervalSubscription = interval(300000) // 5 minutes = 300000 milliseconds
    .pipe(
      takeWhile(() => this.alive) // This ensures the interval stops when the component is destroyed
    )
    .subscribe(() => {
      this.isTokenExpired();
    });
  }

  ngOnDestroy() {
    // Unsubscribe from the interval when the component is destroyed
    this.alive = false;
    if (this.intervalSubscription) {
      this.intervalSubscription.unsubscribe();
    }
  }

  changePassword() {
    const initialState = {
      title: "Change Password",
      backdrop: true,
      ignoreBackdropClick: true,
      closeBtnName: "Close",
    };
    this.modalRef = this.modalService.show(ChangePasswordComponent, { initialState });
  }

  logout() {
    this._authService.logout().subscribe((res: any) => {
      if (res) {
        localStorage.removeItem("UserName");
        localStorage.removeItem("LoginSoid");
        localStorage.removeItem("ScreenName");
        localStorage.removeItem("UserSoid");
        localStorage.removeItem("Role");
        localStorage.removeItem("OfficeSoid");
        localStorage.removeItem("OfficeName");
        localStorage.removeItem("NameFirst");
        localStorage.removeItem("Realm");
        localStorage.removeItem("Modules");
        localStorage.removeItem("Token");
        localStorage.clear();
        this.router.navigateByUrl("login");
      } else {
        this.toast.warning("Error While logging out", "Error!");
      }
    }, (err) => {
      this.toast.error(err);
    });

  }

  officeChange() {
    localStorage.setItem("OfficeSoid", this.officeInfo);

    location.reload();
  }


  changeOffice(office) {
    localStorage.setItem("OfficeSoid", office.OfficeSoid);
    localStorage.setItem("OfficeName", office.OfficeName);
    this.officeName = localStorage.getItem("OfficeName");
    this.getOfficeInfo(office.OfficeSoid);
    localStorage.setItem("isXeroTokenExpired", "false");
    this.isXeroTokenExpired = false;
    this.isTokenExpired();
  }

  private isTokenExpired() {
    this._xeroService.isTokenExpired(localStorage.getItem("OfficeSoid")).subscribe((res: any) => {

      if (res != null && res.TokenStatus == "Expired") {
        localStorage.setItem("isXeroTokenExpired", "true");
        if (localStorage.getItem("isXeroTokenExpired") != null && localStorage.getItem("isXeroTokenExpired") == 'true') {
          this.isXeroTokenExpired = true;
        }
      } else {
        this.isXeroTokenExpired = false;
        localStorage.removeItem("isXeroTokenExpired");
      }

      if (res != null && res.Accounts) {        
        localStorage.setItem("Accounts", res.Accounts);
      } else {
        localStorage.removeItem("Accounts");
      }


      if (res != null && res.TaxRates) {        
        localStorage.setItem("XeroTaxRates", res.TaxRates);
      } else {
        localStorage.removeItem("XeroTaxRates");
      }
    });
  }


  private getOfficeInfo(soId: string): void {
    this._officeService.getOfficeInfo(soId)
      .subscribe((res: any) => {
        if (res && res.XeroInfo && res.XeroInfo.Accounts) {
          localStorage.setItem("Accounts", res.XeroInfo.Accounts);

          localStorage.setItem("XeroTaxRates", res.XeroInfo.TaxRates);

        }
         location.reload();
      });

      this._officeService.getModulesInfo(soId).subscribe((res: any) => {
        if (res) {
          localStorage.setItem('Modules', JSON.stringify(res.Modules));
          // localStorage.setItem("BillingAddress", res.BillStreet + ',' + res.response.BillingAddress);
          localStorage.setItem('BillStreet', res.BillingStreet);
          localStorage.setItem('BillingZip', res.BillingZip);
          localStorage.setItem('BillingCountry', res.BillingCountry);
          localStorage.setItem('BillingState', res.BillingState);  
        }
      });
  }


  

}
