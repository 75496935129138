import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {AuthService} from '../services/auth.service';
import {OfficeService} from '../services/office.service';
import {MasterService} from '../services/master.service';
import {ConfirmedValidator} from './confirm-validator.validator';
import { Roles } from '../enum/roles';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;

  employmentForm: FormGroup;
  isSubmitted = false;
  hasLoginError = false;
  errorMessage = '';
  offices: any = [];
  showLoginForm = true;
  showOfficeForm = false;
  showChangePasswordForm = false;
  isNewUser = false;

  formValid = true;

  changePasswordForm: FormGroup;
  authResponse: any = {};
  officeInfo: string = "";
  userParams: any;
  constructor(private _masterService: MasterService, private toast: ToastrService, private _authService: AuthService,
    private router: Router, private _officeService: OfficeService,
    private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      emailId: ['', Validators.required],
      password: ['', Validators.required]
    });
    this.employmentForm = this.formBuilder.group({
      officeList: ['', Validators.required]
    });

    this.changePasswordForm = this.formBuilder.group({
      newPassword: ['', [
        Validators.required, Validators.minLength(8),
        Validators.pattern(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\^$*.\[\]{}()\?\-"!@#%&\/\\,><':;\|_~`\+\=]).{8,}/)
      ]],
      confirmPassword: ['', [Validators.required]]
    }, {
      validator: ConfirmedValidator('newPassword', 'confirmPassword')
    });

    const urlSearch = window.location.search;
    if (!!urlSearch) {
      this.userParams = new URLSearchParams(urlSearch);
      this.showLoginForm = false;
      this.showChangePasswordForm = true;
    }
  }

  encryptSSN() {
    this._authService.encryptSSN().subscribe((res: any) => {
    });

  }

  login(userInfo = null) {
    //  debugger;
    this.hasLoginError = false;
    this.isSubmitted = true;
    if (this.loginForm.invalid && !userInfo) {
      return;
    }
    localStorage.removeItem('Token');

    this._authService.login(userInfo ? userInfo : this.loginForm.value).subscribe((res: any) => {
      this.showLoginForm = true;
      this.showOfficeForm = false;
      this.showChangePasswordForm = false;
      const newPassResponse = 'NEW_PASSWORD_REQUIRED';
      if (res && res.status === 'SUCCESS' || res.status == newPassResponse) {
        this.showLoginForm = false;
        if (res.status == newPassResponse || res.response.IsNew) {
          this.toast.info('Please change your password to continue.', res.response.IsNew ? 'First Time Login' : '');
          this.showChangePasswordForm = true;
          this.isNewUser = res.response.IsNew;
          localStorage.setItem('UserName', res.response.UserName);
          localStorage.setItem('LoginSoid', res.response.LoginSoid);
          localStorage.setItem('ScreenName', res.response.ScreenName);
          localStorage.setItem('UserSoid', res.response.UserSoid);
          localStorage.setItem('Token', res.response.Token);
        } else {
          this.loadOfficeData(res);
        }
      } else if (res && res.status === 'FAILED') {
        this.toast.error(res.error, 'Error!');
      } else {
        this.toast.error("An error has ocurred", 'Error!');
      }
    }, (err) => {
      this.toast.error(err);
    });

  }

  private loadOfficeData(res: any) {
    this.authResponse = res;

    if (res.response.Role == Roles.SuperAdmin) {
      this.showLoginForm = false;
      this.showOfficeForm = true;
      localStorage.setItem('Offices', JSON.stringify(res.response.Offices));
      this.offices = res.response.Offices;
    } else if (res.response.Employments && res.response.Employments.length > 1) {
      this.showLoginForm = false;
      this.showOfficeForm = true;
      this.offices = res.response.Offices;
      if (res.response.Offices.length > 0) {
        res.response.Employments.forEach(element => {
          const office = res.response.Offices.filter(e => (e.OfficeSoid == element));
          if (!(office && office[0])) {
            this.offices.push(office[0]);
          }
        });
        localStorage.setItem('Offices', JSON.stringify(res.response.Offices));
      }

    } else if (res.response.Employments.length == 1) {
      localStorage.setItem('Offices', JSON.stringify(res.response.Offices));
      this.getOfficeInfo(res.response.Employments[0]);

    } else {
      this.toast.warning('Office is unassigned, Please contact Admin.', 'Warning!');
    }
  }

  public getOfficeInfo(officeSoid) {
    localStorage.setItem('OfficeSoid', officeSoid);
    this.loadValues(this.authResponse);

    this._officeService.getOfficeInfo(officeSoid)
    .subscribe((res: any) => {
      if (res && res.XeroInfo && res.XeroInfo.Accounts) {
        localStorage.setItem("Accounts", res.XeroInfo.Accounts);
      }
    this._officeService.getModulesInfo(officeSoid).subscribe((res: any) => {
      if (res) {

        localStorage.setItem('OfficeSoid', officeSoid);
        localStorage.setItem('OfficeName', res.OfficeName);
        localStorage.setItem('Modules', JSON.stringify(res.Modules));
        // localStorage.setItem("BillingAddress", res.BillStreet + ',' + res.response.BillingAddress);
        localStorage.setItem('BillStreet', res.BillingStreet);
        localStorage.setItem('BillingZip', res.BillingZip);
        localStorage.setItem('BillingCountry', res.BillingCountry);
        localStorage.setItem('BillingState', res.BillingState);
        this.router.navigateByUrl('dashboard');

      }
    });
  });

  }

  public goToDashboard() {
    if (this.officeInfo == '') {
      this.toast.warning('Please select office', 'Warning!');
    }
    this.getOfficeInfo(this.officeInfo);
    // 14275869-+ var selectedValue = this.offices.filter(e=> (e.OfficeSoid === this.officeInfo))


  }

  private loadValues(res: any) {
    localStorage.setItem('UserName', res.response.UserName);
    localStorage.setItem('LoginSoid', res.response.LoginSoid);
    localStorage.setItem('ScreenName', res.response.ScreenName);
    localStorage.setItem('UserSoid', res.response.UserSoid);
    localStorage.setItem('NameFirst', res.response.NameFirst);
    localStorage.setItem('NameLast', res.response.NameLast);
    localStorage.setItem('Realm', res.response.Realm);
    localStorage.setItem('Token', res.response.Token);
    localStorage.setItem('ProfilePhoto', res.response.ProfilePhoto);
    this._masterService.fetchMasterList();
  }

  changePassword() {
    for (const inner in this.changePasswordForm.controls) {
      this.changePasswordForm.get(inner).markAsTouched();
      this.changePasswordForm.get(inner).updateValueAndValidity();
    }
    if (!this.changePasswordForm.valid) {
      this.formValid = false;
      this.toast.warning('Fill the required fields.', 'Warning!');
      return;
    }

    const password = btoa(this.changePasswordForm.controls.newPassword.value)

    if (!!this.userParams) {
      let userEmail = this.userParams.get('userEmail');
      let recoveryCode = this.userParams.get('recoveryCode');
      this._authService.confirmForgotPassword(userEmail, password, recoveryCode).subscribe((res: any) => {
        if (!!res) {
          this.showChangePasswordForm = false;
          this.toast.success("Password Changed Successfully.", "Success!");
          window.location.replace(window.location.pathname);
        } else {
          this.toast.warning("Error Changing Password", "Error!");
        }
      }, (err) => {
        this.toast.error(err);
      });
    } else {
      const userInfo = {emailId: this.loginForm.value.emailId, password: this.changePasswordForm.controls.newPassword.value};
      userInfo.password = this.changePasswordForm.controls.newPassword.value
      userInfo.emailId = this.loginForm.value.emailId;

      this._authService.FirstLoginChangePassword(password).subscribe((res: any) => {
        if (res.status === 'SUCCESS') {
          this.showChangePasswordForm = false;
          this.toast.success('Password Changed Successfully.', 'Success!');
          this.login(userInfo);
        } else {
          this.toast.warning('Error Changing Password', 'Error!');
        }
      }, (err) => {
        this.toast.error(err);
      });
    }
  }

}
