import { Component, OnInit, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ServicesListService } from '../../../services/services.service';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject } from 'rxjs';
import { StaffService } from '../../../services/staff.service';
import { DocumentsService } from '../../../services/documents.service';

@Component({
  selector: 'app-add-product',
  templateUrl: './add-product.component.html',
  styleUrls: ['./add-product.component.css']
})
export class AddProductComponent implements OnInit {


  title: string = "Record Payment";
  selectCallType: string;
  closeBtnName: string = "Close";
  selectedTab: string = "tab1"; //Initial tab active
  paymentTypes: Array<string> = ['USD', 'POUNDS', 'TINAR', 'BHAT'];
  addProductDocumentForm: FormGroup;
  feeField: boolean = true;
  serviceName: string;
  uploadType: string = "MY DOCUMENTS";
  phoneType: string;
  phoneValue: string;
  onlineType: string;
  onlineValue: string;
  faceType: string;
  faceValue: string;
  durationHour: string;
  durationMinutes: string;
  servicePrice: string;
  currency: string;
  module: string;
  formValid: boolean = true;
  locationModeObserver: Subject<string> = new Subject<string>();
  priceChangeObserver: Subject<string> = new Subject<string>();
  subscriptionServices: any = [];
  public event: EventEmitter<any> = new EventEmitter();
  public clientInfo: any = [];
  staffList: any = [];
  myDocumentsList: any = [];
  @ViewChild('labelImport')
  labelImport: ElementRef;
  agelimit: number = 10;

  formImport: FormGroup;
  fileToUpload: File = null;



  onFileChange(files: FileList) {
    this.labelImport.nativeElement.innerText = Array.from(files)
      .map(f => f.name)
      .join(', ');
    this.fileToUpload = files.item(0);
  }


  constructor(private toast: ToastrService, private _service: ServicesListService,
    private _documentService: DocumentsService,
    public bsModalRef: BsModalRef, sb: FormBuilder,
    private _staffService: StaffService,) {


  }



  ngOnInit() {

    this.getStaffList();
    this.getMyDocumentList();

    this.addProductDocumentForm = new FormGroup({
      serviceProduct: new FormControl('', [Validators.required]),
      productPrice: new FormControl('',[Validators.required]),
      tax1: new FormControl(''),
      tax2: new FormControl(''),
      tax3: new FormControl()
    });

  }


  createNewDocument() {
    for (let inner in this.addProductDocumentForm.controls) {
      this.addProductDocumentForm.get(inner).markAsTouched();
      this.addProductDocumentForm.get(inner).updateValueAndValidity();
    }
    if (!this.addProductDocumentForm.valid) {
      this.formValid = false;
      this.toast.warning("Fill the required fields.", "Warning!");
      return;
    }
    const testData = new FormData();
    testData.append('UploadType', this.uploadType);
    
    if (this.uploadType === "MY DOCUMENTS") {
      testData.append('AddToMyDocuments', "false");
    } else {
      testData.append('File', this.fileToUpload);
      testData.append('Size', this.formatBytes(this.fileToUpload.size));
      
     // testData.append('AddToMyDocuments', this.addClientDocumentForm.controls.AddToMyDocuments.value);      
    }


    let userFullName = localStorage.getItem("NameFirst") + " " + localStorage.getItem("NameLast");
    
    

    
    this._documentService.createNewDocument(testData).subscribe((res: any) => {
      if (res) {
        this.toast.success("Document created successfully.", "Success!");
        this.triggerEvent();
        //this.bsModalRef.hide();
      } else {
        this.toast.warning("Error Creating service", "Error!");
      }
    });

  }

  triggerEvent() {
    this.event.emit();
  }

  private getStaffList() {
    this._staffService.getActiveStaffs().subscribe((res: any) => {
      if (res) {
        this.staffList = res;
      } else {
        this.staffList = [];
      }
    });
  }

  private getMyDocumentList() {
    this._documentService.getMyDocumentsList().subscribe((res: any) => {
      if (res) {
        this.myDocumentsList = res;
      }
    });
  }

  chosenTab(tab, locationMode) {
    this.locationModeObserver.next(locationMode);
    this.uploadType = locationMode;
    return this.selectedTab = tab;
  }

  formatBytes(bytes) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const decimals = 2;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}
}
