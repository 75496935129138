import { Component, OnInit, EventEmitter } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ClientService } from '../../../services/client.service';
import { EventPlannerComponent } from '../../../base/eventplanner/eventplanner.component';
import { AddClientDocumentsComponent } from '../add-client-documents/add-client-documents.component';
import { ServiceInvoiceComponent } from '../service-invoice/service-invoice.component';
import { RecordPaymentComponent } from '../record-payment/record-payment.component';
import { ServiceEstimateComponent } from '../service-estimate/service-estimate.component';
import { AddClientComponent } from '../add-client/add-client.component';
import { ListPaymentComponent } from '../list-payments/list-payments.component';
import { ListInvoiceComponent } from '../list-invoice/list-invoice.component';

@Component({
  selector: 'app-searchclient',
  templateUrl: './searchclient.component.html',
  styleUrls: ['./searchclient.component.css']
})
export class SearchclientComponent implements OnInit {
  title: string;
  searchText: string;
  clientList: any = [];
  public selectedDate: string;
  public selectedStaff: string;
  public selectedService: string;
  public nextStep: string;
  modalRef: BsModalRef;
  public event: EventEmitter<any> = new EventEmitter();
  closeBtnName: string = "Close";


  constructor(private modalService: BsModalService, private _clientService: ClientService, public bsModalRef: BsModalRef) { }

  ngOnInit() {
    this.getClientList();
  }

  private getClientList() {
    this._clientService.getClientList().subscribe((res: any) => {
      if (res) {
        this.clientList = res;
      }
    });
  }


  logEvent(loginId) {
    var clientInfo = this.clientList.filter(proj => proj.Soid === loginId);
    clientInfo[0].Email = clientInfo[0].EmailAddress;
    if (this.nextStep === "Document") {
      this.uploadDocument(clientInfo[0]);
    } else if (this.nextStep === "Invoice") {
      this.showInvoice(clientInfo[0]);
    } else if (this.nextStep === "RecordPayment") {
      this.showInvoiceList(clientInfo[0]);
    } else if (this.nextStep === "Estimate") {
      this.serviceEstimate(clientInfo[0]);
    } else {
      this.openEventPlanner(clientInfo[0]);
    }
  }

  showRecordPayment(clientInfo) {
    this.bsModalRef.hide();

    const initialState = {
      header: "Record Payment",
      backdrop: true,
      ignoreBackdropClick: true,
      clientInfo: clientInfo,
    };
    this.modalRef = this.modalService.show(RecordPaymentComponent, { initialState });
    this.modalRef.content.event.subscribe(data => {
      this.triggerEvent();
    });
  }

  serviceEstimate(clientInfo) {
    this.bsModalRef.hide();
    const initialState = {
      header: "Invoice",
      backdrop: true,
      ignoreBackdropClick: true,
      clientInfo: clientInfo,
    };
    this.modalRef = this.modalService.show(ServiceEstimateComponent, { initialState });
    
    this.modalRef.setClass('invoice-dialog');
    this.modalRef.content.event.subscribe(data => {
     // this.triggerEvent();
    });
  }

  showInvoice(clientInfo) {
    this.bsModalRef.hide();
    const initialState = {
      header: "New Invoice",
      backdrop: true,
      ignoreBackdropClick: true,
      clientInfo: clientInfo,
    };
    this.modalRef = this.modalService.show(ServiceInvoiceComponent, { initialState });
    this.modalRef.setClass('invoice-dialog');
    this.modalRef.content.event.subscribe(data => {
      this.triggerEvent();
    });
  }


  showInvoiceList(clientInfo) {
    this.bsModalRef.hide();
    const initialState = {
      header: "Invoice List",
      backdrop: true,
      ignoreBackdropClick: true,
      clientInfo: clientInfo,
    };
    this.modalRef = this.modalService.show(ListInvoiceComponent, { initialState });
    this.modalRef.content.event.subscribe(data => {
      this.triggerEvent();
      
    });
  }

  uploadDocument(clientInfo) {

    this.bsModalRef.hide();

    const initialState = {
      header: "New Client Document",
      backdrop: true,
      ignoreBackdropClick: true,
      clientInfo: clientInfo,
    };
    this.modalRef = this.modalService.show(AddClientDocumentsComponent, { initialState });
    this.modalRef.content.event.subscribe(data => {
      this.triggerEvent();
    });
  }

  openEventPlanner(clientInfo) {
    this.bsModalRef.hide();
    const initialState = {
      header: "New Appointment",
      //services: clientInfo[0].tag.split(","),
      clientInfo: clientInfo,
      backdrop: true,
      ignoreBackdropClick: true,
      selectedDate: this.selectedDate,
      selectedService: this.selectedService,
      selectedStaff: this.selectedStaff
    };
    this.modalRef = this.modalService.show(EventPlannerComponent, { initialState });
  }


  triggerEvent() {
    this.event.emit();
  }


  openNewClientDialog() {

    this.bsModalRef.hide();

    const initialState = {
      source: "APPOINTMENT"
    };

    
    this.modalRef = this.modalService.show(AddClientComponent, { initialState });
  //   this.modalRef.content.event.subscribe(data => {
  //     this.triggerEvent();
  //   });
  // }
  }

}
