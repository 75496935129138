import { Component, OnInit, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup, FormControl,  Validators} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { DocumentsService } from '../../../services/documents.service';

@Component({
  selector: 'app-edit-document',
  templateUrl: './edit-document.component.html',
  styleUrls: ['./edit-document.component.css']
})
export class EditDocumentComponent implements OnInit {


  closeBtnName: string = "Close";
  editDocument: FormGroup;
 
  formValid: boolean = true;
  public event: EventEmitter<any> = new EventEmitter();
  public header: string;
  public documentInfo: any= {};
  documentTitle : "";
  documentDescription: "";
  resetVar: false;

  constructor(private toast: ToastrService, private _documentService: DocumentsService,
    public bsModalRef: BsModalRef, sb: FormBuilder) {
  }

  ngOnInit() {
    this.documentTitle = this.documentInfo.DocumentTitle;
    this.documentDescription = this.documentInfo.DocumentDescription;


    this.editDocument = new FormGroup({
      documentTitle: new FormControl('',[Validators.required]),
      documentDescription: new FormControl(),
    });
  }

  createNewDocument() {

    var updateFileUploadInfo : any = {};
    updateFileUploadInfo.DocumentTitle = this.documentTitle;
    updateFileUploadInfo.DocumentDescription = this.documentDescription;
    updateFileUploadInfo.Soid = this.documentInfo.Soid;


    this._documentService.updateFileUpload(updateFileUploadInfo).subscribe((res: any) => {
      if (res) {
        this.toast.success("Document updated successfully.", "Success!");
        this.triggerEvent();
        this.bsModalRef.hide();
      } else {
        this.toast.warning("Error Creating service", "Error!");
      }
    });

  }

  triggerEvent() {
    this.event.emit();
  }
}
