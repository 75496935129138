<div class="modal-header">
  <h4 class="modal-title pull-left">{{header}}</h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form class="client-modal" [formGroup]="addClientInsuranceForm">
    <div class="form-row">
      <div class="form-group col-md-6">
        <mat-form-field>                                                                                            
          <mat-label>Insurance</mat-label>
          <mat-select formControlName="insurance"  [(ngModel)]="insurance" (selectionChange)="updateValidators()" >
            <mat-option *ngFor="let insurance of insuranceCategory" [value]="insurance.code">{{insurance.name}}</mat-option>
          </mat-select>
        <mat-error *ngIf="addClientInsuranceForm.controls.insurance.errors != null && !formValid">
          Please select insurance
        </mat-error>
      </mat-form-field>
      </div>
      <div class="form-group col-md-6" *ngIf="insurance == 'Personal'">
        <mat-form-field>                                                                                            
          <mat-label>Personal Insurance</mat-label>
          <mat-select formControlName="personal"  [(ngModel)]="personal">
            <mat-option *ngFor="let personal of personalInsurance" [value]="personal.code">{{personal.name}}</mat-option>
          </mat-select>
        <mat-error *ngIf="addClientInsuranceForm.controls.personal.errors != null && !formValid">
          Please select personal insurance
        </mat-error>
      </mat-form-field>
      </div>
      <div class="form-group col-md-6" *ngIf="insurance == 'Commercial'">
        <mat-form-field>                                                                                            
          <mat-label>Commercial Insurance</mat-label>
          <mat-select formControlName="commercial"  [(ngModel)]="commercial">
            <mat-option *ngFor="let commercial of commericalInsurance" [value]="commercial.code">{{commercial.name}}</mat-option>
          </mat-select>
        <mat-error *ngIf="addClientInsuranceForm.controls.commercial.errors != null && !formValid">
          Please select Commercial insurance
        </mat-error>
      </mat-form-field>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-md-6">
          <mat-form-field>
              <input type="text" autocomplete="off" formControlName="insuranceCompany" matInput placeholder="Insurance Company">
              <mat-error *ngIf="addClientInsuranceForm.controls.insuranceCompany.errors != null && !formValid">
                Please enter first name
              </mat-error>
          </mat-form-field>
         
      </div>
      <div class="form-group col-md-6">
          <mat-form-field>
              <input type="text" autocomplete="off" formControlName="policyNo" matInput placeholder="Policy No#">
              <mat-error *ngIf="addClientInsuranceForm.controls.policyNo.errors != null && !formValid">
                  Please enter last name
                </mat-error>
          </mat-form-field>
      </div>
  </div>

    
    <div class="form-row">
      <div class="form-group col-md-6">
        <mat-form-field>
          <input matInput readonly formControlName="policyTermFrom"
             [(ngModel)]="policyTermFrom"  [matDatepicker]="policyTermFrompicker" placeholder="Policy Term From">
          <mat-datepicker-toggle matSuffix [for]="policyTermFrompicker"></mat-datepicker-toggle>
          <mat-datepicker #policyTermFrompicker></mat-datepicker>
          <mat-error *ngIf="addClientInsuranceForm.controls.policyTermFrom.errors != null && !formValid">
            Please enter date of birth
          </mat-error>
        </mat-form-field>
      </div>
      <div class="form-group col-md-6">
        <mat-form-field>
          <input matInput readonly formControlName="policyTermTo"
             [(ngModel)]="policyTermTo"  [matDatepicker]="policyTermTopicker" placeholder="Policy Term To">
          <mat-datepicker-toggle matSuffix [for]="policyTermTopicker"></mat-datepicker-toggle>
          <mat-datepicker #policyTermTopicker></mat-datepicker>
          <mat-error *ngIf="addClientInsuranceForm.controls.policyTermTo.errors != null && !formValid">
            Please enter date of birth
          </mat-error>
        </mat-form-field>
      </div>
    </div>
</form>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">{{closeBtnName}}</button>
  <button type="button" class="btn btn-primary" (click)="addDependent()">Create</button>
</div>