import { FormBuilder, FormGroup } from '@angular/forms';
import { Component, ComponentFactoryResolver, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
//import {PageEvent} from '@angular/material/paginator/api/pageevent';
import { ClientService } from '../../services/client.service';
import { MasterService } from '../../services/master.service';
import { USStatesService } from '../../services/us-states.service';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { EventPlannerComponent } from '../../base/eventplanner/eventplanner.component';
import * as _ from 'underscore';
import { SearchclientComponent } from '../modal/searchclient/searchclient.component';
import { AuthService } from '../../services/auth.service';
import { OfficeService } from '../../services/office.service';

import { Country } from '@angular-material-extensions/select-country';
import { PageEvent } from '@angular/material/paginator';
import { AddClientComponent } from '../modal/add-client/add-client.component';
@Component({
  selector: 'app-client',
  templateUrl: './client.component.html',
  styleUrls: ['./client.component.css']
})
export class ClientComponent implements OnInit {
  searchText: string;
  lowValue: number = 0;
  highValue: number = 5;
  sortBy: string = "";
  filterBy: string = "Active";
  officeList: any = [];
  selectedOffice: string = "";
  clientList: any = [];
  modules: any[] = [];
  selectedOffices: any[] = [];

  // MatPaginator Inputs
  clientLength = Number;
  pageIndex: number = 0;
  clientPageSize = 5;
  clientPageSizeOptions: number[] = [5, 10, 25, 100];

  modalRef: BsModalRef;
  isAdmin: boolean = false;

  constructor(private _authService: AuthService, private masterService: MasterService, private usStatesService: USStatesService,
    private toast: ToastrService, private datePipe: DatePipe, private _clientService: ClientService, private _officeService: OfficeService,
    private modalService: BsModalService, private _router: Router, private fb: FormBuilder,) { }

  ngOnInit() {

    this._authService.getUserRole().subscribe((res: string) => {
      if (res) {
        const permissions = this._authService.getPermissions(res);
        this.isAdmin = permissions.isAdmin;
      }
    }, (err) => {
      this.toast.error(err.message)
    });

    this.getClientList();

    this.selectedOffice = localStorage.getItem("OfficeSoid");
    this.getOfficeList();
  }

  private getOfficeList() {
    this._officeService.getOfficeListByUser(localStorage.getItem("UserSoid")).subscribe((res: any) => {
      if (res) {
        this.officeList = res.Office;
      } else {
        this.officeList = [];
      }
    });
  }

  private officeChange() {
    this.modules = [];
    if (this.selectedOffices.length > 0) {
      let officeModules: any[] = this.selectedOffices
        .map(office => office.Modules)
        .reduce((a, b) => a.concat(b), []);
      officeModules.forEach(module => {
        // Check if the module doesn't already exist in this.modules
        if (!this.modules.find(m => m.Soid == module.Soid && m.Name == module.Name)) {
          // console.log('Module does not exist in this.modules:', module);
          this.modules.push(module);
          // Check if the module exists in all selected offices
          if (officeModules.filter(m => m.Soid == module.Soid && m.Name == module.Name).length == this.selectedOffices.length) {
            //this.modules.push(module);
          }
        }
      });

    }
  }

  filterClientList(event) {
    this.filterBy = event.srcElement.value;
    this.getClientList();
  }


  public getOfficeFilter(event) {
    this._clientService.getClientByOffice(this.selectedOffice, this.filterBy).subscribe((res: any) => {
      if (res) {
        this.clientLength = res.length;
        this.clientList = res;
      }
    });
  }

  private getClientList() {
    this._clientService.getClientListFilterBy(this.filterBy).subscribe((res: any) => {
      if (res) {
        this.clientLength = res.length;
        this.clientList = res;
      }
    });
  }

  public getClientPaginatorData(event: PageEvent): PageEvent {
    this.lowValue = event.pageIndex * event.pageSize;
    this.highValue = this.lowValue + event.pageSize;
    return event;
  }

  editClient(clientId) {
    this._router.navigate(['client/' + clientId]);
  }

  openPlannerModal() {
    const initialState = {
      title: "Client search",
      backdrop: true,
      ignoreBackdropClick: true,
      closeBtnName: "Close",
    };
    this.modalRef = this.modalService.show(SearchclientComponent, { initialState });
  }

  sortFieldChange(event) {
    this.sortBy = event.srcElement.value;
    this.clientList = _.sortBy(this.clientList, this.sortBy);
  }

  openNewClientDialog() {
    const initialState = {
      source: "APPOINTMENT"
    };
    this.modalRef = this.modalService.show(AddClientComponent, { initialState });
  }
}
