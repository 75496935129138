<div class="modal-header">
  <h4 class="modal-title pull-left">Add New Client</h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body contentScroll" style="height: 450px; overflow-y: auto; overflow-x: hidden;">
  <form class="client-modal" [formGroup]="clientSubmitForm">
    <div class="form-row">
      <div class="form-group col-md-6">
        <mat-form-field>
          <input type="email" autocomplete="off" required formControlName="email" matInput placeholder="Email">
          <mat-error *ngIf="clientSubmitForm.controls.email.errors != null && 
                      clientSubmitForm.controls.email.errors.email != null">
            Please enter a valid email address
          </mat-error>
          <mat-error *ngIf="clientSubmitForm.controls.email.errors != null && 
                      clientSubmitForm.controls.email.errors.required != null">
            Please enter email address
          </mat-error>
        </mat-form-field>
      </div>
      <div class="form-group col-md-6">
       
        
          <mat-form-field>
            <mat-label>Personal Mobile</mat-label>
            <ngx-mat-intl-tel-input (countryChanged)="countryChange($event)" (focusout)="phoneNumberFieldFocusOut($event)"
            [preferredCountries]="['us', 've']" [enablePlaceholder]="true" [enableSearch]="true" name="personalMobile"
            id="personalMobile" format="national" formControlName="mobilePhone" placeholder="Personal Mobile"
            #phoneField>
            </ngx-mat-intl-tel-input>
            <mat-error *ngIf="clientSubmitForm.controls.mobilePhone.errors?.required">Please enter valid mobile
              number</mat-error>
            <mat-error *ngIf="clientSubmitForm.controls.mobilePhone.errors?.validatePhoneNumber">Please enter valid
              mobile number</mat-error>
            <mat-error *ngIf="clientSubmitForm.controls.mobilePhone.errors?.maxlength">Please enter valid mobile
              number</mat-error>
        </mat-form-field>
         
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-md-6">
        <mat-form-field>
          <input type="text" autocomplete="off" required formControlName="firstName" matInput placeholder="First Name">
          <mat-error *ngIf="clientSubmitForm.controls.firstName.errors != null && !formValid">
            Please enter first name
          </mat-error>
        </mat-form-field>

      </div>
      <!--<div class="form-group col-md-4">
        <mat-form-field>
          <input type="text" autocomplete="off" formControlName="middleName" matInput placeholder="Middle Name">
          <mat-error *ngIf="clientSubmitForm.controls.middleName.errors != null && !formValid">
            Please enter middle name
          </mat-error>
        </mat-form-field>
      </div>-->
      <div class="form-group col-md-6">
        <mat-form-field>
          <input type="text" autocomplete="off" required formControlName="lastName" matInput placeholder="Last Name">
          <mat-error *ngIf="clientSubmitForm.controls.lastName.errors != null && !formValid">
            Please enter last name
          </mat-error>
        </mat-form-field>
      </div>
    </div>

    <div class="form-row">

      <div class="form-group col-md-6">
        <!-- <mat-form-field floatLabel="always">
          <mat-label class="label">Due On</mat-label>
          <input pickerType="calendar" matInput class="data" 
           [owlDateTimeTrigger]="dt" [owlDateTime]="dt" formControlName="dob" [(ngModel)]="dueOn">
          <owl-date-time #dt [hour12Timer]="true"></owl-date-time>
          <mat-error *ngIf="clientSubmitForm.controls.dob.errors != null && !formValid">
            Please enter due date
          </mat-error>
        </mat-form-field> -->
        <mat-form-field>
          <input matInput [max]="agelimit" autocomplete="off" formControlName="dob" [matDatepicker]="picker"
            placeholder="DOB">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
          <mat-error *ngIf="clientSubmitForm.controls.dob.errors != null && !formValid">
            Please select date of birth
          </mat-error>
        </mat-form-field>
        </div>
        <!-- <div class="form-group col-md-6">

        <mat-form-field>
          <input matInput [max]="agelimit" autocomplete="off" formControlName="dob" [mask]="clientMask"
          [showMaskTyped]="true" placeHolderCharacter="*"   placeholder="DOB">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
          <mat-error *ngIf="clientSubmitForm.controls.dob.errors != null && !formValid">
            Please select date of birth
          </mat-error>
        </mat-form-field>
      </div> -->
      <div class="form-group col-md-6">
        <mat-form-field>
          <mat-label>Martial Status</mat-label>
          <mat-select formControlName="martialStatus">
            <mat-option *ngFor="let martial of martialStatus" [value]="martial.code">{{martial.name}}</mat-option>
          </mat-select>
          <mat-error *ngIf="clientSubmitForm.controls.martialStatus.errors != null && !formValid">
            Please select Martial Status
          </mat-error>
        </mat-form-field>
      </div>
    </div>

    <div class="form-row">
      <div class="form-group" [ngClass]="(idType == 'SSN' || idType == 'ITIN Number')   ? 'col-md-6' : 'col-md-12'">
        <mat-form-field>
          <mat-label>ID Proof</mat-label>
          <mat-select (selectionChange)="updateValidators()" formControlName="idType" [(ngModel)]="idType">
            <mat-option *ngFor="let type of idTypes" [value]="type.code">{{type.name}}</mat-option>
          </mat-select>
          <mat-error *ngIf="clientSubmitForm.controls.idType.errors != null && !formValid">
            Please select ID Proof
          </mat-error>
        </mat-form-field>
      </div>
      <div class="form-group col-md-6" *ngIf="idType == 'SSN'">
        <mat-form-field>
          <input type="text" maxlength="11" [(ngModel)]="hiddenSSN" [hiddenInput]="true" mask="XXX-XX-0000"
            autocomplete="off" formControlName="ssn" matInput placeholder="SSN*">

          <input type="hidden" [(ngModel)]="hiddenSSN" formControlName="actualSSN">
          <mat-error *ngIf="clientSubmitForm.controls.ssn.errors != null && 
                      clientSubmitForm.controls.ssn.errors.mask != null">
            Please enter valid SSN
          </mat-error>
          <mat-error *ngIf="clientSubmitForm.controls.ssn.errors != null && 
                      clientSubmitForm.controls.ssn.errors.required != null">
            Please enter SSN
          </mat-error>

        </mat-form-field>
      </div>
      <div class="form-group col-md-6" *ngIf="idType == 'ITIN Number'">
        <mat-form-field>
          <input type="text" prefix="9" mask="00-00-0000" [showMaskTyped]="true" autocomplete="off" maxlength="14"
          formControlName="itinNumber" matInput placeholder="ITIN Number">
          <mat-error *ngIf="clientSubmitForm.controls.itinNumber.errors != null && 
            clientSubmitForm.controls.itinNumber.errors.mask != null">
            Please enter valid ITIN Number
          </mat-error>
          <mat-error *ngIf="clientSubmitForm.controls.itinNumber.errors != null && 
            clientSubmitForm.controls.itinNumber.errors.required != null">
            Please enter ITIN Number
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="form-row" *ngIf="idType == 'Driving License'">
      <div class="form-group col-md-6">
        <mat-form-field>
          <input type="text" autocomplete="off" maxlength="20" formControlName="dlNumber" matInput
             placeholder="Driving License Number">
          <mat-error *ngIf="clientSubmitForm.controls.dlNumber.errors != null && 
          clientSubmitForm.controls.dlNumber.errors.required != null && !formValid">
            Please enter driving license number
          </mat-error>
          <mat-error *ngIf="clientSubmitForm.controls.dlNumber.errors != null && 
          clientSubmitForm.controls.dlNumber.errors.pattern != null && !formValid">
            Please enter valid license number
          </mat-error>
        </mat-form-field>
      </div>
      <div class="form-group col-md-6">
   
        <mat-form-field>
          <mat-label>Driving License State</mat-label>
          <mat-select formControlName="dlState">
            <mat-option *ngFor="let states of usStates" [value]="states.abbreviation">{{states.name}}</mat-option>
          </mat-select>
          <mat-error *ngIf="clientSubmitForm.controls.dlState.errors != null && !formValid">
            Please select driving license state
          </mat-error>
        </mat-form-field>
      </div>
      <div class="form-group col-md-6">
        <mat-form-field>
          <input matInput [max]="currentDate" autocomplete="off" [matDatepicker]="dlStartDate"
            formControlName="dlStartDate" placeholder="DL Issue Date">
          <mat-datepicker-toggle matSuffix [for]="dlStartDate"></mat-datepicker-toggle>
          <mat-datepicker #dlStartDate></mat-datepicker>
          <mat-error *ngIf="clientSubmitForm.controls.dlStartDate.errors != null && !formValid">
            Please select driving license start date
          </mat-error>
        </mat-form-field>
      </div>
      <div class="form-group col-md-6">
        <mat-form-field>
          <input matInput [min]="currentDate" autocomplete="off" [matDatepicker]="dlExpirationDate"
            formControlName="dlExpirationDate" placeholder="DL Expiration Date">
          <mat-datepicker-toggle matSuffix [for]="dlExpirationDate"></mat-datepicker-toggle>
          <mat-datepicker #dlExpirationDate></mat-datepicker>
          <mat-error *ngIf="clientSubmitForm.controls.dlExpirationDate.errors != null && !formValid">
            Please select driving license expiration date
          </mat-error>
        </mat-form-field>
      </div>

    </div>
    <div class="form-row" *ngIf="idType == 'Passport'">
      <div class="form-group col-md-6">
        <mat-form-field>
          <input type="text" autocomplete="off" maxlength="20" formControlName="passportNumber" matInput
             placeholder="Passport Number">
          <mat-error *ngIf="clientSubmitForm.controls.passportNumber.errors != null && 
          clientSubmitForm.controls.passportNumber.errors.required != null && !formValid">
            Please enter driving license number
          </mat-error>
          <mat-error *ngIf="clientSubmitForm.controls.passportNumber.errors != null && 
          clientSubmitForm.controls.passportNumber.errors.pattern != null && !formValid">
            Please enter valid license number
          </mat-error>
        </mat-form-field>
      </div>
      <div class="form-group col-md-6">
          <mat-select-country autocomplete="off" appearance="legacy" placeHolder="Issued Country" label="Issued Country"
            formControlName="passportCountry">
        </mat-select-country>
      </div>
      <div class="form-group col-md-6">
        <mat-form-field>
          <input matInput [max]="currentDate" autocomplete="off" [matDatepicker]="IssueDate"
            formControlName="passportIssueDate" placeholder="Issued Date">
          <mat-datepicker-toggle matSuffix [for]="IssueDate"></mat-datepicker-toggle>
          <mat-datepicker #IssueDate></mat-datepicker>
          <mat-error *ngIf="clientSubmitForm.controls.passportIssueDate.errors != null && !formValid">
            Please select passport issue date
          </mat-error>
        </mat-form-field>
      </div>
      <div class="form-group col-md-6">
        <mat-form-field>
          <input matInput [min]="currentDate" autocomplete="off" [matDatepicker]="expirationDate"
            formControlName="passportExpirationDate" placeholder="Expiration Date">
          <mat-datepicker-toggle matSuffix [for]="expirationDate"></mat-datepicker-toggle>
          <mat-datepicker #expirationDate></mat-datepicker>
          <mat-error *ngIf="clientSubmitForm.controls.passportExpirationDate.errors != null && !formValid">
            Please select passport expiration date
          </mat-error>
        </mat-form-field>
      </div>

    </div>
    <div class="form-row">

      <div class="form-group col-md-6">
        <mat-form-field>
          <mat-select formControlName="status" placeholder="Status">
            <mat-option *ngFor="let status of clientStatus" [value]="status.code">{{status.name}}</mat-option>
          </mat-select>
          <mat-error *ngIf="clientSubmitForm.controls.status.errors != null && !formValid">
            Please select status
          </mat-error>
        </mat-form-field>
      </div>
      <div class="form-group col-md-6">
        <mat-form-field>
          <input matInput autocomplete="off" formControlName="lmsMemberDate" [matDatepicker]="lmsMemberDate"
            placeholder="LMS Member Date">
          <mat-datepicker-toggle matSuffix [for]="lmsMemberDate"></mat-datepicker-toggle>
          <mat-datepicker #lmsMemberDate></mat-datepicker>
          <mat-error *ngIf="clientSubmitForm.controls.lmsMemberDate.errors != null && !formValid">
            Please select LMS Member Date
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="form-row" *ngIf="officeList && officeList.length > 0">
      <div class="form-group col-md-12">
        <mat-form-field>
          <mat-select  placeholder="Office" formControlName="office" [(ngModel)]="selectedOffices" (selectionChange)="officeChange()" multiple>
            <mat-option *ngFor="let office of officeList" [value]="office">{{office.CompanyName}}</mat-option>
          </mat-select>
          <mat-error *ngIf="clientSubmitForm.controls.office.errors != null && !formValid">
            Please select office
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-md-12">
        <mat-form-field>
          <mat-label>Modules</mat-label>
          <mat-select [formControl]="moduleControl" multiple>

            <mat-select-trigger>
              <mat-chip-list>
                <mat-chip *ngFor="let module of moduleControl.value" [removable]="true"
                  (removed)="onModuleRemoved(module)">
                  {{ module.Name }}
                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
              </mat-chip-list>
            </mat-select-trigger>

            <mat-option *ngFor="let module of modules" [value]="module">{{module.Name}}
            </mat-option>

          </mat-select>
          <mat-error *ngIf="moduleControl.errors != null && !formValid">
            Please select modules
          </mat-error>
        </mat-form-field>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="modalRef.hide()">Close</button>
  <button type="button" class="btn btn-primary" (click)="saveClient()">Save changes</button>
</div>