<section class="home-section">
   <div class="container d-flex h-85">
      <div class="row center">

         <div class="col-lg-8 d-lg-flex d-none">
            <div class="img-holder"> <img src="assets/images/1.png" alt="holder"></div>
         </div>
         <div class="col-lg-4 col-md-8 col-sm-12">
            <div class="form-body mb-4 mt-4">
               <div class="logo">
                  <img src="assets\images\logo-png\full logo in white.png" title="LMS" />
               </div>
               <div class="form-text" *ngIf="!showChangePasswordForm">
                  <h5>Login</h5>
                  <p>Sign in to your account to continue.</p>
               </div>

               <div class="form-text" *ngIf="showChangePasswordForm">
                  <h5 *ngIf="isNewUser">First Time Login!!</h5>
                  <h5 *ngIf="!!userParams && !isNewUser">Recover your Account</h5>
                  <p>Change your password to continue.</p>
               </div>

               <div class="form-content" *ngIf="showChangePasswordForm">
                  <form [formGroup]="changePasswordForm">

                     <div class="help-block" *ngIf="changePasswordForm.controls.newPassword.errors && changePasswordForm.controls.newPassword.errors.required != null && !formValid">
                        Please enter new password
                     </div>
                      <div class="help-block" *ngIf="changePasswordForm.controls.newPassword.errors && changePasswordForm.controls.newPassword.errors.minlength != null && !formValid">
                        Password must be at least 8 characters
                      </div>
                      <div class="help-block" *ngIf="changePasswordForm.controls.newPassword.errors && changePasswordForm.controls.newPassword.errors.pattern != null && !formValid">
                        Password must contain:
                        <ul>
                           <li>At least 1 number</li>
                           <li>At least 1 special character</li>
                           <li>At least 1 uppercase letter</li>
                           <li>At least 1 lowercase letter</li>
                        </ul>
                      </div>

                      <div class="help-block" *ngIf="changePasswordForm.controls.confirmPassword.errors && changePasswordForm.controls.confirmPassword.errors.required  != null && !formValid">
                        Confirm Password is required
                     </div>
                      <div class="help-block" *ngIf="changePasswordForm.controls.confirmPassword.errors && changePasswordForm.controls.confirmPassword.errors.confirmedValidator  != null && !formValid">
                        Password and confirm don't match
                      </div>

                     <div class="form-group">
                        <label for="newPassword">Enter your new password</label>
                        <div class="input-icon"
                           [ngClass]="{ 'has-error': isSubmitted && changePasswordForm.controls.newPassword.errors != null}">
                           <input style="text-transform: none !important" autocomplete="off" type="password" placeholder="New Password" formControlName="newPassword" class="form-control"
                              id="newPassword" name="newPassword"  autofocus="" required=""> <i
                              class="uil uil-at"></i>
                        </div>
                     </div>

                     <div class="form-group">
                        <label for="confirmPassword">Confirm password</label>
                        <div class="input-icon"
                           [ngClass]="{ 'has-error': isSubmitted && changePasswordForm.controls.confirmPassword.errors != null}">
                           <input  style="text-transform: none !important" placeholder="Confirm Password" autocomplete="off" type="password" formControlName="confirmPassword"
                            class="form-control" id="confirmPassword" name="confirmPassword"  autofocus="" required=""> <i
                              class="uil uil-at"></i>
                        </div>
                     </div>

                     
                     <div class="d-flex">
                        <button class="btn btn-success mt-3" (click)="changePassword()">Submit</button>

                     </div>
                  </form>
               </div>


               <div class="form-content" *ngIf="showOfficeForm">
                  <form [formGroup]="employmentForm">

                     <div class="form-group">
                        <label for="email">Please Select Office</label><br>
                        <select matNativeControl required formControlName="officeList" [(ngModel)]="officeInfo">
                           <option value="">Select</option>
                           <option *ngFor="let e of offices" [value]="e.OfficeSoid">{{e.OfficeName}}</option>
                        </select>
                     </div>
                     <div class="d-flex">
                        <button class="btn btn-success mt-3" (click)="goToDashboard()">Go to Dashboard</button>

                     </div>
                  </form>
               </div>

               <div class="form-content" *ngIf="showLoginForm">
                  <form [formGroup]="loginForm" class="login-container" >
                     <div *ngIf="isSubmitted && loginForm.controls.emailId.errors != null" class="help-block">
                        <div *ngIf="loginForm.controls.emailId.errors.required">Email is required</div>
                     </div>
                     <div *ngIf="isSubmitted && loginForm.controls.password.errors != null" class="help-block">
                        <div *ngIf="loginForm.controls.password.errors.required">Password is required</div>
                     </div>
                     <div class="form-group">
                        <label for="email">Email address</label>
                        <div class="input-icon"
                           [ngClass]="{ 'has-error': isSubmitted && loginForm.controls.emailId.errors != null}">
                           <input autocomplete="off" style="text-transform: none !important" type="email" formControlName="emailId" class="form-control"
                              id="email" name="email" placeholder="Enter Your Email" autofocus="" required=""> <i
                              class="uil uil-at"></i>
                        </div>
                     </div>
                     <div class="form-group">
                        <div class="d-flex"> <label for="password">Password</label>
                           <a href="lost-password" class="lost-password">Lost Password?</a>
                        </div>
                        <div class="input-icon"
                           [ngClass]="{ 'has-error': isSubmitted && loginForm.controls.password.errors != null }">
                           <span class="passtoggle uil uil-eye"></span>
                           <input style="text-transform: none !important" autocomplete="off" type="password" formControlName="password" class="form-control"
                              id="password" name="password" placeholder="Enter Your Password" required=""> <i
                              class="uil uil-key-skeleton"></i>
                        </div>
                     </div>
                     <div class="d-flex">
                        <button class="btn btn-success mt-3" (click)="login()">Sign in</button>
                     </div>
                  </form>
               </div>
            </div>
         </div>
      </div>
   </div>
</section>