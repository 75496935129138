<div class="modal-header">
    <h4 class="modal-title pull-left">Add Task</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body contentScroll">
      <div class="search-modal result-list">
        <form [formGroup]="taskForm">
          <div class="form-row">
            <div class="form group col-md-5">
              <mat-form-field>
                <mat-label class="label" floatLabel="always">Originator</mat-label>
                <input matInput class="data" [(value)]="originatorName" readonly>
              </mat-form-field>
            </div>
            <div class="form group col-md-1">           
            </div>
            <div class="form-group col-md-5">
              <form class="example-form">
                <mat-form-field floatLabel="always">
                  <mat-label class="label">Assignee</mat-label>
                    <input class="data" type="text" [formControl]="assigneeControl" [(ngModel)]="assigneeName" aria-label="Number" matInput [matAutocomplete]="auto">
                    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="OnAssigneeSelected($event.option)">
                        <mat-option  *ngFor="let option of filteredOptions | async" [value]="option.PersonName">
                        {{option.PersonName}}
                        </mat-option>
                    </mat-autocomplete>
                    <mat-error *ngIf="assigneeControl.errors != null && !formValid">
                      Please select an assignee
                    </mat-error>
                </mat-form-field>
              </form>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-12">         
              <form [formGroup]="collabForm">
                <mat-label style="color: rgba(0,0,0,.54); font-weight: 500;">Collaborators</mat-label>
                <ng-multiselect-dropdown
                  name="Assignee"
                  [settings]="dropdownSettings"
                  [data]="subscriptionServices" 
                  [(ngModel)]="collabsDetails"
                  formControlName = "collaboratorControl"
                  [disabled]="disabled">
                </ng-multiselect-dropdown>
              </form>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-6">
              <mat-form-field floatLabel="always">
                <mat-label class="label">Priority</mat-label>
                  <mat-select class="data" formControlName="priorityControl"  [(value)]="priority">              
                    <mat-option [value]="0">Low</mat-option>
                    <mat-option [value]="1">Normal</mat-option>
                    <mat-option [value]="2">High</mat-option>
                  </mat-select>
                  <mat-error *ngIf="taskForm.controls.priorityControl.errors != null && !formValid">
                    Please select a priority
                  </mat-error>
              </mat-form-field>
            </div>
          </div> 
          <div class="form-row">
            <div class="form-group col-md-6">
              <mat-form-field floatLabel="always">
                <mat-label class="label">Due On</mat-label>
                <!-- <input matInput class="data" [matDatepicker]="picker" [(ngModel)]="dueOn" formControlName="dueOnControl">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
                <mat-error *ngIf="taskForm.controls.dueOnControl.errors != null && !formValid">
                  Please enter due date
                </mat-error> -->
                <input matInput class="data" [owlDateTimeTrigger]="dt" [owlDateTime]="dt" formControlName="dueOnControl" [(ngModel)]="dueOn">
                <owl-date-time #dt [hour12Timer]="true"></owl-date-time>
                <mat-error *ngIf="taskForm.controls.dueOnControl.errors != null && !formValid">
                  Please enter due date
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-6">
              <mat-form-field floatLabel="always">
                <mat-label class="label">Category</mat-label>
                <mat-select class="data"  [(value)]="category" formControlName="categoryControl">              
                    <mat-option [value]="'Customer'">Customer</mat-option>
                    <mat-option [value]="'Agency'">Agency</mat-option>
                    <mat-option [value]="'Ticket'">Ticket</mat-option>
                    <mat-option [value]="'To Do'">To Do</mat-option>
                    <mat-option [value]="'Bug'">Bug</mat-option>
                </mat-select>
                <mat-error *ngIf="taskForm.controls.categoryControl.errors != null && !formValid">
                  Please select a category
                </mat-error>
            </mat-form-field>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-12">
              <mat-form-field floatLabel="always">
                <mat-label class="label">Subject</mat-label>
                <input matInput class="data" [(ngModel)]="subject" formControlName="subjectControl">
                <mat-error *ngIf="taskForm.controls.subjectControl.errors != null && !formValid">
                  Please enter a subject
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </form>       
      </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-success" (click)="onAddButtonClick()">Add</button>
    <button type="button" class="btn btn-danger" (click)="bsModalRef.hide()">{{closeBtnName}}</button>
  </div>