import { FormBuilder, FormGroup } from '@angular/forms';
import { FormControl, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import { AddOfficeComponent } from '../modal/addoffice/addoffice.component';
import { OfficeService } from '../../services/office.service';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { EventPlannerComponent } from '../../base/eventplanner/eventplanner.component';
import { PageEvent } from '@angular/material/paginator';
import * as _ from 'underscore';

import { Component, OnInit, TemplateRef, ViewChild, HostListener } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
@Component({
  selector: 'app-office',
  templateUrl: './office.component.html',
  styleUrls: ['./office.component.css']
})
export class OfficeComponent implements OnInit {
  searchText: string;
  myForm: FormGroup;
  OfficeSubmitForm: FormGroup;
  disabled = false;
  ShowFilter = false;
  limitSelection = false;
  selectedItems: any = [];
  dropdownSettings: any = {};
  lowValue: number = 0;
  highValue: number = 5;
  OfficeRequest: any = {};
  firstName: string;
  middleName: string;
  lastName: string;
  personalEmail: string;
  businessEmail: string;
  formValid: boolean = true;
  subscriptionServices: any = [];
  agelimit: Date;
  sortBy: string = "";
  OfficeRequestInfo: any = {};
  selectedOfficeSoid: string;

  countries: string[] = [
    'Canada', 'Mexico', 'United States'
  ];

  officeList: any = [];

  // MatPaginator Inputs
  length = Number;
  pageIndex: number = 0;
  pageSize = 5;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  @ViewChild('OfficeView')
  public officeView;



  modalRef: BsModalRef;
  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);
  isAdmin: boolean = false;
  isSuperAdmin: boolean = false;

  constructor(private toast: ToastrService, private datePipe: DatePipe, private _OfficeService: OfficeService, private modalService: BsModalService,
    private _router: Router, private fb: FormBuilder, private _authService: AuthService) { }

  ngOnInit() {
    var date = new Date();
    date.setFullYear(date.getFullYear() - 18);
    this.agelimit = date;

    //this.selectedItems = [{ item_id: 2, item_text: 'Staff' }, { item_id: 6, item_text: 'Documents' }];
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 5,
      allowSearchFilter: this.ShowFilter
    };
    this.myForm = this.fb.group({
      city: [this.selectedItems]
    });
    
    this._authService.getUserRole().subscribe( (res: string) => {
      if (res) {
        const permissions = this._authService.getPermissions(res);
        this.isAdmin = permissions.isAdmin;
        this.isSuperAdmin = permissions.isSuperAdmin;
        this.getOfficeList();
      }
    }, (err) => {
      this.toast.error(err.message)
    });
  }

  private getOfficeList() {
    let officeSoids = localStorage.getItem("Offices") != null ? JSON.parse(localStorage.getItem("Offices")) : [];

    if (officeSoids.length > 0 && (this.isAdmin || this.isSuperAdmin)) {
      officeSoids = officeSoids.map(o => o.OfficeSoid);
      this._OfficeService.getOfficeListByIds(officeSoids).subscribe((res: any) => {
        if (res) {
          this.length = res.length;
          this.officeList = res;
          if (this.officeList && this.officeList.length > 0) {
            this.officeList = _.sortBy(this.officeList, "CreatedOn").reverse();
            this.selectedOfficeSoid  = this.officeList[0].Soid;
            this.officeView.loadView(this.selectedOfficeSoid);
          }
        } else {
          this.officeList = [];
        }
      }, (err) => {
        this.toast.error(err);
      });
    }
  }

  public getPaginatorData(event: PageEvent): PageEvent {
    this.lowValue = event.pageIndex * event.pageSize;
    this.highValue = this.lowValue + event.pageSize;
    return event;
  }

  openAddOfficeModal() {
    this.modalRef = this.modalService.show(AddOfficeComponent);
    this.modalRef.content.event.subscribe(data => {
      this.getOfficeList();
    });
  }

  editOffice(OfficeId) {
    //this._router.navigate(['office/' + OfficeId]);
    this.selectedOfficeSoid = OfficeId;
    this.officeView.loadView(OfficeId);
  }

  sortFieldChange(event) {
    this.sortBy = event.srcElement.value;
    this.officeList = _.sortBy(this.officeList, this.sortBy);
  }

  onItemSelect(item: any) {
  }
  onSelectAll(items: any) {
  }
  toogleShowFilter() {
    this.ShowFilter = !this.ShowFilter;
    this.dropdownSettings = Object.assign({}, this.dropdownSettings, { allowSearchFilter: this.ShowFilter });
  }

  handleLimitSelection() {
    if (this.limitSelection) {
      this.dropdownSettings = Object.assign({}, this.dropdownSettings, { limitSelection: 2 });
    } else {
      this.dropdownSettings = Object.assign({}, this.dropdownSettings, { limitSelection: null });
    }
  }

  getShortName(fullName) {
    return fullName.split(' ').map(n => n[0]).join('');
  }

  convertToArray(valueText) {
    return valueText.split(",");
  }

  transformDateFormat(dateValue) {
    return this.datePipe.transform(new Date(dateValue), 'MM/dd/yyyy');
  }





}
