<div class="section-container">
  <div class="subheader">
    <h2>Client</h2>

    <!-- <h5>Plans and events</h5> -->
  </div>
  <div class="white-bg rounded border-0">
    <div class="row top-menu bg-light bg-grey no-gutters">
      <div class="col-md-6">
        <div class="top-menu-container">
          <button mat-flat-button [matMenuTriggerFor]="menu" color="primary">New <i class="fa fa-plus"></i></button>
          <mat-menu #menu="matMenu" class="top-menu-container-dd">
            <button mat-menu-item (click)="openNewClientDialog()">Client</button>
            <button mat-menu-item (click)="openPlannerModal()">New Event</button>
          </mat-menu>
        </div>
      </div>
      <div class="col-md-6">
        <form class="search-card">
          <div class="card-body row no-gutters align-items-center">
            <div class="col search-card-field">
              <i class="fas fa-search icon-serach"></i>
              <input autocomplete="off" [(ngModel)]="searchText" name="searchText"
                class="form-control form-control-borderless" type="search"
                placeholder="Search...">
            </div>

          </div>
        </form>
      </div>
    </div>
    <div class="row form-page-header">
      <div class="col-md-4">
        <form class="form-inline">
          <label for="inlineFormInputName2" class="mrg-rt-5">Sort By</label>
          <mat-form-field>
            <select matNativeControl required (change)="sortFieldChange($event)">
              <option value="NameFirst">Name</option>
              <option value="CreatedOn">Created On</option>
              <option value="EmailAddress">Email</option>
            </select>
          </mat-form-field>
        </form>
      </div>
      <div class="form-row" *ngIf="officeList && officeList.length > 0">
        <mat-form-field>
          <select matNativeControl placeholder="Office" (change)="getOfficeFilter($event)" [(ngModel)]="selectedOffice">
            <!--<mat-option>None</mat-option>-->
            <option value="All">All</option>
            <option *ngFor="let service of officeList" [value]="service.Soid">{{service.CompanyName}} </option>
            <!--<mat-option value="Custom">Custom</mat-option>-->
          </select>
        </mat-form-field>
      </div>
      <div class="col-md-8 text-right">
        <mat-form-field class="search-results">
          <select matNativeControl required (change)="filterClientList($event)" [(ngModel)]="filterBy">
            <option value="All">All</option>
            <option value="Active">Active</option>
            <option value="InActive">InActive</option>
          </select>
        </mat-form-field>
      </div>
    </div>
    <div class="client-lists">
      <div class="row">
        <div class="col-md-12">
          <ul class="list-unstyled">
            <li class="media"
              *ngFor="let client of clientList | 
                    simpleSearch : 'NameFirst,PersonNumber,NameLast,EmailAddress,SSN,EINNumber,DLNumber,PhoneNumber,Tags,Status': searchText | slice: lowValue : highValue"
              (click)=editClient(client.Soid)>

              <ngx-avatar *ngIf="!client.ProfilePhoto" size="40" name="{{client.NameFirst}} {{client.NameLast}}"></ngx-avatar>
              <ngx-avatar *ngIf="client.ProfilePhoto" size="40" src="{{client.ProfilePhoto}}"></ngx-avatar>

              <div class="media-body">
                <div class="row no-gutters">
                  <div class="client-list-left col-md-4">
                    <h6 class="mt-0 mb-1">{{client.NameFirst | titlecase}} {{client.NameLast | titlecase}}</h6>
                    <span style="font-size:11px"><i class="fas fa-at"></i> {{client.EmailAddress}}</span>
                    <div><span style="font-size: 11px;"><i class="fas fa-mobile-alt"></i> {{client.country}} {{client.PhoneNumber}}</span></div>
                  </div>
                  <div class="col-md-2 result-date text-right">
                    <span class="lable-tag">{{client.PersonNumber}}</span>
                  </div>

                  <div class="col-md-4 result-date text-right" *ngIf="client.Tags">
                    <span *ngFor="let tagName of (client.Tags | modulesNamePipe)" class="lable-tag">{{tagName |
                      titlecase}}</span>
                  </div>
                  <div class="col-md-2 result-date text-right">
                    <span> {{client.CreatedOn | date:"dd MMM yyyy"}}</span>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4 offset-md-8" style="margin-bottom:-10px">
          <mat-paginator class="pagination" [length]="clientLength" [pageSize]="clientPageSize"
            [pageSizeOptions]="clientPageSizeOptions" (page)="getClientPaginatorData($event)">
          </mat-paginator>
        </div>
      </div>
    </div>
  </div>
</div>