<div class="modal-header">
  <h4 class="modal-title pull-left">{{title}}</h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body contentScroll">
    <div class="search-modal result-list">
        <div class="row">
            <div class="col-md-10 offset-md-1">
              <form class="search-card">
                  <div class="card-body row no-gutters align-items-center">
                      <div class="col search-card-field">
                          <i class="fas fa-search icon-serach"></i>
                          <input [(ngModel)]="searchText" name="searchText" class="form-control  form-control-borderless" type="search" placeholder="Search topics or keywords">
                      </div>
                    
                  </div>
              </form>
            </div>
        </div>
        <br/>
        <div class="row">
          <div class="col-md-10 offset-md-1">
            <ul class="list-unstyled">
                <li style="cursor: pointer;" class="media" *ngFor="let client of clientList | simpleSearch : 'NameFirst,NameLast,phoneNo,EmailAddress': searchText" (click)="logEvent(client.Soid)" >
                   <ngx-avatar size="40" name="{{client.NameFirst}} {{client.NameLast}}"></ngx-avatar>
                    <div class="media-body">
                        <div class="row no-gutters">
                            <div class="client-list-left col-md-10">
                              <div class="mt-0 mb-1"><strong>{{client.NameFirst | titlecase}} {{client.NameLast | titlecase}}</strong></div>
                              <span><i class="fas fa-at"></i> {{client.EmailAddress}}</span>
                              <div><span><i class="fas fa-mobile-alt"></i> {{client.PhoneNumber}}</span></div>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
          </div>
        </div>
    </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary" (click)="openNewClientDialog()">Add New Client</button>

  <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">{{closeBtnName}}</button>
</div>