<div class="modal-header">
  <h4 class="modal-title pull-left">{{title}}</h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form class="client-modal" [formGroup]="changePasswordForm">
  
    <div class="form-row" >
      <div class="col-md-6">
        <mat-form-field>
          <div class="help-block requirements" *ngIf="changePasswordForm.controls.password.errors && changePasswordForm.controls.password.errors.minlength != null && !formValid">
            Password must be at least 8 characters
          </div>
          <div class="help-block requirements" *ngIf="changePasswordForm.controls.password.errors && changePasswordForm.controls.password.errors.pattern != null && !formValid">
            Password must contain:
            <ul>
               <li>At least 1 number</li>
               <li>At least 1 special character</li>
               <li>At least 1 uppercase letter</li>
               <li>At least 1 lowercase letter</li>
            </ul>
          </div>
          <input autocomplete="off" formControlName="password" matInput placeholder="Enter your new password" [type]="hide ? 'password' : 'text'">
          <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
          <mat-error *ngIf="changePasswordForm.controls.password.errors && changePasswordForm.controls.password.errors.required != null && !formValid">
            Please enter password
          </mat-error>
        </mat-form-field>
      </div>
    </div>
      <div class="form-row" >
      <div class="col-md-6">
        <mat-form-field>
          <input  autocomplete="off" formControlName="confirmPassword" type="password" matInput
            placeholder="Confirm Password">
          <mat-error *ngIf="changePasswordForm.controls.confirmPassword.errors && changePasswordForm.controls.confirmPassword.errors.required  != null && !formValid">
            Confirm Password is required
          </mat-error>
          <mat-error *ngIf="changePasswordForm.controls.confirmPassword.errors && changePasswordForm.controls.confirmPassword.errors.confirmedValidator  != null && !formValid">
            Password and confirm don't match
          </mat-error>
        </mat-form-field>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">{{closeBtnName}}</button>
  <button type="button" class="btn btn-primary" (click)="createService()">Change My Password</button>
</div>