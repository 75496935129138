import {Component, OnInit, Output, EventEmitter, TemplateRef} from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { NewTimeForAppoinmentComponent } from '../../modal/new-time-for-appoinment/new-time-for-appoinment.component';
import { ResheduleAppoinmentInfoComponent } from '../../modal/reshedule-appoinment-info/reshedule-appoinment-info.component';
import { CancelAppoinmentComponent } from '../../modal/cancel-appoinment/cancel-appoinment.component';
import { DatePipe, Location } from '@angular/common';
import { ClientService } from '../../../services/client.service';
import { InvoiceService } from '../../../services/invoice.service';
import { RecordPaymentComponent } from '../../modal/record-payment/record-payment.component';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { ToastrService } from 'ngx-toastr';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

class Product {
  name: string;
  price: number;
  qty: number;
}
class Invoice {
  customerName: string;
  address: string;
  contactNo: number;
  email: string;

  products: Product[] = [];
  additionalDetails: string;

  constructor() {
    // Initially one empty product row we will show
    this.products.push(new Product());

  }
}

@Component({
  selector: 'app-view-reciept',
  templateUrl: './view-reciept.component.html',
  styleUrls: ['./view-reciept.component.css']
})
export class ViewRecieptComponent implements OnInit {
  invoice = new Invoice();
  public receiptInfo: any = {};
  eventInfo: any = {};
  clientList: any = {};
  myForm: FormGroup;
  contactForm: FormGroup;
  tagForm: FormGroup;
  statusForm: FormGroup;
  infoForm: FormGroup;
  clientForm: FormGroup;
  disabled = false;
  ShowFilter = false;
  limitSelection = false;
  cities: any = [];
  selectedItems: any = [];
  dropdownSettings: any = {};
  modalRef: BsModalRef;
  invoiceInfo: any = {};
  paymentInfo: any = {};
  recieptId: string;
  clientId: string;
  invoiceId: string;
  eventStatus: string;
  selected: string;
  actualValue: string;
  birthDate: Date;
  appoinmentClient: any = {};
  agelimit: 18;
  lmsStartDate: Date;
  expirationDate: Date;
  invoiceStatus: string;
  xeroRecieptURL: string = "https://go.xero.com/Bank/ViewTransaction.aspx?bankTransactionID=";
  subscriptionServices: any = [];
  showView: string;
  staffInfo: any = {};
  clientInfo: any = {};
  balanceInfo: string;
  title: string = 'View Receipt';
  sendPaymentConfirmationPopup: BsModalRef;

  countries: string[] = [
    'Canada', 'Mexico', 'United States'
  ];

  constructor(private modalService: BsModalService, private _router: Router, private datePipe: DatePipe,
    private _clientService: ClientService,public bsModalRef: BsModalRef,private toast: ToastrService,
    private activatedRoute: ActivatedRoute, private location: Location,
    private _invoiceService: InvoiceService,
    private fb: FormBuilder) { }

  ngOnInit() {


    if (this.showView && this.showView == "POPUP") {

      this.recieptId = this.receiptInfo.paymentId;
      this.clientId = this.receiptInfo.clientId;
      this.invoiceId = this.receiptInfo.invoiceId;
    } else {
      this.recieptId = this.activatedRoute.snapshot.params.recieptId;
      this.clientId = this.activatedRoute.snapshot.params.loginId;
      this.invoiceId = this.activatedRoute.snapshot.params.invoiceId;

    }



   this.reloadView();




    //debugger;
    this.selectedItems = [{ item_id: 2, item_text: 'Staff' }, { item_id: 6, item_text: 'Documents' }];
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 5,
      allowSearchFilter: this.ShowFilter
    };
    this.myForm = this.fb.group({
      city: [this.selectedItems]
    });

    this.tagForm = this.fb.group({
      tag: new FormControl(this.selectedItems, Validators.compose([
        Validators.required
      ]))
    });
    this.statusForm = this.fb.group({
      status: new FormControl('', Validators.compose([
        Validators.required
      ]))
    });

    this.contactForm = this.fb.group({
      addressLine1: new FormControl('', Validators.compose([
        Validators.required
      ])),
      addressLine2: new FormControl('', Validators.compose([
      ])), state: new FormControl('', Validators.compose([
        Validators.required
      ]))
      , city: new FormControl('', Validators.compose([
        Validators.required
      ])), country: new FormControl('', Validators.compose([
        Validators.required
      ])), phoneNo: new FormControl('', Validators.compose([
        Validators.required
      ])), pincode: new FormControl('', Validators.compose([
        Validators.required
      ])), homeAddress: new FormControl('', Validators.compose([
        Validators.required
      ])), personalPhoneCountryCode: new FormControl('', Validators.compose([
        Validators.required
      ])), personalMobile: new FormControl('', Validators.compose([
        Validators.required
      ])), businessAddress: new FormControl('', Validators.compose([
        Validators.required
      ])), businessCountryCode: new FormControl('', Validators.compose([
        Validators.required
      ])), businessMobileNo: new FormControl('', Validators.compose([
        Validators.required
      ])), businessEmail: new FormControl('', Validators.compose([
        Validators.required
      ])), zipCode: new FormControl('', Validators.compose([
        Validators.required
      ]))

    });

    this.infoForm = this.fb.group({
      ssn: new FormControl('', Validators.compose([
        Validators.required
      ])),
      dlNumber: new FormControl('', Validators.compose([
        Validators.required
      ])),
      dlState: new FormControl('', Validators.compose([
        Validators.required
      ])),
      dlExpirationDate: new FormControl('', Validators.compose([
        Validators.required
      ])),
      dlStartDate: new FormControl('', Validators.compose([
        Validators.required
      ])),
      ITINNumber: new FormControl('', Validators.compose([
        Validators.required
      ])),
    });
    this.clientForm = this.fb.group({
      email: new FormControl('', Validators.compose([
        Validators.required
      ])),
      firstName: new FormControl('', Validators.compose([
        Validators.required
      ])),
      lastName: new FormControl('', Validators.compose([
        Validators.required
      ])),
      birthday: new FormControl('', Validators.compose([
        Validators.required
      ])),
      companyName: new FormControl('', Validators.compose([
        Validators.required
      ])),
      websiteURL: new FormControl('', Validators.compose([
        Validators.required
      ])),
      lmsStartDate: new FormControl('', Validators.compose([
        Validators.required
      ])),
      languagePreference: new FormControl('', Validators.compose([
        Validators.required
      ])),
      hearAboutUs: new FormControl('', Validators.compose([
        Validators.required
      ])),
      couple: new FormControl('', Validators.compose([
        Validators.required
      ])),
      CompanyName: new FormControl('', Validators.compose([
        Validators.required
      ])),
      EINNumber: new FormControl('', Validators.compose([
        Validators.required
      ]))

    });
  }

  reloadView() {
    this.getInvoice();
    this.getClientInfo();

  }

  clearView() {
    this.invoiceInfo = {};
    this.paymentInfo = {};
  }

  loadView(paymentId, clientId, invoiceId, paymentInfo) {
    this.recieptId = paymentId;
    this.clientId = clientId;
    this.invoiceId = invoiceId;
    //this.paymentInfo = paymentInfo;
    this.reloadView();
    this.showView = "INLINE";
  }

  private getInvoice() {

    if (!this.invoiceId) {
      return;
    }
    this._invoiceService.getInvoice(this.clientId, this.invoiceId).subscribe((res: any) => {
      if (res) {
       // var receiptInfo = res.Payments.filter(option => option.Soid === this.recieptId);
        this.invoiceInfo = res;


        this.paymentInfo = this.invoiceInfo.Payments.filter(e => (e.Soid == this.recieptId))[0];
       // this.paymentInfo = receiptInfo[0];
       // this.xeroRecieptURL = this.xeroRecieptURL + this.paymentInfo.XeroRecieptReference;
        var currentDate = new Date();
        if (parseFloat(this.paymentInfo.Balance) <= 0) {
          this.invoiceStatus = "Paid";
        } else if (currentDate > new Date(this.invoiceInfo.DueOn)) {
          this.invoiceStatus = "Over Due";
        } else if (currentDate <= new Date(this.invoiceInfo.DueOn)) {
          this.invoiceStatus = "Due";
        }
        this.invoiceStatus = "";
        if (this.paymentInfo.Balance == this.paymentInfo.Total) {
          this.balanceInfo = '$' + this.paymentInfo.Balance + 'USD';
        } else if (this.invoiceStatus != "Paid") {
          this.balanceInfo = '$' + this.paymentInfo.Amount + '(out of $' + this.paymentInfo.InvoiceBalance + ') USD';
        }
        this.balanceInfo = '$' + this.paymentInfo.Amount + '(out of $' + this.paymentInfo.InvoiceBalance + ') USD';
      }
    });
  }

  inActiveDocument() {


  }

  downloadFile() {

  }

  recordPayment() {
    const initialState = {
      header: "Record Payment",
      backdrop: true,
      ignoreBackdropClick: true,
      clientInfo: this.clientInfo,
      invoiceInfo: this.invoiceInfo
    };
    this.modalRef = this.modalService.show(RecordPaymentComponent, { initialState });
    this.modalRef.content.event.subscribe(data => {
      // this.triggerEvent();
    });
  }

  convertBase64ToBlob(base64Image: string, mimType) {


    // Decode Base64 string
    const decodedData = window.atob(base64Image);

    // Create UNIT8ARRAY of size same as row data length
    const uInt8Array = new Uint8Array(decodedData.length);

    // Insert all character code into uInt8Array
    for (let i = 0; i < decodedData.length; ++i) {
      uInt8Array[i] = decodedData.charCodeAt(i);
    }

    // Return BLOB image after conversion
    return new Blob([uInt8Array], { type: mimType });
  }

  viewFile() {

  }


  //New Time For Appoinment
  newTimeForAppoinment() {
    const initialState = {
      header: "Reschedule Appoinment",
      closeBtnName: "Save",
      backdrop: true,
      ignoreBackdropClick: true
    };
    this.modalRef = this.modalService.show(ResheduleAppoinmentInfoComponent);
  }

  //New Time For Appoinment Info
  resceduleAppoinmentInfo() {
    const initialState = {
      header: "Reschedule Appoinment",
      //  eventId: this.eventId,
      closeBtnName: "Cancel",
      backdrop: true,
      ignoreBackdropClick: true
    };
    this.modalRef = this.modalService.show(ResheduleAppoinmentInfoComponent, { initialState });
  }
  //Cancel Appoinment
  viewInvoice() {
    window.open("client/" + this.clientId + "/invoice/" + this.recieptId + "/view", '_blank', 'toolbar=0, width=800, height=400');
  }

  showInvoice() {
    this._router.navigate(['client/' + this.clientId + '/invoice/' + this.invoiceId]);
  }

  onItemSelect(test) {

  }

  onInputFocusOut(event) {
    if (event.srcElement.value.trim() != "" && this.actualValue != event.srcElement.value.trim()) {
      this.invokeClientSave(event.srcElement.id, event.srcElement.value);
    }

  }

  private invokeClientSave(selector, value) {
    var requestInfo: any = {};
    requestInfo.FieldName = selector;
    requestInfo.Data = value
    requestInfo.UserName = localStorage.getItem("UserName");
    requestInfo.UserSoid = localStorage.getItem("UserSoid");
    /**  this._clientService.updateClientInfo(this.clientInfo.Soid, requestInfo).subscribe((res: any) => {
       if (res && res.Changed === true) {
         if (res.response == "EMAIL_EXISTS") {
           this.toast.warning("Email Id already exists.", "Warning!");
         } else {
           this.toast.success("Updated Successfully.", "Success!");
         }
       } else {
         this.toast.error("Error Updating Details", "Success!");
       }
     });*/
  }


  private getClientInfo() {
    if (!this.clientId) {
      return;
    }
    this._clientService.getClientInfo(this.clientId).subscribe((res: any) => {
      if (res) {
        this.clientInfo = res;
      }
    });

  }





  onSelectFocus(event) {
    this.actualValue = event.srcElement.value;
  }

  onSelectFocusOut(event) {
    if (event.source._value.trim() != "" && this.actualValue != event.source._value.trim().trim()) {
      this.invokeClientSave(event.source._id, event.source._value.trim());
    }
  }

  onFocusChange(event) {

  }

  onChange(event) {

  }

  dateChange(event) {
    this.invokeClientSave(event.target._elementRef.nativeElement.id, this.datePipe.transform(event.value, 'MM/dd/yyyy'));
  }


  inputFocus(event) {
    this.actualValue = event.srcElement.value;
  }

  goBack() {
    if (window.history.length > 1) {
      this.location.back()
    }
  }




  generatePDF(action = 'open') {
    var billTo = (this.invoiceInfo.BusinessName ? this.invoiceInfo.BusinessName : '') + (this.invoiceInfo.BusinessName ? ' / ' : '') + (this.invoiceInfo.BillingToFirstName + ' ' + this.invoiceInfo.BillingToLastName);

    let docDefinition = {
      content: [
        {       
          
   
          columns: [
            [
            
              {
                text: 'RECEIPT',
                fontSize: 30,
                bold: true,
                alignment: 'left',
                color: 'skyblue',
               
              },
              {
                text: billTo,
                bold: true,
                width: '50%'
              }
            ],
            [
              {
                text: 'Payment Date',
                alignment: 'left',
                fontSize: 10,
                bold: true
              },
              {
                text: this.datePipe.transform(this.invoiceInfo.InvoicedOn, 'MM/dd/yyyy'),
                alignment: 'left',
                fontSize: 10,

              },
              {
                text: 'Sent Date',
                alignment: 'left',
                fontSize: 10,
                bold: true,
              },
              {
                text: this.datePipe.transform(new Date(), 'MM/dd/yyyy'),
                alignment: 'left',
                fontSize: 10,
              },
              {
                text: 'Payment Mode',
                alignment: 'left',
                fontSize: 10,
                bold: true,
              },
              {
                text: this.paymentInfo.PaymentType,
                alignment: 'left',
                fontSize: 10,
              }
             
            ],
            [
              {
                text: this.invoiceInfo.BillingFromFirstName,
                alignment: 'left',
                bold: true,
                fontSize: 10
              },
              { text: this.invoiceInfo.BillingFromAddress ? this.invoiceInfo.BillingFromAddress : '' , fontSize: 10},
              { text: this.invoiceInfo.BillingFromState, fontSize: 10 },
              { text: this.invoiceInfo.BillingFromCountry , fontSize: 10},
              { text: this.invoiceInfo.BillingFromZip, fontSize: 10 },
            ]
          ],
          widths: ['*', '*', '*']   
        },
        [
          '\n\n\n\n'
      ],
       
      {
        text : 'Total USD Paid                        ' + '$' + this.paymentInfo.Amount, alignment: 'right', bold: true, border: top, fontSize: 12,
    },
    [
      '\n\n\n\n'
  ],
  
        {
          layout: 'lightHorizontalLines',
          table: {
              headerRows: 1,
              widths: ['12%', '22%', '22%', '15%', '13%', '15%'], // Changed 'auto' to '*'s
              body: [
                  [ { text: 'Invoice Date', fontSize: 8, bold: true } , 
                  { text: 'Reference', fontSize: 8, bold: true } , 
                  { text: 'Payment Reference', fontSize: 8, bold: true } , 
                  { text: 'Invoice Total', fontSize: 8, bold: true } , 
                  { text: 'Amount Paid', fontSize: 8, bold: true } , 
                  { text: 'Still Owing', fontSize: 8, bold: true }  ],

                  [ {text: this.datePipe.transform(this.invoiceInfo.InvoicedOn, 'MM/dd/yyyy') , fontSize: 8 },
                  {text: this.invoiceInfo.InvoiceNumber , fontSize: 8 },
                  {text: this.paymentInfo.ReceiptNumber , fontSize: 8 },
                  {text: '$' + this.invoiceInfo.Total , fontSize: 8 },
                  {text: '$' + this.paymentInfo.Amount , fontSize: 8 },
                  {text: '$' + this.paymentInfo.InvoiceBalance , fontSize: 8 }]
                   
              ]
              ,
        layout: {
            defaultBorder: false,
        }
          }
      },
     
       
        {
          text: 'Notes:',
          style: 'notes'
        },
        {
          text: this.invoiceInfo.Notes
        },
        {
          style: 'lineSpace',
          columns: [
            [{ text: 'Powered by Love My Systems', alignment: 'right', italics: true, fontSize: 5 }],
          ]
        },
      ],
      styles: {
        sectionHeader: {
          bold: true,
          decoration: 'underline',
          fontSize: 14,
          margin: [0, 15, 0, 15]
        },
        lineSpace: {
          margin: [0, 15, 0, 15]
        },
        notes: {
          bold: true,
          decoration: 'underline',
          fontSize: 10,
          margin: [0, 15, 0, 15]
        },

      }
    };

    const pdfCreator = pdfMake.createPdf(docDefinition);
    if (action === 'download') {
      pdfCreator.download();
    } else if (action === 'print') {
      pdfCreator.print();
    } else if (action === 'pdfGenerator') {
      return pdfCreator;
    } else {
      pdfCreator.open();
    }

  }


  refreshToken() {

    this._invoiceService.refreshToken().subscribe((res: any) => {
      if (res) {
       
      }
    });

  }

  pushToXero() {

    this._invoiceService.pushToXero(this.recieptId).subscribe((res: any) => {
      if (res) {
        this.paymentInfo = res;
        if(res.XeroRecieptReference != null) {
          this.toast.success("Payment pushed to xero successfully", "Info!");
        } else {
          this.toast.error("Error pushing payments", "Error!");
        }
      }
    });

  }

  sendPaymentByEmail(): void {
    this.generatePDF('pdfGenerator').getBlob((blob: Blob) => {
      this._invoiceService.sendPaymentByEmail(blob, this.paymentInfo.Soid)
        .subscribe({
          next: () => this.toast.success('Payment successfully sent'),
          error: () => this.toast.error('Failed to send payment'),
          complete: () => this.sendPaymentConfirmationPopup.hide(),
        });
    });
  }

  openSendConfirmationPopup(template: TemplateRef<any>): void {
    this.sendPaymentConfirmationPopup = this.modalService.show(template);
  }
}
