import { Routes } from '@angular/router';

import { LoginComponent } from './login/login.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { RouterGuard } from './guards/router-guard.service';
import { RoleGuard } from './guards/role-guard.service';
import { LostPasswordComponent } from './lost-password/lost-password.component';

export const APP_ROUTES: Routes = [
    { path: 'login', component: LoginComponent },    
    { path: 'lost-password', component: LostPasswordComponent },
    { path: '', redirectTo: 'dashboard', pathMatch: 'full'},
    { path: '**', component: PageNotFoundComponent }
];