<div class="modal-header">
  <h4 class="modal-title pull-left">{{title}}</h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form class="client-modal" [formGroup]="serviceEstimateForm">
    <!--<div class="form-row">
      <div style="float: left; width: 50px; height:50px;">
        <ngx-avatar size="40" name="{{clientInfo.NameFirst}} {{clientInfo.NameLast}}"></ngx-avatar>
      </div>
      <div style="float: left; width: 80%;">
        <div class="client-list-left">
          <h6 class="mt-0 mb-1">{{clientInfo.NameFirst | titlecase}} {{clientInfo.NameLast | titlecase}}</h6>
          <span><i class="fas fa-at"></i>{{clientInfo.Email}}</span>
        </div>
      </div>


    </div>-->


    <accordion>
      <accordion-group [isOpen]="false" #billingFrom>
        <span accordion-heading>
          <i class="fas" [ngClass]="{'fa-angle-down': billingFrom?.isOpen, 'fa-angle-up': !billingFrom?.isOpen}"></i>
          <strong>From: </strong>{{officeName}}
        </span>
        <br>

        <mat-form-field appearance="fill">
          <mat-label>Business Address</mat-label>
          <textarea matInput formControlName="billingFromAddress" [(ngModel)]="billingFromAddress" disabled></textarea>
        </mat-form-field>

      </accordion-group>

      <div class="form-row">
        <div class="col-md-6">
          <mat-form-field>
            <mat-label>Select Estimate To</mat-label>
            <mat-select [(ngModel)]="billingToSelected" formControlName="billingToSelect"
              (selectionChange)="changeBillingTo()">
              <mat-option *ngFor="let service of billingToList" [value]="service">{{service.name}}</mat-option>
            </mat-select>

          </mat-form-field>
        </div>
      </div>
      <accordion-group [isOpen]="false" #billingTo>
        <span accordion-heading>
          <i class="fas" [ngClass]="{'fa-angle-down': billingTo?.isOpen, 'fa-angle-up': !billingTo?.isOpen}"></i>
          <strong>To: </strong>{{billToName}}
        </span>
        <mat-form-field appearance="fill">
          <mat-label>Contact: {{billToName}}</mat-label>
          <textarea matInput formControlName="billingToAddress" [(ngModel)]="billingToAddress" disabled></textarea>
        </mat-form-field>

      </accordion-group>
      <accordion-group [isOpen]="true" #invoiceDetails>
        <span accordion-heading>
          <i class="fas"
            [ngClass]="{'fa-angle-down': invoiceDetails?.isOpen, 'fa-angle-up': !invoiceDetails?.isOpen}"></i>
          <strong>Estimate Details</strong>
        </span>

        <div class="form-row">
          <div class="col-md-6">
            <mat-form-field>
              <input type="input" autocomplete="off" formControlName="estimateLabel" matInput
                placeholder="Estimate Label">
              <mat-error *ngIf="serviceEstimateForm.controls.estimateLabel.errors != null && !formValid">
                Please enter estimate label
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <mat-form-field>
              <input type="input" readonly [(ngModel)]="estimateNo" autocomplete="off" formControlName="estimateNumber"
                matInput placeholder="Estimate Number">
              <mat-error *ngIf="serviceEstimateForm.controls.estimateNumber.errors != null && !formValid">
                Please enter estimate number
              </mat-error>
            </mat-form-field>
          </div>

        </div>

        <div class="form-row">
          <div class="col-md-6">
            <mat-form-field>
              <input matInput autocomplete="off" formControlName="estimateDate" [matDatepicker]="picker"
                placeholder="Issue Date">
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
              <mat-error *ngIf="serviceEstimateForm.controls.estimateDate.errors != null && !formValid">
                Please select issue date
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <mat-form-field>
              <input matInput autocomplete="off" formControlName="expirationDate" [matDatepicker]="dueDatePicker"
                placeholder="Expiration Date">
              <mat-datepicker-toggle matSuffix [for]="dueDatePicker"></mat-datepicker-toggle>
              <mat-datepicker #dueDatePicker></mat-datepicker>
              <mat-error *ngIf="serviceEstimateForm.controls.expirationDate.errors != null && !formValid">
                Please select expiration date
              </mat-error>
            </mat-form-field>
          </div>

        </div>
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-4">
              <label class="invoice-header">Item</label>
            </div>
            <div class="col-md-1">
              <label class="invoice-header">Quantity</label>
            </div>
            <div class="col-md-1">
              <label class="invoice-header">Price</label>
            </div>
            
            <div class="col-md-4">
              <label class="invoice-header">Tax</label>
            </div>
            <div class="col-md-1">
              <label class="invoice-header">Amount</label>
            </div>
            <div class="col-md-1">
              <label class="invoice-header"></label>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <mat-form-field>
                <mat-label>Select Service</mat-label>
                <mat-select (selectionChange)="serviceChange()" formControlName="serviceItem"
                  [(ngModel)]="invoiceServiceItem">
                  <mat-option *ngFor="let service of serviceList" [value]="service">{{service.Item |
                    titlecase}} {{service.PriceType === 'Flexi Fee' ? '(F)' : '' | titlecase}}</mat-option>
                </mat-select>

              </mat-form-field>
            </div>
            <div class="col-md-1">
              <input type="number" style="width:60px;" (keyup)="serviceChange()" [(ngModel)]="invoiceQuantity"
              formControlName="estimateQuantity" value="1" class="Num" />
            </div>
            <div class="col-md-1">
              <input type="number" [attr.disabled]="changeUnitPrice ? null : true" style="width:60px;" min="0"
              value="0" step=".01" [(ngModel)]="invoiceServiceItem.UnitPrice"
              formControlName="serviceUnitPrice" />
            </div>          
            <div class="col-md-4">
              <mat-form-field>
                <mat-label>Select Tax Rate</mat-label>
                <mat-select  formControlName="taxItem"
                  [(ngModel)]="taxServiceItem">
                  <mat-option *ngFor="let service of taxRates" [value]="service">{{service.Name |
                    titlecase}} ({{service.EffectiveRate}}%) </mat-option>
                </mat-select>

              </mat-form-field>
            </div>
            <div class="col-md-1">
              <input type="number" disabled style="width:60px;" [(ngModel)]="invoicePrice"
                formControlName="servicePrice" />
            </div>
            <div class="col-md-1">
              <button type="button" class="btn btn-primary" (click)="addInvoiceItem()">Add</button>
            </div>
          </div>

          <div class="row" *ngFor="let row of invoiceServiceItems">
            <div class="col-md-4">
              <label>{{row.Item}}</label>
            </div>
            <div class="col-md-1">
         
              <input type="number" style="width:40px;" (input)="unitPriceUpdate($event.target.value, row)"
                    [(value)]="row.Quantity" class="Num" />
            </div>
            <div class="col-md-1">
              {{row.UnitPrice}}
            
            </div>
           
            <div class="col-md-4">
              <label>{{row.TaxAmount}}</label>
            </div>
            <div class="col-md-1">
              {{row.Price}}
            </div>
            <div class="col-md-1">
              <a (click)="deleteConfirm(row)" style="cursor:pointer">
                <mat-icon>delete_forever</mat-icon></a>
            </div>
          </div>
          <div class="row" >
            <div class="col-md-6">
            </div>
           
            <div class="col-md-4">
              <label>Sub Total</label>
            </div>
            <div class="col-md-1">
              {{subTotal}}
            </div>
            
          </div>

          <div class="row" style="color:brown;font-weight: 500;">
            <div class="col-md-6">
            </div>
           
            <div class="col-md-4">
              <label>Tax</label>
            </div>
            <div class="col-md-1">
              {{totalTax}}
            </div>
            
          </div>
          <div class="row" style="font-weight: 500;">
            <div class="col-md-6">
            </div>
           
            <div class="col-md-4">
              <label>Total</label>
            </div>
            <div class="col-md-1">
              {{invoiceTotal}}
            </div>
            
          </div>

      
        </div>
      </accordion-group>
      <accordion-group [isOpen]="false" #invoiceNotes>
        <span accordion-heading>
          <i class="fas" [ngClass]="{'fa-angle-down': invoiceNotes?.isOpen, 'fa-angle-up': !invoiceNotes?.isOpen}"></i>
          Notes
        </span>

        <div class="form-row">
          <div class="col-md-12">
            <mat-form-field>
              <input type="input" autocomplete="off" formControlName="notes" matInput placeholder="Notes">
              <mat-error *ngIf="serviceEstimateForm.controls.notes.errors != null && !formValid">
                Please enter notes
              </mat-error>
            </mat-form-field>
          </div>
        </div>

      </accordion-group>
    </accordion>

  </form>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">{{closeBtnName}}</button>
  <button type="button" class="btn btn-primary" (click)="createNewDocument()">Save</button>
</div>