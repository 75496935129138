import { Injectable } from '@angular/core';
import { Api } from './api';

@Injectable({
  providedIn: 'root'
})

export class XeroService {

  constructor(public api: Api) { }

  getInvoices() {
    let seq = this.api.get('Xero/Invoices');
    return seq;
  }

  generateToken(OfficeSoid, code) {
    let seq = this.api.post('Xero/Office/' + OfficeSoid + '/code/' + code, null);

    return seq;
  }

  isTokenExpired(OfficeSoid) {
    let seq = this.api.get('Xero/Office/' + OfficeSoid + '/isTokenExpired');
    return seq;
  }

  updateXeroTenant(OfficeSoid, tenantInfo) {
    let seq = this.api.post('Xero/Office/' + OfficeSoid + '/UpdateXeroTenant', tenantInfo);
    return seq;
  }


  
  refreshToken() {
    let seq = this.api.get('Xero/Office/Refresh');
    return seq;
  }

  
}

