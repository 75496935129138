import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatTableDataSource } from "@angular/material/table";
import { ActivatedRoute } from "@angular/router";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { TaskService } from "src/app/services/tasks.service";
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { DocumentsService } from "src/app/services/documents.service";
import { StaffService } from "src/app/services/staff.service";

@Component({
    selector: 'task-edit',
    templateUrl: './taskedit.component.html',
    styleUrls: ['./taskedit.component.css']
})
export class TaskEditComponent implements OnInit {

    soId: string;
    task: any;
    showTask: boolean = false;
    myControl = new FormControl();
    filteredOptions: Observable<string[]>;
    selectedCollab: any;
    collaborator: string;
    @ViewChild('labelImport')
    labelImport: ElementRef;
    fileToUpload: File = null;
    displayedColumns: string[] = ['FileName', 'FileType', 'PostedByName'];
    displayedCommentsColumns: string[] = ['CreatedOn', 'AuthorName', 'Content'];
    public dataSource;
    public commentDataSource;
    actualValue: string;
    comment: string = '';
    modalRef: BsModalRef;
    modalCollaborator: any;
    userSoid: string;
    employees: any[] = [];

    constructor(private activatedRoute: ActivatedRoute, private _taskService: TaskService, private datePipe: DatePipe, private toast: ToastrService, private modalService: BsModalService, private _documentService: DocumentsService, private _staffService: StaffService) { }

    async ngOnInit() {
        await this.getStaffList();
        this.soId = this.activatedRoute.snapshot.params.taskId;
        this.userSoid = localStorage.getItem('UserSoid');
        this.getTask();
        this.filteredOptions = this.myControl.valueChanges
            .pipe(
                startWith(''),
                map(value => this._filter(value))
            );
    }

    private getStaffList(): any {
        this._staffService.getActiveStaffs().subscribe((res: any) => {
            if (res) {
                this.employees = res;
            }
        });
    }

    public getTask() {
        this._taskService.getTaskInfo(this.soId).subscribe((res: any) => {
            if (res) {
                this.task = res;
                this.task.NextStepOn = this.task.NextStepOn ? new Date(this.task.NextStepOn) : null;
                this.dataSource = new MatTableDataSource<any>(this.task.Attachments);
                this.commentDataSource = new MatTableDataSource<any>(this.task.Comments);
                this.showTask = true;
            } else {

            }
        });
    }

    private _filter(value: any): string[] {
        const filterValue = value.toLowerCase();

        return this.employees.filter(option => option.PersonName.toLowerCase().includes(filterValue));
    }

    public onAddCollaboratorButtonClick() {
        var request: any = {};
        request.Role = this.selectedCollab.Role;
        request.UserName = this.selectedCollab.PersonName;
        request.UserSoid = this.selectedCollab.PersonSoid;
        this._taskService.addCollaborator(this.soId, request).subscribe((res: any) => {
            if (res) {
                this.task = res;
                this.task.NextStepOn = this.task.NextStepOn ? new Date(this.task.NextStepOn) : null;
                this.dataSource = new MatTableDataSource<any>(this.task.Attachments);
                this.commentDataSource = new MatTableDataSource<any>(this.task.Comments);
                this.collaborator = '';
            } else {

            }
        });

    }

    public OnCollabsSelected(collab: any) {
        this.selectedCollab = this.employees.filter(x => x.PersonName == collab.value)[0];
    }

    public async onChangeToAssigneeButtonClick(item: any) {
        var request: any = {};
        request.Role = "Assignee";
        request.UserName = this.task.AssigneeName;
        request.UserSoid = this.task.AssigneeSoid;
        await this._taskService.addCollaborator(this.soId, request).subscribe(async (res: any) => {
            if (res) {
                var updateRequest: any = {};
                updateRequest.ObjectSoid = this.soId;
                updateRequest.UserName = localStorage.getItem('UserName');
                updateRequest.UserSoid = localStorage.getItem('UserSoid');
                await this.updateAssigneeSoid(item, updateRequest);
                await this.updateAssigneeName(item, updateRequest);
                await this.deleteAssignedCollaborator(item);
            } else {

            }
        });
    }

    public async updateAssigneeSoid(item: any, request: any) {
        request.Data = item.UserSoid;
        request.FieldName = "AssigneeSoid";
        this._taskService.updateTaskInfo(this.soId, request).subscribe((res: any) => {
            if (res) {
            }
        });
    }

    public async updateAssigneeName(item: any, request: any) {
        request.Data = item.UserName;
        request.FieldName = "AssigneeName";
        this._taskService.updateTaskInfo(this.soId, request).subscribe((res: any) => {
            if (res) {
            }
        });
    }

    public async deleteAssignedCollaborator(item: any) {
        this._taskService.deleteCollaborator(this.soId, item.Soid).subscribe((res: any) => {
            if (res) {
                this.task = res;
                this.task.NextStepOn = this.task.NextStepOn ? new Date(this.task.NextStepOn) : null;
                this.dataSource = new MatTableDataSource<any>(this.task.Attachments);
                this.commentDataSource = new MatTableDataSource<any>(this.task.Comments);
            }
        });
    }

    public async OnOkDeleteButtonClick() {
        await this.deleteAssignedCollaborator(this.modalCollaborator);
        this.modalRef.hide()
    }

    public onFileChange(files: FileList) {
        this.labelImport.nativeElement.innerText = Array.from(files)
            .map(f => f.name)
            .join(', ');
        this.fileToUpload = files.item(0);
    }

    public OnUploadButtonClick() {
        var files = [];
        files.push(this.fileToUpload);
        const request = new FormData();
        request.append('Files', this.fileToUpload);
        request.append('TaskSoid', this.soId);
        request.append('CreatedBy', localStorage.getItem("UserName"));
        request.append('CreatedID', localStorage.getItem("UserSoid"));
        request.append('Subject', this.task.Subject);

        this._taskService.addAttachments(request).subscribe((res: any) => {
            if (res) {
                this.dataSource = new MatTableDataSource<any>(res.task.Attachments);
            }
        });
    }

    onInputFocusOut(event) {
        if (event.srcElement.value.trim() != "" && this.actualValue != event.srcElement.value.trim()) {
            this.invokeTaskSave(event.srcElement.id, event.srcElement.value);
        }

    }

    inputFocus(event) {
        this.actualValue = event.srcElement.value;
    }

    private invokeTaskSave(selector, value) {
        var requestInfo: any = {};
        requestInfo.FieldName = selector;
        requestInfo.Data = value
        requestInfo.UserName = localStorage.getItem("UserName");
        requestInfo.UserSoid = localStorage.getItem("UserSoid");
        this._taskService.updateTaskInfo(this.soId, requestInfo).subscribe((res: any) => {
            if (res && res.Changed === true) {
                this.toast.success("Updated Successfully.", "Success!");
            } else {
                this.toast.error("Error Updating Details", "Error!");
            }
        });
    }

    onSelectFocus(event) {
        this.actualValue = event.srcElement.value;
    }

    onSelectNumberFocusOut(event) {
        if (event.source._value.toString().trim() != "" && this.actualValue != event.source._value.toString().trim().trim()) {
            this.invokeTaskSave(event.source._id, Number(event.source._value.toString().trim()));
        }
    }

    onSelectFocusOut(event) {
        if (event.source._value.trim() != "" && this.actualValue != event.source._value.trim().trim()) {
            this.invokeTaskSave(event.source._id, event.source._value.trim());
        }
    }

    dateChange(event) {
        this.invokeTaskSave(event.target._elementRef.nativeElement.id, this.datePipe.transform(event.value, 'MM/dd/yyyy HH:mm'));
    }

    dateTimeChange(event) {
        this.invokeTaskSave(event.source.elementRef.nativeElement.id, this.datePipe.transform(event.value, 'MM/dd/yyyy HH:mm'));
    }

    public onAddCommentButtonClick() {
        var request: any = {};
        request.AuthorName = localStorage.getItem("UserName");
        request.Content = this.comment;

        this._taskService.addTaskComment(this.soId, request).subscribe((res: any) => {
            if (res) {
                this.task = res;
                this.task.NextStepOn = this.task.NextStepOn ? new Date(this.task.NextStepOn) : null;
                this.dataSource = new MatTableDataSource<any>(this.task.Attachments);
                this.commentDataSource = new MatTableDataSource<any>(this.task.Comments);
            }
        });

    }

    openModal(template: TemplateRef<any>, item: any) {
        this.modalRef = this.modalService.show(template);
        this.modalCollaborator = item;
    }

    public onChangeStatusButtonClick(status: string) {
        const request: any = {};
        request.Status = status;
        request.TaskSoid = this.soId;
        this._taskService.updateTaskStatus(this.soId, request).subscribe((res: any) => {
            if (res) {
                this.task = res;
                this.task.NextStepOn = this.task.NextStepOn ? new Date(this.task.NextStepOn) : null;
                this.dataSource = new MatTableDataSource<any>(this.task.Attachments);
                this.commentDataSource = new MatTableDataSource<any>(this.task.Comments);
            }
        });
    }

    viewFile(item: any) {
        var request: any = {};
        request.fileId = item.Soid;
        var documentType = item.FileType.toLowerCase();
        this._documentService.downloadFile(request).subscribe((res: any) => {
            if (res) {
                const mimType = documentType === '.pdf' ? 'application/pdf' : documentType === '.xlsx' ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' :
                    documentType === '.pptx' ? 'application/pptx' : documentType === '.csv' ? 'application/csv' :
                        documentType === '.docx' ? 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' :
                            documentType === '.jpg' ? 'application/jpg' : documentType === '.png' ? 'image/png' : '';


                // download the file
                var fileURL: any = URL.createObjectURL(this.convertBase64ToBlob(res.data.response, mimType));

                //window.open("data:application/pdf," + escape(res.data.response)); 
                const a = document.createElement('a');
                a.href = fileURL;
                // a.download = this.documentInfo.FileName;
                a.target = "_blank"
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            }
        });

    }

    convertBase64ToBlob(base64Image: string, mimType) {


        // Decode Base64 string
        const decodedData = window.atob(base64Image);

        // Create UNIT8ARRAY of size same as row data length
        const uInt8Array = new Uint8Array(decodedData.length);

        // Insert all character code into uInt8Array
        for (let i = 0; i < decodedData.length; ++i) {
            uInt8Array[i] = decodedData.charCodeAt(i);
        }

        // Return BLOB image after conversion
        return new Blob([uInt8Array], { type: mimType });
    }

}