import { Component, OnInit, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Subject, ReplaySubject } from 'rxjs';
import { ModulesService } from '../../../services/modules.service';
import { MasterService } from '../../../services/master.service';
import { take, takeUntil } from 'rxjs/operators';

import { OfficeService } from '../../../services/office.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-add-modules',
  templateUrl: './add-modules.component.html',
  styleUrls: ['./add-modules.component.css']
})
export class AddModulesComponent implements OnInit {

  title: string = "Business Info";
  selectCallType: string;
  closeBtnName: string = "Close";
  notesForm: FormGroup;
  formValid: boolean = true;
  clientInfo: any = {};
  header: string;
  editModule: any = {};
  officeList: any = [];
  clientNote: string;
  officeIds: string[] = [];
  public event: EventEmitter<any> = new EventEmitter();

  constructor(private datePipe: DatePipe, private toast: ToastrService,private _officeService: OfficeService,
    private masterService: MasterService, private moduleService: ModulesService,
    public bsModalRef: BsModalRef, sb: FormBuilder) {
  }

  ngOnInit() {
    this.notesForm = new FormGroup({
      module: new FormControl('', Validators.compose([
        Validators.required
      ])),
      childOffice: new FormControl('', Validators.compose([
      ])),
    });
    if (this.editModule && this.editModule.Soid) {
      this.clientNote = this.editModule.Name;
      this.officeIds = this.editModule.OfficeSoid;
    }

    this.getOfficeList();
  }



  createNewDocument() {
    for (let inner in this.notesForm.controls) {
      this.notesForm.get(inner).markAsTouched();
      this.notesForm.get(inner).updateValueAndValidity();
    }
    if (!this.notesForm.valid) {
      this.formValid = false;
      this.toast.warning("Fill the required fields.", "Warning!");
      return;
    }

    var officeIds = !this.officeIds || this.officeIds.length == 0 ?
      [localStorage.getItem("OfficeSoid")] : this.officeIds;
    if (this.editModule && this.editModule.Soid) {
      var requestInfo: any = {};
      requestInfo.FieldName = "Name";
      requestInfo.Data = this.notesForm.controls.module.value;
      requestInfo.UserName = localStorage.getItem("UserName");
      requestInfo.UserSoid = localStorage.getItem("UserSoid");
      requestInfo.OfficeSoid = officeIds;
      this.moduleService.updateModules(this.editModule.Soid, requestInfo).subscribe((res: any) => {
        if (res) {
          localStorage.setItem("Modules", JSON.stringify(res));
          this.toast.success("Updated module successfully.", "Success!");
          this.triggerEvent();
          this.bsModalRef.hide();
        } else {
          this.toast.warning("Error Creating service", "Error!");
        }
      }, (err) => {
        this.toast.error(err);
      });
    } else {
      var notes: any = {};
      notes.Name = this.notesForm.controls.module.value;
      notes.StaffSoid = localStorage.getItem("UserSoid");
      notes.StaffName = localStorage.getItem("NameFirst") + " " + localStorage.getItem("NameLast");
      notes.Description = this.notesForm.controls.module.value;
      notes.OfficeSoid = officeIds;
      notes.Status = "Active";
      
      this.moduleService.saveModules(notes).subscribe((res: any) => {
        if (res) {
          localStorage.setItem("Modules", JSON.stringify(res));            

          this.toast.success("Added module successfully.", "Success!");
          this.triggerEvent();
          this.bsModalRef.hide();
        } else {
          this.toast.warning("Error Creating module", "Error!");
        }
      });
    }
  }


  private getOfficeList() {    
    let officeSoids = localStorage.getItem("Offices") != null ? JSON.parse(localStorage.getItem("Offices")) : [];

    if (officeSoids.length > 0) {
      officeSoids = officeSoids.map(o => o.OfficeSoid);
      this._officeService.getOfficeListByIds(officeSoids).subscribe((res: any) => {
        if (res) {
          this.officeList = res;
        } else {
          this.officeList = [];
        }
      }, (err) => {
        this.toast.error(err);
      });
    }
  }




  triggerEvent() {
    this.event.emit();
  }
}
