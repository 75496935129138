<div class="modal-header">
  <h4 class="modal-title pull-left">{{header}}</h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form class="client-modal" [formGroup]="businessInfoForm">

    <div class="form-row">
      <div class="form-group col-md-6">
        <mat-form-field>
          <input type="text" autocomplete="off" 
             formControlName="CompanyName"
            name="CompanyName" id="CompanyName" [(ngModel)]="clientInfo.CompanyName" matInput
            placeholder="Company Name">
          <mat-error *ngIf="businessInfoForm.controls.CompanyName.errors != null">
            Please enter company name
          </mat-error>
        </mat-form-field>
      </div>
      <div class="form-group col-md-6">
        <mat-form-field>
          <input type="text" autocomplete="off" maxlength="14" mask="00-0000000" 
            formControlName="EINNumber" name="EINNumber"
            id="EINNumber" [(ngModel)]="clientInfo.EINNumber" matInput placeholder="EIN Number">
          <mat-error *ngIf="businessInfoForm.controls.EINNumber.errors != null">
            Please enter valid EINNumber
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-md-6">
        <mat-form-field>
          <mat-label>NAICS</mat-label>
          <mat-select formControlName="naicsC" placeholder="NAICS" #singleSelect>
            <mat-option>
              <ngx-mat-select-search [formControl]="naicsCtrl"></ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let item of filteredNaics  | async" [value]="item.code">
             {{item.code}}-{{item.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="form-group col-md-6">
        <mat-form-field>
          <input type="text"  [(ngModel)]="clientInfo.BusinessType" autocomplete="off"
            formControlName="businessType" matInput placeholder="Occupation / Industry">

        </mat-form-field>
      </div>
      <div class="form-group col-md-6">
        <mat-form-field>
          <input matInput [(ngModel)]="businessStarts" Name="BusinessStartDate"
            id="BusinessStartDate" autocomplete="off" formControlName="businessStartDate"
            [matDatepicker]="businssStartDatePic" placeholder="Business Start Date">
          <mat-datepicker-toggle matSuffix [for]="businssStartDatePic"></mat-datepicker-toggle>
          <mat-datepicker #businssStartDatePic></mat-datepicker>

        </mat-form-field>
      </div>
      <div class="form-group col-md-6">
        <mat-form-field>
          <input type="text" autocomplete="off" 
           name="WebsiteURL" id="WebsiteURL"
            formControlName="WebsiteURL" [(ngModel)]="clientInfo.WebsiteURL" matInput placeholder="Website URL">
        </mat-form-field>
      </div>
      <div class="form-group col-md-6">
        <mat-form-field>
          <input type="number" 
            [(ngModel)]="clientInfo.NumberOfEmployees"
            (keypress)="($event.charCode >= 48 && $event.charCode < 58)" autocomplete="off"
            formControlName="NumberOfEmployees" matInput placeholder="Number of Employees #">

        </mat-form-field>
      </div>
      <div class="form-group col-md-6">
        <mat-form-field>
          <mat-label>Commercial Property</mat-label>
          <mat-select  formControlName="commericalProperty"
            [(ngModel)]="clientInfo.CommercialProperty">
            <mat-option *ngFor="let space of commericalSpace" [value]="space.code">
              {{space.name}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <!-- <div class="form-group col-md-6">
        <mat-form-field>
          <mat-label>Insurance</mat-label>
          <mat-select [formControl]="clientInsurances">
            <mat-option value="None">None</mat-option>
            <mat-optgroup *ngFor="let group of clientInsurance" [label]="group.name"
                      [disabled]="group.disabled">
            <mat-option *ngFor="let item of group.list" [value]="item.code">
              {{item.name}}
            </mat-option>
          </mat-optgroup>
          </mat-select>
        </mat-form-field>
      </div> -->
    </div>
    <div class="form-row">
      <div class="form-group col-md-6">
        <mat-form-field>
          <input type="text" autocomplete="off" 
           
            formControlName="businessEmail" matInput name="BusinessEmail" id="BusinessEmail"
            placeholder="Business Email" [(ngModel)]="clientInfo.BusinessEmail">

        </mat-form-field>
      </div>
      <div class="form-group col-md-6">
        <mat-form-field>
          
          <ngx-mat-intl-tel-input   (countryChanged)="countryChange($event)"
          (focusout)="phoneNumberFieldFocusOut($event)"  [preferredCountries]="['us', 've']"
            [enablePlaceholder]="true" placeholder="Business Phone Number" [enableSearch]="true"
            format="national" name="BusinessMobileNo" id="BusinessMobileNo" 
            formControlName="phone">
          </ngx-mat-intl-tel-input>
        
        <mat-error *ngIf="businessInfoForm.controls.phone.errors?.required">Please enter valid mobile number</mat-error>
        <mat-error *ngIf="businessInfoForm.controls.phone.errors?.validatePhoneNumber">Please enter valid mobile number</mat-error>
        <mat-error *ngIf="businessInfoForm.controls.phone.errors?.maxlength">Please enter valid mobile number</mat-error>
      </mat-form-field>
      </div>

    </div>
    <div class="form-row">
      <div class="card-payment-checkbox">
        <mat-checkbox formControlName="addressSame" (change)="setBusinessAddress($event)"  >Same as Home Address</mat-checkbox>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-md-6">
        <mat-form-field>
          <input autocomplete="off" type="text" 
            formControlName="businessAddress" name="BusinessAddress" id="BusinessAddress"
            [(ngModel)]="clientInfo.BusinessAddress" matInput placeholder="Business Address">
          <mat-error *ngIf="businessInfoForm.controls.businessAddress.errors != null">
            Please enter business address
          </mat-error>
        </mat-form-field>
      </div>
      <div class="form-group col-md-6">
        <mat-form-field>
          <input 
            type="text" name="BusinessCity" id="BusinessCity" autocomplete="off"
            [(ngModel)]="clientInfo.BusinessCity" formControlName="businessCity" matInput
            placeholder="City">
        </mat-form-field>
      </div>
      
      <div class="form-group col-md-6">
        <mat-select-country 
         name="BusinessAddressCountry"
          id="BusinessAddressCountry" autocomplete="off" appearance="legacy"
          placeHolder="Country" label="Country" [value]="businessCountryName" class="className"
          formControlName="businessAddressCountry">
        </mat-select-country>
      </div>

      <div class="form-group col-md-6"
        *ngIf="businessCountryName && businessCountryName.alpha2Code != 'US'">
        <mat-form-field>
          <input type="text" autocomplete="off" 
            [(ngModel)]="clientInfo.BusinessState" name="BusinessState" id="BusinessState"
            formControlName="businessState" matInput placeholder="State">
          <mat-error *ngIf="businessInfoForm.controls.businessState.errors != null">
            Please enter business address state
          </mat-error>

        </mat-form-field>
      </div>
      <div class="form-group col-md-6"
        *ngIf="businessCountryName && businessCountryName.alpha2Code == 'US'">
        <mat-form-field>
          <mat-label>State</mat-label>
          <mat-select name="BusinessState" id="BusinessState"  formControlName="businessState"
            [(ngModel)]="clientInfo.BusinessState">
            <mat-option *ngFor="let states of usStates" [value]="states.abbreviation">
              {{states.name}}</mat-option>
          </mat-select>
          <mat-error *ngIf="businessInfoForm.controls.businessState.errors != null">
            Please select driving license state
          </mat-error>
        </mat-form-field>
      </div>
      <div class="form-group col-md-6">
        <mat-form-field>
          <input [(ngModel)]="clientInfo.BusinessZipCode" 
           
            name="BusinessZipCode" id="BusinessZipCode"
            (keypress)="($event.charCode >= 48 && $event.charCode < 58)" type="number"
            autocomplete="off" formControlName="businessZipCode" matInput
            placeholder="Zip Code">
          <mat-error *ngIf="businessInfoForm.controls.businessZipCode.errors != null">
            Please enter business address zip code
          </mat-error>

        </mat-form-field>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">{{closeBtnName}}</button>
  <button type="button" class="btn btn-primary" (click)="createNewDocument()">Create</button>
</div>