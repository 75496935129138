<div class="section-container">
  <div class="subheader">
    <h2>Dashboard</h2>

    <!-- <h5>Plans and events</h5> -->
  </div>
  
  <div class="white-bg rounded border-0 p-3">
    <div class="row billing-dashboard">

      <div class="col-lg-4">
        <div class="portlet light">
          <div class="portlet-title">
            <div class="caption font-green"><span class="caption-subject">Upcoming Today ({{currentDate | date:"EEEE, MMM dd"}})</span></div>
          </div>
          <div class="portlet-body">
            <div class="row" style="min-height: 200px">
              <div class="list-group" style="min-width:100%" *ngIf="upcomingEvents.length > 0">
                <a (click)="appointmentPage(event.Soid)" *ngFor="let event of upcomingEvents"  class="list-group-item list-group-item-action flex-column align-items-start">
                  <div class="d-flex w-100 justify-content-between">
                    
                    <span style="margin-bottom:-5px">{{event.Subject}}</span>
                    <span style="font-size:9px">{{event.Time.Start | date:'shortTime'}} - {{event.Time.End | date:'shortTime'}}</span>
                  </div>
                  <p style="margin-bottom:-5px">{{event.Participants | ParticipantName : 'Customer' | titlecase}}</p>
                  <small>{{event.Status}}</small>
                </a>    

              </div>

              <div  class="list-group-item list-group-item-action flex-column align-items-start" *ngIf="upcomingEvents.length < 1">
                <div class="d-flex w-100 justify-content-between">
                  <h6 class="mb-1">No Events</h6>
                </div>
              </div>
             
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-8">
        <div class="portlet light">
          <div class="portlet-title">
            <div class="caption font-green"><span class="caption-subject">Payment (USD)</span></div>
          </div>
          <div class="portlet-body">
            <div class="row">
              <div class="col-lg-4 col-md-4 col-sm-4">
                <a class="dashboard-stat dashboard-stat-v2 red">
                  <div class="visual"><i class="fas fa-dollar-sign"></i></div>
                  <div class="details">
                    <div class="number"><span data-counter="counterup" class="ng-tns-c66-0">${{overDueAmount}}</span></div>
                    <div class="desc">OverDue</div>
                  </div>
                </a>
              </div>
              <div class="col-lg-4 col-md-4 col-sm-4">
                <a class="dashboard-stat dashboard-stat-v2 red">
                  <div class="visual"><i class="fas fa-dollar-sign"></i></div>
                  <div class="details">
                    <div class="number"><span data-counter="counterup" class="ng-tns-c66-0">${{dueAmount}}</span></div>
                    <div class="desc">Due</div>
                  </div>
                </a>
              </div>
              <div class="col-lg-4 col-md-4 col-sm-4">
                <a class="dashboard-stat dashboard-stat-v2 blue">
                  <div class="visual"><i class="fas fa-dollar-sign"></i></div>
                  <div class="details">
                    <div class="number"><span data-counter="counterup" class="ng-tns-c66-0">${{notYetDueAmount}}</span></div>
                    <div class="desc">Not Yet Due</div>
                  </div>
                </a>
              </div>
            </div>
           
            <div class="row">
              <div class="col-sm-6">
                <hr class="mt-0">
                <div class="row">
                  <div class="col-sm-6">
                    <div class="c-callout c-callout-info"><small class="text-muted">Today</small>
                      <br><strong class="h4">${{todayAmount}}</strong></div>
                  </div>
                  <div class="col-sm-6">
                    <div class="c-callout c-callout-purple"><small class="text-muted">This Week</small>
                      <br><strong class="h4">${{thisWeekAmount}}</strong></div>
                  </div>
                </div>
               
                
              </div>
              <div class="col-sm-6">
                <hr class="mt-0">
                <div class="row">
                  <div class="col-sm-6">
                    <div class="c-callout c-callout-warn"><small class="text-muted">Month To Date</small>
                      <br><strong class="h4">${{thisMonthAmount}}</strong></div>
                  </div>
                  <div class="col-sm-6">
                    <div class="c-callout c-callout-success"><small class="text-muted">Year To Date</small>
                      <br><strong class="h4">${{thisYearAmount}}</strong></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
   
      
      <div class="portlet light">
        <div class="portlet-title">
          <div class="caption font-green"><span class="caption-subject">Calendar</span></div>
        </div>
        <div class="portlet-body">
            <full-calendar [options]="calendarOptions"></full-calendar>
        </div>
      </div>
     
  
  </div>
</div>