<div class="section-container">
  <div class="subheader"  style="margin-left: 0;">
    <h2>Invoice Details</h2>
    <!-- <h5>Plans and events</h5> -->
  </div>
  <div class="white-bg rounded border-0">
    <div class="row top-menu bg-light bg-grey no-gutters">
      <div class="col-md-6">
        <div class="top-menu-container">
          <a class="btn-link btn-icon-back" (click)="goBack()"><i class="fa fa-chevron-left"></i></a>
          <!-- <a class="btn btn-primary"  >Charge</a>-->
          <!-- <a class="btn btn-primary" (click)="recordPayment()">View Receipt</a> -->
          <!--<a class="btn btn-primary" (click)="recordPayment()">Resend Receipt</a>-->
          <a class="btn btn-primary" (click)="recordPayment()"
            *ngIf="invoiceInfo.XeroStatus == 'AUTHORISED' && invoiceStatus != 'Paid' && invoiceStatus != 'DELETED' && invoiceStatus != 'VOIDED' ">Record Payment</a>
          <a class="btn btn-primary" (click)="getXeroStatus()" *ngIf="invoiceInfo.XeroStatus == 'DRAFT'  && invoiceStatus != 'DELETED' && invoiceStatus != 'VOIDED' ">Get Status</a>
          <a class="btn btn-primary" (click)="submitToXero()" *ngIf="invoiceInfo.XeroStatus == 'Pending'  && invoiceStatus != 'DELETED' && invoiceStatus != 'VOIDED' ">Submit to
            Xero</a>
           <!--<a class="btn btn-primary" (click)="generatePDF()">View Invoice</a>
         <a class="btn btn-primary" (click)="approveInvoice()"
            >Refund</a>-->


          <a class="btn btn-secondary  mr-2" [matMenuTriggerFor]="invoiceOptions">Invoice Options   <i class="ml-2 fas fa-ellipsis-v"></i></a>

          <mat-menu #invoiceOptions="matMenu">
            <button mat-menu-item (click)="voidInvoice()"  *ngIf="invoiceInfo.XeroStatus == 'AUTHORISED'">
              <mat-icon>receipt_long</mat-icon>
              <span>Void</span>
            </button>
            <button mat-menu-item (click)="deleteInvoice()"  *ngIf="invoiceInfo.XeroStatus == 'DRAFT'">
              <mat-icon>receipt_long</mat-icon>
              <span>Delete</span>
            </button>
            <button mat-menu-item (click)="editInvoiceInfo()" *ngIf="invoiceInfo.XeroStatus == 'DRAFT' || invoiceInfo.XeroStatus == 'Pending'">
              <mat-icon>request_quote</mat-icon>
              <span>Edit</span>
            </button>
            <button mat-menu-item (click)="repeatInvoiceInfo()">
              <mat-icon>view_in_ar</mat-icon>
              <span>Repeat</span>
            </button>
          </mat-menu>

          <a class="btn btn-warning" [matMenuTriggerFor]="menu"><i class="fas fa-ellipsis-v"></i></a>

          <mat-menu #menu="matMenu">
            <button mat-menu-item *ngIf="invoiceStatus != 'DELETED'">
              <mat-icon>receipt_long</mat-icon>
              <span>Cancel Payment</span>
            </button>
            <button mat-menu-item (click)="generatePDF('download')">
              <mat-icon>receipt_long</mat-icon>
              <span>Download</span>
            </button>
            <button mat-menu-item (click)="generatePDF('print')">
              <mat-icon>request_quote</mat-icon>
              <span>Print</span>
            </button>
            <button mat-menu-item (click)="viewPayments()" *ngIf="invoiceStatus != 'DELETED'">
              <mat-icon>view_in_ar</mat-icon>
              <span>View Payments</span>
            </button>
            <button mat-menu-item (click)="showInvoiceSendConfirmationPopup(template)">
              <mat-icon>mail</mat-icon>
              <span>Send email</span>
            </button>
          </mat-menu>

        </div>
      </div>
      <div class="col-md-6 text-right">
        <div class="top-menu-container">
          <!-- <a class="btn btn-secondary">View on calender</a>
          <a class="btn btn-secondary">View conversation</a>
          <a class="btn btn-secondary" (click)="newTimeForAppoinment()">New Time</a>
          <a class="btn btn-secondary" (click)="resceduleAppoinmentInfo()">Info</a> -->
        </div>
      </div>
    </div>
    <div class="padding-10">
      <!-- <div class="container"> -->
      <div class="row">
        <div class="col-md-12">
          <div class="row">
            <div class="date-container">
              <div class="date"><i class="far fa-file-image fa-5x"></i> <!--
                <span class="binds"></span>
                <span class="month">{{documentInfo.Time.Start | date:"EE" | uppercase}}</span>
                <h1 class="day">{{documentInfo.Time.Start | date:"dd"}}
                  <div class="month-text">{{documentInfo.Time.Start | date:"MMM"}}</div>
                </h1>
               -->
              </div>
            </div>
            <div class="col-md-5">
              <h5>{{invoiceInfo.Label}} #{{invoiceInfo.InvoiceNumber}}</h5>
              <h2 *ngIf="balanceInfo">{{balanceInfo}}</h2>
              <ul class="list-group list-group-flush list-no-borders">
                <li class="list-group-item">
                  <i class="fa fa-clock mr-2"></i>
                  <span [ngClass]="{'deleted-status': invoiceStatus === 'VOIDED' || invoiceStatus === 'DELETED' }">{{invoiceStatus}}</span>
              </li>
                <li class="list-group-item"><i class="fa fa-user-tie  mr-2"></i> {{invoiceInfo.BusinessName ?
                  invoiceInfo.BusinessName : '' | titlecase}}{{invoiceInfo.BusinessName ? ' / ' : '' |
                  titlecase}}{{invoiceInfo.BillingToFirstName | titlecase}} {{invoiceInfo.BillingToLastName |
                  titlecase}}</li>
                <li class="list-group-item"><i class="fa fa-mobile-alt  mr-2"></i>{{clientInfo.PersonalMobile}} </li>
                <li class="list-group-item"><i class="fa fa-email-alt  mr-2"></i>{{clientInfo.Email}} </li>

              </ul>
            </div>

            <div class="col-md-4" style="padding-top:17px">
              <img src="assets/images/xero-logo.png" height="75px" width="75px" class="rounded" />
              <span style="font-weight:500;font-size: 16px;padding-left: 10px;">{{invoiceInfo.XeroStatus}}</span>
            </div>

          </div>
          <div class="row">
            <div class="col-md-4">
              <div class="card">
                <div class="card-header">
                  Details
                </div>
                <div class="card-body card-info">
                  <label>Staff</label>
                  <!-- <button>{{eventInfo.staffFName}} {{eventInfo.staffLName}} <button *ngIf="eventStatus == 'Scheduled'" (click)="assignStaff()" class="btn btn-link">Assign</button></p> -->
                  <p>{{invoiceInfo.StaffName}}</p>
                  <label>Created On</label>
                  <!-- <button>{{eventInfo.staffFName}} {{eventInfo.staffLName}} <button *ngIf="eventStatus == 'Scheduled'" (click)="assignStaff()" class="btn btn-link">Assign</button></p> -->
                  <p>{{invoiceInfo.CreatedOn | date:"dd MMM yyyy"}}</p>
                  <label>Issue Date</label>
                  <!-- <button>{{eventInfo.staffFName}} {{eventInfo.staffLName}} <button *ngIf="eventStatus == 'Scheduled'" (click)="assignStaff()" class="btn btn-link">Assign</button></p> -->
                  <p>{{invoiceInfo.InvoicedOn | date:"dd MMM yyyy"}}</p>
                  <label>Due Date</label>
                  <!-- <button>{{eventInfo.staffFName}} {{eventInfo.staffLName}} <button *ngIf="eventStatus == 'Scheduled'" (click)="assignStaff()" class="btn btn-link">Assign</button></p> -->
                  <p>{{invoiceInfo.DueOn | date:"dd MMM yyyy"}}</p>
                  <!-- <label>Payment Details</label> -->
                  <!-- <button>{{eventInfo.staffFName}} {{eventInfo.staffLName}} <button *ngIf="eventStatus == 'Scheduled'" (click)="assignStaff()" class="btn btn-link">Assign</button></p> -->
                  <!-- <p>Mar 31</p> -->
                  <label>Status Summary</label>
                  <!-- <button>{{eventInfo.staffFName}} {{eventInfo.staffLName}} <button *ngIf="eventStatus == 'Scheduled'" (click)="assignStaff()" class="btn btn-link">Assign</button></p> -->
                  <p>{{invoiceInfo.Notes}}</p>

                  <label>Purchase Order</label>
                  <!-- <button>{{eventInfo.staffFName}} {{eventInfo.staffLName}} <button *ngIf="eventStatus == 'Scheduled'" (click)="assignStaff()" class="btn btn-link">Assign</button></p> -->
                  <p>{{invoiceInfo.PurchaseOrder}}</p>

                  <ng-container *ngIf="invoiceInfo.XeroReference != null">
                    <label style="display: inline-block;padding-right: 5px;">Xero Reference</label>(<a class=""
                      target="_blank" href="{{invoiceURL}}"
                      style="color: blue;cursor:pointer; display: inline-block;">Link</a>)
                    <!-- <button>{{eventInfo.staffFName}} {{eventInfo.staffLName}} <button *ngIf="eventStatus == 'Scheduled'" (click)="assignStaff()" class="btn btn-link">Assign</button></p> -->
                    <p>{{invoiceInfo.XeroReference}}</p>
                  </ng-container>
                </div>
              </div>
              <br>

            </div>
            <div class="col-md-8">
              <div class="card">
                <div class="card-header">
                  <div class="row">
                    <div class="col-md-7">
                      Items
                    </div>
                    <div class="col-md-2">
                    </div>
                    <div class="col-md-3">
                      Total
                    </div>
                  </div>
                </div>
                <div class="card-body card-info">
                  <div class="row" *ngFor="let row of invoiceInfo.Items">
                    <div class="col-md-7">
                      {{row.Item}} <br>
                      {{row.Quantity}} x {{row.UnitPrice}} USD
                    </div>
                    <div class="col-md-2">
                    </div>
                    <div class="col-md-3">
                      ${{row.Price}} USD
                    </div>
                  </div>
                  <div class="row" style="padding: 5px 0;
                  border-bottom: 1px solid blue"></div>
                  <div class="row" style="
                  font-weight: 500;">
                    <div class="col-md-7">
                      Subtotal
                    </div>
                    <div class="col-md-2">
                    </div>
                    <div class="col-md-3">
                      ${{invoiceInfo.SubTotal}} USD
                    </div>
                  </div>
                  <br>
                  <div class="row" style="color:brown;
                  font-weight: 500;">
                    <div class="col-md-7">
                      Tax
                    </div>
                    <div class="col-md-2">
                    </div>
                    <div class="col-md-3">
                      ${{invoiceInfo.Taxes}} USD
                    </div>
                  </div>
                  <br>
                  <div class="row">
                    <div class="col-md-7" style="font-weight: 500;">
                      <strong>Total Amount</strong>
                    </div>
                    <div class="col-md-2">
                    </div>
                    <div class="col-md-3" style="font-weight: 500;">
                      <strong>${{invoiceInfo.Total}} USD</strong>
                    </div>
                  </div>
                  <br>
                  <div class="row" *ngFor="let payment of invoiceInfo.Payments">
                    <div class="col-md-4">

                    </div>
                    <div class="col-md-5">
                      <strong>Less
                        <a class="" (click)="loadReceipt(clientId,invoiceInfo.Soid,payment.Soid, payment)"
                        style="color: blue;cursor:pointer; display: inline-block;">Payment</a>
                      </strong><br>
                      {{payment.CreatedOn | date:"dd MMM yyyy"}}
                    </div>
                    <div class="col-md-3">
                      <strong>${{payment.Amount}} USD</strong>
                      <a class="" *ngIf="payment.XeroRecieptReference != null"
                      target="_blank" href="{{receiptURL}}{{payment.XeroRecieptReference}}"

                        style="color: blue;cursor:pointer; display: inline-block;margin-left: 5px;">
                        <img src="assets/images/xero-icon.png" height="24px" width="24px" class="rounded" /></a>
                    </div>
                  </div>
                  <br>
                  <div class="row" *ngIf="invoiceInfo.Balance > 0  && invoiceStatus != 'DELETED'" >
                    <div class="col-md-7" style="color: red;font-weight: 500;">
                      <strong>Balance Due</strong>
                    </div>
                    <div class="col-md-2">
                    </div>
                    <div class="col-md-3" style="color: red;font-weight: 500;">
                      <strong>${{invoiceInfo.Balance}} USD</strong>
                    </div>
                  </div>
                  <br>
                </div>
              </div>
              <br />

            </div>

          </div>
        </div>

      </div>

      <!-- </div> -->
    </div>
  </div>
</div>

<ng-template #template>
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title">Send invoice by email</h5>
      <button type="button" class="close" aria-label="Close" (click)="sendInvoiceConfirmationPopupRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      Do you want to send <b>{{ invoiceInfo.InvoiceNumber }}</b> invoice by email?
    </div>
    <div class="modal-footer">
      <button class="btn btn-danger" (click)="sendInvoiceConfirmationPopupRef.hide()">Cancel</button>
      <button class="btn btn-info" (click)="sendInvoiceByEmail()" >Send</button>
    </div>
  </div>
</ng-template>
