import { DatePipe } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormControlName, FormGroup, Validators } from "@angular/forms";
import { BsModalRef } from "ngx-bootstrap/modal";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { TaskService } from "src/app/services/tasks.service";
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'task-add',
    templateUrl: './taskadd.component.html',
    styleUrls: ['./taskadd.component.css']
})
export class TaskAddComponent implements OnInit {
    title: string;
    searchText: string;
    clientList: any = [];
    public selectedDate: string;
    public selectedStaff: string;
    public selectedService: string;
    public nextStep: string;
    closeBtnName: string = "Close";
    subscriptionServices: any = [];
    dropdownSettings: any = {};
    assigneeName: string;
    assigneeControl = new FormControl('', [Validators.required]);
    filteredOptions: Observable<string[]>;
    selectedAssignee: any;
    disabled = false;
    ShowFilter = true;
    myForm: FormGroup;
    originatorName: string;
    originatorSoid: string;
    priority: Number;
    dueOn: Date;
    category: string;
    subject: string = '';
    taskForm: FormGroup;
    collabForm: FormGroup;
    formValid:boolean = true;
    collabsDetails: any;
    employees: any[];

    constructor(public bsModalRef: BsModalRef, private fb: FormBuilder, private datePipe: DatePipe, private _taskService: TaskService, private toast: ToastrService) { }

    ngOnInit() {
        this.originatorName = localStorage.getItem('NameFirst') + ' ' + localStorage.getItem('NameLast');
        this.originatorSoid = localStorage.getItem('UserSoid');
        var emps: any = {};
        for (var e of this.employees) {
            emps = {};
            emps.item_id = e.PersonSoid;
            emps.item_text = e.NameFirst + ' ' + e.NameLast;
            this.subscriptionServices.push(emps);
        }

        this.filteredOptions = this.assigneeControl.valueChanges
            .pipe(
                startWith(''),
                map(value => this._filter(value))
            );

        this.taskForm = new FormGroup({
            priorityControl: new FormControl('', [Validators.required]),
            dueOnControl: new FormControl('', [Validators.required]),
            categoryControl: new FormControl('', [Validators.required]),
            subjectControl: new FormControl('', [Validators.required])
        });

        this.collabForm = new FormGroup({
            collaboratorControl : new FormControl('')
        });

        this.dropdownSettings = {
            singleSelection: false,
            idField: 'item_id',
            textField: 'item_text',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            allowSearchFilter: this.ShowFilter
        };

        // this.assigneeForm = new FormGroup({
        //     assigneeControl: new FormControl('', [Validators.required])
        // });
    }

    private _filter(value: any): string[] {
        const filterValue = value.toLowerCase();

        return this.employees.filter(option => option.PersonName.toLowerCase().includes(filterValue));
    }

    public OnAssigneeSelected(collab: any) {

        this.selectedAssignee = this.employees.filter(x => x.PersonName == collab.value)[0];
    }

    public onAddButtonClick(){
        if (!this.taskForm.valid){
            for (let inner in this.taskForm.controls) {
              this.taskForm.get(inner).markAsTouched();
              this.taskForm.get(inner).updateValueAndValidity();
            }
            this.formValid = false;
        }

        if (this.assigneeControl.status == "INVALID"){
            this.assigneeControl.markAsTouched();
            this.assigneeControl.updateValueAndValidity();
            this.formValid = false;
        }

        if(this.taskForm.valid && this.assigneeControl.status == "VALID"){
            var collabsRequest = [];
            if(this.collabsDetails){
                for(let c of this.collabsDetails){
                    const request : any = {};
                    request.UserName = c['item_text'];
                    request.UserSoid = c['item_id'];
                    request.Role = this.employees.filter(e => e.PersonSoid == c['item_id'])[0].Role;
                    collabsRequest.push(request);
                }
            }
    
            var request: any = {};
            request.AssigneeName = this.assigneeName;
            request.AssigneeSoid = this.employees.filter(e => e.PersonName == this.assigneeName)[0].PersonSoid;
            request.Category = this.category;
            request.Collaborators = collabsRequest;
            request.DueOn = this.datePipe.transform(this.dueOn, 'MM/dd/yyyy HH:mm');
            request.OfficeName = localStorage.getItem("OfficeName");
            request.OfficeSoid = localStorage.getItem("OfficeSoid");
            request.OriginatorName = this.originatorName + ' '+ '['+localStorage.getItem("Role")+']';
            request.OriginatorSoid = this.originatorSoid;
            request.Priority = this.priority;
            request.Status = "New";
            request.Subject = this.subject;
            request.Tags = "";
    
            this._taskService.addTask(request).subscribe((res: any) => {
                if (res) {
                    this.toast.success("Added Successfully.", "Success!");
                    this.bsModalRef.hide();
                } else {
                    this.toast.error("Error Adding Task", "Error!");
                }
            });
        }
       
    }
}