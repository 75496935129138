
<div class="modal-header">
  <h4 class="modal-title pull-left">Xero Organization</h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body contentScroll" style="height: 125px; overflow-y: auto; overflow-x: hidden;">
  <mat-form-field>
    <mat-label>Select Xero Organization</mat-label>
    <mat-select [(ngModel)]="tenant" >
      <mat-option *ngFor="let service of xeroTenaants" [value]="service">{{service.Organization |
        titlecase}} </mat-option>
    </mat-select>

  </mat-form-field>

</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary" (click)="closeModal()">Close</button>
  <button type="button" class="btn btn-primary" (click)="updateXero()">Save</button>
</div>