import { FormBuilder, FormGroup } from '@angular/forms';

import { Component, HostListener, OnInit, TemplateRef, ViewChild } from '@angular/core';

import { FormControl, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { Router, ActivatedRoute } from '@angular/router';
import { OfficeService } from '../../../services/office.service';
import { StaffService } from '../../../services/staff.service';
import { ToastrService } from 'ngx-toastr';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { EventPlannerComponent } from '../../../base/eventplanner/eventplanner.component';
import { PageEvent } from '@angular/material/paginator';
import { Country } from '@angular-material-extensions/select-country';
import { DatePipe, Location } from '@angular/common';
import * as _ from 'underscore';
import { USStatesService } from '../../../services/us-states.service';
import { ModulesService } from '../../../services/modules.service';
import { pairwise, startWith } from 'rxjs/operators';


@Component({
  selector: 'app-office-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.css']
})
export class OfficeViewComponent implements OnInit {
  disableOffice: boolean = true;
  clientForm: FormGroup;
  formatAllowed = ['image/jpeg', 'image/jpg', 'image/png', 'application/pdf'];
  contactForm: FormGroup;
  tagForm: FormGroup;
  statusForm: FormGroup;
  xeroForm: FormGroup;
  disabled = false;
  ShowFilter = false;
  limitSelection = false;
  soId: string;
  selectedItems: any = [];
  branchOffice: any = [];
  staffDropdownSettings: any = {};
  clientDropdownSettings: any = {};
  isParentOffice: boolean = false;
  officeInfo: any = {XeroInfo: {}};
  birthDate: Date;
  activeModules: any = [];
  actualValue: string;
  staffSearch: string;
  agelimit: Date;
  staffList: any = [];
  clientList: any = [];
  modalRef: BsModalRef;
  staffLength = Number;
  pageIndex: number = 0;
  clientPageSize = 5;
  staffPageSize = 5;
  clientPageSizeOptions: number[] = [5, 10, 25, 100];
  staffPageSizeOptions: number[] = [5, 10, 25, 100];
  staffLowValue: number = 0;
  staffHighValue: number = 5;
  clientLowValue: number = 0;
  clientHighValue: number = 5;
  searchClient: string;
  clientLength: number = 0;

  servicePageSizeOptions: number[] = [5, 10, 25, 100];
  serviceLowValue: number = 0;
  serviceHighValue: number = 5;
  servicePageSize = 5;
  serviceList: any = [];
  serviceLength: number = 0;
  searchService: string;
  officeStatus: string;
  defaultValue: Country;
  phoneNumberCountryCode: number;
  usStates: any = [];
  toppingsControl = new FormControl([]);
  toppings = new FormControl([]);
  officeList: any = [];
  currentValue: any = [];
  previousValue: any = [];
  xeroURL: string;
  clientId: string;
  clientSecret: string;
  organization: string;

  constructor(private moduleService: ModulesService, private usStatesService: USStatesService, private location: Location, private modalService: BsModalService,
    private toast: ToastrService,
    private _officeService: OfficeService, private fb: FormBuilder,
    private _router: Router,
    private _staffService: StaffService,
    private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.usStates = this.usStatesService.getAllStates();
    this.soId = this.activatedRoute.snapshot.params.soId;


    this.defaultValue = {
      name: 'United States of America',
      alpha2Code: 'US',
      alpha3Code: 'USA',
      numericCode: '840',
      callingCode: '+1'
    };

    var date = new Date();
    date.setFullYear(date.getFullYear() - 18);
    this.agelimit = date;
    this.getOfficeInfo();
    this.moduleService.getAllOfficeModules(localStorage.getItem("OfficeSoid")).subscribe((modules: any) => {
      this.activeModules = modules.filter(tp => tp.Status == "Active");;
      
    });


    this.tagForm = this.fb.group({
      tag: new FormControl(this.selectedItems, Validators.compose([
        Validators.required
      ]))
    });
    this.xeroForm = this.fb.group({
      clientId: new FormControl('', Validators.compose([
      ])),
      organization: new FormControl('', Validators.compose([
      ])),
      clientSecret: new FormControl('', Validators.compose([
      ])),
    });

    this.statusForm = this.fb.group({
      status: new FormControl('', Validators.compose([
        Validators.required
      ]))
    });

    this.contactForm = this.fb.group({
      addressLine1: new FormControl('', Validators.compose([
        Validators.required
      ])), state: new FormControl('', Validators.compose([
        Validators.required
      ]))
      , city: new FormControl('', Validators.compose([
        Validators.required
      ])), country: new FormControl('', Validators.compose([
        Validators.required
      ])), zipCode: new FormControl('', Validators.compose([
        Validators.required
      ]))

    });
    this.clientForm = this.fb.group({
      email: new FormControl('', Validators.compose([
        Validators.required
      ])),
      firstName: new FormControl('', Validators.compose([
        Validators.required
      ])), emailAddress: new FormControl('', Validators.compose([
        Validators.required
      ])),
      phoneNumber: new FormControl('', Validators.compose([
        Validators.required
      ]))

    });
  }

  loadView(soId) {
    this.soId = soId;
    this.getOfficeInfo();


  }


  refreshToken() {
    this._officeService.refreshToken(this.soId).subscribe((res: any) => {
    });
  }
  getOfficeInfo() {
    if (!this.soId) {
      return;
    }
    this.xeroURL = "";
    this.xeroURL = "";
    this.clientId = "";
    this.clientSecret = "";
    this._officeService.getOfficeInfo(this.soId).subscribe((res: any) => {
      if (res) {
        this.officeInfo = res.Office ? res.Office : [];
        if (this.officeInfo) {
          this.serviceList = this.officeInfo.Services ? this.officeInfo.Services : [];
          this.serviceLength = this.officeInfo.Services ? this.officeInfo.Services.length : 0;

          localStorage.setItem("XeroRequestOfficeID", this.officeInfo.Soid);
        } 

        if (res.XeroInfo) {
          this.xeroURL = res.XeroInfo.XeroUrl;     
          this.clientId = res.XeroInfo.ClientId;
          this.clientSecret = res.XeroInfo.ClientSecret;
          this.organization = res.XeroInfo.Organization;
          localStorage.setItem("XeroTaxRates", res.XeroInfo.TaxRates);
          localStorage.setItem("Accounts", res.XeroInfo.Accounts);

        } 
       
        this.getOfficeList();
        this.isParentOffice = this.officeInfo.IsParentOffice ? true : false;
        this.clientList = res.Clients ? res.Clients : [];
        this.clientLength = res.Clients ? res.Clients.length : [];
        this.officeStatus = this.officeInfo.Active ? 'true' : 'false';
        var countryValue: any = {};
        countryValue.name = this.officeInfo.BillCountry;
        countryValue.alpha2Code = this.officeInfo.BillCountryAlphaCode;
        countryValue.alpha3Code = '';
        countryValue.numericCode = '';
        countryValue.callingCode = '';
        this.defaultValue = countryValue;


        this.selectedItems = [];
        this.officeInfo.Modules.forEach(element => {
          var module = this.activeModules.filter(activeMod => (element.ModuleSoid === activeMod.Soid
            && element.Status == "Enrolled"));

          if (module && module[0]) {
            this.toppingsControl.value.push(module[0]);
            this.selectedItems.push(module[0]);
          }

        });
      }
    });
  }

  onInputFocusOut(event) {
    if (event.srcElement.value.trim() != "" && this.actualValue != event.srcElement.value.trim()) {
      this.invokeClientSave(event.srcElement.id, event.srcElement.value);
    }

  }

  onXeroInputFocusOut(event) {
    if (event.srcElement.value.trim() != "" && this.actualValue != event.srcElement.value.trim()) {
      this.invokeXeroSave(event.srcElement.id, event.srcElement.value);
    }

  }



  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    const id = (event.target as HTMLElement).id;
    if (event.ctrlKey && (event.key === 'v' || event.key === 'V') && (id == 'ClientId' || id == 'ClientSecret')) {
      // Perform your save action here
      setTimeout(() => {
        const value = (event.target as HTMLInputElement).value;
        this.actualValue = value;
        this.invokeXeroSave(id, value);
      }, 300);

      
      //this.invokeXeroSave(id, value);
    }
  }

  invokeClientSave(selector, value) {
    var requestInfo: any = {};
    requestInfo.FieldName = selector;
    requestInfo.Data = value
    requestInfo.UserName = localStorage.getItem("UserName");
    requestInfo.UserSoid = localStorage.getItem("UserSoid");
    this._officeService.updateOffice(this.soId, requestInfo).subscribe((res: any) => {
      if (selector != "BillCountryAlphaCode") {
        if (res && res.Changed === true) {
          if (res.response == "EMAIL_EXISTS") {
            this.toast.warning("Email Id already exists.", "Warning!");
          } else if (res.FieldName === 'Logo') {
            this.officeInfo.Logo = res.Data
          } else {
            this.toast.success("Updated Successfully.", "Success!");
          }
        } else {
          this.toast.error("Error Updating Details", "Success!");
        }
      }
    });
  }


  invokeXeroSave(selector, value) {
    var requestInfo: any = {};
    if (selector == 'ClientId') {
      requestInfo.ClientId = value;
    } else {
      requestInfo.ClientSecret = value;
    }
   
    requestInfo.OfficeSoid = this.soId;
    requestInfo.UserName = localStorage.getItem("UserName");
    requestInfo.UserSoid = localStorage.getItem("UserSoid");
    this._officeService.updateXero(requestInfo).subscribe((res: any) => {
    
        if (res && res.Status === "SUCCESS") {
          if (res.XeroUrl != null) {
            this.toast.success("Updated Successfully, Click 'Generate Xero Token' button to access Xero", "Success!");
            this.xeroURL = res.XeroUrl;
          } else {
            this.toast.success("Updated Successfully", "Success!");
          }
         
        } else {
          this.toast.error("Error Updating Details", "Info!");
        }
    });
  }

  inputFocus(event) {
    this.actualValue = event.srcElement.value;
  }

  



  onSelectFocus(event) {
    this.actualValue = event.srcElement.textContent;
  }

  onFocusOut(event) {

   this.invokeClientSave("IsParentOffice", event.checked);
  }

  onSelectFocusOut(event) {
    if (this.actualValue != event.value.trim()) {
      if (event.source._value.trim() != "" && this.actualValue != event.source._value.trim().trim()) {
        this.invokeClientSave(event.source._id, event.source._value.trim() == "true" ? true : event.source._value.trim());
      }
    }
  }

  sortFieldChange(event) {
  }

  onFocusChange(event) {

  }

  onChange(event) {

  }
  onItemSelect(event) {

  }

  public getStaffPaginatorData(event: PageEvent): PageEvent {
    this.staffLowValue = event.pageIndex * event.pageSize;
    this.staffHighValue = this.staffLowValue + event.pageSize;
    return event;
  }

  public getClientPaginatorData(event: PageEvent): PageEvent {
    this.staffLowValue = event.pageIndex * event.pageSize;
    this.staffHighValue = this.staffLowValue + event.pageSize;
    return event;
  }
  public getServicePaginatorData(event: PageEvent): PageEvent {
    this.serviceLowValue = event.pageIndex * event.pageSize;
    this.serviceHighValue = this.serviceLowValue + event.pageSize;
    return event;
  }

  onCountrySelected(event, id, field) {
    this.defaultValue.name = event.name;
    this.defaultValue.alpha2Code = event.alpha2Code;
    var requestInfo: any = {};
    requestInfo.CountryAlphaCode = event.alpha2Code;
    requestInfo.Country = event.name;
    requestInfo.UserName = localStorage.getItem("UserName");
    requestInfo.UserSoid = localStorage.getItem("UserSoid");
    this._officeService.updateOfficeCountry(this.soId, requestInfo).subscribe((res: any) => {
      if (res && res.Changed === true) {
        this.toast.success("Updated Successfully.", "Success!");
      } else {
        this.toast.error("Error Updating Details", "Success!");
      }
    });


  }


  goBack() {
    if (window.history.length > 1) {
      this.location.back()
    }
  }

  staffSort(event) {
    this.staffList = _.sortBy(this.staffList, event.srcElement.value);
  }

  phoneNumberFieldFocusOut(event, field) {
    var isValid = this.clientForm.controls.phoneNumber.errors != null ? false : true;
    if (isValid && event.srcElement.value.trim() != "" && this.actualValue != event.srcElement.value.trim()) {
      var countryCode = this.phoneNumberCountryCode ? '+' + this.phoneNumberCountryCode : '+1';
      this.invokeClientSave(field, countryCode + event.srcElement.value);
    }
  }


  private getStaffList() {
    this._staffService.getStaffByOffice(this.soId, "Active").subscribe((res: any) => {
      if (res) {
        this.staffList = res ? res : [];
        this.staffList = _.sortBy(res, "NameFirst");
        this.staffLength = this.staffList ? this.staffList.length : 0;
      } else {
        this.staffList = [];
      }
    });
  }

  countryChange(event) {
    this.phoneNumberCountryCode = event.dialCode;
  }

  onSelect(event) {
  }

  

  private getOfficeList() {
    let officeSoids = localStorage.getItem("Offices") != null ? JSON.parse(localStorage.getItem("Offices")) : [];

    if (officeSoids.length > 0) {
      officeSoids = officeSoids.map(o => o.OfficeSoid);
      this._officeService.getOfficeListByIds(officeSoids).subscribe((res: any) => {
        if (res) {
          this.branchOffice = [];
          this.officeList = res;
          if (this.officeInfo.BranchOffices) {
            this.officeInfo.BranchOffices.forEach(element => {
              if (element.Status == "Active") {
                this.branchOffice.push(this.officeList.filter(e => (e.Soid === element.Soid))[0]);
              }
             
            });
          }
          this.toppings.value.push(this.branchOffice);
          this.previousValue = this.branchOffice;
        } else {
          this.officeList = [];
        }
      }, (err) => {
        this.toast.error(err);
      });
    }
  }

  initEmploymentChange() {
    this.toppings.valueChanges.pipe(startWith(null), pairwise()).subscribe({
      next: (value: number[]) => {

        if (value[0] != null) {
          this.previousValue = value[0];
        }
        this.currentValue = value[1];


        const newValue = this.previousValue === null ?
          this.currentValue[0] : this.currentValue.find((value: number) => !this.previousValue.includes(value));


        if (newValue && this.previousValue != null) {
            this.updateEmployment(newValue, "Active");
        } else if (this.previousValue != null) {
          this.previousValue.forEach(p => {
            var removeValue = this.currentValue.filter(e => (e.Soid == p.Soid));

            if (removeValue.length == 0) {
               this.updateEmployment(p, "InActive");
            }


          });
        }
      }
    });
  }


  updateEmployment(info, status) {
    var requestInfo: any = {};
    requestInfo.Soid = info.Soid;
    requestInfo.CompanyName = info.CompanyName;
    requestInfo.Status = status;
    this._officeService.updateBranchOffice(this.soId, requestInfo).subscribe((res: any) => {
      if (res && res == "SUCCESS") {
        this.toast.success("Updated Successfully.", "Success!");
      } else {
        this.toast.error("Error Updating Details", "Success!");
      }
    });
  }

  updateBranchOffice(event) {    
    if (!event) {

      this._officeService.updateBranchOffice(this.soId, this.toppings.value).subscribe((res: any) => {
        if (res && res == "SUCCESS") {
          this.toast.success("Updated Successfully.", "Success!");
        } else {
          this.toast.error("Error Updating Details", "Success!");
        }
      });

    }
   
  }


  changeTag(event) {

    if (!event) {
      this._officeService.addorUpdateModule(this.soId, this.toppingsControl.value).subscribe((res: any) => {

        let modules: any = {};

        if (res.Soid === localStorage.getItem("OfficeSoid")) {
          res.Modules.forEach(element => {
            if (element.Status === "Enrolled") {
              modules[element.ModuleSoid] = element.ModuleName;
            }
          });
          localStorage.setItem("Modules", JSON.stringify(modules));
        }


      }
      );
    }

  }

  deleteOfficeLogo() {
    this.invokeClientSave('Logo', null);
  }

  handleOfficeLogoChange(e: Event) {
    const file = (e.target as HTMLInputElement).files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      this.invokeClientSave('Logo', reader.result)
    }
    reader.readAsDataURL(file);
  }
}
