import { FormBuilder, FormGroup } from '@angular/forms';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
//import {PageEvent} from '@angular/material/paginator/api/pageevent';
import { InvoiceService } from '../../../services/invoice.service';
import { USStatesService } from '../../../services/us-states.service';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { EventPlannerComponent } from '../../../base/eventplanner/eventplanner.component';
import _ from 'lodash';
import { SearchclientComponent } from '../../modal/searchclient/searchclient.component';
import { OfficeService } from 'src/app/services/office.service';
import { Country } from '@angular-material-extensions/select-country';
import { PageEvent } from '@angular/material/paginator';
@Component({
  selector: 'app-client',
  templateUrl: './invoice-list.component.html',
  styleUrls: ['./invoice-list.component.css']
})
export class InvoiceListComponent implements OnInit {
  defaultValue: Country
  searchText: string;
  myForm: FormGroup;
  showView: string = "INVOICE"
  clientSubmitForm: FormGroup;
  disabled = false;
  ShowFilter = false;
  limitSelection = false;
  selectedItems: any = [];
  dropdownSettings: any = {};
  selectedInvoiceSoid: string;
  lowValue: number = 0;
  highValue: number = 5;
  clientRequest: any = {};
  firstName: string;
  middleName: string;
  lastName: string;
  personalEmail: string;
  businessEmail: string;
  formValid: boolean = true;
  subscriptionServices: any = [];
  agelimit: Date;
  sortBy: string = "CreatedOn";
  clientRequestInfo: any = {};
  idTypes: string[] = ['Driving License', 'SSN', 'ITIN Number'];
  idType: string;
  countries: string[] = [
    'Canada', 'Mexico', 'United States'
  ];
  martialStatus: string[] = ['Married', 'Widowed', 'Separated', 'Divorced', 'Single'];
  languagePreference: string[] = [
    'English', 'Spanish'
  ];
  hearAboutUs: string[] = [
    'Web', 'Facebook', 'Web', 'Facebook', 'Instagram', 'YouTube', 'Twitter', 'LinkedIn', 'Friend', 'Other'
  ];
  toppingsControl = new FormControl('', Validators.compose([
    Validators.required
  ]));
  toppingList: string[] = ['Extra cheese', 'Mushroom', 'Onion', 'Pepperoni', 'Sausage', 'Tomato'];
  currentDate = new Date();
  hearAboutUsSelect: string;
  businessMobile: string;
  personalMobile: string;
  

  phoneForm = new FormGroup({
    mobilePhone: new FormControl('')

 });

 hiddenSSN: string;
  @ViewChild('phoneField')
  public phoneField;

  @ViewChild('businessphoneField')
  public businessphoneField;


  @ViewChild('viewInvoice')
  public viewInvoice;

  invoiceList: any = [];
  usStates: any = [];

  // MatPaginator Inputs
  invoicesLength = Number;
  pageIndex: number = 0;
  invoicePageSize = 5;
  invoicePageSizeOptions: number[] = [5, 10, 25, 100];
  customPatterns = { '0': { pattern: new RegExp('([A-Za-z0-9_-]+)') } };


  modalRef: BsModalRef;
  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);
  
  overDueAmount: string;
  dueAmount: string;
  filteredTotal: string;
  separateDialCode = false;
  
  duePayment: any = [];
  overDuePayment: any = [];
  allInvoices: any = [];
  officeList: any = [];
  selectedOffice: string;
  role: string;
  xeroURL: string;

  constructor(private usStatesService: USStatesService,private _officeService: OfficeService,  private toast: ToastrService, private datePipe: DatePipe, private _invoiceService: InvoiceService, private modalService: BsModalService, private _router: Router, private fb: FormBuilder,) { }

  ngOnInit() {
    var date = new Date();
    this.xeroURL = localStorage.getItem("XeroURL");
    date.setFullYear(date.getFullYear() - 18);
    this.agelimit = date;
    this.currentDate = new Date();
    this.usStates = this.usStatesService.getAllStates();
    
    this.defaultValue = {
      name: 'United States of America',
      alpha2Code: 'US',
      alpha3Code: 'USA',
      numericCode: '840',
      callingCode: '+1'
    };

    
    this.selectedOffice = localStorage.getItem("OfficeSoid");
  
    this.getInvoiceList();

    this.myForm = this.fb.group({
      city: [this.selectedItems]
    });
    this.getOfficeList();
    this.role = localStorage.getItem("Role");
  }

  getChangeOffice() {
    this.getInvoiceList();
  }

  private getInvoiceList() {
    this._invoiceService.getInvoiceList(this.selectedOffice).subscribe((res: any) => {
      if (res) {
        this.invoicesLength = res.length;
        this.invoiceList = res;
        this.allInvoices = res;
        this.invoiceList = _.sortBy(this.invoiceList, this.sortBy).reverse();
        this.overDuePayment =  this.invoiceList.filter(e => (this.currentDate > new Date(e.DueOn)));
        this.duePayment =  this.invoiceList.filter(e => (this.currentDate <= new Date(e.DueOn)));
        this.overDueAmount = (_.sumBy(this.overDuePayment, 'Balance')).toFixed(2);
        this.dueAmount = (_.sumBy( this.duePayment, 'Balance')).toFixed(2);;
        this.filteredTotal = (_.sumBy(this.invoiceList, 'Total')).toFixed(2);;

        if (this.invoiceList && this.invoiceList.length > 0) {
          var invoice = this.invoiceList[0];
          this.showInvoice(invoice.Soid, invoice.BillingToSoid);
        }
      }
    });
  }

  public getinvoicePaginatorData(event: PageEvent): PageEvent {
    this.lowValue = event.pageIndex * event.pageSize;
    this.highValue = this.lowValue + event.pageSize;
    return event;
  }

  openModal(template: TemplateRef<any>) {
	  this.clientSubmitForm.reset();
    this.modalRef = this.modalService.show(template);
  }

  showInvoice(invoiceSoid, clientSoid) {
    this.viewInvoice.loadView(invoiceSoid, clientSoid);
    this.selectedInvoiceSoid = invoiceSoid;
   // this._router.navigate(['client/' + clientSoid + '/invoice/' + invoiceSoid]);
  }

  
  loadClientSearch(type) {
    const initialState = {
      title: "Client search",
      backdrop: true,
      ignoreBackdropClick: true,
      closeBtnName: "Close",      
      nextStep: type
  };
    this.modalRef = this.modalService.show(SearchclientComponent, { initialState });
      this.modalRef.content.event.subscribe(data => {
       this.getInvoiceList();
     }); 
  }

  addClient() {

  }

  sortFieldChange(event) {
    this.sortBy = event.srcElement.value;
    this.invoiceList = _.sortBy(this.invoiceList, this.sortBy);
  }

  onItemSelect(item: any) { }
  onSelectAll(items: any) {
  }
  toogleShowFilter() {
    this.ShowFilter = !this.ShowFilter;
    this.dropdownSettings = Object.assign({}, this.dropdownSettings, { allowSearchFilter: this.ShowFilter });
  }

  handleLimitSelection() {
    if (this.limitSelection) {
      this.dropdownSettings = Object.assign({}, this.dropdownSettings, { limitSelection: 2 });
    } else {
      this.dropdownSettings = Object.assign({}, this.dropdownSettings, { limitSelection: null });
    }
  }

  getShortName(fullName) {
    return fullName.split(' ').map(n => n[0]).join('');
  }

  convertToArray(valueText) {
    return valueText.split(",");
  }

  saveClient() {
   
  }

  private getOfficeList() {    
    this._officeService.getAccessOfficeList(localStorage.getItem("OfficeSoid")).subscribe((res: any) => {
      if (res) {
        this.officeList = res.Office;        
      }
    });
  }


  transformDateFormat(dateValue) {
    return this.datePipe.transform(new Date(dateValue), 'MM/dd/yyyy');
  }

  addNewClient() {

  }

  onToppingRemoved(topping: string) {
    const toppings = this.toppingsControl.value as string[];
    this.removeFirst(toppings, topping);
    this.toppingsControl.setValue(toppings); // To trigger change detection
  }

  private removeFirst<T>(array: T[], toRemove: T): void {
    const index = array.indexOf(toRemove);
    if (index !== -1) {
      array.splice(index, 1);
    }
  }

  updateValidators() {
    const ssn = this.clientSubmitForm.controls.ssn;
    const itinNumber = this.clientSubmitForm.controls.itinNumber;
    const dlNumber = this.clientSubmitForm.controls.dlNumber;
    const dlState = this.clientSubmitForm.controls.dlState;
    const dlStartDate = this.clientSubmitForm.controls.dlStartDate;
    const dlExpirationDate = this.clientSubmitForm.controls.dlExpirationDate;

    ssn.setValidators(null);
    itinNumber.setValidators(null);
    dlNumber.setValidators(null);
    dlState.setValidators(null);
    dlStartDate.setValidators(null);
    dlExpirationDate.setValidators(null);
    if (this.idType == "SSN") {      
      ssn.setValidators([Validators.required]);
    } else if (this.idType == "ITIN Number") {      
      itinNumber.setValidators([Validators.required]);
    } else if (this.idType == "Driving License") {      
      dlNumber.setValidators([Validators.required, Validators.pattern('([A-Za-z0-9-]+)')]);
      dlState.setValidators([Validators.required]);
      dlStartDate.setValidators([Validators.required]);
      dlExpirationDate.setValidators([Validators.required]);
    }
  }


  compareDate(dueDate) {
    if (this.currentDate > new Date(dueDate)) {
      return "Over Due";
    } else if (this.currentDate <= new Date(dueDate)) {
      return "Due";
    }
  }

  showFilter(filter) {
    if (filter == "OverDue") {
      this.invoiceList = this.overDuePayment;      
    } else if (filter == "Due") {
      this.invoiceList = this.duePayment; 
    } else if (filter == "All") {
      this.invoiceList = this.allInvoices;
    }
  }

}
