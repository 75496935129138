import {Injectable} from '@angular/core';
import { MasterService } from '../services/master.service';

@Injectable()
export class MasterDataProvider {

    constructor(private _masterService: MasterService) {
    }

    load() {
        return new Promise((resolve, reject) => {
            this._masterService.getMasterList().subscribe((res: any) => {
                if (res) {
                    this._masterService.masterList = res;
                    resolve(true);
                }
              });
            
        })
    }
}