import { HttpClient, HttpParams, HttpHeaders, HttpEvent, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router} from '@angular/router';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
/**
 * Api is a generic REST Api handler. Set your API url first.
 */
@Injectable()
export class Api {
 // url: string = 'http://localhost:18080/api/';
  //url: string = 'http://10.1.8.63:8081/wm-membership';
 // url: string = 'http://18.136.194.236:5000/api/';
 //domain: string = 'http://app.lmstx.net:72/Server/';
// domain: string = 'http://localhost:18080/';

  domain: string = environment.api_url;
 //domain: string = 'http://app.lmstx.net:82/Server/';
 //domain: string = 'http://app.lmstx.net:72/Server/'; //test
  url: string = this.domain + 'api/';

  getProgress:boolean = false;
  postProgress:boolean = false;

  constructor(public http: HttpClient, public router: Router) {
  }

  getApiStatus() {
    if(this.getProgress || this.postProgress) {
      return true;
    } else {
      return false;
    }
  }

  delete(endpoint: string, reqOpts?: any) {
    this.postProgress = true;
    return this.http.delete(this.url + endpoint,  this.getHttpHeaders()).pipe(
      map(res => {

        this.postProgress = false;
        return res;
      }),
      catchError(err => {
        this.postProgress = false;
        this.clearLocalStorage(err);
        return 'f';
      })
    );
  }

  get(endpoint: string, params?: any, reqOpts?: any) {
    this.getProgress = true;
    return this.http.get(this.url + endpoint, this.getHttpHeaders()).pipe(
      map(res => {
        this.getProgress = false;
        return res;
      }),
      catchError(err => {
        this.getProgress = false;
        this.clearLocalStorage(err);
        throw new Error(err.error.ExceptionMessage);
      })
    );
  }

  post(endpoint: string, body: any, reqOpts?: any) {
    this.postProgress = true;
    return this.http.post(this.url + endpoint, body, this.getHttpHeaders()).pipe(
      map((res: any) => {

        this.postProgress = false;
        if (res.error) {
          throw new Error(res.error);
        }
        return res;
      }),
      catchError(err => {
        this.clearLocalStorage(err);
        this.postProgress = false;
        throw new Error(err.error ? err.error.ExceptionMessage ? err.error.ExceptionMessage: err.error.message : err.message);
      })
    );
  }

  patch(endpoint: string, body: any, reqOpts?: any) {
    this.postProgress = true;
    return this.http.patch(this.url + endpoint, body, this.getHttpHeaders()).pipe(
      map(res => {

        this.postProgress = false;
        return res;
      }),
      catchError(err => {
        this.postProgress = false;
        this.clearLocalStorage(err);
        throw new Error(err.error.ExceptionMessage);
      })
    );
  }

  postExternalUrl(endpoint: string, body: any, reqOpts?: any) {
    return this.http.post(endpoint, body, this.getFormUrlEncode()).pipe(
     map(res => {
       this.clearLocalStorage(res);
       return res;
     }));
 }

  postFormdata(endpoint: string, body: any, reqOpts?: any) {
        return this.http.post<any>(this.domain + endpoint, body, this.getFormHttpHeaders()).pipe(
        map(res => {
          this.clearLocalStorage(res);
          return res;
        }));
  }

  ajaxPost(endpoint: string, body: any, reqOpts?: any) {
    this.getProgress = true;
    return this.http.post(this.domain + endpoint,  body, this.getHttpHeaders()).pipe(
      map(res => {

        this.getProgress = false;
        return res;
      }),
      catchError(err => {
        this.getProgress = false;
        this.clearLocalStorage(err);
        return 'failed';
      })
    );
  }

  private clearLocalStorage(res) {

    if (res.status === 401) {
      localStorage.removeItem("UserName");
    localStorage.removeItem("LoginSoid");
    localStorage.removeItem("ScreenName");
    localStorage.removeItem("UserSoid");
    localStorage.removeItem("Role");
    localStorage.removeItem("OfficeSoid");
    localStorage.removeItem("OfficeName");
    localStorage.removeItem("NameFirst");
    localStorage.removeItem("Realm");
    localStorage.removeItem("Modules");
    localStorage.removeItem("Token");
      if (this.router.url != "/" && this.router.url != "/login" && this.router.url != "/tc" && this.router.url.indexOf("/downloadEcard") == -1) {
        this.router.navigateByUrl("/login");
        window.location.reload();
      }
    }
  }

  private getHttpHeaders() {
    let headers = new HttpHeaders();
    if (window.localStorage['Token'] && window.localStorage['Token'] != "") {
      headers = new HttpHeaders().set("Content-Type", "application/json").set("UserName", window.localStorage['UserName'])
      //.set("OfficeSoid", window.localStorage['OfficeSoid'])
      .set("UserSoid", window.localStorage['UserSoid']).set("Token", '{F0290832-DAAF-48B9-9A09-9C64CD824C2E}')
      .set("Authorization", "Bearer " + window.localStorage['Token'])
      .set("OfficeSoid", (window.localStorage['OfficeSoid'] && window.localStorage['OfficeSoid'] != "") ? window.localStorage['OfficeSoid'] : "");
    } else {
      headers = new HttpHeaders().set("Token", '{F0290832-DAAF-48B9-9A09-9C64CD824C2E}')
      .set("UserName", 'Public')
      .set("UserSoid", '');
    }

    //headers.append("Origin", "*");
    var obj = {headers}
    return obj;
  }

  private getFormHttpHeaders() {
    let headers = new HttpHeaders();
    if (window.localStorage['Token'] && window.localStorage['Token'] != "") {
      headers = new HttpHeaders().set("UserName", window.localStorage['UserName'])
      .set("UserSoid", window.localStorage['UserSoid']).set("Token", '{F0290832-DAAF-48B9-9A09-9C64CD824C2E}')
      .set("Authorization", "Bearer " + window.localStorage['Token'])
      .set("OfficeSoid", (window.localStorage['OfficeSoid'] && window.localStorage['OfficeSoid'] != "") ? window.localStorage['OfficeSoid'] : "");
    } else {
      headers = new HttpHeaders().set("Token", '{F0290832-DAAF-48B9-9A09-9C64CD824C2E}')
      .set("UserName", 'Public')
      .set("UserSoid", '');
    }
    if (window.localStorage['OfficeSoid'] && window.localStorage['OfficeSoid'] != "") {
      headers.set("OfficeSoid", window.localStorage['OfficeSoid']);
    }


    //headers.append("Origin", "*");
    var obj = {headers}
    return obj;
  }

  private getFormUrlEncode() {
    let headers = new HttpHeaders();

    headers = new HttpHeaders().set("Content-Type", 'application/x-www-form-urlencoded');
    var obj = {headers}
    return obj;
  }

}
