import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators, FormGroup, FormControlName } from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-reschedule-appoinment',
  templateUrl: './reschedule-appoinment.component.html',
  styleUrls: ['./reschedule-appoinment.component.css']
})
export class RescheduleAppoinmentComponent implements OnInit {
  closeBtnName: string = "Close";
  header: string;
  clientForm: FormGroup;
  formValid: boolean = true;
  selectedDetailType: string;
  meetingLocation: string;
  showAddress: boolean = false;
  clientInfo: any = {
    addressLine1: "1330 OST",
    addressLine2: "",
    birthday: "08/17/1963",
    city: "Houston",
    country: "United States",
    createdBy: 0,
    createdTs: "8/7/19 8:17:26 PM",
    email: "buzzruby2day@gmail.com",
    firstName: "Ruby",
    lastName: "Ramirez",
    loginId: "7",
    phoneNo: "832-487-6138",
    pincode: 1111,
    state: "Texas",
    tag: "",
    type: "Customer"
  }
    
  constructor(private fb: FormBuilder, private toast: ToastrService, public bsModalRef: BsModalRef) { }

  ngOnInit() {
    this.clientForm = this.fb.group({
      appoinmentTitle: new FormControl('', Validators.compose([
        Validators.required
      ])),
      type: new FormControl('', Validators.compose([
        Validators.required
      ])),
      phoneNo: new FormControl('', Validators.compose([
        Validators.required
      ])),
      meetingLocation: new FormControl('', Validators.compose([
        Validators.required
      ]))
    });
  }

  save() {

    if (!this.clientForm.valid){
      for (let inner in this.clientForm.controls) {
        this.clientForm.get(inner).markAsTouched();
        this.clientForm.get(inner).updateValueAndValidity();
      }
      this.formValid = false;
      this.toast.warning("Fill the required fields.", "Warning!");
      return;
    }

  }

  typeChange() {
    if (this.selectedDetailType === "Phone - client calls you") {
      //var staffInfo = this.getStaffInfo(this.staff);
      this.meetingLocation = "+01 100 1234 34";
      this.showAddress = false;
    } else if (this.selectedDetailType === "In Person - at your location") {
      this.meetingLocation  = "+00 123 32 45";
      this.showAddress = false;
    } else if (this.selectedDetailType === "Phone - you call the client") {
      this.showAddress = true;
    } else if (this.selectedDetailType === "In Person- at Client's location") {
      this.showAddress = true;
    }

  }

}
