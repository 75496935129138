import { Component, OnInit, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ServicesListService } from '../../../services/services.service';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject } from 'rxjs';
import { StaffService } from '../../../services/staff.service';
import { InvoiceService } from '../../../services/invoice.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-record-payment',
  templateUrl: './record-payment.component.html',
  styleUrls: ['./record-payment.component.css']
})
export class RecordPaymentComponent implements OnInit {
                                                 

  title: string = "Record Payment";
  selectCallType: string;
  closeBtnName: string = "Close";
  selectedTab: string = "tab1"; //Initial tab active
  paymentTypes: Array<string> = ['USD', 'POUNDS', 'TINAR', 'BHAT'];
  paymentMethods: Array<string> = ['Cash', 'Credit Card', 'Bank Transfer', 'ACH', 'Check', 'Paypal6290', 'Other'];
  recordPaymentDocumentForm: FormGroup;
  feeField: boolean = true;
  serviceName: string;
  uploadType: string = "MY DOCUMENTS";
  phoneType: string;
  phoneValue: string;
  onlineType: string;
  onlineValue: string;
  faceType: string;
  faceValue: string;
  durationHour: string;
  durationMinutes: string;
  servicePrice: string;
  currency: string;
  module: string;
  formValid: boolean = true;
  locationModeObserver: Subject<string> = new Subject<string>();
  priceChangeObserver: Subject<string> = new Subject<string>();
  subscriptionServices: any = [];
  public event: EventEmitter<any> = new EventEmitter();
  public clientInfo: any = [];
  staffList: any = [];
  myDocumentsList: any = [];
  @ViewChild('labelImport')
  labelImport: ElementRef;
  agelimit: number = 10;
  receiptNumber: string= "";

  formImport: FormGroup;
  fileToUpload: File = null;
  invoiceInfo: any = {};
  accounts: any = null;
  xeroAccount: any = null;



  onFileChange(files: FileList) {
    this.labelImport.nativeElement.innerText = Array.from(files)
      .map(f => f.name)
      .join(', ');
    this.fileToUpload = files.item(0);
  }


  constructor(private toast: ToastrService, private _service: ServicesListService,
    private _invoiceService: InvoiceService,
    public bsModalRef: BsModalRef, sb: FormBuilder,private _router: Router,
    private _staffService: StaffService,) {
  }



  ngOnInit() {

    this.getReceiptNumber();

    this.recordPaymentDocumentForm = new FormGroup({
      //serviceText: new FormControl('', [Validators.required]),
      amount: new FormControl('',[Validators.required]),
      paymentMethod: new FormControl('',[Validators.required]),
      accounts: new FormControl('',[Validators.required]),
      paymentDate: new FormControl('',[Validators.required]),
      sendReceipt: new FormControl(),
      issueAnInvoice: new FormControl(),
      receiptNumber: new FormControl('',[Validators.required]),
    });
    this.accounts = this.groupByReportingCodeName(JSON.parse(localStorage.getItem("Accounts")));
  }



  groupByReportingCodeName(data: any[]) {
    return data.reduce((result, current) => {
      (result[current.ReportingCodeName] = result[current.ReportingCodeName] || []).push(current);
      return result;
    }, {});
  }

  recordPayment() {
    for (let inner in this.recordPaymentDocumentForm.controls) {
      this.recordPaymentDocumentForm.get(inner).markAsTouched();
      this.recordPaymentDocumentForm.get(inner).updateValueAndValidity();
    }
    console.log("accounts", this.recordPaymentDocumentForm);
    if (!this.recordPaymentDocumentForm.valid) {
      this.formValid = false;
      this.toast.warning("Fill the required fields.", "Warning!");
      return;
    }

    var balance = this.invoiceInfo.Balance;
    var pay = this.recordPaymentDocumentForm.controls.amount.value;
    if (pay > balance) {
      this.toast.warning("Amount should be less than invoice balance " +  balance, "Warning!");
      return;
    }
    var testData : any = {};

    let userFullName = localStorage.getItem("NameFirst") + " " + localStorage.getItem("NameLast");
    testData.StaffSoid = localStorage.getItem("UserSoid");
    testData.StaffName =  userFullName;
    testData.ReceiptNumber = this.recordPaymentDocumentForm.controls.receiptNumber.value;
    testData.PaidOn = this.recordPaymentDocumentForm.controls.paymentDate.value;
    testData.Amount = this.recordPaymentDocumentForm.controls.amount.value;
    testData.PaymentType = this.recordPaymentDocumentForm.controls.paymentMethod.value;
    testData.InvoiceNumber = this.invoiceInfo.InvoiceNumber;
    testData.InvoiceSoid = this.invoiceInfo.Soid;
    testData.AccountId = this.xeroAccount.AccountId;
    testData.AccountName = this.xeroAccount.Name;
    //testData.PaymentInfo = this.recordPaymentDocumentForm.controls.serviceText.value;

    
    
    
    this._invoiceService.recordPayment(this.clientInfo.Soid, testData).subscribe((res: any) => {
      if (res) {

        if (res.XeroStatus == "PENDING") {
          this.toast.info("Payment saved, not updated in Xero.", "Info!");
        }  else if (res.XeroStatus == "SUBMITTED") {
          this.toast.success("Payment recorded in Xero successfully.", "Success!");
        }
      
        //this.triggerEvent();
        var invoiceInfo: any = {};
        invoiceInfo.clientId =  this.clientInfo.Soid;
        invoiceInfo.invoiceId = this.invoiceInfo.Soid;
        invoiceInfo.receiptId = res.receiptId;
        invoiceInfo.paymentInfo = res;
        this.event.emit(invoiceInfo);
        this.bsModalRef.hide();
         // var transactionLen = res.Payments.length;
         // var transactions = res.Payments[transactionLen - 1];
    //    this._router.navigate(['client/' + this.clientInfo.Soid + '/invoice/' + this.invoiceInfo.Soid + '/receipt/' + res.receiptId]);
      } else {
        this.toast.warning("Error Creating service", "Error!");
      }
    });

  }

  triggerEvent() {
    this.event.emit();
  }


  private getReceiptNumber() {
    this._invoiceService.getReceiptNumber().subscribe((res: any) => {
      if (res) {
        this.receiptNumber = res;
      } 
    });
  }

 
}
