<div class="section-container">
    <div class="subheader">
        <h2>Appointment</h2>
        <!-- <h5>Plans and events</h5> -->
    </div>
    <div class="white-bg rounded border-0">
        <div class="row top-menu bg-light bg-grey no-gutters">
            <div class="col-md-6">
              <div class="top-menu-container">
                <a class="btn-link btn-icon-back" (click)="goBack()"><i class="fa fa-chevron-left"></i></a>
               </div>
              </div>
              <div class="col-md-6">
                <div class="top-menu-container d-flex flex-row-reverse">
                  <!--<a class="btn btn-primary" *ngIf="eventStatus == 'Scheduled'">Edit</a>-->
                  <a class="btn btn-primary" (click)="cancelAppoinment('Completed')"  *ngIf="eventStatus == 'Scheduled'">Completed</a>
                  <button class="btn" [matMenuTriggerFor]="menu" >
                    <i class="fas fa-ellipsis-v"></i>
                  </button>
                  <mat-menu #menu>
                    <button mat-menu-item (click)="cancelAppoinment('Cancel')" *ngIf="eventStatus == 'Scheduled'">
                      <mat-icon>cancel</mat-icon>
                      <span>Cancel</span>
                    </button>
                    <button mat-menu-item (click)="resceduleAppoinment()" *ngIf="eventStatus == 'Scheduled'">
                      <mat-icon>event</mat-icon>
                      <span>Reschedule</span>
                    </button>
                    <button mat-menu-item (click)="showConfirmationPopup(template)">
                      <mat-icon>email</mat-icon>
                      <span>Send Email</span>
                    </button>
                  </mat-menu>
                </div>
              </div>
             <!--<div class="col-md-6 text-right">
                <div class="top-menu-container">
                  <a class="btn btn-secondary">View on calender</a>
                  <a class="btn btn-secondary">View conversation</a>
                  <a class="btn btn-secondary" (click)="newTimeForAppoinment()">New Time</a>
                  <a class="btn btn-secondary" (click)="resceduleAppoinmentInfo()">Info</a>
                </div>
              </div>-->
        </div>
        <div class="padding-10">
          <!-- <div class="container"> -->
          <div class="row">
            <div class="col-md-9">
              <div class="row">
                  <div class="date-container">
                    <div class="date" *ngIf="eventInfo.Time">
                      <span class="binds"></span>
                      <span class="month">{{eventInfo.Time.Start | date:"EE" | uppercase}}</span>
                      <h1 class="day">{{eventInfo.Time.Start | date:"dd"}}
                        <div class="month-text">{{eventInfo.Time.Start | date:"MMM"}}</div>
                      </h1>

                    </div>
                  </div>
                  <div class="col-md-9" *ngIf="eventInfo.Time">
                      <h5>{{eventInfo.Subject | titlecase}}</h5>
                      <h4>{{eventInfo.Time.Start | date:"EE, MMMM d, h:mma"}} - {{eventInfo.Time.End | date:"EE, MMMM d, h:mma"}}</h4>
                      <ul class="list-group list-group-flush list-no-borders">
                        <li class="list-group-item"><i class="fa fa-clock  mr-2"></i> {{eventStatus}} {{eventStatus == 'Cancelled' ? ' Message: ' + eventInfo.message : ''}}</li>
                        <li *ngIf="eventInfo.Participants" class="list-group-item"><i class="fa fa-user-tie  mr-2"></i> with {{eventInfo.Participants | ParticipantName : 'Customer' | titlecase}}</li>
                        <li class="list-group-item"><i class="fa fa-mobile-alt  mr-2"></i> {{clientInfo.PersonalMobile}}</li>
                      </ul>
                  </div>
                </div>
            </div>
            <div class="col-md-3">
              <div class="row">
                <div class="col-md-6 offset-md-3">
                    <div class="profile-img" (click)="goToClientProfile()">
                        <img *ngIf="!!imgSource" class="img-fluid" src="{{imgSource}}" alt=""/>
                    </div>
                    <div class="profile-head text-center">
                        <h5 contenteditable="true" *ngIf="eventInfo.Participants">
                          {{eventInfo.Participants | ParticipantName : 'Customer' | titlecase}}
                        </h5>
                        <h6>
                            {{appoinmentClient.Status}}
                        </h6>
                    </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="card">
                <div class="card-header">
                    More Info.
                  </div>
                <div class="card-body card-info">
                    <label>Staff</label>
                    <!-- <button>{{eventInfo.staffFName}} {{eventInfo.staffLName}} <button *ngIf="eventStatus == 'Scheduled'" (click)="assignStaff()" class="btn btn-link">Assign</button></p> -->
                    <p *ngIf="eventInfo.Participants">{{eventInfo.Participants | ParticipantName : 'Staff' | titlecase}} <button *ngIf="eventStatus === 'Scheduled'" (click)="assignStaff()" class="btn btn-link">Assign</button></p>
                    <label>Meeting Type</label>
                    <p>{{eventInfo.MeetingType}}</p>
                    <label>Meeting Info</label>
                    <p>{{eventInfo.MeetingLocation}}</p>
                </div>
              </div>
                <br>
                <!-- <div class="card">
                  <div class="card-header">
                      Client notification &amp; follow ups
                    </div>
                    <div class="card-body card-info">
                        <label>Booking confirmation</label>
                        <p></p>
                        <label>First remainder</label>
                        <p></p>
                    </div>
                </div> -->
              </div>
              <div class="col-md-6">
                  <div class="card">
                      <div class="card-header">
                          Fee Info
                        </div>
                        <div class="card-body card-info">
                          <label>Fee Type</label>
                            <p>{{eventInfo.ServiceFeeType}}</p>
                            <label>Fee</label>
                            <p>{{eventInfo.ServicePrice == null || eventInfo.ServicePrice == 0  ? 'Free of Cost' : '$' +  eventInfo.ServicePrice}}</p>
                        </div>
                        <div class="card-footer">
                          <button class="btn btn-link">Record Payment</button>
                          <button class="btn btn-link">Create Invoice</button>
                          <button class="btn btn-link">...</button>
                        </div>
                    </div>
                    <br/>
                    <div class="card">
                        <!-- <div class="card-header">
                            Internal note
                          </div>
                          <div class="card-body card-info">

                          </div> -->
                          <div class="card-footer">
                            <button class="btn btn-link">Add note</button>
                          </div>
                      </div>
              </div>

          </div>

          <!-- </div> -->
      </div>
    </div>
</div>

<ng-template #template>
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title">Send appointment by email</h5>
      <button type="button" class="close" aria-label="Close" (click)="sendEmailConfirmationPopup.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      Do you want to send <b>{{ eventInfo.Subject }}</b> appointment by email?
    </div>
    <div class="modal-footer">
      <button class="btn btn-danger" (click)="sendEmailConfirmationPopup.hide()">Cancel</button>
      <button class="btn btn-info" (click)="sendEmail()" >Send</button>
    </div>
  </div>
</ng-template>
