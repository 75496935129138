/// <reference path="../../../../../node_modules/@types/googlemaps/index.d.ts"/>
import { Component, OnInit, ViewChild, Input,EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormBuilder, FormControl, FormGroup, Validators, FormControlName } from '@angular/forms';
import { StaffService } from '../../../services/staff.service';
import { ServicesListService } from '../../../services/services.service';
import { EventsService } from '../../../services/events.service';
import {ToastrService} from 'ngx-toastr';
import { DatePipe } from '@angular/common';



@Component({
  selector: 'app-assignstaff',
  templateUrl: './assignstaff.component.html',
  styleUrls: ['./assignstaff.component.css']
})
export class AssignStaffComponent implements OnInit {
  public titles;
  public header : string;
  public services: any =  [];

  eventInfo: any = {};
  public Id: number;

  
  public event: EventEmitter<any> = new EventEmitter();

  eventForm: FormGroup;
  isRepeatChecked : boolean = false;
  closeBtnName: string = "Close Planner"
  meetingLocation: string;
  //services: string[] = ["2018 Tax return", "In-office appointment", "30 minutes phone call", "Introductory Tax Preparation Phone Call"]
  isEditable: boolean = false;
  selectedDetailType: string;
  meetingAddress:string;
  staffList: any = [];
  serviceList: any = [];
  clientInfo: any = {};
  serviceRequest: string;
  startEventDate: string;
  endEventDate: string;
  startDateTime: string = "";
  endDateTime: string;
  staff: string;
  description: string;
  showAddress: boolean = false;
  serviceFeeType: string;
  servicePrice: string;
  formValid:boolean = true;
  occurenceType: string = "Daily";
  //seasons: string[] = ['Days', 'Weeks', 'Monthly'];
  seasons: string[] = ['Daily', 'Weekly'];
  hours: string[];
  places: string[] = ['First', 'Second', 'Third', 'Fourth'];
  weekdays: string[] = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  startTimeList: any = [];
  endTimeList: any = [];
  dailyInterval:number;
  isWeekDays:number;
  recurWeek:number;
  occurenceCount:number;
  cServiceName:string;
  staffIds:string;
  eventStatus:string;
  participantInfo: any = {};

  constructor(private _serviceList: ServicesListService, private datePipe: DatePipe, private toast: ToastrService,
    private _eventsService: EventsService,
     private _staffService: StaffService,
      public bsModalRef: BsModalRef,
       private fb: FormBuilder ) { }

  ngOnInit() {
   // this.staff = "50";

    this.eventForm = new FormGroup({
      staff: new FormControl('', [Validators.required]),
    });
    this.getStaffList();
  }

  showOptions(event, value) {
  }

  editDetails() {
    this.isEditable = false;
  }

  saveDetails() {
    this.isEditable = true;
  }

  discardChanges() {
    this.eventForm.reset();
    this.isEditable = true;
  }

  getAddress(event) {
    this.meetingAddress =  event.name;
  }

  private getStaffList() {
    this._staffService.getActiveStaffs().subscribe((res: any) => {
      if (res) {
        this.staffList = res;
        this.participantInfo = this.getStaffInfo(this.eventInfo.ParticipantSoid);
      } else {
        this.staffList = [];
      }
    });
  }

  


  createEvent() {
    if (!this.eventForm.valid){
      for (let inner in this.eventForm.controls) {
        this.eventForm.get(inner).markAsTouched();
        this.eventForm.get(inner).updateValueAndValidity();
      }
      this.formValid = false;
      this.toast.warning("Fill the required fields.", "Warning!");
      //return;
    }

   

    var eventData:any = {};
    var staffInfo = this.getStaffInfo(this.staff);
    eventData.NewParticipantSoid = staffInfo[0].PersonSoid;
    eventData.NewParticipantName = staffInfo[0].NameFirst + ' ' + staffInfo[0].NameLast;
    eventData.NewParticipantType = "Staff";
    eventData.eventId = this.eventInfo.eventId;
    eventData.UserName = localStorage.getItem("UserName");
    eventData.UserSoid =  localStorage.getItem("UserSoid");

    this._eventsService.assignStaff(this.Id, this.eventInfo.Soid, eventData).subscribe((res: any) => {
      if (res) {
        this.bsModalRef.hide();
        
        this.triggerEvent();
        this.toast.success("Schedule is successfully assigned.", "Success!");
      } else {
        this.toast.success("Error Assigning, Please try again later.", "Success!");
      }
    });

  }

  triggerEvent() {
    this.event.emit();
  }

  getStaffInfo(loginId: String) {
    return this.staffList.filter(proj => proj.PersonSoid === loginId);
  }

  typeChange() {
    var staffInfo = this.getStaffInfo(this.staff);
  }

  endEventDateChange() {
    this.endEventDate =  this.startEventDate;
  }



}

