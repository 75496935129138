import { Component, OnInit, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup, FormControl,  Validators} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { DocumentsService } from '../../../services/documents.service';

@Component({
  selector: 'app-add-my-documents',
  templateUrl: './add-my-documents.component.html',
  styleUrls: ['./add-my-documents.component.css']
})
export class AddMyDocumentsComponent implements OnInit {

  title: string = "New service - 1 on 1 appoinment";
  selectCallType: string;
  closeBtnName: string = "Close";
  selectedTab: string = "tab1"; //Initial tab active
  paymentTypes: Array<string> = ['USD', 'POUNDS', 'TINAR', 'BHAT'];
  addMyDocumentForm: FormGroup;
  feeField: boolean = true;
  serviceName: string;
  locationMode: string = "Face To Face";
  phoneType: string;
  phoneValue: string;
  onlineType: string;
  onlineValue: string;
  faceType: string;
  faceValue: string;
  durationHour: string;
  durationMinutes: string;
  servicePrice: string;
  currency: string;
  module: string;
  formValid: boolean = true;
  locationModeObserver: Subject<string> = new Subject<string>();
  priceChangeObserver: Subject<string> = new Subject<string>();
  subscriptionServices: any = [];
  public event: EventEmitter<any> = new EventEmitter();
  public header: string;
  resetVar: false;

  @ViewChild('labelImport')
  labelImport: ElementRef;

  formImport: FormGroup;
  fileToUpload: File = null;



  onFileChange(files: FileList) {
    this.labelImport.nativeElement.innerText = Array.from(files)
      .map(f => f.name)
      .join(', ');
    this.fileToUpload = files.item(0);
  }

  constructor(private toast: ToastrService, private _documentService: DocumentsService,
    public bsModalRef: BsModalRef, sb: FormBuilder) {
  }

  ngOnInit() {
    this.addMyDocumentForm = new FormGroup({

      documentTitle: new FormControl('',[Validators.required]),
      documentDescription: new FormControl(),
      importFile: new FormControl(),
    });
  }

  private readBase64(file): Promise<any> {
    const reader = new FileReader();
    const future = new Promise((resolve, reject) => {
      reader.addEventListener('load', function () {
        resolve(reader.result);
      }, false);
      reader.addEventListener('error', function (event) {
        reject(event);
      }, false);
    
      reader.readAsDataURL(file);
    });
    return future;
    }

  createNewDocument() {

    for (let inner in this.addMyDocumentForm.controls) {
      this.addMyDocumentForm.get(inner).markAsTouched();
      this.addMyDocumentForm.get(inner).updateValueAndValidity();
    }
    if (!this.addMyDocumentForm.valid) {
      this.formValid = false;
      this.toast.warning("Fill the required fields.", "Warning!");
      return;
    }

    this.readBase64(this.fileToUpload)
    .then((file) => {
      let userFullName = localStorage.getItem("NameFirst") + " " + localStorage.getItem("NameLast");
    const testData = new FormData();
   // testData.append('File', this.fileToUpload);
    testData.append('FileContent', file);

      testData.append('FileName', this.fileToUpload.name);
      testData.append('Extension', this.fileToUpload.type);      
     // console.log(this.fileToUpload);
      testData.append('Size', this.formatBytes(this.fileToUpload.size));

    testData.append("DocumentTitle", this.addMyDocumentForm.controls.documentTitle.value);
    testData.append("DocumentDescription", this.addMyDocumentForm.controls.documentDescription.value
     ? this.addMyDocumentForm.controls.documentDescription.value : '');
    testData.append("UserName", userFullName);
    testData.append("UserSoid", localStorage.getItem("UserSoid"));
    testData.append("OwnerSoid", localStorage.getItem("UserSoid"));
    testData.append("OwnerName", userFullName);
    testData.append("DocumentType", "MY DOCUMENTS");
    testData.append("OfficeSoid", localStorage.getItem("OfficeSoid"));
    testData.append("PersonalFolder", localStorage.getItem("UserSoid"));
    testData.append("OwnerType", "Staff");

    this._documentService.createNewDocument(testData).subscribe((res: any) => {
      if (res) {
        this.toast.success("Document created successfully.", "Success!");
        this.triggerEvent();
        this.bsModalRef.hide();
      } else {
        this.toast.warning("Error Creating service", "Error!");
      }
    });
    });
  }

  formatBytes(bytes) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const decimals = 2;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

  triggerEvent() {
    this.event.emit();
  }
}
