import {Component, OnInit, Output, EventEmitter, Input, TemplateRef} from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { NewTimeForAppoinmentComponent } from '../../modal/new-time-for-appoinment/new-time-for-appoinment.component';
import { ResheduleAppoinmentInfoComponent } from '../../modal/reshedule-appoinment-info/reshedule-appoinment-info.component';
import { CancelAppoinmentComponent } from '../../modal/cancel-appoinment/cancel-appoinment.component';
import { DatePipe, Location } from '@angular/common';
import { ClientService } from '../../../services/client.service';
import { InvoiceService } from '../../../services/invoice.service';
import { RecordPaymentComponent } from '../../modal/record-payment/record-payment.component';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { ListPaymentComponent } from '../../modal/list-payments/list-payments.component';
import { ToastrService } from 'ngx-toastr';
import { ViewRecieptComponent } from '../view-reciept/view-reciept.component';
import {OfficeService} from '../../../services/office.service';
import {Observable} from 'rxjs';
import { ConfirmationDialog } from '../../modal/confirmation-dialog/confirmation-dialog.component';
import { ServiceInvoiceComponent } from '../../modal/service-invoice/service-invoice.component';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

class Product {
  name: string;
  price: number;
  qty: number;
}
class Invoice {
  customerName: string;
  address: string;
  contactNo: number;
  email: string;

  products: Product[] = [];
  additionalDetails: string;

  constructor() {
    // Initially one empty product row we will show
    this.products.push(new Product());

  }
}

@Component({
  selector: 'app-view-invoice',
  templateUrl: './view-invoice.component.html',
  styleUrls: ['./view-invoice.component.css']
})
export class ViewInvoiceComponent implements OnInit {
  invoice = new Invoice();

  eventInfo: any = {};
  header: string;
  clientList: any = {};
  myForm: FormGroup;
  contactForm: FormGroup;
  tagForm: FormGroup;
  statusForm: FormGroup;
  infoForm: FormGroup;
  clientForm: FormGroup;
  disabled = false;
  ShowFilter = false;
  limitSelection = false;
  cities: any = [];
  selectedItems: any = [];
  dropdownSettings: any = {};
  modalRef: BsModalRef;
  invoiceInfo: any = {};
  invoiceId: number;
  clientId: number;
  eventStatus: string;
  selected: string;
  actualValue: string;
  birthDate: Date;
  appoinmentClient: any = {};
  agelimit: 18;
  lmsStartDate: Date;
  expirationDate: Date;
  invoiceStatus: string;
  @Input() showView: string;
  amountPaid: string;
  xeroURL: string = "https://go.xero.com/AccountsReceivable/Edit.aspx?InvoiceID=";
  invoiceURL: string;
  receiptURL: string = "https://go.xero.com/Bank/ViewTransaction.aspx?bankTransactionID=";
  subscriptionServices: any = [];
  sendInvoiceConfirmationPopupRef: BsModalRef;

  staffInfo: any = {};
  clientInfo: any = {};
  balanceInfo: string;
  officeInfo: any = {};

  countries: string[] = [
    'Canada', 'Mexico', 'United States'
  ];

  constructor(private modalService: BsModalService, private _router: Router, private datePipe: DatePipe,
    private _clientService: ClientService,private route: ActivatedRoute,
    private activatedRoute: ActivatedRoute, private location: Location,
    private _invoiceService: InvoiceService, private toast: ToastrService,
    private _officeService: OfficeService,
    private fb: FormBuilder) { }

  ngOnInit() {
 
    this.route.paramMap.subscribe((params: ParamMap) => {
    this.invoiceId = this.activatedRoute.snapshot.params.invoiceId;
    this.clientId = this.activatedRoute.snapshot.params.loginId;
   this.getInvoice();
    this.getClientInfo();
      
      // You can call a method or perform any action here based on the parameter value
    });
    this.invoiceId = this.activatedRoute.snapshot.params.invoiceId;
    this.clientId = this.activatedRoute.snapshot.params.loginId;
    this.getInvoice();
    this.getClientInfo();


    //debugger;
    this.selectedItems = [{ item_id: 2, item_text: 'Staff' }, { item_id: 6, item_text: 'Documents' }];
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 5,
      allowSearchFilter: this.ShowFilter
    };
    this.myForm = this.fb.group({
      city: [this.selectedItems]
    });

    this.tagForm = this.fb.group({
      tag: new FormControl(this.selectedItems, Validators.compose([
        Validators.required
      ]))
    });
    this.statusForm = this.fb.group({
      status: new FormControl('', Validators.compose([
        Validators.required
      ]))
    });

    this.contactForm = this.fb.group({
      addressLine1: new FormControl('', Validators.compose([
        Validators.required
      ])),
      addressLine2: new FormControl('', Validators.compose([
      ])), state: new FormControl('', Validators.compose([
        Validators.required
      ]))
      , city: new FormControl('', Validators.compose([
        Validators.required
      ])), country: new FormControl('', Validators.compose([
        Validators.required
      ])), phoneNo: new FormControl('', Validators.compose([
        Validators.required
      ])), pincode: new FormControl('', Validators.compose([
        Validators.required
      ])), homeAddress: new FormControl('', Validators.compose([
        Validators.required
      ])), personalPhoneCountryCode: new FormControl('', Validators.compose([
        Validators.required
      ])), personalMobile: new FormControl('', Validators.compose([
        Validators.required
      ])), businessAddress: new FormControl('', Validators.compose([
        Validators.required
      ])), businessCountryCode: new FormControl('', Validators.compose([
        Validators.required
      ])), businessMobileNo: new FormControl('', Validators.compose([
        Validators.required
      ])), businessEmail: new FormControl('', Validators.compose([
        Validators.required
      ])), zipCode: new FormControl('', Validators.compose([
        Validators.required
      ]))

    });

    this.infoForm = this.fb.group({
      ssn: new FormControl('', Validators.compose([
        Validators.required
      ])),
      dlNumber: new FormControl('', Validators.compose([
        Validators.required
      ])),
      dlState: new FormControl('', Validators.compose([
        Validators.required
      ])),
      dlExpirationDate: new FormControl('', Validators.compose([
        Validators.required
      ])),
      dlStartDate: new FormControl('', Validators.compose([
        Validators.required
      ])),
      ITINNumber: new FormControl('', Validators.compose([
        Validators.required
      ])),
    });
    this.clientForm = this.fb.group({
      email: new FormControl('', Validators.compose([
        Validators.required
      ])),
      firstName: new FormControl('', Validators.compose([
        Validators.required
      ])),
      lastName: new FormControl('', Validators.compose([
        Validators.required
      ])),
      birthday: new FormControl('', Validators.compose([
        Validators.required
      ])),
      companyName: new FormControl('', Validators.compose([
        Validators.required
      ])),
      websiteURL: new FormControl('', Validators.compose([
        Validators.required
      ])),
      lmsStartDate: new FormControl('', Validators.compose([
        Validators.required
      ])),
      languagePreference: new FormControl('', Validators.compose([
        Validators.required
      ])),
      hearAboutUs: new FormControl('', Validators.compose([
        Validators.required
      ])),
      couple: new FormControl('', Validators.compose([
        Validators.required
      ])),
      CompanyName: new FormControl('', Validators.compose([
        Validators.required
      ])),
      EINNumber: new FormControl('', Validators.compose([
        Validators.required
      ]))

    });
  }

  private getInvoice() {
    if (!this.clientId) {
      return;
    }
    this._invoiceService.getInvoice(this.clientId, this.invoiceId).subscribe((res: any) => {
      if (res) {
        this.invoiceInfo = res;
        this.parseInvoiceInfo();
        this.getOfficeInfo(this.invoiceInfo.OfficeSoid);
      }
    });
  }


  

  public deleteInvoice() {
    const initialState = {
      confirmMessage: "Are you sure to delete the invoice?",
      backdrop: true,
      ignoreBackdropClick: true
    };
    this.modalRef = this.modalService.show(ConfirmationDialog, { initialState });
    this.modalRef.content.event.subscribe(data => {
      if (data) {
        this._invoiceService.deleteInvoice(this.invoiceId).subscribe((res: any) => {
          if (res) {
            this.invoiceInfo = res;
            this.parseInvoiceInfo();
          }
        });
      }
    });
  }

  public voidInvoice() {
    const initialState = {
      confirmMessage: "Are you sure to void the invoice?",
      backdrop: true,
      ignoreBackdropClick: true
    };
    this.modalRef = this.modalService.show(ConfirmationDialog, { initialState });
    this.modalRef.content.event.subscribe(data => {
      if (data) {
        this._invoiceService.voidInvoice(this.invoiceId).subscribe((res: any) => {
          if (res) {
            this.invoiceInfo = res;
            this.parseInvoiceInfo();
          }
        });
      }
    });
  }


  repeatInvoiceInfo() {
    // Don't change this header title, used in conditin check.
    const initialState = {
      header: "Repeat Invoice",
      backdrop: true,
      ignoreBackdropClick: true,
      clientInfo: { ...this.clientInfo }, // Creating a shallow copy using spread operator
      sInvoiceInfo: { ...this.invoiceInfo },
    };
    
    this.modalRef = this.modalService.show(ServiceInvoiceComponent, { initialState });
    this.modalRef.setClass('invoice-dialog');
    this.modalRef.content.event.subscribe(data => {
      
  //    this._router.navigate(['/client/' + this.clientInfo.Soid + '/invoice/' + res.Soid]);
    });
  }

  editInvoiceInfo() {
     // Don't change this header title, used in conditin check.
    const initialState = {
      header: "Edit Invoice",
      backdrop: true,
      ignoreBackdropClick: true,
      clientInfo: { ...this.clientInfo }, // Creating a shallow copy using spread operator
      sInvoiceInfo: { ...this.invoiceInfo },
    };
   
    this.modalRef = this.modalService.show(ServiceInvoiceComponent, { initialState });
    this.modalRef.setClass('invoice-dialog');
    this.modalRef.content.event.subscribe(data => {
      this.getInvoice();
  //    this._router.navigate(['/client/' + this.clientInfo.Soid + '/invoice/' + res.Soid]);
    });
  }

  private getOfficeInfo(soId: string): void {
    this._officeService.getOfficeInfo(soId)
      .subscribe((res: any) => {
        this.officeInfo = res.Office;
      });
  }

  private parseInvoiceInfo() {
    var currentDate = new Date();

    if (parseFloat(this.invoiceInfo.Balance) <= 0) {
      this.invoiceStatus = "Paid";
    } else if (currentDate > new Date(this.invoiceInfo.DueOn)) {
      this.invoiceStatus = "Over Due";
    } else if (currentDate <= new Date(this.invoiceInfo.DueOn)) {
      this.invoiceStatus = "Due";
    }
    if (this.invoiceInfo.Balance == this.invoiceInfo.Total) {
      this.balanceInfo = '$' + this.invoiceInfo.Balance + 'USD';
    } else if (this.invoiceStatus != "Paid") {
      this.balanceInfo = '$' + this.invoiceInfo.Balance + '(out of $' + this.invoiceInfo.Total + ') USD';
    }
    this.amountPaid = (this.invoiceInfo.Total - this.invoiceInfo.Balance).toFixed(2);
    this.invoiceURL = this.xeroURL + this.invoiceInfo.XeroReference;

   this.invoiceStatus = this.invoiceInfo.Status;
  }

  inActiveDocument() {


  }

  downloadFile() {

  }

  recordPayment() {
    const initialState = {
      header: "Record Payment",
      backdrop: true,
      ignoreBackdropClick: true,
      clientInfo: this.clientInfo,
      invoiceInfo: this.invoiceInfo
    };
    this.modalRef = this.modalService.show(RecordPaymentComponent, { initialState });
    this.modalRef.content.event.subscribe(data => {
     
      if (this.showView == undefined || this.showView == "INVOICE") {
        this.loadReceipt(data.clientId, data.invoiceId, data.paymentInfo.Soid, data.paymentInfo);
        this.getInvoice();

      }

    });
  }

  viewPayments() {
    if (this.invoiceInfo.Payments && this.invoiceInfo.Payments.length > 0) {
      const initialState = {
        header: "View Payments",
        backdrop: true,
        ignoreBackdropClick: true,
        paymentList: this.invoiceInfo.Payments,
        invoiceAmount: this.invoiceInfo.Total
      };
      this.modalRef = this.modalService.show(ListPaymentComponent, { initialState });
      this.modalRef.content.event.subscribe(data => {
        // this.triggerEvent();
      });
    } else {
      this.toast.warning("No Payments.", "Info!");
    }
  }

  convertBase64ToBlob(base64Image: string, mimType) {


    // Decode Base64 string
    const decodedData = window.atob(base64Image);

    // Create UNIT8ARRAY of size same as row data length
    const uInt8Array = new Uint8Array(decodedData.length);

    // Insert all character code into uInt8Array
    for (let i = 0; i < decodedData.length; ++i) {
      uInt8Array[i] = decodedData.charCodeAt(i);
    }

    // Return BLOB image after conversion
    return new Blob([uInt8Array], { type: mimType });
  }

  viewFile() {

  }

  loadView(invoiceId, clientId) {
    this.invoiceId = invoiceId;
    this.clientId = clientId;
    this.getInvoice();
    this.getClientInfo();
  }

  loadReceipt(clientId, invoiceSoid, paymentSoid, paymentInfo) {
    const initialState = {
      header: "Receipt View",
      closeBtnName: "Save",
      backdrop: true,
      ignoreBackdropClick: true,
      showView: "POPUP",
      paymentInfo: paymentInfo,
      receiptInfo: {
        clientId: clientId,
        invoiceId: invoiceSoid,
        paymentId: paymentSoid
      }
    };
    this.modalRef = this.modalService.show(ViewRecieptComponent, { initialState, class: 'modal-lg' });
  }

  //New Time For Appoinment
  newTimeForAppoinment() {
    const initialState = {
      header: "Reschedule Appoinment",
      closeBtnName: "Save",
      backdrop: true,
      ignoreBackdropClick: true
    };
    this.modalRef = this.modalService.show(ResheduleAppoinmentInfoComponent);
  }

  //New Time For Appoinment Info
  resceduleAppoinmentInfo() {
    const initialState = {
      header: "Reschedule Appoinment",
      //  eventId: this.eventId,
      closeBtnName: "Cancel",
      backdrop: true,
      ignoreBackdropClick: true
    };
    this.modalRef = this.modalService.show(ResheduleAppoinmentInfoComponent, { initialState });
  }
  //Cancel Appoinment
  viewInvoice() {
    window.open("client/" + this.clientId + "/invoice/" + this.invoiceId + "/view", '_blank', 'toolbar=0, width=800, height=400');
  }

  getXeroStatus() {
    this._invoiceService.GetXeroStatus(this.invoiceId).subscribe((res: any) => {


      if (res && res.XeroStatus == 'AUTHORISED') {
        this.invoiceInfo = res;
        this.parseInvoiceInfo();
        this.toast.success("Invoice approved successfully, Please make payment.", "Info!");
      } else if (res && res.XeroStatus == 'DRAFT') {
        this.toast.info("Approve invoice in Xero", "Info!");
      } else if (res && res.XeroStatus == 'Pending') {
        this.toast.info("Submit the invoice to Xero", "Info!");
      } else {
        this.toast.error("Error processing request", "Info!");
      }


    });

  }

  submitToXero() {
    this._invoiceService.submitToXero(this.invoiceId).subscribe((res: any) => {


      if (res && res.XeroStatus == 'DRAFT') {
        this.invoiceInfo = res;
        this.parseInvoiceInfo();
        this.toast.success("Invoice submitted to Xero, Please approve.", "Info!");
      } else {
        this.toast.error("Error submitting Invoice", "Error!");
      }


    });

  }

  onItemSelect(test) {

  }

  onInputFocusOut(event) {
    if (event.srcElement.value.trim() != "" && this.actualValue != event.srcElement.value.trim()) {
      this.invokeClientSave(event.srcElement.id, event.srcElement.value);
    }

  }

  private invokeClientSave(selector, value) {
    var requestInfo: any = {};
    requestInfo.FieldName = selector;
    requestInfo.Data = value
    requestInfo.UserName = localStorage.getItem("UserName");
    requestInfo.UserSoid = localStorage.getItem("UserSoid");
    /**  this._clientService.updateClientInfo(this.clientInfo.Soid, requestInfo).subscribe((res: any) => {
       if (res && res.Changed === true) {
         if (res.response == "EMAIL_EXISTS") {
           this.toast.warning("Email Id already exists.", "Warning!");
         } else {
           this.toast.success("Updated Successfully.", "Success!");
         }
       } else {
         this.toast.error("Error Updating Details", "Success!");
       }
     });*/
  }


  private getClientInfo() {
    if (!this.clientId) {
      return;
    }
    this._clientService.getClientInfo(this.clientId).subscribe((res: any) => {
      if (res) {
        this.clientInfo = res;
      }
    });

  }


  onSelectFocus(event) {
    this.actualValue = event.srcElement.value;
  }

  onSelectFocusOut(event) {
    if (event.source._value.trim() != "" && this.actualValue != event.source._value.trim().trim()) {
      this.invokeClientSave(event.source._id, event.source._value.trim());
    }
  }

  onFocusChange(event) {

  }

  onChange(event) {

  }

  dateChange(event) {
    this.invokeClientSave(event.target._elementRef.nativeElement.id, this.datePipe.transform(event.value, 'MM/dd/yyyy'));
  }


  inputFocus(event) {
    this.actualValue = event.srcElement.value;
  }

  goBack() {
    if (window.history.length > 1) {
      this.location.back()
    }
  }

  generatePDF(action = 'open') {
    var billTo = (this.invoiceInfo.BusinessName ? this.invoiceInfo.BusinessName : '') + (this.invoiceInfo.BusinessName ? ' / ' : '') + (this.invoiceInfo.BillingToFirstName + ' ' + this.invoiceInfo.BillingToLastName);
console.log(this.invoiceInfo.Items);
    const logo: any = [
      {
        text: this.officeInfo.CompanyName,
        alignment: 'left',
        width: 100,
        color: 'gray',
        lineHeight: 2
      }
    ];

    if (this.officeInfo.Logo) {
      logo.push(
        {
          image: this.officeInfo.Logo,
          fit: [100, 100],
          width: 'auto',
        },
      );
    }

    let docDefinition = {
      content: [
        {
          columns: [
            [
              ...logo,
            ],
            {
              text: this.invoiceInfo.Label,
              fontSize: 35,
              bold: true,
              alignment: 'right',
              color: 'skyblue',
              width: '*',
            },
          ]
        },
        {
          text: "#" + this.invoiceInfo.InvoiceNumber,
          fontSize: 20,
          bold: true,
          alignment: 'right',
        },
        {
          columns: [
            [
              {
                text: 'From: ' + this.invoiceInfo.BillingFromFirstName,
                bold: true
              },
              { text: this.invoiceInfo.BillingFromAddress && this.invoiceInfo.BillingFromAddress != 'null' ? this.invoiceInfo.BillingFromAddress : '' },
              { text: this.invoiceInfo.BillingFromState && this.invoiceInfo.BillingFromState != 'null' ? this.invoiceInfo.BillingFromState : '' },
              { text: this.invoiceInfo.BillingFromCountry && this.invoiceInfo.BillingFromCountry != 'null' ? this.invoiceInfo.BillingFromCountry : '' },
              { text: this.invoiceInfo.BillingFromZip && this.invoiceInfo.BillingFromZip != 'null' ? this.invoiceInfo.BillingFromZip : '' },
            ],

            [
              {
                text: `Date: ${new Date().toLocaleString()}`,
                alignment: 'right',
                fontSize: 7,
              },
            ]
          ]

        },
        {

        },
        {
          style: 'lineSpace',
          columns: [
            [
              {
                text: 'To: ' + billTo,
                bold: true
              },
              { text: this.invoiceInfo.BillingToAddress ? this.invoiceInfo.BillingToAddress : '' },
              { text: this.invoiceInfo.BillingToState ? this.invoiceInfo.BillingToState : '' },
              { text: this.invoiceInfo.BillingToCountry ? this.invoiceInfo.BillingToCountry : '' },
              { text: this.invoiceInfo.BillingToZip ? this.invoiceInfo.BillingToZip : '' },
            ],
            [
              {
                text: 'Date Of Issue',
                alignment: 'right',
                fontSize: 10,
                bold: true
              },
              {
                text: this.datePipe.transform(this.invoiceInfo.InvoicedOn, 'MM/dd/yyyy'),
                alignment: 'right',
                fontSize: 10,

              },
              {
                text: 'Due Date',
                alignment: 'right',
                fontSize: 10,
                bold: true,
              },
              {
                text: this.datePipe.transform(this.invoiceInfo.DueOn, 'MM/dd/yyyy'),
                alignment: 'right',
                fontSize: 10,
              },
              {
                text: 'Balance Due',
                alignment: 'right',
                fontSize: 10,
                bold: true
              },
              {
                text: '$' + this.invoiceInfo.Balance + ' USD',
                alignment: 'right',
                fontSize: 10,
              },
            ]
          ]

        },
        {
          layout: 'lightHorizontalLines',
          table: {
            headerRows: 1,
            widths: ['*', 'auto', 'auto', 'auto', 'auto'],
            body: [
              ['Product', 'Price', 'Quantity', 'Tax', 'Total'],
              ...this.invoiceInfo.Items.map(p => ([p.Item, '$' + p.UnitPrice, p.Quantity, p.TaxPercentage + '%', '$' + (p.UnitPrice * p.Quantity).toFixed(2) + ' USD'])),
              [{ text: 'Sub Total', bold:true, colSpan: 4 }, {}, {}, {}, '$' + this.invoiceInfo.SubTotal + ' USD'],
              [{ text: 'Tax',bold:true, colSpan: 4 }, {}, {}, {}, '$' + this.invoiceInfo.Taxes + ' USD'],
              [{ text: 'Grand Total', bold:true,colSpan: 4 }, {}, {}, {}, '$' + this.invoiceInfo.Total + ' USD']
            ]
          }
        },
        {
          text: 'Notes:',
          style: 'notes'
        },
        {
          text: this.invoiceInfo.Notes
        },
        {
          style: 'lineSpace',
          columns: [
            [{ text: 'Powered by Love My Systems', alignment: 'right', italics: true, fontSize: 5 }],
          ]
        },
      ],
      styles: {
        sectionHeader: {
          bold: true,
          decoration: 'underline',
          fontSize: 14,
          margin: [0, 15, 0, 15]
        },
        lineSpace: {
          margin: [0, 15, 0, 15]
        },
        notes: {
          bold: true,
          decoration: 'underline',
          fontSize: 10,
          margin: [0, 15, 0, 15]
        },

      }
    };

    const pdfGenerator = pdfMake.createPdf(docDefinition);
    if (action === 'download') {
      pdfGenerator.download();
    } else if (action === 'print') {
      pdfGenerator.print();
    } else if (action === 'pdfGenerator') {
      return pdfGenerator;
    }else {
      pdfGenerator.open();
    }

  }

  sendInvoiceByEmail(): void{
    this.generatePDF('pdfGenerator').getBlob((file: Blob) => {
      this._invoiceService.sendInvoiceByEmail(file, this.invoiceInfo.Soid)
        .subscribe({
          next: () => this.toast.success('Invoice sent successfully'),
          error: () => this.toast.error('Failed to send invoice'),
          complete: () => this.sendInvoiceConfirmationPopupRef.hide(),
        });
    });
  }

  showInvoiceSendConfirmationPopup(template: TemplateRef<any>): void {
    this.sendInvoiceConfirmationPopupRef = this.modalService.show(template);
  }
}
