<section class="home-section forgot-pass-section">
   <div class="container d-flex h-85">
      <div class="row center">
         <div class="col-lg-7 d-lg-flex d-none">
            <div class="img-holder"> <img src="assets/images/forgot_password.png" alt="holder"></div>
         </div>
         <div class="col-lg-5 col-md-8 col-sm-12">
            <div class="form-body mb-4 mt-4">
               <div class="logo">
                  <img src="assets\images\logo-png\full logo in black.png" title="LMS" />
               </div>
               <div class="form-text">
                  <h5>Forgot your password?</h5>
                  <p>Enter your email address to reset your password.</p>
               </div>

               <div class="form-content">
                  <form [formGroup]="lostPassForm" class="login-container" >
                     <div *ngIf="isSubmitted && lostPassForm.controls.emailId.errors != null" class="help-block">
                        <div *ngIf="lostPassForm.controls.emailId.errors.required">Email is required</div>
                     </div>
                     <div class="form-group">
                        <label for="email">Email address</label>
                        <div class="input-icon" [ngClass]="{ 'has-error': isSubmitted && lostPassForm.controls.emailId.errors != null}">
                           <input [(ngModel)]="email" autocomplete="off" style="text-transform: none !important" type="email" formControlName="emailId" class="form-control"
                              id="email" name="email" placeholder="Enter Your Email" autofocus="" required=""> <i
                              class="email-input"></i>
                        </div>
                     </div>
                     <div class="d-flex">
                        <button class="btn reset-button" (click)="forgotPassword()">Reset Password</button>
                     </div>
                     <div class="d-flex">
                        <a class="back-to-login" (click)="backToLogin()">Back to Sign in</a>
                     </div>
                  </form>
               </div>
            </div>
         </div>
      </div>
   </div>
</section>