<div class="modal-header">
    <h4 class="modal-title pull-left">{{header}}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <h6>Scheduled: Test App, Fri, August 06 at 09.00AM</h6>
    <p>Daily(10 times)</p>
    <div class="container">
      <div class="row align-items-center">
        <div class="col-md-4">
            <button class="btn btn-secondary">This Appoinment</button>
        </div>
        <div class="col-md-8">
          All other appoinment in the series will remain the same
        </div>
      </div>
      <div class="row align-items-center">
          <div class="col-md-4">
              <button class="btn btn-secondary">This Appoinment</button>
          </div>
          <div class="col-md-8">
            All other appoinment in the series will remain the same All other appoinment in the series will remain the same All other appoinment in the series will remain the same All other appoinment in the series will remain the same
          </div>
        </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="bsModalRef.hide()">{{closeBtnName}}</button>
  </div>