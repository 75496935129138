import { FormGroup } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ServicesListService } from '../../../services/services.service';
import {ToastrService} from 'ngx-toastr';
import { Location} from '@angular/common';
import * as _ from 'underscore';
import { ModulesService } from '../../../services/modules.service';
import { Subject } from 'rxjs';
import { MasterService } from '../../../services/master.service';
import { OfficeService } from 'src/app/services/office.service';

@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.css']
})
export class ServiceViewComponent implements OnInit {
  addService: FormGroup;
  feeField: boolean = true;
  serviceName:string;
  durationHour:string;
  durationMinutes:string;
  servicePrice:string;
  currency:string;
  module: string;
  formValid:boolean = true;
  priceChangeObserver : Subject<string> = new Subject<string>();
  modules: any = [];
  serviceInfo: any = {};
  soId: string;  
  selectedFee: string = 'With Fee';
  serviceOnlineMode: any = [];
  officeId: string;  
  selectedModule: any;
  selectedOffices: any[] = [];
  officeList: any[] = [];
  
  constructor(private masterService: MasterService, private _officeService: OfficeService,  private _moduleService: ModulesService, private location: Location,
    private toast: ToastrService,
    private _serviceService: ServicesListService, 
    private activatedRoute: ActivatedRoute) { }
    
    ngOnInit() {
      this.serviceOnlineMode = this.masterService.getMasterItems("SERVICEONLINEMODE");
      this.addService = new FormGroup({      
        office: new FormControl('', [Validators.required]),
        module: new FormControl('', [Validators.required]),
        serviceText: new FormControl('', [Validators.required]),
        durationHour: new FormControl(),
        durationMinutes: new FormControl(),
        servicePrice: new FormControl(''),
        currency: new FormControl(''),
      });
      
      if (localStorage.getItem("Modules") && localStorage.getItem("Modules") != "undefined") {
        var services : any = [];
        services = JSON.parse(localStorage.getItem("Modules"));
        var service: any = {};
        
        for (var key in services) {
          service = {};
          service.itemId = key;
          service.itemText = services[key];
          this.modules.push(service);
        }
      }
      
      this.officeId = this.activatedRoute.snapshot.params.officeSoid;
      this.soId = this.activatedRoute.snapshot.params.soId;
      
      this.getServiceInfo();
      
      const servicePrice = this.addService.get('servicePrice');
      
      this.priceChangeObserver.subscribe(feeField => {
        this.servicePrice = "";
        this.currency = "";
        if (feeField == "With Fee") {        
          servicePrice.setValidators([Validators.required]);
        } else {
          servicePrice.setValidators(null);
        }
      });
      
      this.getOfficeList();
    }
    
    
    
    getServiceInfo() {
      this._serviceService.getService(this.officeId, this.soId).subscribe((res: any) => {
        if (res) {
          this.serviceInfo = res;
          this.serviceName = this.serviceInfo.Item;
          this.durationHour = this.serviceInfo.DurationInHours.toString();
          this.durationMinutes = this.serviceInfo.DurationInMinutes.toString();
          this.servicePrice = this.serviceInfo.UnitPrice;
          this.module = this.serviceInfo.ModuleSoid;
          this.selectedFee = this.serviceInfo.PriceType;

          this.getSelection();
        }
      });
    }
    
    invokeClientSave(selector, value) {
      var requestInfo : any= {};
      requestInfo.FieldName = selector;
      requestInfo.Data = value
      requestInfo.UserName = localStorage.getItem("UserName");
      requestInfo.UserSoid =  localStorage.getItem("UserSoid");
    }

    private getOfficeList() {    
      this._officeService.getOfficeListByUser(localStorage.getItem("UserSoid")).subscribe((res: any) => {
        if (res) {
          this.officeList = res.Office;
        } else {
          this.officeList = [];
        }
        this.getSelection();
      });
    }
    
    public getSelection() {
      if (this.officeList.length > 0) {
        this._officeService.getServiceSelectedOffices(this.soId).subscribe((res: any) => {
          if (res) {
            this.selectedOffices = res;
          } else {
            this.selectedOffices = [];
          }
          this.officeChange();
        });
      } else {
        this.selectedOffices = [];
        this.modules = [];
      }
    }

    public officeChange() {
      this.modules = [];
      let selection = this.officeList.filter(o => this.selectedOffices.find(so => so == o.Soid));
      if (selection.length > 0) {
        let officeModules: any[] = selection.map(function(p){ return p.Modules; })
          .reduce(function(a, b){ return a.concat(b); }, []);
        officeModules.forEach(module => {
          if (!this.modules.find(m => m.Soid == module.Soid && m.Name == module.Name) &&
          officeModules.filter(m => m.Soid == module.Soid && m.Name == module.Name).length == selection.length) {
            this.modules.push(module);
          }
        });
        this.selectedModule = this.modules.find(module => module.Soid == this.module);
      }
    }
    
    createService(){
      for (let inner in this.addService.controls) {
        this.addService.get(inner).markAsTouched();
        this.addService.get(inner).updateValueAndValidity();
      }
      if (!this.addService.valid){
        this.formValid = false;
        this.toast.warning("Fill the required fields.", "Warning!");
        return;
      }

      var service: any = {};
      if (this.selectedFee == "With Fee") {
        service.UnitPrice = parseFloat(this.servicePrice  == null || this.servicePrice == '' ? '0' : this.servicePrice);
      } else {
        service.UnitPrice = 0;
      }
      service.ModuleSoid = this.selectedModule.Soid;
      service.ModuleName = this.selectedModule.Name;
      service.Category = this.serviceName;
      service.Item = this.serviceName;
      service.Description = this.serviceName;
      service.UnitOfMeasure = "Each"
      service.DurationInHours = parseInt(this.durationHour  == null ? '0' : this.durationHour);
      service.DurationInMinutes = parseInt(this.durationMinutes == null ? '0' : this.durationMinutes);
      service.PriceType = this.selectedFee;
      
      this._serviceService.updateService(this.soId, {service: service, officeSoid: this.selectedOffices}).subscribe((res: any) => {
        if (res) {
          this.toast.success("Service updated successfully.", "Success!");
        } else {
          this.toast.warning("Error Creating service", "Error!");
        }
      });
      
    }
    
    
    goBack() {
      if (window.history.length > 1) {
        this.location.back()
      }
    }
    
    showFeeField(field) {    
      this.selectedFee = field;
      this.feeField = this.feeField !== false ? false : true;
      this.priceChangeObserver.next(this.selectedFee.toString());
    }
    
  }
  