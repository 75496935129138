<div class="modal-header">
  <h4 class="modal-title pull-left">{{title}}</h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form class="client-modal" [formGroup]="addProductDocumentForm">
    <div class="form-row">
      <div style="float: left; width: 50px; height:50px;">
        <ngx-avatar size="40" name="{{clientInfo.NameFirst}} {{clientInfo.NameLast}}"></ngx-avatar>
      </div>
      <div style="float: left; width: 80%;">
        <div class="client-list-left">
          <h6 class="mt-0 mb-1">{{clientInfo.NameFirst | titlecase}} {{clientInfo.NameLast | titlecase}}</h6>
          <span><i class="fas fa-at"></i>{{clientInfo.Email}}</span>
        </div>
      </div>
    </div>

    <div class="form-row">
      <div class="col-md-6">
        <mat-form-field>
          <mat-label>Service Product</mat-label>
          <mat-select [(ngModel)]="module" formControlName="serviceProduct">
            <mat-option *ngFor="let staff of staffList" [value]="staff">
              {{staff.NameFirst}} {{staff.NameLast}} ({{staff.EmailAddress}})
            </mat-option>
          </mat-select>
          <mat-error *ngIf="addProductDocumentForm.controls.serviceProduct.errors != null && !formValid">
            Please select Staff
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-6">
        <mat-form-field>            
          <input type="input" autocomplete="off" [(ngModel)]="serviceName" formControlName="productPrice" matInput placeholder="Product Price ($)" >            
          <mat-error *ngIf="addProductDocumentForm.controls.productPrice.errors != null && !formValid">
              Please enter service name
          </mat-error>
      </mat-form-field>
      </div>
    </div>

    <div class="form-row">
      <div class="col-md-4">
        <mat-form-field>
          <mat-label>Tax1</mat-label>
          <mat-select [(ngModel)]="module" formControlName="tax1">
            <mat-option *ngFor="let staff of staffList" [value]="staff">
              {{staff.NameFirst}} {{staff.NameLast}} ({{staff.EmailAddress}})
            </mat-option>
          </mat-select>
          <mat-error *ngIf="addProductDocumentForm.controls.tax1.errors != null && !formValid">
            Please select Staff
          </mat-error>
        </mat-form-field>
      </div>
        <div class="col-md-4">
        <mat-form-field>
          <mat-label>Tax2</mat-label>
          <mat-select [(ngModel)]="module" formControlName="tax2">
            <mat-option *ngFor="let staff of staffList" [value]="staff">
              {{staff.NameFirst}} {{staff.NameLast}} ({{staff.EmailAddress}})
            </mat-option>
          </mat-select>
          <mat-error *ngIf="addProductDocumentForm.controls.tax2.errors != null && !formValid">
            Please select Staff
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-4">
        <mat-form-field>
          <mat-label>Tax3</mat-label>
          <mat-select [(ngModel)]="module" formControlName="tax3">
            <mat-option *ngFor="let staff of staffList" [value]="staff">
              {{staff.NameFirst}} {{staff.NameLast}} ({{staff.EmailAddress}})
            </mat-option>
          </mat-select>
          <mat-error *ngIf="addProductDocumentForm.controls.tax3.errors != null && !formValid">
            Please select Staff
          </mat-error>
        </mat-form-field>
        
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">{{closeBtnName}}</button>
  <button type="button" class="btn btn-primary" (click)="createNewDocument()">Add</button>
</div>