
import { FormBuilder, FormGroup } from '@angular/forms';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormControl, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import { PageEvent } from '@angular/material/paginator';
import { StaffService } from '../../services/staff.service';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import * as _ from 'underscore';
import { MasterService } from '../../services/master.service';
import { OfficeService } from '../../services/office.service';

import { AuthService } from '../../services/auth.service';

import { pairwise, startWith } from 'rxjs/operators'

@Component({
  selector: 'app-staff',
  templateUrl: './staff.component.html',
  styleUrls: ['./staff.component.css']
})
export class StaffComponent implements OnInit {
  searchText: string;
  myForm: FormGroup;
  clientSubmitForm: FormGroup;
  disabled = false;
  ShowFilter = false;
  limitSelection = false;
  selectedItems: any = [];
  dropdownSettings: any = {};
  lowValue: number = 0;
  highValue: number = 5;
  staffRequest: any = {};
  firstName: string;
  lastName: string;
  formValid: boolean = true;
  subscriptionServices: any = [];
  agelimit: Date;
  sortBy: string = "";
  filterBy: string = "Active";
  toppings = new FormControl();
  currentValue: any = [];
  previousValue: any = [];

  selectedOffice: any = {};



  countries: string[] = [
    'Canada', 'Mexico', 'United States'
  ];

  staffList: any = [];
  topping: any = {};
  officeList: any = [];
  staffPosition: any = [];
  staffRole: any = [];
  staffStatus: any = [];

  // MatPaginator Inputs
  length = Number;
  pageIndex: number = 0;
  pageSize = 5;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  phoneNumber: string;
  phoneNumberCountryCode: number;

  isAdmin: boolean = false;
  isSuperAdmin: boolean = false;
  modalRef: BsModalRef;
  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);
  constructor(private _authService: AuthService, private masterService: MasterService, private toast: ToastrService, private datePipe: DatePipe,
     private _staffService: StaffService, private modalService: BsModalService, private _officeService: OfficeService,
      private _router: Router, private fb: FormBuilder,) { }

  ngOnInit() {
   
    var date = new Date();
    date.setFullYear(date.getFullYear() - 18);
    this.agelimit = date;

    this.clientSubmitForm = new FormGroup({
      email: new FormControl('', Validators.compose([
        Validators.required, Validators.email
      ])), firstName: new FormControl('', Validators.compose([
        Validators.required
      ])), lastName: new FormControl('', Validators.compose([
        Validators.required
      ])),
      roleName: new FormControl('', Validators.compose([
        Validators.required
      ])),
      phone: new FormControl('', Validators.required),
      position: new FormControl('', Validators.required)
    });

    this.selectedOffice = localStorage.getItem("OfficeSoid");
    

    this.getStaffList();

    this._authService.getUserRole().subscribe( (res: string) => {
      if (res) {
        const permissions = this._authService.getPermissions(res);
        this.isAdmin = permissions.isAdmin;
        this.isSuperAdmin = permissions.isSuperAdmin;
        
        if (this.isSuperAdmin) {
          this.staffRole = this.masterService.getMasterItems("STAFFSUPERADMINROLE");
        } else {
          this.staffRole = this.masterService.getMasterItems("STAFFROLE");
        }
      }
    }, (err) => {
      this.toast.error(err.message)
    });
    this.getOfficeList();
  }


   getSelectedStaffList(event) {
    this._staffService.getStaffByOffice(this.selectedOffice, this.filterBy).subscribe((res: any) => {
      if (res) {
        this.staffList = _.sortBy(res, this.filterBy);
        this.length = res.length;
      } else {
        this.staffList = [];
      }
    });
  }

  private getStaffList() {
    this._staffService.getStaffsListFilterBy(this.filterBy).subscribe((res: any) => {
      if (res) {
        this.staffList = _.sortBy(res, "NameFirst");
        this.length = res.length;
      } else {
        this.staffList = [];
      }
    });
  }



  public getPaginatorData(event: PageEvent): PageEvent {
    this.lowValue = event.pageIndex * event.pageSize;
    this.highValue = this.lowValue + event.pageSize;
    return event;
  }

  openModal(template: TemplateRef<any>) {
    this.clientSubmitForm.reset();
    this.staffPosition = this.masterService.getMasterItems("STAFFPOSITION");
    this.staffStatus = this.masterService.getMasterItems("STAFFSTATUS");

    this.modalRef = this.modalService.show(template);
  }

  editClient(clientId) {
    this._router.navigate(['staff/' + clientId]);
  }

  addClient() {

  }

  sortFieldChange(event) {
    this.sortBy = event.srcElement.value;
    this.staffList = _.sortBy(this.staffList, this.sortBy);
  }

  filterStaffList(event) {
    this.filterBy = event.srcElement.value;
    this.getStaffList();
  }


  private getOfficeList() {    
    let officeSoids = localStorage.getItem("Offices") != null ? JSON.parse(localStorage.getItem("Offices")) : [];

    if (officeSoids.length > 0) {
      officeSoids = officeSoids.map(o => o.OfficeSoid);
      this._officeService.getOfficeListByIds(officeSoids).subscribe((res: any) => {
        if (res) {
          this.officeList = res;
        } else {
          this.officeList = [];
        }
      }, (err) => {
        this.toast.error(err);
      });
    }
  }

  onItemSelect(item: any) {
  }
  onSelectAll(items: any) {
  }
  toogleShowFilter() {
    this.ShowFilter = !this.ShowFilter;
    this.dropdownSettings = Object.assign({}, this.dropdownSettings, { allowSearchFilter: this.ShowFilter });
  }

  handleLimitSelection() {
    if (this.limitSelection) {
      this.dropdownSettings = Object.assign({}, this.dropdownSettings, { limitSelection: 2 });
    } else {
      this.dropdownSettings = Object.assign({}, this.dropdownSettings, { limitSelection: null });
    }
  }

  getShortName(fullName) {
    return fullName.split(' ').map(n => n[0]).join('');
  }

  convertToArray(valueText) {
    return valueText.split(",");
  }

  initEmploymentChange() {
    this.toppings.valueChanges.pipe(startWith(null), pairwise()).subscribe({
      next: (value: number[]) => {

        if (value[0] != null) {
          this.previousValue = value[0];
        }
        this.currentValue = value[1];


        const newValue = this.previousValue === null ?
          this.currentValue[0] : this.currentValue.find((value: number) => !this.previousValue.includes(value));


        if (newValue && this.previousValue != null) {
        //  this.updateEmployment(newValue.Soid);
        } else if (this.previousValue != null) {
          this.previousValue.forEach(p => {
            var removeValue = this.currentValue.filter(e => (e.Soid == p.Soid));


            if (removeValue.length == 0) {
             // this.updateEmployment(p.Soid);
            }


          });
        }
      }
    });
  }

  saveStaff() {
    for (let inner in this.clientSubmitForm.controls) {
      this.clientSubmitForm.get(inner).markAsTouched();
      this.clientSubmitForm.get(inner).updateValueAndValidity();
    }
    if (!this.clientSubmitForm.valid) {      
      if (!this.clientSubmitForm.valid) {
        this.formValid = false;
        this.toast.warning("Fill the required fields.", "Warning!");
        return;
      }
    }

    let userInfo: any = {};

    this.staffRequest.NameFirst = this.clientSubmitForm.controls.firstName.value;
    this.staffRequest.NameLast = this.clientSubmitForm.controls.lastName.value;
    this.staffRequest.EmailAddress = this.clientSubmitForm.controls.email.value;
    this.staffRequest.OfficeSoid = [localStorage.getItem("OfficeSoid")];
    this.staffRequest.Realm = "Agency";
    this.staffRequest.Active = true;
    this.staffRequest.Role = this.clientSubmitForm.controls.roleName.value;
    this.staffRequest.Position = this.clientSubmitForm.controls.position.value;

    this.staffRequest.Status = "Active";

    this.staffRequest.Employments = this.toppings.value;    
    
    var countryCode = this.phoneNumberCountryCode ? '+' + this.phoneNumberCountryCode : '+1';
    this.staffRequest.PersonalMobile = countryCode + this.phoneNumber;
    this.staffRequest.Email = this.clientSubmitForm.controls.email.value;

    this._staffService.saveStaffInfo(this.staffRequest).subscribe((res: any) => {
      if (res && res.status != null && res.status == "SUCCESS" && res.response) {
        if (res.response == "EMAIL_EXISTS") {
          this.toast.warning("Email already exists.", "Warning!");
        } else {
          this.toast.success("Staff created successfully.", "Success!");
          this.modalRef.hide();
          this.editClient(res.response.userSoid);
        }
      } else {
        this.toast.error("Error creating staff!", "Error!");
      }
    }, (err) => {
      this.toast.error(err);
    });
  }

  transformDateFormat(dateValue) {
    return this.datePipe.transform(new Date(dateValue), 'MM/dd/yyyy');
  }

  countryChange(event) {
    this.phoneNumberCountryCode = event.dialCode;
  }
  

  phoneNumberFieldFocusOut(event) {
    if (event.srcElement.value.trim() != "") {
      this.phoneNumber = event.srcElement.value;     
    }
  }

}

