<div class="modal-header">
    <h4 class="modal-title pull-left">{{header}}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form class="client-modal" [formGroup]="cancelAppoinmentForm">
     <div class="form-row">
          <mat-form-field>
              <textarea [(ngModel)]="message" type="text" formControlName="message" matInput placeholder="Message"></textarea>
          </mat-form-field>
      </div>
     </form> 
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="save()">{{closeBtnName}}</button>
  </div>