import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: "ParticipantName" })
export class ArrayFilterPipe implements PipeTransform {
  transform(itemList: any, ParticipantType: string) {
    if (!itemList)
      return [];
    if (!ParticipantType)
      return itemList;
    let filteredList = [];
    if (itemList.length > 0) {
      var participant = itemList.filter(e => e.ParticipantType == ParticipantType && (e.IsActive == null || e.IsActive));
      return participant[0].ParticipantName;
    }
    return "";
  }
}