<div class="section-container">
    <div class="subheader">
        <h2>Modules</h2>

    </div>
    <div class="white-bg rounded border-0">
        <div class="row top-menu bg-light bg-grey no-gutters">
            <div class="col-md-5">
                <div class="top-menu-container">
                    <a class="btn btn-success" (click)="OnAddTaskButtonClick()">Add Module</a>
                    <a class="btn btn-primary" *ngIf="viewAll" (click)="OnBackButtonClick()">Back</a>
                </div>
            </div>
            <div class="col-md-5">
            <div class="form-row" *ngIf="officeList && officeList.length > 0">
                <mat-form-field>
                  <select  matNativeControl placeholder="Office" (change)="getOfficeFilter($event)" [(ngModel)]="selectedOffice">
                    <!--<mat-option>None</mat-option>-->
                    <option value="All">All</option>
                    <option *ngFor="let service of officeList" [value]="service.Soid">{{service.CompanyName}} </option>
                    <!--<mat-option value="Custom">Custom</mat-option>-->
                  </select>
                </mat-form-field>
              </div>
            </div>
            <div class="col-md-2 text-right">
                <mat-form-field class="search-results">
                  <select matNativeControl required (change)="filterModules()" [(ngModel)]="filterBy">
                    <option value="All">All</option>
                    <option value="Active">Active</option>
                    <option value="InActive">InActive</option>
                  </select>
                </mat-form-field>
            </div>
           
        </div>

        <table #table mat-table matSort [dataSource]="dataSource" multiTemplateDataRows style="width: 100%;" *ngIf="showData">
            <ng-container matColumnDef="Edit" >
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element" >
                    <button *ngIf="element.Status == 'Active'" type="button" class="btn btn-primary" (click)="OnEditButtonClick(element)">Edit</button>
                </td>
            </ng-container>

            <ng-container matColumnDef="Delete">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element">
                    <button *ngIf="element.Status == 'Active'" type="button" class="btn btn-danger" (click)="openConfirmationDialog(element)">Delete</button>
                    <button *ngIf="element.Status == 'InActive'" type="button" class="btn btn-primary" (click)="activeteModule(element)">Activate</button>
                </td>
            </ng-container>

            <ng-container matColumnDef="Name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
                <td mat-cell *matCellDef="let element">{{element.Name}}</td>
            </ng-container>

            <ng-container matColumnDef="Status">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
                <td mat-cell *matCellDef="let element">{{element.Status}}</td>
            </ng-container>

            <ng-container matColumnDef="CreatedBy">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Created By</th>
                <td mat-cell *matCellDef="let element">{{element.CreatedBy}}</td>
            </ng-container>

            <ng-container matColumnDef="CreatedOn">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Created On</th>
                <td mat-cell *matCellDef="let element">{{element.CreatedOn | date:'MM-dd-yyyy hh:mm a'}}</td>
            </ng-container>

     

          

            <ng-container matColumnDef="noRecord">
                <td mat-footer-cell *matFooterCellDef colspan="11">No Modules Found</td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let element; columns: displayedColumns;" class="element-row"
            [class.expanded-row]="expandedElement === element" ></tr>
           
            <tr [ngClass]="{'hide': !(dataSource?.data!=null && dataSource?.data.length==0)}" mat-footer-row
            *matFooterRowDef="['noRecord'];sticky:false"></tr>
        </table>

        <mat-paginator #paginator *ngIf="dataSource" [length]="dataSource?.data.length" [pageIndex]="0" [pageSize]="10"
            [pageSizeOptions]="[20, 50,100, 150]">

        </mat-paginator>


    </div>
</div>

<ng-template #template>
    <div class="modal-header">
       <h4 class="modal-title pull-left">Delete Task</h4>
       <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
          <span aria-hidden="true">×</span>
       </button>
    </div>
    <div class="modal-body">
        Are you sure you want to delete the task ?
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-danger" (click)="OnOkDeleteButtonClick()">Ok</button>
        <button type="button" class="btn btn-secondary" (click)="modalRef.hide()">Cancel</button>
    </div>
 </ng-template>