import { Component, OnInit, EventEmitter } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import {FormBuilder, FormGroup, FormControl, Validators} from '@angular/forms';
import {OfficeService } from '../../../services/office.service';
import {ToastrService} from 'ngx-toastr';
import { Observable, Subject } from 'rxjs';
import { StaffService } from 'src/app/services/staff.service';
import { ConfirmationDialog } from '../confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-addoffice',
  templateUrl: './addoffice.component.html',
  styleUrls: ['./addoffice.component.css']
})
export class AddOfficeComponent implements OnInit {
  currentValue: any = [];
  previousValue: any = [];

  toppings = new FormControl([]);
  title: string = "Add Office";
  selectCallType: string;
  closeBtnName: string = "Close";
  selectedTab: string = "tab1"; //Initial tab active
  paymentTypes: Array<string> = ['USD', 'POUNDS', 'TINAR', 'BHAT'];
  addOfficeForm: FormGroup;
  feeField: boolean = true;
  companyName:string;
  contactName:string;
  emailAddress:string;
  isParentOffice:boolean = false;
  phoneNumber:string;
  phoneNumberCountryCode: string;
  formValid:boolean = true;
  locationModeObserver : Subject<string> = new Subject<string>();
  priceChangeObserver : Subject<string> = new Subject<string>();
  subscriptionServices: any = [];
  public event: EventEmitter<any> = new EventEmitter();
  toppingsControl = new FormControl('', Validators.compose([
    Validators.required
  ]));

  officeList: any = [];
  officeLogo: string | null = null;
  officeLogoName: string;

  constructor(private toast: ToastrService, private _service: OfficeService, public bsModalRef: BsModalRef,
    public confirmModalRef: BsModalRef, private modalService: BsModalService, private _staffService: StaffService) {

  /*  this.addServiceForm = sb.group({
      hideRequired: false,
      floatLabel: 'auto',
    });*/
  }

  showFeeField() {
    this.feeField = this.feeField !== false ? false : true;
    this.priceChangeObserver.next(this.feeField.toString());
    return this.feeField;
  }

  ngOnInit() {
    this.addOfficeForm = new FormGroup({
      companyName: new FormControl('', [Validators.required]),
      contactName: new FormControl('', [Validators.required]),
      isParentOffice: new FormControl(''),
      emailAddress: new FormControl('', [Validators.required, Validators.email]),
      phoneNumber: new FormControl('', [Validators.required]),
    });

    if (localStorage.getItem("Modules") && localStorage.getItem("Modules") != "undefined") {
      var services : any = [];
        services = JSON.parse(localStorage.getItem("Modules"));
        var service: any = {};

        for (var key in services) {
          service = {};
          service.item_id = key;
          service.item_text = services[key];
          this.subscriptionServices.push(service);
        }
    }

    this.getOfficeList();
  }

  createService(){
    for (let inner in this.addOfficeForm.controls) {
      this.addOfficeForm.get(inner).markAsTouched();
      this.addOfficeForm.get(inner).updateValueAndValidity();
    }
    if (!this.addOfficeForm.valid){
      this.formValid = false;
      this.toast.warning("Fill the required fields.", "Warning!");
      return;
    }

    var serviceRequest: any = {};
    serviceRequest.companyName = this.companyName;
    serviceRequest.contactName = this.contactName;
    serviceRequest.emailAddress = this.emailAddress;
    serviceRequest.isParentOffice = this.isParentOffice;
    serviceRequest.logo = this.officeLogo;
    this.toppings.value.forEach (e => {
      e.Status = "Active"
    });
    serviceRequest.branchOffices = this.toppings.value;

    var countryCode = this.phoneNumberCountryCode ? '+' + this.phoneNumberCountryCode : '+1';
    serviceRequest.phoneNumber = countryCode + this.phoneNumber;

    this._staffService.validateEmailAddress(serviceRequest.emailAddress).subscribe((userExists: any) => {
      if (userExists) {
        const initialState = {
          confirmTitle: "Email address already exists",
          confirmMessage: "Do you want to create the office and add the existing user to it?"
        };
        this.confirmModalRef = this.modalService.show(ConfirmationDialog, {initialState});
        this.confirmModalRef.content.event.subscribe(confirmed => {
          if (confirmed) {
            this.createOffice(serviceRequest);
          }
        });
      } else {
        this.createOffice(serviceRequest);
      }
    }, (err) => {
      this.toast.error(err);
    });
  }

  private createOffice(office: any) {
    this._service.createOffice(office).subscribe((res: any) => {
      if (res) {
        this.toast.success("Office created successfully.", "Success!");
        
        this._service.getOfficeListByUser(localStorage.getItem("UserSoid")).subscribe((oRes: any) => {
          if (oRes) {
            let offices: any[] = oRes.Office.map(o => {
              return {
              OfficeSoid: o.Soid,
              OfficeName: o.CompanyName
              }
            })
            localStorage.setItem('Offices', JSON.stringify(offices));
            this.triggerEvent();
            this.bsModalRef.hide();
          }
        });
      } else {
       this.toast.warning("Error Creating Office", "Error!");
      }
    }, (err) => {
      this.toast.error(err);
    });

  }

  triggerEvent() {
    this.event.emit();
  }

  phoneNumberFieldFocusOut(event) {
    if (event.srcElement.value.trim() != "") {
      this.phoneNumber = event.srcElement.value;
    }
  }


  countryChange(event) {
    this.phoneNumberCountryCode = event.dialCode;
  }

  private getOfficeList() {
    this._service.getOfficeList().subscribe((res: any) => {
      if (res) {
        var office = [];
        this.officeList = res;

        //this.personInfo.Employee.forEach(element => {
          // if (element.Status == "Active") {
          //office.push(this.officeList.filter(e => (e.Soid === element))[0]);
          // }
       // });
        //this.previousValue = this.officeList;
      //  this.toppings.setValue(this.officeList);
      // this.initEmploymentChange()
      } else {
        this.officeList = [];
      }
    }, (err) => {
      this.toast.error(err);
    });
  }

  // Converts file from office logo field to Base image
  handleOfficeLogoChange(e: Event) {
     const file = (e.target as HTMLInputElement).files[0];
     this.officeLogoName = file.name;
     const reader = new FileReader();
     reader.onloadend = () => {
       this.officeLogo = <string>reader.result;
     }
    reader.readAsDataURL(file);
  }
}
