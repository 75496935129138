import { Injectable } from '@angular/core';
import { Api } from './api';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class InvoiceService {

  constructor(public api: Api) { }

  createInvoice(clientSoid, requestData) {
    let seq = this.api.post('Customer/'+ localStorage.getItem("OfficeSoid") + '/InvoiceAdd/'+ clientSoid, requestData  );
    return seq;
  }

  createEstimates(clientSoid, requestData) {
    let seq = this.api.post('Customer/'+ localStorage.getItem("OfficeSoid") + '/AddEstimates/'+ clientSoid, requestData  );
    return seq;
  }

  getInvoice(clientSoid, invoiceId) {
    let seq = this.api.get('Customer/'+  clientSoid + '/CustomerInvoice/'+ invoiceId);
    return seq;
  }


  pushToXero(invoiceId) {
    let seq = this.api.post('Customer/PushToXero/'+ invoiceId, null);
    return seq;
  }


  deleteInvoice(invoiceId) {
    let seq = this.api.delete('Customer/Delete/'+ invoiceId);
    return seq;
  }

  voidInvoice(invoiceId) {
    let seq = this.api.delete('Customer/Void/'+ invoiceId);
    return seq;
  }


  repeatInvoice(invoiceId) {
    let seq = this.api.post('Customer/InvoiceRepeat/'+ invoiceId, null);
    return seq;
  }

  approveInvoice(invoiceId) {
    let seq = this.api.post('Customer/ApproveInvoice/'+ invoiceId, null);
    return seq;
  }

  GetXeroStatus(invoiceId) {
    let seq = this.api.post('Customer/XeroStatus/'+ invoiceId, null);
    return seq;
  }

  submitToXero(invoiceId) {
    let seq = this.api.post('Customer/SubmitToXero/'+ invoiceId, null);
    return seq;
  }

  getCustomerTransaction(clientSoid, transactionId) {
    let seq = this.api.get('Customer/'+  clientSoid + '/GetCustomerTransaction/'+ transactionId);
    return seq;
  }

  getEstimates(clientSoid, invoiceId) {
    let seq = this.api.get('Customer/'+  clientSoid + '/CustomerEstimates/'+ invoiceId);
    return seq;
  }

  deleteEstimate(invoiceId) {
    let seq = this.api.post('Customer/CustomerEstimates/'+ invoiceId + '/Delete', null);
    return seq;
  }

  getCustomerInfo(clientSoid) {
    let seq = this.api.get('Customer/Invoice/' +  clientSoid);
    return seq;
  }

  recordPayment(clientSoid, requestData) {
    let seq = this.api.post('Customer/'+ localStorage.getItem("OfficeSoid") + '/AddPayment/'+ clientSoid, requestData  );
    return seq;
  }

  getInvoiceList(OfficeSoid) {
    let seq = this.api.get('Customer/'+ OfficeSoid + '/Invoices');
    return seq;
  }

  getTransactionList() {
    let seq = this.api.get('Customer/'+ localStorage.getItem("OfficeSoid") + '/Transactions');
    return seq;
  }

  getPayments(officeSoid) {
    let seq = this.api.get('Customer/'+ officeSoid + '/AllPayments');
    return seq;
  }

  getInvoiceNumber() {
    let seq = this.api.get('Customer/'+ localStorage.getItem("OfficeSoid") + '/InvoiceNumber');
    return seq;
  }

  getReceiptNumber() {
    let seq = this.api.get('Customer/'+ localStorage.getItem("OfficeSoid") + '/ReceiptNumber');
    return seq;
  }

  getEstimateNumber() {
    let seq = this.api.get('Customer/'+ localStorage.getItem("OfficeSoid") + '/EstimateNumber');
    return seq;
  }

  sendInvoiceByEmail(file: Blob, invoiceSoid: string): Observable<any> {
    const formData = new FormData();
    formData.append('invoice', file);
    return this.api.postFormdata(`api/Customer/CustomerInvoice/${invoiceSoid}/SendByEmail`, formData);
  }

  sendEstimateByEmail(file: Blob, estimateSoid: string): Observable<any> {
    const formData = new FormData();
    formData.append('estimate', file);
    return this.api.postFormdata(`api/Customer/CustomerEstimates/${estimateSoid}/SendByEmail`, formData);
  }

  sendPaymentByEmail(file: Blob, paymentSoid: string): Observable<any> {
    const formData = new FormData();
    formData.append('payment', file);
    return this.api.postFormdata(`api/Customer/CustomerPayment/${paymentSoid}/SendByEmail`, formData);
  }

  refreshToken() {
    let seq = this.api.get('XeroAdmin/Office/Refresh');

    return seq;
  }
}

