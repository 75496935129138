import { Injectable } from '@angular/core';
import { Api } from '../services/api';

@Injectable({
  providedIn: 'root'
})

export class DashboardService {

  constructor(public api: Api) { }

  getStatusCount() {
    var officeSoid = localStorage.getItem("OfficeSoid");
    let seq = this.api.get('Customer/' + officeSoid + '/Dashboard');
    return seq;
  }

}

