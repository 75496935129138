import { Component, OnInit, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Subject, ReplaySubject } from 'rxjs';
import { ClientService } from '../../../services/client.service';
import { MasterService } from '../../../services/master.service';
import { take, takeUntil } from 'rxjs/operators';

import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-person-note',
  templateUrl: './person-note.component.html',
  styleUrls: ['./person-note.component.css']
})
export class PersonNoteComponent implements OnInit {

  title: string = "Business Info";
  selectCallType: string;
  closeBtnName: string = "Close";
  notesForm: FormGroup;
  formValid: boolean = true;
  clientInfo: any = {};
  header: string;
  editNote: any = {};
  clientNote: string;
  public event: EventEmitter<any> = new EventEmitter();

  constructor(private datePipe: DatePipe, private toast: ToastrService,
    private masterService: MasterService, private _clientService: ClientService,
    public bsModalRef: BsModalRef, sb: FormBuilder) {
  }

  ngOnInit() {
    this.notesForm = new FormGroup({
      notes: new FormControl('', Validators.compose([
        Validators.required
      ])),
    });
    if (this.editNote && this.editNote.Soid) {
      this.clientNote = this.editNote.Notes;
    }
  }



  createNewDocument() {

    for (let inner in this.notesForm.controls) {
      this.notesForm.get(inner).markAsTouched();
      this.notesForm.get(inner).updateValueAndValidity();
    }
    if (!this.notesForm.valid) {
      this.formValid = false;
      this.toast.warning("Fill the required fields.", "Warning!");
      return;
    }

    if (this.editNote && this.editNote.Soid) {
      var requestInfo: any = {};
      requestInfo.FieldName = "Notes";
      requestInfo.Data = this.notesForm.controls.notes.value;
      requestInfo.UserName = localStorage.getItem("UserName");
      requestInfo.UserSoid = localStorage.getItem("UserSoid");
      this._clientService.updatePersonNotes(this.clientInfo.Soid, this.editNote.Soid, requestInfo).subscribe((res: any) => {
        if (res) {
          this.toast.success("Updated Note successfully.", "Success!");
          this.triggerEvent();
          this.bsModalRef.hide();
        } else {
          this.toast.warning("Error Creating service", "Error!");
        }
      });
    } else {
      var notes: any = {};
      notes.NoteType = "PERSONNOTES";
      notes.StaffSoid = localStorage.getItem("UserSoid");
      notes.StaffName = localStorage.getItem("NameFirst") + " " + localStorage.getItem("NameLast");
      notes.Notes = this.notesForm.controls.notes.value;
      notes.Active = true;

      this._clientService.saveNotes(this.clientInfo.Soid, notes).subscribe((res: any) => {
        if (res) {
          this.toast.success("Added Note successfully.", "Success!");
          this.triggerEvent();
          this.bsModalRef.hide();
        } else {
          this.toast.warning("Error Creating service", "Error!");
        }
      });
    }
  }



  triggerEvent() {
    this.event.emit();
  }
}
