import { Injectable } from '@angular/core';
import { Api } from './api';

@Injectable({
  providedIn: 'root'
})

export class TaskService {

  constructor(public api: Api) { }

  getUserTasksList(soId) {
    let seq = this.api.get('Task/' + soId + '/GetByUser');
    return seq;
  }

  addCollaborator(taskId, data) { 
    let seq = this.api.post('Task/'+taskId+'/AddTaskCollaborator', data);
    return seq;
  }

  getTaskInfo(soId) {
    let seq = this.api.get('Task/' + soId);
    return seq;
  }

  updateTaskInfo(taskId, data) {
    let seq = this.api.patch('Task/' + taskId,data);
    return seq;
  }

  deleteCollaborator(taskId, soId) {
    let seq = this.api.delete('Task/' + taskId+'/DeleteTaskCollaborator/'+soId);
    return seq;
  }

  addAttachments(data) { 
    let seq = this.api.postFormdata('Ajax/UploadTaskAttachment', data);
    return seq;
  }
  
  downloadTaskAttachment(data){
    let seq = this.api.ajaxPost('Ajax/DownloadUploadedTaskFile', data);
    return seq;
  }

  addTaskComment(taskId, data){
    let seq = this.api.post('Task/' + taskId +'/AddTaskComment', data);
    return seq;
  }

  updateTaskStatus(taskId, data) {
    let seq = this.api.patch('Task/' + taskId + '/ChangeStatus',data);
    return seq;
  }

  getAllTasks() {
    let seq = this.api.get('Task');
    return seq;
  }

  addTask(data){
    let seq = this.api.post('Task/AddNew', data);
    return seq;
  }

  deleteTask(taskId) {
    let seq = this.api.delete('Task/' + taskId);
    return seq;
  }

}

