import { Component, OnInit, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ServicesListService } from '../../../services/services.service';
import { ClientService } from '../../../services/client.service';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject } from 'rxjs';
import { StaffService } from '../../../services/staff.service';
import { InvoiceService } from '../../../services/invoice.service';
import { Router } from '@angular/router';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import _ from 'lodash';
import { ConfirmationDialog } from '../confirmation-dialog/confirmation-dialog.component';


@Component({
  selector: 'app-service-invoice',
  templateUrl: './service-invoice.component.html',
  styleUrls: ['./service-invoice.component.css']
})
export class ServiceInvoiceComponent implements OnInit {

  billingToList: any = [];
  title: string = "Invoice";
  header: string = "";
  selectCallType: string;
  closeBtnName: string = "Close";
  selectedTab: string = "tab1"; //Initial tab active
  paymentTypes: Array<string> = ['USD', 'POUNDS', 'TINAR', 'BHAT'];
  serviceInvoiceForm: FormGroup;
  feeField: boolean = true;
  serviceName: string;
  uploadType: string = "MY DOCUMENTS";
  phoneType: string;
  phoneValue: string;
  onlineType: string;
  onlineValue: string;
  faceType: string;
  faceValue: string;
  durationHour: string;
  durationMinutes: string;
  servicePrice: string;
  currency: string;
  module: string;
  formValid: boolean = true;
  locationModeObserver: Subject<string> = new Subject<string>();
  priceChangeObserver: Subject<string> = new Subject<string>();
  subscriptionServices: any = [];
  public event: EventEmitter<any> = new EventEmitter();
  public clientInfo: any = [];
  staffList: any = [];
  serviceList: any = [];
  invoiceNo: string;
  myDocumentsList: any = [];
  @ViewChild('labelImport')
  labelImport: ElementRef;
  agelimit: number = 10;
  invoicePreListItems: any = {};
  formImport: FormGroup;
  fileToUpload: File = null;
  InvoicePriceListItemQuantity: number = 0;
  invoiceServiceItems: any = [];
  invoiceSubTotal: number = 0.00;
  invoiceTotal: number = 0.00;
  modalRef: BsModalRef;
  invoiceQuantity: number = 1;
  officeName: string;
  billingFromAddress: string;
  billingToAddress: string;
  billingToSelected: any = {};
  invoiceServiceItem: any = {};
  clientBusiness: any = {};
  clientDependent: any = {};
  invoicePrice: number;
  changeUnitPrice: boolean = false;
  billToName: string;
  disableInvoice: boolean = true;
  totalTax: number = 0.00;
  subTotal: number = 0.00;
  sInvoiceInfo: any = {};
  notes: string;
  invoiceLabel: string;
  purchaseOrder: string;
  invoiceIssueDate:  Date;
  invoiceDueDate:  Date;
  taxRates: any = [];
  taxServiceItem: any = {};

  constructor(private toast: ToastrService, private _service: ServicesListService,
    private _invoiceService: InvoiceService,private modalService: BsModalService,
    private _router: Router,
    private _clientService: ClientService,
    public bsModalRef: BsModalRef, sb: FormBuilder,
    private _staffService: StaffService,) {
  }


  getDefaultTaxRates () {
    var taxItem = this.taxRates.filter(e => e.TaxType == 'NONE');

    if (taxItem && taxItem[0]) {
      return taxItem[0]
    } else {
      return this.taxRates[0];
    }
  }
  ngOnInit() {


    const clientInfoCopy = _.cloneDeep(this.clientInfo);
    const invoiceInfoCopy = _.cloneDeep(this.sInvoiceInfo);
    //this.getStaffList();
    this.getServiceList();


    if (this.header != "Edit Invoice") {
      this.getInvoiceNumber();
    }
    this.officeName = localStorage.getItem("OfficeName");
    this.billingFromAddress = localStorage.getItem("BillStreet") + localStorage.getItem("BillingState")
      + localStorage.getItem("BillingCountry") + localStorage.getItem("BillingZip");


      this.taxRates = JSON.parse(localStorage.getItem("XeroTaxRates")) || [];

      this.taxServiceItem = this.getDefaultTaxRates();
      

    this._clientService.getClientInfo(this.clientInfo.Soid).subscribe((res: any) => {
      if (res) {
        this.clientInfo = res;
        this.billingToAddress = "Address: " + (this.clientInfo.HomeAddress ? this.clientInfo.HomeAddress + ', ' : '')
          + (this.clientInfo.HomeAddressCity ? this.clientInfo.HomeAddressCity + ', ' : '')
          + (this.clientInfo.HomeAddressState ? this.clientInfo.HomeAddressState + ', ' : '')
          + (this.clientInfo.ZipCode ? this.clientInfo.ZipCode + ', ' : '')         
          + this.clientInfo.HomeAddressCountry;
        this.billToName = this.clientInfo.NameFirst + ' ' + this.clientInfo.NameLast; 

        var billTo: any = {};
        billTo.code = "Client";
        billTo.name = this.clientInfo.NameFirst + ' ' + this.clientInfo.NameLast + ' (Client)';
        billTo.Soid = this.clientInfo.Soid;
        this.billingToSelected.code = "Client";
        this.billingToList.push(billTo);
        // this.clientDependent = this.clientInfo.Dependent.filter(w => w.Status == "Active" && w.DependentType == "Spouse");
     
        // this.clientDependent.forEach(element => {
        //   billTo = {};
        //   billTo.code = "Spouse";
        //   billTo.name = element.FirstName + ' ' + element.LastName + ' (Spouse)';
        //   billTo.Soid = element.Soid;
        //   this.billingToList.push(billTo);
        //  // element.MarriageAnniversaryDate =  element.MarriageAnniversaryDate ? new Date(element.MarriageAnniversaryDate) : null;
        // });

        // if (this.clientInfo.SpouseFirstName && this.clientInfo.SpouseLastName != "") {
        //   billTo = {};
        //   billTo.code = "Spouse";
        //   billTo.name = this.clientInfo.SpouseFirstName + ' ' + this.clientInfo.SpouseLastName + ' (Spouse)';
        //   this.billingToList.push(billTo);
        // }
        this.clientBusiness = this.clientInfo.BusinessInfo.filter(w => w.Status == "Active");
        
        this.clientBusiness.forEach(element => {
          billTo = {};
          billTo.code = "Business";
          billTo.name = element.CompanyName + ' (Business)';
          billTo.Soid = element.Soid;
          this.billingToList.push(billTo);
         // element.MarriageAnniversaryDate =  element.MarriageAnniversaryDate ? new Date(element.MarriageAnniversaryDate) : null;
        });

        if (invoiceInfoCopy && invoiceInfoCopy.Soid) {
          invoiceInfoCopy.Soid = this.header == 'Repeat Invoice' ? null : invoiceInfoCopy.Soid;
          this.invoiceServiceItems = invoiceInfoCopy.Items;          
          this.notes = invoiceInfoCopy.Notes;
          this.invoiceLabel = invoiceInfoCopy.Label;
          this.purchaseOrder = invoiceInfoCopy.PurchaseOrder;
          this.calculateSubTotal();

          let selectingBillingTo = this.billingToList.filter(w => w.Soid == invoiceInfoCopy.CustomerSoid);
         
          if (selectingBillingTo && selectingBillingTo[0]) {
            this.billingToSelected = selectingBillingTo[0];
          }
          if (this.header == "Edit Invoice") {
            this.invoiceIssueDate = invoiceInfoCopy.InvoicedOn;
            this.invoiceDueDate  = invoiceInfoCopy.DueOn;
            this.invoiceNo = invoiceInfoCopy.InvoiceNumber;
          }
        }
      }
    });



    this.serviceInvoiceForm = new FormGroup({
      invoiceLabel: new FormControl('', [Validators.required]),
      //currency: new FormControl('',[Validators.required]),
      invoiceNumber: new FormControl('', [Validators.required]),
      purchaseOrder: new FormControl(),
      issueDate: new FormControl('', [Validators.required]),
      dueDate: new FormControl('', [Validators.required]),
      notes: new FormControl(),
      serviceItem: new FormControl(),
      taxItem: new FormControl(),
      invoiceQuantity: new FormControl(),
      serviceUnitPrice: new FormControl(),
      servicePrice: new FormControl(),
      billingToAddress: new FormControl(),
      billingFromAddress: new FormControl(),
      billingToSelect: new FormControl(),
    });
  }


  createNewDocument() {
    for (let inner in this.serviceInvoiceForm.controls) {
      this.serviceInvoiceForm.get(inner).markAsTouched();
      this.serviceInvoiceForm.get(inner).updateValueAndValidity();
    }
    if (!this.serviceInvoiceForm.valid) {
      this.formValid = false;
      this.toast.warning("Fill the required fields.", "Warning!");
      return;
    }
    if (this.invoiceServiceItems && this.invoiceServiceItems.length == 0 ) {
      this.toast.warning("Please add service items.", "Warning!");
      return;
    }


    var requestInfo: any = {};
    requestInfo.StaffSoid = localStorage.getItem("UserSoid");
    requestInfo.StaffName = localStorage.getItem("NameFirst") + " " + localStorage.getItem("NameLast");
    requestInfo.InvoiceNumber = this.serviceInvoiceForm.controls.invoiceNumber.value;
    requestInfo.InvoicedOn = this.serviceInvoiceForm.controls.issueDate.value;
    requestInfo.DueOn = this.serviceInvoiceForm.controls.dueDate.value;
    requestInfo.Notes = this.serviceInvoiceForm.controls.notes.value;
    requestInfo.Label = this.serviceInvoiceForm.controls.invoiceLabel.value;
    requestInfo.Items = this.invoiceServiceItems;

    requestInfo.BillingFromAddress = localStorage.getItem("BillStreet");
    requestInfo.BillingFromState = localStorage.getItem("BillingState");
    requestInfo.BillingFromZip = localStorage.getItem("BillingZip");
    requestInfo.BillingFromCountry = localStorage.getItem("BillingCountry");    

    //requestInfo.BusinessName = this.clientInfo.CompanyName;

    
    requestInfo.BillingToEmail = this.clientInfo.Email;
    requestInfo.BillingFromFirstName = localStorage.getItem("OfficeName");
    requestInfo.BillingFromLastName = "";
    requestInfo.BillingFromEmail = "";
    requestInfo.Status = "ISSUED";
   

    if (this.billingToSelected.code == "Spouse") {
      let billingTo = this.clientDependent.filter(w => w.Soid == this.billingToSelected.Soid);

      requestInfo.BillingToFirstName = billingTo[0].FirstName;
      requestInfo.BillingToLastName = billingTo[0].LastName;

      requestInfo.BillingToAddress = this.clientInfo.HomeAddress;
      requestInfo.BillingToState = this.clientInfo.HomeAddressState;
      requestInfo.BillingToZip = this.clientInfo.ZipCode;
      requestInfo.BillingToCountry = this.clientInfo.HomeAddressCountry;
      requestInfo.BillingToCity = this.clientInfo.HomeAddressCity;

      requestInfo.CustomerSoid = billingTo[0].Soid;
    } else if (this.billingToSelected.code == "Client") {
      requestInfo.BillingToFirstName = this.clientInfo.NameFirst;
      requestInfo.BillingToLastName = this.clientInfo.NameLast;

      requestInfo.BillingToAddress = this.clientInfo.HomeAddress;
    requestInfo.BillingToState = this.clientInfo.HomeAddressState;
    requestInfo.BillingToZip = this.clientInfo.ZipCode;
    requestInfo.BillingToCountry = this.clientInfo.HomeAddressCountry;
    requestInfo.BillingToCity = this.clientInfo.HomeAddressCity;
    requestInfo.CustomerSoid = this.clientInfo.Soid;
    } else if (this.billingToSelected.code == "Business") {
      let billingTo = this.clientBusiness.filter(w => w.Soid == this.billingToSelected.Soid);
      requestInfo.BillingToFirstName = this.clientInfo.NameFirst;
      requestInfo.BillingToLastName = this.clientInfo.NameLast;
      requestInfo.BusinessName = billingTo[0].CompanyName;

      requestInfo.BillingToAddress = billingTo[0].Address;
      requestInfo.BillingToState = billingTo[0].State;
      requestInfo.BillingToZip = billingTo[0].ZipCode;
      requestInfo.BillingToCountry = billingTo[0].Country;
      requestInfo.BillingToCity = billingTo[0].City;
      requestInfo.CustomerSoid = billingTo[0].Soid;

    }
    requestInfo.BillingToSoid = this.clientInfo.Soid;
    requestInfo.Taxes = this.totalTax;
    requestInfo.PurchaseOrder = this.purchaseOrder;

    if (this.header == "Edit Invoice") {
      requestInfo.Soid = this.sInvoiceInfo.Soid;
      requestInfo.XeroStatus = this.sInvoiceInfo.XeroStatus;
      requestInfo.XeroReference = this.sInvoiceInfo.XeroReference;
    }
   

    this._invoiceService.createInvoice(this.clientInfo.Soid, requestInfo).subscribe((res: any) => {
      if (res && res.Soid != null) {
        this.toast.success("Invoice created successfully.", "Success!");
        this.header = "";
        this._router.navigate(['/client/' + this.clientInfo.Soid + '/invoice/' + res.Soid]);
        this.triggerEvent();

        this.bsModalRef.hide();
      } else {
        this.toast.warning("Error Creating service", "Error!");
      }
    });

  }

  private getServiceList() {
    this._service.getServicesList(localStorage.getItem("OfficeSoid")).subscribe((res: any) => {
      if (res) {
        this.serviceList = res;
      }
    });
  }

  private getInvoiceNumber() {
    this._invoiceService.getInvoiceNumber().subscribe((res: any) => {
      if (res) {
        this.invoiceNo = res;
      }
    });
  }

  unitPriceUpdate(quantity, lineItem) {
  

    this.invoiceServiceItems
    .filter(item => {
      if (item.ListItemSoid == lineItem.ListItemSoid) {
        item.Quantity = quantity;
        item.Price = parseFloat(item.UnitPrice) * parseFloat(item.Quantity);
        if (item.TaxPercentage > 0) {
          item.TaxAmount = Number(Number(item.Price * (item.TaxPercentage/100)).toFixed(2));
        } else {
          item.TaxAmount = 0;
        }
          return true; // Include the modified object in the new array
      }
      return true; // Include all other objects in the new array without modification
  });
  this.calculateSubTotal();

  }

  addInvoiceItem() {
    if (this.invoiceServiceItem.UnitPrice == undefined) {
      return;
    }

    this.invoicePreListItems = this.serviceInvoiceForm.controls.serviceItem.value;
    if (this.invoicePreListItems != null && this.invoicePreListItems == "") return;

    var isInvoiceExists = false;
    this.invoiceServiceItems
    .filter(item => {
      if (item.ListItemSoid == this.invoicePreListItems.Soid) {
        item.Quantity = item.Quantity + this.serviceInvoiceForm.controls.invoiceQuantity.value;
        item.Price = parseFloat(item.UnitPrice) * parseFloat(item.Quantity);

       
        isInvoiceExists = true;
          return true; // Include the modified object in the new array
      }
      return true; // Include all other objects in the new array without modification
  });
    if (!isInvoiceExists) {
      var invoiceItem: any = {};
      invoiceItem.Item = this.invoicePreListItems.Item;
      invoiceItem.UnitPrice = this.invoicePreListItems.UnitPrice;
      invoiceItem.Quantity = this.serviceInvoiceForm.controls.invoiceQuantity.value;
      invoiceItem.Price = parseFloat(invoiceItem.UnitPrice) * parseFloat(invoiceItem.Quantity);
      invoiceItem.ModuleName = this.invoicePreListItems.ModuleName;
      invoiceItem.ListItemSoid = this.invoicePreListItems.Soid;
      invoiceItem.TaxType = this.taxServiceItem.TaxType;

      invoiceItem.TaxType = this.taxServiceItem.TaxType;
      if (this.taxServiceItem.EffectiveRate > 0) {
        invoiceItem.TaxAmount = Number(Number(invoiceItem.Price * (this.taxServiceItem.EffectiveRate/100)).toFixed(2));
      } else {
        invoiceItem.TaxAmount = 0;
      }
      
      invoiceItem.TaxPercentage = this.taxServiceItem.EffectiveRate;
      this.invoiceServiceItems.push(invoiceItem);
    }
    this.calculateSubTotal();
    this.invoiceQuantity = 1;
    this.invoiceServiceItem = {};
    this.invoicePrice = null;
    this.taxServiceItem = this.getDefaultTaxRates();
  }

  deleteConfirm(item) {
    const initialState = {
      confirmMessage: "Are you sure to delete the invoice item?",
      backdrop: true,
      ignoreBackdropClick: true
    };
    this.modalRef = this.modalService.show(ConfirmationDialog, { initialState });
    this.modalRef.content.event.subscribe(data => {
      if (data) {
        this.deleteItem(item);
      }
    });

  }

  deleteItem(item) {
    const indexToRemove = this.invoiceServiceItems.findIndex(item => item.ListItemSoid === item.ListItemSoid);

    if (indexToRemove !== -1) {
        this.invoiceServiceItems.splice(indexToRemove, 1);
    }
    this.calculateSubTotal();
  }


 

  calculateSubTotal() {
    var subTotal = 0;
    this.invoiceServiceItems.forEach(element => {
      if (subTotal == 0) {
        subTotal = element.Price;
      } else {
        subTotal += element.Price;
      }
    });
    this.subTotal = subTotal;
    this.totalTax = _.sumBy(this.invoiceServiceItems, 'TaxAmount');
    this.invoiceTotal = this.totalTax + this.subTotal;
  }

  triggerEvent() {
    this.event.emit();
  }



  serviceChange() {
    this.invoicePrice = this.invoiceServiceItem.UnitPrice * this.invoiceQuantity;
    this.changeUnitPrice = this.invoiceServiceItem.PriceType == 'Flexi Fee';
  }

  changeBillingTo() {

    if (this.billingToSelected.code == "Spouse") {
      let billingTo = this.clientDependent.filter(w => w.Soid == this.billingToSelected.Soid);
      this.billToName = billingTo[0].FirstName + ' ' + billingTo[0].LastName;      
      this.billingToAddress = "Address: " + (this.clientInfo.HomeAddress ? this.clientInfo.HomeAddress + ', ' : '')
          + (this.clientInfo.HomeAddressCity ? this.clientInfo.HomeAddressCity + ', ' : '')
          + (this.clientInfo.HomeAddressState ? this.clientInfo.HomeAddressState + ', ' : '')
          + (this.clientInfo.ZipCode ? this.clientInfo.ZipCode + ', ' : '')        
          + (this.clientInfo.HomeAddressCountry ? this.clientInfo.HomeAddressCountry + ', ' : '') ;
    } else if (this.billingToSelected.code == "Client") {
      this.billToName = this.clientInfo.NameFirst + ' ' + this.clientInfo.NameLast; 
      this.billingToAddress = "Address: " + (this.clientInfo.HomeAddress ? this.clientInfo.HomeAddress + ', ' : '')
          + (this.clientInfo.HomeAddressCity ? this.clientInfo.HomeAddressCity + ', ' : '')
          + (this.clientInfo.HomeAddressState ? this.clientInfo.HomeAddressState + ', ' : '')
          + (this.clientInfo.ZipCode ? this.clientInfo.ZipCode + ', ' : '')         
          + (this.clientInfo.Country ? this.clientInfo.Country + ', ' : '');      
    } else if (this.billingToSelected.code == "Business") {
      let billingTo = this.clientBusiness.filter(w => w.Soid == this.billingToSelected.Soid);
      this.billToName = billingTo[0].CompanyName;
      this.billingToAddress = "Address: " + (billingTo[0].Address ? billingTo[0].Address + ', ' : '')
          + (billingTo[0].City ? billingTo[0].City + ', ' : '')
          + (billingTo[0].State ? billingTo[0].State + ', ' : '')
          + (billingTo[0].ZipCode ? billingTo[0].ZipCode + ', ' : '')         
          + (billingTo[0].Country ? billingTo[0].Country + ', ' : '');  
    }

  }


}
