/// <reference path="../../../../node_modules/@types/googlemaps/index.d.ts"/>
import { Component, OnInit, ViewChild, Input, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormBuilder, FormControl, FormGroup, Validators, FormControlName } from '@angular/forms';
import { StaffService } from '../../services/staff.service';
import { ServicesListService } from '../../services/services.service';
import { EventsService } from '../../services/events.service';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { OfficeService } from 'src/app/services/office.service';
import { ClientService } from 'src/app/services/client.service';
import { EventTypes } from 'src/app/enum/eventTypes';


@Component({
  selector: 'app-addplanner',
  templateUrl: './eventplanner.component.html',
  styleUrls: ['./eventplanner.component.css']
})
export class EventPlannerComponent implements OnInit {
  public titles;
  public header: string;
  public services: any = [];
  public selectedDate: string;
  public selectedStaff: string;
  public selectedService: string;

  eventForm: FormGroup;
  isRepeatChecked: boolean = false;
  closeBtnName: string = "Close Planner"
  map: google.maps.Map;
  meetingLocation: string;
  //services: string[] = ["2018 Tax return", "In-office appointment", "30 minutes phone call", "Introductory Tax Preparation Phone Call"]
  isEditable: boolean = false;
  selectedDetailType: string;
  meetingAddress: string;
  staffList: any = [];
  officeAddress: any = [];
  serviceList: any = [];
  @Input('meetingLoc') meetingLoc: any;
  clientInfo: any = {};
  serviceRequest: number;
  startEventDate: string;
  endEventDate: string;
  startDateTime: string = "";
  endDateTime: string;
  staff: string;
  description: string;
  showAddress: boolean = false;
  showLMSAddress: boolean = false;
  showCountryCode: boolean = false;
  serviceFeeType: string;
  servicePrice: string;
  formValid: boolean = true;
  occurenceType: string = "Daily";
  //seasons: string[] = ['Days', 'Weeks', 'Monthly'];
  seasons: string[] = ['Daily', 'Weekly'];
  hours: string[];
  serviceFeeArray: string[] = ["Paid - Display Fee only(No online payment)", "Paid - Suggest to pay at booking", "Paid - Require to pay at booking"];
  places: string[] = ['First', 'Second', 'Third', 'Fourth'];
  weekdays: string[] = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  startTimeList: any = [];
  endTimeList: any = [];
  dailyInterval: number;
  isWeekDays: number;
  recurWeek: number;
  occurenceCount: number;
  cServiceName: string;
  staffIds: string;
  showUSDPrice: boolean = false;
  Where: string = "Meeting Location";
  public event: EventEmitter<any> = new EventEmitter();
  servicePriceUSD: string;
  showBusinessAddresses: boolean = false;
  businessAddresses: any = [];
  eventTypes = EventTypes;

  constructor(private _serviceList: ServicesListService,
    private datePipe: DatePipe,
    private _router: Router,
    private toast: ToastrService,
    private _eventsService: EventsService,
    private _officeService: OfficeService,
    private _staffService: StaffService,
    public bsModalRef: BsModalRef,
    private fb: FormBuilder,
    private _clientService: ClientService) { }

  ngOnInit() {
    this.startTimeList = this.getHoursList(0);
    this.endTimeList = this.getHoursList(0);
    if (this.selectedDate != null && this.selectedDate != undefined) {
      this.startEventDate = this.selectedDate;
      this.endEventDate = this.selectedDate
    }
    if (this.selectedStaff != null && this.selectedStaff != undefined) {
      this.staff = this.selectedStaff;
    }
    if (this.selectedService != null && this.selectedService != undefined) {
      this.serviceRequest = parseFloat(this.selectedService);
    }
    /**this.eventForm = new FormGroup({
      detailsType: new FormControl('', [Validators.required]),
      repeatChecked: new FormControl(''),
      meetingLocation: new FormControl('', [Validators.required]),
      serviceFeeType: new FormControl('', [Validators.required]),
      servicePrice: new FormControl('', [Validators.required]),
      startEventDate: new FormControl('', [Validators.required]),
      endEventDate: new FormControl('', [Validators.required]),
      description: new FormControl('', [Validators.required]),
      serviceRequest: new FormControl('', [Validators.required]),
      staff: new FormControl('', [Validators.required]),
      occurenceType: new FormControl(false),
      dailyInterval:  new FormControl(false),
      isWeekDays:  new FormControl(false),
      startDateTime: new FormControl('', [Validators.required]),
      endDateTime: new FormControl('', [Validators.required]),
      recurWeek: new FormControl(false),
      occurenceCount: new FormControl(false),
      customServiceName: new FormControl(false)
    });*/

    this.eventForm = new FormGroup({
      detailsType: new FormControl(''),
      repeatChecked: new FormControl(''),
      meetingLocation: new FormControl(''),
      serviceFeeType: new FormControl(''),
      servicePrice: new FormControl(''),
      startEventDate: new FormControl('', [Validators.required]),
      endEventDate: new FormControl('', [Validators.required]),
      description: new FormControl('', [Validators.required]),
      serviceRequest: new FormControl(''),
      staff: new FormControl('', [Validators.required]),
      occurenceType: new FormControl(false),
      dailyInterval: new FormControl(false),
      isWeekDays: new FormControl(false),
      startDateTime: new FormControl('', [Validators.required]),
      endDateTime: new FormControl('', [Validators.required]),
      recurWeek: new FormControl(false),
      occurenceCount: new FormControl(false),
      customServiceName: new FormControl(false)
    });
    this.getStaffList();
    this.getServiceList();
    this.getOfficeAddress();
    this.getBusinessAddresses();
  }

  showOptions(event, value) {
  }

  editDetails() {
    this.isEditable = false;
  }

  saveDetails() {
    this.isEditable = true;
  }

  discardChanges() {
    this.eventForm.reset();
    this.isEditable = true;
  }

  getAddress(event) {
    this.meetingAddress = event.name;
  }

  private getStaffList() {
    this._staffService.getActiveStaffs().subscribe((res: any) => {
      if (res) {
        this.staffList = res;
      } else {
        this.staffList = [];
      }
    });
  }

  private getOfficeAddress() {
    this._officeService.getOfficeAddress(localStorage.getItem("OfficeSoid")).subscribe((res: any) => {
      if (res) {
        this.officeAddress = res;
      } else {
        this.officeAddress = [];
      }
    });
  }

  private getBusinessAddresses() {
    this._clientService.getClientInfo(this.clientInfo.Soid).subscribe((res: any) => {
      if (res) {
        this.businessAddresses = res.BusinessInfo;
      } else {
        this.businessAddresses = [];
      }
    });
  }

  private getServiceList() {
    this._serviceList.getServicesList(localStorage.getItem("OfficeSoid")).subscribe((res: any) => {
      if (res) {
        this.serviceList = res;
        let modules = this.clientInfo.Tags;
        // this.serviceList = res.filter(function (tag) {
        //   return modules.split(",").indexOf(tag.ModuleSoid) >= 0;
        // });
        if (this.serviceList.length == 0) {
          this.toast.warning("No services available", "Warning!");
        }
      }
    });
  }

  serviceFeeChange() {

    this.showUSDPrice = false;
    if (this.serviceFeeArray.indexOf(this.serviceFeeType) >= 0) {
      this.showUSDPrice = true;
    }
  }


  serviceChange() {
    var service = this.serviceList.filter(proj => proj.serviceId === this.serviceRequest);
    /*if (service && service[0]) {
      this.selectedDetailType =  service[0].locationType;
      this.servicePrice =  service[0].price;
      if (this.selectedDetailType === "Phone - client calls you") {
        this.meetingLocation =  service[0].locationValue;
        this.showAddress = false;
      } else if (this.selectedDetailType === "In Person - at your location") {
        this.meetingLocation =  service[0].locationValue;
        this.showAddress = false;
      } else if (this.selectedDetailType === "Phone - you call the client") {
        this.showAddress = true;
      } else if (this.selectedDetailType === "In Person- at Client's location") {
        this.showAddress = true;
      }
    }**/
  }

  private getHoursList(startTime) {
    var x = 30; //minutes interval
    var times = []; // time array
    var tt = startTime; // start time
    var ap = ['AM', 'PM']; // AM-PM

    //loop to increment the time and push results in array
    for (var i = 0; tt < 24 * 60; i++) {
      var hh = Math.floor(tt / 60); // getting hours of day in 0-24 format
      var mm = (tt % 60); // getting minutes of the hour in 0-55 format
      times[i] = ("0" + hh).slice(-2) + ':' + ("0" + mm).slice(-2);
      tt = tt + x;
    }

    return times;
  }
  createEvent() {
    if (!this.eventForm.valid) {
      for (let inner in this.eventForm.controls) {
        this.eventForm.get(inner).markAsTouched();
        this.eventForm.get(inner).updateValueAndValidity();
      }
      this.formValid = false;
      this.toast.warning("Fill the required fields.", "Warning!");
      return;
    }



    var eventData: any = {};
    eventData.serviceRequest = this.serviceRequest;
    eventData.startTime = this.transformDateFormat(this.startEventDate, this.startDateTime);
    eventData.endTime = this.transformDateFormat(this.endEventDate, this.endDateTime);
    eventData.staffLoginId = this.staff;
    eventData.clientLoginId = this.clientInfo.loginId;
    eventData.description = this.description;
    eventData.meetingType = this.selectedDetailType;
    var staffInfo = this.getStaffInfo(this.staff);
    eventData.staffEmail = staffInfo[0].EmailAddress;
    eventData.clientEmail = this.clientInfo.email;
    eventData.serviceFeeType = this.serviceFeeType;
    eventData.servicePrice = this.servicePrice;
    eventData.meetingLocation = this.showAddress ? this.meetingAddress : this.meetingLocation;
    eventData.isRepeatChecked = this.isRepeatChecked;

    eventData.occurenceType = "";
    eventData.occurenceCount = "";
    eventData.dailyInterval = "";
    eventData.isWeekDays = "";
    eventData.recurWeek = "";

    if (this.isRepeatChecked) {
      eventData.occurenceType = this.occurenceType;
      eventData.occurenceCount = this.occurenceCount;
      if (this.occurenceType === "Daily") {
        if (this.isWeekDays == 2) {
          eventData.isWeekDays = this.isWeekDays;
        } else {
          eventData.dailyInterval = this.dailyInterval;
        }
      } else {
        eventData.recurWeek = this.recurWeek;
      }
    }

    // eventData.serviceRequest = tserviceRequest;

    var eventInfo: any = {};
    eventInfo.CreatedBy = localStorage.getItem("UserSoid");
    eventInfo.OfficeSoid = localStorage.getItem("OfficeSoid");
    eventInfo.OfficeName = localStorage.getItem("OfficeName");
    eventInfo.Subject = this.description;
    eventInfo.Category = "Customer";
    eventInfo.TimeSet = {};
    eventInfo.TimeSet.Start = this.transformDateFormat(this.startEventDate, this.startDateTime);
    eventInfo.TimeSet.End = this.transformDateFormat(this.endEventDate, this.endDateTime);
    eventInfo.TimeSet.WholeDay = false;
    eventInfo.Participants = [];
    var participantInfo: any = {};
    participantInfo.UserSoid = staffInfo[0].Soid;
    participantInfo.UserName = staffInfo[0].NameFirst + ' ' + staffInfo[0].NameLast;
    participantInfo.UserType = "Staff";
    eventInfo.Participants.push(participantInfo);
    participantInfo = {};
    participantInfo.UserSoid = this.clientInfo.Soid;
    participantInfo.UserName = this.clientInfo.NameFirst + ' ' + this.clientInfo.NameLast;
    participantInfo.UserType = "Customer";
    eventInfo.Participants.push(participantInfo);


    eventInfo.ServiceFeeType = this.serviceFeeType;
    eventInfo.ServicePrice = parseFloat(this.servicePriceUSD  == null || this.servicePriceUSD == '' ? '0' : this.servicePriceUSD);
    eventInfo.MeetingLocation = this.showAddress ? this.meetingAddress : this.meetingLocation;
    eventInfo.MeetingType = this.selectedDetailType;




    this._eventsService.createEvent(eventInfo).subscribe((res: any) => {
      if (res && res.Soid) {
        this.bsModalRef.hide();
        this.triggerEvent(res.Soid);
        this.showAppointmentView(res.Soid);
      }
    });

  }

  showAppointmentView(appointmentSoid) {
    this._router.navigate(['/appointment/' + appointmentSoid]);
  }

  triggerEvent(soid) {
    this.event.emit({ soid: soid });
  }

  getStaffInfo(loginId: String) {
    return this.staffList.filter(proj => proj.PersonSoid === loginId);
  }

  typeChange() {
    this.meetingLocation = "";
    this.showAddress = false;
    this.showCountryCode = false;
    this.showLMSAddress = false;
    this.showBusinessAddresses = false;
    this.Where = "tesing";
    switch (this.selectedDetailType) {
      case this.eventTypes.PhoneClientCalls:
        var staffInfo = this.getStaffInfo(this.staff);
        this.meetingLocation = staffInfo[0].PhoneNumber;
        this.showCountryCode = true;
        this.Where = "Staff Mobile Number";
      break;
      case this.eventTypes.InPersonAtYours:
        this.meetingLocation = "";
        this.showLMSAddress = true;
        this.Where = "Staff Location";
      break;
      case this.eventTypes.PhoneYouCall:
        this.meetingLocation = this.clientInfo.PersonalMobile;
        this.showCountryCode = true;
        this.Where = "Client Mobile Number";
      break;
      case this.eventTypes.InPersonAtClients:
        this.showBusinessAddresses = true;
        this.Where = "Client Location";
      break;
      case this.eventTypes.Skype:
        this.Where = "Staff Skype Id";
      break;
      case this.eventTypes.OnlineOther:
        this.Where = "Online Meeting URL";
      break;
    }

  }

  endEventDateChange() {
    this.endEventDate = this.startEventDate;
  }

  transformDateFormat(dateValue, time) {
    var eventTime = time.split(":");
    let date = new Date(dateValue);
    date.setHours(eventTime[0]);
    date.setMinutes(eventTime[1]);
    return date;
  }

  startDateTimeChange() {
    //if (this.startEventDate === this.endEventDate){
    this.endDateTime = "";
    var eventTime: any = [];
    eventTime = this.startDateTime.split(":");
    if (eventTime[1] === "30") {
      this.endTimeList = this.getHoursList((eventTime[0] * 60) + 60);
    } else {
      this.endTimeList = this.getHoursList((eventTime[0] * 60) + 30);
    }
    // } else {
    //   this.endTimeList = this.getHoursList((eventTime[0] * 60) + 30);
    // }
  }


}

