import { FormBuilder, FormGroup } from '@angular/forms';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
//import {PageEvent} from '@angular/material/paginator/api/pageevent';
import { ClientService } from '../../../services/client.service';
import { MasterService } from '../../../services/master.service';
import { USStatesService } from '../../../services/us-states.service';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { EventPlannerComponent } from '../../../base/eventplanner/eventplanner.component';
import * as _ from 'underscore';
import { SearchclientComponent } from '../../modal/searchclient/searchclient.component';
import { AuthService } from '../../../services/auth.service';
import { OfficeService } from '../../../services/office.service';
import { Country } from '@angular-material-extensions/select-country';
import { PageEvent } from '@angular/material/paginator';
import {combineLatest} from 'rxjs';
import {ModulesService} from '../../../services/modules.service';
import {flatten} from 'lodash';
@Component({
  selector: 'app-add-client',
  templateUrl: './add-client.component.html',
  styleUrls: ['./add-client.component.css']
})
export class AddClientComponent implements OnInit {

  office = new FormControl();
 showRequired : boolean = true;
  public source: string;
  defaultValue: Country
  searchText: string;
  myForm: FormGroup;
  clientSubmitForm: FormGroup;
  disabled = false;
  ShowFilter = false;
  limitSelection = false;
  selectedItems: any = [];
  dropdownSettings: any = {};
  lowValue: number = 0;
  highValue: number = 5;
  clientRequest: any = {};
  firstName: string;
  middleName: string;
  lastName: string;
  personalEmail: string;
  businessEmail: string;
  formValid: boolean = true;
  modules: any = [];
  agelimit: Date;
  sortBy: string = "";
  clientRequestInfo: any = {};
  idTypes: string[];
  idType: string;
  countries: string[] = [
    'Canada', 'Mexico', 'United States'
  ];
  martialStatus: string[];
  languagePreference: string[];
  hearAboutUs: string[];
  moduleControl = new FormControl('', Validators.compose([
    Validators.required
  ]));
  toppingList: string[];
  currentDate: Date;
  hearAboutUsSelect: string;
  businessMobile: string;
  personalMobile: string;
  clientStatus: any = [];
  phoneNumberCountryCode: number;

  phoneForm = new FormGroup({
    mobilePhone: new FormControl('')

 });
 filterBy: string = "Active";

 officeList: any = [];
 selectedOffices: any[] = [];
 hiddenSSN: string;
  @ViewChild('phoneField')
  public phoneField;

  @ViewChild('businessphoneField')
  public businessphoneField;

  clientList: any = [];
  usStates: any = [];

  // MatPaginator Inputs
  clientLength = Number;
  pageIndex: number = 0;
  clientPageSize = 5;
  clientPageSizeOptions: number[] = [5, 10, 25, 100];
  customPatterns = { '0': { pattern: new RegExp('([A-Za-z0-9_-]+)') } };



  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);

  clientMask="09/09/0000";

  separateDialCode = false;
  isAdmin: boolean = false;

  constructor(private _authService: AuthService, private masterService: MasterService, private usStatesService: USStatesService,
    private toast: ToastrService, private datePipe: DatePipe, private _clientService: ClientService,  public modalRef: BsModalRef,
    private _officeService: OfficeService,
    private modalService: BsModalService, private _router: Router, private fb: FormBuilder,
    private _moduleService: ModulesService,
  ) { }

  ngOnInit() {
    this._authService.getUserRole().subscribe( (res: string) => {
      if (res) {
        const permissions = this._authService.getPermissions(res);
        this.isAdmin = permissions.isAdmin;
      }
    }, (err) => {
      this.toast.error(err.message)
    });
    var date = new Date();
    date.setFullYear(date.getFullYear() - 18);
    this.agelimit = date;
    this.currentDate = new Date();
    this.usStates = this.usStatesService.getAllStates();

    this.defaultValue = {
      name: 'United States of America',
      alpha2Code: 'US',
      alpha3Code: 'USA',
      numericCode: '840',
      callingCode: '+1'
    };

    this.martialStatus = this.masterService.getMasterItems("MARTIALSTATUS");
    this.hearAboutUs = this.masterService.getMasterItems("REFERRAL");
    this.languagePreference = this.masterService.getMasterItems("LANGUAGEPREFERENCE");
    this.clientStatus = this.masterService.getMasterItems("CLIENTSTATUS");
    this.idTypes = this.masterService.getMasterItems("IDPROOFTYPE");

    this.clientSubmitForm = new FormGroup({
      businessAddressCountry: new FormControl('', Validators.compose([
        //   Validators.required
      ])),
      office: new FormControl('', Validators.compose([Validators.required])),
      homeAddressCountry: new FormControl('', Validators.compose([
        //   Validators.required
      ])),
      addressLine1: new FormControl('', Validators.compose([
        //   Validators.required
      ])),
      addressLine2: new FormControl('', Validators.compose([
      ])),
      email: new FormControl('', Validators.compose([
        Validators.required, Validators.email
      ]))
      , firstName: new FormControl('', Validators.compose([
        Validators.required
      ])), middleName: new FormControl('', Validators.compose([
        //  Validators.required
      ])), lastName: new FormControl('', Validators.compose([
        Validators.required
      ]))
      , dob: new FormControl('', Validators.compose([
         Validators.required
      ])), tag: new FormControl('', Validators.compose([
        //Validators.required
      ])), status: new FormControl('', Validators.compose([
        Validators.required
      ])), homeAddress: new FormControl('', Validators.compose([
        //Validators.required
      ])), companyName: new FormControl('', Validators.compose([
        // Validators.required
      ])), businessAddress: new FormControl('', Validators.compose([
        //  Validators.required
      ])), businessEmail: new FormControl('', Validators.compose([Validators.email
        //    Validators.required
      ])), martialStatus: new FormControl('', Validators.compose([
            Validators.required
      ])), mobilePhone: new FormControl('', Validators.compose([Validators.required
        //    Validators.required
      ])),
       websiteURL: new FormControl('', Validators.compose([
        //    Validators.required
      ])), lmsMemberDate: new FormControl(''),
      languagePreference: new FormControl('', Validators.compose([
        //    Validators.required
      ])), hearAboutUs: new FormControl('', Validators.compose([
        //    Validators.required
      ])), zipCode: new FormControl('', Validators.compose([
        //    Validators.required
      ])), homeAddressState: new FormControl('', Validators.compose([
        //    Validators.required
      ])), idType: new FormControl('', Validators.compose([
            Validators.required
      ])), dlNumber: new FormControl('', Validators.compose([
        //    Validators.required
      ])), dlState: new FormControl('', Validators.compose([
        //    Validators.required
      ])), dlExpirationDate: new FormControl('', Validators.compose([
        //    Validators.required
      ])), dlStartDate: new FormControl('', Validators.compose([
        //    Validators.required
      ])), ssn: new FormControl('', Validators.compose([
        //    Validators.required
      ])), einNumber: new FormControl('', Validators.compose([
        //    Validators.required
      ])), itinNumber: new FormControl('', Validators.compose([
        //    Validators.required
      ])), businessStartDate: new FormControl('', Validators.compose([
        //    Validators.required
      ])), numberOfEmployees: new FormControl('', Validators.compose([
        //    Validators.required
      ])), businessZipCode: new FormControl('', Validators.compose([
        //    Validators.required
      ])), businessState: new FormControl('', Validators.compose([
        //    Validators.required
      ])), phone: new FormControl('', Validators.compose([
        //    Validators.required
      ])), otherHearAboutUs: new FormControl('', Validators.compose([
        //    Validators.required
      ])), actualSSN: new FormControl('', Validators.compose([
        //    Validators.required
      ])),passportNumber: new FormControl('', Validators.compose([
        //    Validators.required
      ])), passportCountry: new FormControl('', Validators.compose([
        //    Validators.required
      ])), passportIssueDate: new FormControl('', Validators.compose([
        //    Validators.required
      ])), passportExpirationDate: new FormControl('', Validators.compose([
        //    Validators.required
      ]))
    });

    this.myForm = this.fb.group({
      city: [this.selectedItems]
    });

    if (this.source == "APPOINTMENT") {
      this.showDefaultForm();
      this.showRequired = false;
    }

    this.getOfficeList();
  }

  filterClientList(event) {
    this.filterBy = event.srcElement.value;
  //  this.getClientList();

  }

  private getOfficeList() {
    this._officeService.getOfficeListByUser(localStorage.getItem("UserSoid")).subscribe((res: any) => {
      if (res) {
        this.officeList = res.Office;
      } else {
        this.officeList = [];
      }
    });
  }

  private officeChange() {
    if (this.selectedOffices.length) {
      combineLatest(this.selectedOffices.map((office) => this._moduleService.getAllOfficeModules(office.Soid)))
        .subscribe({
          next: (modules: any[]) => {
            const officeModules = flatten(modules);
            officeModules.forEach(module => {
              if (!this.modules.find(m => m.Soid === module.Soid && m.Name === module.Name) &&
                officeModules.filter(m => m.Soid === module.Soid && m.Name === module.Name).length === this.selectedOffices.length) {
                this.modules.push(module);
              }
            });
          },
        });
    }
  }

  private getClientList() {
    this._clientService.getClientListFilterBy(this.filterBy).subscribe((res: any) => {
      if (res) {
        this.clientLength = res.length;
        this.clientList = res;
      }
    });
  }

  public getClientPaginatorData(event: PageEvent): PageEvent {
    this.lowValue = event.pageIndex * event.pageSize;
    this.highValue = this.lowValue + event.pageSize;
    return event;
  }

  openModal(template: TemplateRef<any>) {
    this.martialStatus = this.masterService.getMasterItems("MARTIALSTATUS");
    this.hearAboutUs = this.masterService.getMasterItems("REFERRAL");
    this.languagePreference = this.masterService.getMasterItems("LANGUAGEPREFERENCE");
    this.clientStatus = this.masterService.getMasterItems("CLIENTSTATUS");
    this.idTypes = this.masterService.getMasterItems("IDPROOFTYPE");
	  this.clientSubmitForm.reset();
   // this.modalRef = this.modalService.show(template);
    this.showDefaultForm();
  }

  showDefaultForm() {
    this.clientSubmitForm.reset();
    this.updateValidators();

    const dob = this.clientSubmitForm.controls.dob;
    const status = this.clientSubmitForm.controls.status;
    this.moduleControl.setValidators(null);

    dob.setValidators(null);
    status.setValidators(null);

  }

  editClient(clientId) {
    this._router.navigate(['client/' + clientId]);
  }

  openPlannerModal() {


    const initialState = {
      title: "Client search",
      backdrop: true,
      ignoreBackdropClick: true,
      closeBtnName: "Close",
    };
    this.modalRef = this.modalService.show(SearchclientComponent, { initialState });
    /**  this.modalRef = this.modalService.show(EventPlannerComponent);
      this.modalRef.content.event.subscribe(data => {

     }); */
  }

  addClient() {

  }

  sortFieldChange(event) {
    this.sortBy = event.srcElement.value;
    this.clientList = _.sortBy(this.clientList, this.sortBy);
  }

  onItemSelect(item: any) { }
  onSelectAll(items: any) {
  }
  toogleShowFilter() {
    this.ShowFilter = !this.ShowFilter;
    this.dropdownSettings = Object.assign({}, this.dropdownSettings, { allowSearchFilter: this.ShowFilter });
  }

  handleLimitSelection() {
    if (this.limitSelection) {
      this.dropdownSettings = Object.assign({}, this.dropdownSettings, { limitSelection: 2 });
    } else {
      this.dropdownSettings = Object.assign({}, this.dropdownSettings, { limitSelection: null });
    }
  }

  getShortName(fullName) {
    return fullName.split(' ').map(n => n[0]).join('');
  }

  convertToArray(valueText) {
    return valueText.split(",");
  }

  saveClient() {
    this.moduleControl.markAsTouched();
    this.moduleControl.updateValueAndValidity();
    for (let inner in this.clientSubmitForm.controls) {
      this.clientSubmitForm.get(inner).markAsTouched();
      this.clientSubmitForm.get(inner).updateValueAndValidity();
    }
    if (!this.clientSubmitForm.valid || !this.moduleControl.valid) {
      this.formValid = false;
      this.toast.warning("Fill the required fields.", "Warning!");
      return;
    }


    this.clientRequest.EmailAddress = this.clientSubmitForm.controls.email.value;
    this.clientRequest.Email = this.clientSubmitForm.controls.email.value;
    var countryCode = this.phoneNumberCountryCode ? '+' + this.phoneNumberCountryCode : '+1';
    this.clientRequest.PersonalMobile = countryCode + this.personalMobile;
    this.clientRequest.NameFirst = this.clientSubmitForm.controls.firstName.value;
    this.clientRequest.NameLast = this.clientSubmitForm.controls.lastName.value;

    //this.clientRequest.NameMiddle = this.clientSubmitForm.controls.middleName.value;

    if (this.clientSubmitForm.controls.dob.value) {
      this.clientRequest.DateOfBirth = this.datePipe.transform(this.clientSubmitForm.controls.dob.value, 'MM/dd/yyyy');
    } else {
      this.clientRequest.DateOfBirth = "";
    }

    this.clientRequest.MartialStatus = this.clientSubmitForm.controls.martialStatus.value;

    this.clientRequest.IDType = this.clientSubmitForm.controls.idType.value;


    if (this.clientRequest.IDType == "Driving License") {
      this.clientRequest.DLState = this.clientSubmitForm.controls.dlState.value;
      this.clientRequest.DLNumber = this.clientSubmitForm.controls.dlNumber.value;
      this.clientRequest.DLExpirationDate = this.datePipe.transform(this.clientSubmitForm.controls.dlExpirationDate.value, 'MM/dd/yyyy');
      this.clientRequest.DLStartDate = this.datePipe.transform(this.clientSubmitForm.controls.dlStartDate.value, 'MM/dd/yyyy');
    } else if (this.clientRequest.IDType == "ITIN Number") {
      this.clientRequest.ITINNumber = this.clientSubmitForm.controls.itinNumber.value;
    } else if (this.clientRequest.IDType == "Passport") {
      this.clientRequest.PassportNumber = this.clientSubmitForm.controls.passportNumber.value;
      this.clientRequest.PassportExpirationDate = this.datePipe.transform(this.clientSubmitForm.controls.passportExpirationDate.value, 'MM/dd/yyyy');
      this.clientRequest.PassportIssueDate = this.datePipe.transform(this.clientSubmitForm.controls.passportIssueDate.value, 'MM/dd/yyyy');
      this.clientRequest.PassportCountry = this.clientSubmitForm.controls.passportCountry.value.name;
      this.clientRequest.PassportCountryAplhaCode = this.clientSubmitForm.controls.passportCountry.value.alpha2Code;
    } else {
      this.clientRequest.SSN = btoa(this.hiddenSSN);
    }

    if (this.clientSubmitForm.controls.lmsMemberDate.value) {
      this.clientRequest.LmsMemberDate = this.datePipe.transform(this.clientSubmitForm.controls.lmsMemberDate.value, 'MM/dd/yyyy');
    } else {
      this.clientRequest.LmsMemberDate = "";
    }

    var tag = "";
    var tags = this.moduleControl.value;
    if (tags) {
      tags.forEach(function (element) {
        if (tag === "") {
          tag = element.itemId;
        } else {
          tag = tag + "," + element.itemId;
        }
      });
      this.clientRequest.Tags = tag;
    }

    if (this.clientSubmitForm.controls.status.value) {
      this.clientRequest.Status = this.clientSubmitForm.controls.status.value;
    } else {
      this.clientRequest.Status = "Active"
    }



    this.clientRequest.OfficeSoid = this.selectedOffices.map(office => office.Soid);
    this.clientRequest.Realm = "World";
    this.clientRequest.Role = "User";

    this._clientService.saveClientInfo(this.clientRequest).subscribe((res: any) => {
      if (res && res.status != null && res.status == "SUCCESS" && res.response) {
        if (res.response == "EMAIL_EXISTS") {
          this.toast.warning("Email already exists.", "Warning!");
        } else {
          this.toast.success("Client created successfully.", "Success!");
          this.modalRef.hide();
          this.editClient(res.response.userSoid);
        }
      } else {
        this.toast.error("Error creating client.", "Error!");
      }
    }, (err) => {
      this.toast.error(err);
    });
  }


  transformDateFormat(dateValue) {
    return this.datePipe.transform(new Date(dateValue), 'MM/dd/yyyy');
  }

  addNewClient() {

  }

  onModuleRemoved(module: string) {
    const modules = this.moduleControl.value as string[];
    this.removeFirst(modules, module);
    this.moduleControl.setValue(modules); // To trigger change detection
  }

  private removeFirst<T>(array: T[], toRemove: T): void {
    const index = array.indexOf(toRemove);
    if (index !== -1) {
      array.splice(index, 1);
    }
  }

  updateValidators() {
    const ssn = this.clientSubmitForm.controls.ssn;
    const itinNumber = this.clientSubmitForm.controls.itinNumber;
    const dlNumber = this.clientSubmitForm.controls.dlNumber;
    const dlState = this.clientSubmitForm.controls.dlState;
    const dlStartDate = this.clientSubmitForm.controls.dlStartDate;
    const dlExpirationDate = this.clientSubmitForm.controls.dlExpirationDate;

    ssn.setValidators(null);
    itinNumber.setValidators(null);
    dlNumber.setValidators(null);
    dlState.setValidators(null);
    dlStartDate.setValidators(null);
    dlExpirationDate.setValidators(null);
    if (this.idType == "SSN") {
      ssn.setValidators([Validators.required]);
    } else if (this.idType == "ITIN Number") {
     // itinNumber.setValidators([Validators.required]);
    } else if (this.idType == "Driving License") {
  //    dlNumber.setValidators([Validators.required, Validators.pattern('([A-Za-z0-9-]+)')]);
      dlNumber.setValidators([Validators.pattern('([A-Za-z0-9-]+)')]);
    //  dlState.setValidators([Validators.required]);
    //  dlStartDate.setValidators([Validators.required]);
  //    dlExpirationDate.setValidators([Validators.required]);
    }
  }

  phoneNumberFieldFocusOut(event) {
    if (event.srcElement.value.trim() != "") {
      this.personalMobile = event.srcElement.value;
    }
  }

  countryChange(event) {
    this.phoneNumberCountryCode = event.dialCode;
  }


}
