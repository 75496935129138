import { Component, OnInit, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ServicesListService } from '../../../services/services.service';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject } from 'rxjs';
import { ClientService } from '../../../services/client.service';
import { DocumentsService } from '../../../services/documents.service';
import { MasterService } from '../../../services/master.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-add-insurance-info',
  templateUrl: './add-insurance-info.component.html',
  styleUrls: ['./add-insurance-info.component.css']
})
export class AddClientInsuranceComponent implements OnInit {


  header: string;
  selectCallType: string;
  closeBtnName: string = "Close";
  selectedTab: string = "tab1";
  addClientInsuranceForm: FormGroup;
  feeField: boolean = true;
  serviceName: string;
  uploadType: string = "MY DOCUMENTS";
  phoneType: string;
  phoneValue: string;
  onlineType: string;
  onlineValue: string;
  faceType: string;
  faceValue: string;
  durationHour: string;
  durationMinutes: string;
  servicePrice: string;
  currency: string;
  module: string;
  formValid: boolean = true;
  locationModeObserver: Subject<string> = new Subject<string>();
  priceChangeObserver: Subject<string> = new Subject<string>();
  subscriptionServices: any = [];
  public event: EventEmitter<any> = new EventEmitter();
  public clientInfo: any = [];
  staffList: any = [];
  myDocumentsList: any = [];
  @ViewChild('labelImport')
  labelImport: ElementRef;
  staffAssigned: string;

  formImport: FormGroup;
  fileToUpload: File = null;
  dependentRelationship: any = [];
  martialStatus: string[];
  policyTermFrom: string = "";
  policyTermTo: string = "";
  agelimit: Date;
  personalMobileCountryCode: string = "";
  insurance: string = "";
  hiddenSSN: string = "";
  personalMobile: string = "";
  gender: any = [];
  commericalInsurance: any = [];
  insuranceCategory: any = [];
  personalInsurance: any = [];



  constructor(private masterService: MasterService, private toast: ToastrService, private datePipe: DatePipe,
    private _service: ServicesListService,
    private _documentService: DocumentsService,
    public bsModalRef: BsModalRef, sb: FormBuilder,
    private _clientService: ClientService,) {
  }

  ngOnInit() {

    this.commericalInsurance = this.masterService.getMasterItems("COMMERICALINSURANCE");
    this.insuranceCategory = this.masterService.getMasterItems("INSURANCECATEGORY");
    this.personalInsurance = this.masterService.getMasterItems("PERSONALINSURANCE");
    

    this.dependentRelationship = this.masterService.getMasterItems("RELATIONSHIP");

    this.martialStatus = this.masterService.getMasterItems("MARTIALSTATUS");
    this.gender = this.masterService.getMasterItems("GENDER");

    this.addClientInsuranceForm = new FormGroup({
      insurance: new FormControl('', [Validators.required]),
      commercial: new FormControl(''),
      personal: new FormControl(''),
      insuranceCompany: new FormControl('', [Validators.required]),
      policyNo: new FormControl('', [Validators.required]),
      policyTermFrom: new FormControl('', [Validators.required]),
      policyTermTo: new FormControl('', [Validators.required]),
    });

    this.staffAssigned = localStorage.getItem("UserSoid");

    var date = new Date();
    date.setFullYear(date.getFullYear() - 18);
    this.agelimit = date;

  }

  addDependent() {
    for (let inner in this.addClientInsuranceForm.controls) {
      this.addClientInsuranceForm.get(inner).markAsTouched();
      this.addClientInsuranceForm.get(inner).updateValueAndValidity();
    }
    if (!this.addClientInsuranceForm.valid) {
      this.formValid = false;
      this.toast.warning("Fill the required fields.", "Warning!");
      return;
    }


    var insuranceInfo: any = {};
    insuranceInfo.InsuranceCategory = this.addClientInsuranceForm.controls.insurance.value;
    if (this.insurance == "Personal") {
      insuranceInfo.InsuranceType = this.addClientInsuranceForm.controls.personal.value;
    } else {
      insuranceInfo.InsuranceType = this.addClientInsuranceForm.controls.commercial.value;
    }
    
    insuranceInfo.PolicyTermFrom = this.datePipe.transform(this.addClientInsuranceForm.controls.policyTermFrom.value, 'MM/dd/yyyy');
    insuranceInfo.PolicyTermTo = this.datePipe.transform(this.addClientInsuranceForm.controls.policyTermTo.value, 'MM/dd/yyyy');
      
    insuranceInfo.InsuranceCompany = this.addClientInsuranceForm.controls.insuranceCompany.value;
    insuranceInfo.PolicyNo = this.addClientInsuranceForm.controls.policyNo.value;
    insuranceInfo.Status = 'Active';

    this._clientService.saveInsuranceInfo(this.clientInfo.Soid, insuranceInfo).subscribe((res: any) => {
      if (res) {
        this.toast.success("Insurance added successfully.", "Success!");
        this.triggerEvent(res);
        this.bsModalRef.hide();
      } else {
        this.toast.warning("Error Creating service", "Error!");
      }
    });


  }


  triggerEvent(res) {
    this.event.emit(res);
  }


  updateValidators() {
    const personalDate = this.addClientInsuranceForm.controls.personal;
    const commercialDate = this.addClientInsuranceForm.controls.commercial;

    personalDate.setValidators(null);
    commercialDate.setValidators(null);
    if (this.insurance === 'Personal') {
      personalDate.setValidators([Validators.required]);
    } else {
      commercialDate.setValidators([Validators.required]);
    }
  }

  phoneNumberFieldFocusOut(event) {
    if (event.srcElement.value.trim() != "") {
      this.personalMobile = event.srcElement.value;
    }
  }

  countryChange(event) {

    this.personalMobileCountryCode = event.dialCode;
  }
}
