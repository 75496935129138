import { FormBuilder, FormGroup } from '@angular/forms';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormControl, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
//import {PageEvent} from '@angular/material/paginator/api/pageevent';
import { DocumentsService } from '../../services/documents.service';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';

import { SearchclientComponent } from '../modal/searchclient/searchclient.component';
import { AddClientDocumentsComponent } from '../modal/add-client-documents/add-client-documents.component';
import { AddMyDocumentsComponent } from '../modal/add-my-documents/add-my-documents.component'
import * as _ from 'underscore';
import { PageEvent } from '@angular/material/paginator';
import { EditDocumentComponent } from '../modal/edit-document/edit-document.component';
import { OfficeService } from '../../services/office.service';

@Component({
  selector: 'app-client',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.css']
})
export class DocumentsComponent implements OnInit {
  searchText: string;
  myForm: FormGroup;
  clientSubmitForm: FormGroup;
  disabled = false;
  ShowFilter = false;
  limitSelection = false;
  selectedItems: any = [];
  dropdownSettings: any = {};
  lowValue: number = 0;
  highValue: number = 5;
  clientRequest: any = {};
  firstName: string;
  middleName: string;
  lastName: string;
  personalEmail: string;
  businessEmail: string;
  formValid: boolean = true;
  subscriptionServices: any = [];
  agelimit: Date;
  sortBy: string = "";
  clientRequestInfo: any = {};
  idTypes: string[] = ['Driving License', 'SSN', 'ITIN Number'];
  idType: string;
  countries: string[] = [
    'Canada', 'Mexico', 'United States'
  ];
  officeList: any = [];
  documentList: any = [];
  myDocumentsList: any = [];
  selectedOffice: string;

  // MatPaginator Inputs
  documentLength = Number;
  showListLoader: boolean = false;
  pageIndex: number = 0;
  documentPageSize = 5;
  documentPageSizeOptions: number[] = [5, 10, 25, 100];

  _opened: boolean = false;

  modalRef: BsModalRef;
  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);
  constructor(private toast: ToastrService, private datePipe: DatePipe, private _documentsService: DocumentsService,
    private modalService: BsModalService, private _router: Router, private fb: FormBuilder,private _officeService: OfficeService) { }

  ngOnInit() {
    var date = new Date();
    date.setFullYear(date.getFullYear() - 18);
    this.agelimit = date;

    this.selectedOffice = localStorage.getItem("OfficeSoid");


    this.getDocumentList();

    this.getMyDocumentList();

    this.getOfficeList();

    //this.selectedItems = [{ item_id: 2, item_text: 'Staff' }, { item_id: 6, item_text: 'Documents' }];
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 5,
      allowSearchFilter: this.ShowFilter
    };
    this.myForm = this.fb.group({
      city: [this.selectedItems]
    });
  }


  public getOfficeFilter(event) {
    this.getDocumentList();
  }

  private getDocumentList() {
    this.showListLoader = true;
    this._documentsService.getDocumentsList(this.selectedOffice).subscribe((res: any) => {
      this.showListLoader = false;
      if (res && res != 'f') {
        this.documentLength = res.length;
        this.documentList = res;
      }
    });
  }

  private getMyDocumentList() {
    this._documentsService.getMyDocumentsList().subscribe((res: any) => {
      if (res && res != 'f') {
        this.myDocumentsList = res;
      }
    });
  }

  private getOfficeList() {    
    this._officeService.getAccessOfficeList(localStorage.getItem("OfficeSoid")).subscribe((res: any) => {
      if (res) {
        this.officeList = res.Office;        
      }
    });
  }

  public getClientPaginatorData(event: PageEvent): PageEvent {
    this.lowValue = event.pageIndex * event.pageSize;
    this.highValue = this.lowValue + event.pageSize;
    return event;
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  editClient(documentSoid) {
    this._router.navigate(['document/' + documentSoid]);
  }

  openPlannerModal() {


    const initialState = {
      title: "Client search",
      backdrop: true,
      ignoreBackdropClick: true,
      closeBtnName: "Close",
    };
    this.modalRef = this.modalService.show(SearchclientComponent, { initialState });
    /**  this.modalRef = this.modalService.show(EventPlannerComponent);
      this.modalRef.content.event.subscribe(data => {
     }); */
  }

  convertBase64ToBlob(base64Image: string, mimType) {


    // Decode Base64 string
    const decodedData = window.atob(base64Image);

    // Create UNIT8ARRAY of size same as row data length
    const uInt8Array = new Uint8Array(decodedData.length);

    // Insert all character code into uInt8Array
    for (let i = 0; i < decodedData.length; ++i) {
      uInt8Array[i] = decodedData.charCodeAt(i);
    }

    // Return BLOB image after conversion
    return new Blob([uInt8Array], { type: mimType });
  }

  viewFile(fileDocument) {

    const url = fileDocument.FileContent;
    const a = document.createElement('a');
    a.href = url;
    a.target = "_blank";
    a.download = fileDocument.FileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);


    /**var request: any = {};
    request.fileId = fileDocument.Soid;
    this._documentsService.downloadFile(request).subscribe((res: any) => {
      if (res) {  
        const mimType = fileDocument.ContentType === '.pdf' ? 'application/pdf' : fileDocument.ContentType === '.xlsx' ? 'application/xlsx' :
        fileDocument.ContentType === '.pptx' ? 'application/pptx' : fileDocument.ContentType === '.csv' ? 'application/csv' :
        fileDocument.ContentType === '.docx' ? 'application/docx' :
        fileDocument.ContentType === '.jpg' ? 'application/jpg' : fileDocument.ContentType === '.png' ? 'application/png' : '';
 
    
        // download the file
        var fileURL: any = URL.createObjectURL(this.convertBase64ToBlob(res.data.response, mimType));

        //window.open("data:application/pdf," + escape(res.data.response)); 
          const a = document.createElement('a');
          a.href = fileURL;
         // a.download = this.documentInfo.FileName;
          a.target="_blank"
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
      }
    });**/

  }

  addClient() {

  }

  sortFieldChange(event) {
    this.sortBy = event.srcElement.value;
    this.documentList = _.sortBy(this.documentList, this.sortBy);
  }

  onItemSelect(item: any) { }
  onSelectAll(items: any) {
  }
  toogleShowFilter() {
    this.ShowFilter = !this.ShowFilter;
    this.dropdownSettings = Object.assign({}, this.dropdownSettings, { allowSearchFilter: this.ShowFilter });
  }

  handleLimitSelection() {
    if (this.limitSelection) {
      this.dropdownSettings = Object.assign({}, this.dropdownSettings, { limitSelection: 2 });
    } else {
      this.dropdownSettings = Object.assign({}, this.dropdownSettings, { limitSelection: null });
    }
  }

  getShortName(fullName) {
    return fullName.split(' ').map(n => n[0]).join('');
  }

  convertToArray(valueText) {
    return valueText.split(",");
  }

  transformDateFormat(dateValue) {
    return this.datePipe.transform(new Date(dateValue), 'MM/dd/yyyy');
  }

  addNewClient() {

  }



  openServiceModal() {
    const initialState = {
      header: "New Client Document",
      services: "",
      clientInfo: "",
      backdrop: true,
      ignoreBackdropClick: true
    };
    this.modalRef = this.modalService.show(AddClientDocumentsComponent);
    this.modalRef.content.event.subscribe(data => {
      //this.getServiceList();
    });

  }


  myDocumentModal() {
    const initialState = {
      header: "New My Document",
      services: "",
      clientInfo: "",
      backdrop: true,
      ignoreBackdropClick: true
    };
    this.modalRef = this.modalService.show(AddMyDocumentsComponent, { initialState });
    this.modalRef.content.event.subscribe(data => {
      this.getMyDocumentList();
    });

  }


  openClientSearch() {
    const initialState = {
      title: "Client search",
      backdrop: true,
      ignoreBackdropClick: true,
      nextStep: 'Document'
    };
    this.modalRef = this.modalService.show(SearchclientComponent, { initialState });
    this.modalRef.content.event.subscribe(data => {
      this.getDocumentList();
      this.getMyDocumentList();
    });

  }

  _toggleSidebar() {
    this._opened = !this._opened;
  }

  inActiveDocument(soid) {
    this._documentsService.inactiveFileUpload(soid).subscribe((res: any) => {
      if (res) {
        this.toast.success("Document InActive successfully.", "Success!");
        this.getMyDocumentList();
      } else {
        this.toast.warning("Error Creating service", "Error!");
      }
    });

  }

  editMyDocument(document) {
    const initialState = {
      header: "Edit Document",
      documentInfo: document,
      backdrop: true,
      ignoreBackdropClick: true
    };
    this.modalRef = this.modalService.show(EditDocumentComponent, { initialState });
    this.modalRef.content.event.subscribe(data => {
      this.getMyDocumentList();

    });




  }




}
