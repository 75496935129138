import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {Observable, pipe, throwError} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import decode from 'jwt-decode';
import {User} from '../interface/user'
import { Api } from '../services/api';
import { Roles } from '../enum/roles';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private message: string;
  
  constructor(public api: Api, private _router: Router) { }
  
  /**
  * this is used to clear anything that needs to be removed
  */
  clear(): void {
    localStorage.clear();
  }
  
  /**
  * check for expiration and if token is still existing or not
  * @return {boolean}
  */
  isAuthenticated(): boolean {
    //return localStorage.getItem('authToken') != null && !this.isTokenExpired();
    return localStorage.getItem('UserSoid') != null;
  }
  
  
  loginAdmin(id, pwd) {
    let seq = this.api.post('Credentials/Login', {"password": pwd, "username": id});
    return seq;
  }
  
  login(userInfo: User) {
    let seq = this.api.post('Credentials/Auth', {"password": userInfo.password, "username": userInfo.emailId});
    return seq;
  }
  
  encryptSSN() {
    let seq = this.api.get('Person/encryptSSN');
    return seq;
  }
  
  encryptDependentSSN() {
    let seq = this.api.get('Person/encryptDependentSSN');
    return seq;
  }
  
  /**
  * this is used to clear local storage and also the route to login
  */
  logout() {
    let seq = this.api.get('Credentials/LogOut');
    return seq;
  }
  
  
  
  changePassword(pwd) {
    let seq = this.api.patch('Credentials/' + localStorage.getItem('UserSoid')+  '/ChangePassword', {"password": pwd, "UserSoid": localStorage.getItem('UserSoid')});
    return seq;
  }
  
  FirstLoginChangePassword(pwd) {
    let seq = this.api.patch('Credentials/FirstLoginChangePassword/' + localStorage.getItem('UserSoid'), {"password": pwd, "UserSoid": localStorage.getItem('UserSoid')});
    return seq;
  }
  
  getRole() {
    return localStorage.getItem('Role');
  }
  
  getUserRole() {
    let seq = this.api.get(`User/${localStorage.getItem('UserSoid')}/UserRole`);
    return seq;
  }

  getPermissions(role: string) {
    return { role, isAdmin: role === Roles.Admin, isSuperAdmin: role === Roles.SuperAdmin };
  }
  
  forgotPassword(email: string) {
    let seq = this.api.post('Credentials/ForgotPassword', { "email" : email });
    return seq;
  }
  
  confirmForgotPassword(email: string, password: string, confirmationCode: string) {
    let seq = this.api.post('Credentials/ConfirmForgotPassword', {"email": email, "password": password, "confirmationCode": confirmationCode});
    return seq;
  }
}