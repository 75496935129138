import { Component, OnInit, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup, FormControl,  Validators} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Subject, ReplaySubject } from 'rxjs';
import { ClientService } from '../../../services/client.service';
import { MasterService } from '../../../services/master.service';
import { take, takeUntil } from 'rxjs/operators';
import { Country } from '@angular-material-extensions/select-country';
import { USStatesService } from '../../../services/us-states.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-business-info',
  templateUrl: './business-info.component.html',
  styleUrls: ['./business-info.component.css']
})
export class BusinessInfoComponent implements OnInit {

  title: string = "Business Info";
  selectCallType: string;
  closeBtnName: string = "Close";
  businessInfoForm: FormGroup;  
  formValid: boolean = true;
  businessCountryName: Country;
  defaultValue: Country;
  emptyCountry: Country;
  businessPhone: string;

  public naicsCtrl: FormControl = new FormControl();
  //clientInsurances: FormControl = new FormControl();
  protected _onDestroy = new Subject<void>();
  public filteredNaics: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  naics: any = [];
  clientInsurance: any = [];
  commericalSpace: any = [];
  clientInfo: any = {};
  clientData: any = {};
  clientSoid: string = "";
  businessStarts: Date;
  public event: EventEmitter<any> = new EventEmitter();
  public header: string;  
  usStates: any = [];  
  phoneNumberCountryCode: string;

  
  constructor(private datePipe: DatePipe, private usStatesService: USStatesService, private toast: ToastrService,
     private masterService: MasterService, private _clientService: ClientService,
    public bsModalRef: BsModalRef, sb: FormBuilder) {
  }

  ngOnInit() {
    this.defaultValue = {
      name: 'United States of America',
      alpha2Code: 'US',
      alpha3Code: 'USA',
      numericCode: '840',
      callingCode: '+1'
    };
    this.emptyCountry = {
      name: '',
      alpha2Code: '',
      alpha3Code: '',
      numericCode: '',
      callingCode: ''
    };
    this.businessCountryName = this.defaultValue;

    this.businessInfoForm = new FormGroup({
      CompanyName: new FormControl('', Validators.compose([
        Validators.required
      ])),
      NumberOfEmployees: new FormControl('', Validators.compose([
        Validators.required
      ])),
      businessStartDate: new FormControl('', Validators.compose([
        Validators.required
      ])),
      WebsiteURL: new FormControl('', Validators.compose([
      ])),
      EINNumber: new FormControl('', Validators.compose([
        Validators.required
      ])),
      businessEmail: new FormControl('', Validators.compose([
        Validators.required
      ])),
      phone: new FormControl('', Validators.compose([
        Validators.required
      ])),
      businessAddress: new FormControl('', Validators.compose([
        Validators.required
      ])),
      businessCity: new FormControl('', Validators.compose([
        Validators.required
      ])),
      businessAddressCountry: new FormControl('', Validators.compose([
        Validators.required
      ])),
      businessZipCode: new FormControl('', Validators.compose([
        Validators.required
      ])),
      businessState: new FormControl('', Validators.compose([
        Validators.required
      ])),businessType: new FormControl('', Validators.compose([
        Validators.required
      ])),naicsC: new FormControl('', Validators.compose([
        Validators.required
      ])),commericalProperty : new FormControl('', Validators.compose([
        Validators.required
      ])),
      addressSame: new FormControl('', Validators.compose([
      ]))

    });
    this.usStates = this.usStatesService.getAllStates();    
    this.naics = this.masterService.getMasterItems("NAICS");
    this.clientInsurance = this.masterService.getMasterItems("CLIENTINSURANCE");
    this.commericalSpace = this.masterService.getMasterItems("COMMERICALSPACE");

    this.setInitialValue();
    this.naicsCtrl.setValue([]);
    this.filteredNaics.next(this.naics.slice());

    var businessCountry: any = {};
    businessCountry.name = this.clientInfo.BusinessAddressCountry;
    businessCountry.alpha2Code = this.clientInfo.BusinessAddressCountryAlphaCode;
    businessCountry.alpha3Code = '';
    businessCountry.numericCode = '';
    businessCountry.callingCode = '';
    this.businessCountryName = this.clientInfo.BusinessAddressCountry != null ? businessCountry : this.defaultValue;

    this.naicsCtrl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
      this.filterNaicsList();
    });
  }

  

  createNewDocument() {

    for (let inner in this.businessInfoForm.controls) {
      this.businessInfoForm.get(inner).markAsTouched();
      this.businessInfoForm.get(inner).updateValueAndValidity();
    }
    if (!this.businessInfoForm.valid) {
      this.formValid = false;
      this.toast.warning("Fill the required fields.", "Warning!");
      return;
    }
    var businessInfo: any = {};
    var countryCode = "";
    if (this.businessPhone.indexOf('+') < 0) {
      var countryCode = this.phoneNumberCountryCode ? '+' + this.phoneNumberCountryCode : '+1';
    }
    businessInfo.EINNumber =  this.businessInfoForm.controls.EINNumber.value;
    businessInfo.WebsiteUrl =  this.businessInfoForm.controls.WebsiteURL.value;
    businessInfo.Address =  this.businessInfoForm.controls.businessAddress.value;
    businessInfo.CountryAlphaCode =  this.businessInfoForm.controls.businessAddressCountry.value.alpha2Code;
    businessInfo.Country =  this.businessInfoForm.controls.businessAddressCountry.value.name;
    businessInfo.Email =  this.businessInfoForm.controls.businessEmail.value; 
    businessInfo.MobileNo =  countryCode + this.businessPhone; 
    businessInfo.CompanyName =  this.businessInfoForm.controls.CompanyName.value; 
    businessInfo.ZipCode = this.businessInfoForm.controls.businessZipCode.value; 
    businessInfo.State = this.businessInfoForm.controls.businessState.value; 
    businessInfo.City = this.businessInfoForm.controls.businessCity.value; 
    businessInfo.EmployeesCount = this.businessInfoForm.controls.NumberOfEmployees.value; 
    businessInfo.BusinessStartDate = this.datePipe.transform(this.businessInfoForm.controls.businessStartDate.value, 'MM/dd/yyyy');
    
    businessInfo.NAICS = this.businessInfoForm.controls.naicsC.value; 
    //businessInfo.Insurance = this.clientInsurances.value; 
    businessInfo.CommercialProperty = this.businessInfoForm.controls.commericalProperty.value; 
    businessInfo.BusinessType = this.businessInfoForm.controls.businessType.value; 
    businessInfo.Status = 'Active'

    this._clientService.saveBusinessInfo(this.clientSoid, businessInfo).subscribe((res: any) => {
      if (res) {
        this.toast.success("Added Business Successfully.", "Success!");
        this.triggerEvent(res);
        this.bsModalRef.hide();
      } else {
        this.toast.warning("Error Creating service", "Error!");
      }
    });
  }

  
  protected filterNaicsList() {
    if (!this.naics) {
      return;
    }
    // get the search keyword
    let search = this.naicsCtrl.value;
    if (!search) {
      this.filteredNaics.next(this.naics.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredNaics.next(
      this.naics.filter(bank => (bank.name.toLowerCase().indexOf(search) > -1 || bank.code.toLowerCase().indexOf(search) > -1))
    );
  }

  triggerEvent(res) {
    this.event.emit(res);
  }

  private setInitialValue() {
    this.filteredNaics
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        this.naics.compareWith = (a: any, b: any) => a === b;
      });
  }

  setBusinessAddress(event) {
    if (event.checked) {
      this.clientInfo.BusinessAddress = this.clientData.HomeAddress;
      this.clientInfo.BusinessCity = this.clientData.HomeAddressCity;
      this.clientInfo.BusinessState = this.clientData.HomeAddressState;
      this.clientInfo.BusinessZipCode = this.clientData.ZipCode;

      var businessCountry: any = {};
      businessCountry.name = this.clientData.HomeAddressCountry;
      businessCountry.alpha2Code = this.clientData.HomeAddressCountryAlphaCode;
      businessCountry.alpha3Code = '';
      businessCountry.numericCode = '';
      businessCountry.callingCode = '';
      this.businessCountryName = this.clientData.HomeAddressCountry != null ? businessCountry : this.defaultValue;
    } else {
      this.clientInfo.BusinessAddress = "";
      this.clientInfo.BusinessCity = "";
      this.clientInfo.BusinessState = "";
      this.clientInfo.BusinessZipCode = "";
      //this.businessCountryName : CountryCode;
      this.businessCountryName = this.emptyCountry;

    }
  }

  countryChange(event) {
    this.phoneNumberCountryCode = event.dialCode;
  }

  phoneNumberFieldFocusOut(event) {
    if (event.srcElement.value.trim() != "") {
      this.businessPhone = event.srcElement.value;     
    }
  }
}
