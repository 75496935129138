import { Injectable } from '@angular/core';
import { Api } from './api';

@Injectable({
  providedIn: 'root'
})

export class OfficeService {

  constructor(public api: Api) { }

  getOfficeList() {
    let seq = this.api.get('Office');
    return seq;
  }

  updateBranchOffice(officeSoid, office) {
    let seq = this.api.post('Office/' + officeSoid + '/AddOrUpdate', office);
    return seq;
  }

  getOfficeListByIds(officeSoid) {
    let seq = this.api.post('Office/ListByIds', officeSoid);
    return seq;
  }

  createOffice(data) { 
    let seq = this.api.post('Office', data);
    return seq;
  }

  
  addorUpdateModule(soId, data) {
    let seq = this.api.post('Office/' + soId + '/AddorUpdateModule', data);
    return seq;
  }

  getActiveModules(soId) {
    let seq = this.api.get('Office/' + soId + '/GetActiveModules');
    return seq;
  }

  getOfficeAddress(soId) {
    let seq = this.api.get('Office/' + soId + '/Address');
    return seq;
  }
  
  getOfficeInfo(soId) {
    let seq = this.api.get('Office/' + soId + '/Get');
    return seq;
  }

  getAccessOfficeList(soId) {
    let seq = this.api.get('Office/' + soId + '/List');
    return seq;
  }

  getOfficeListByUser(soId) {
    let seq = this.api.get('Office/' + soId + '/ListByUser');
    return seq;
  }

  getModulesInfo(soId) {
    let seq = this.api.get('Office/' + soId + '/Modules');
    return seq;
  }

  getModuleOffices(soId) {
    let seq = this.api.get('Office/' + soId + '/ModuleOffices');
    return seq;
  }

  getServiceSelectedOffices(soId) {
    let seq = this.api.get('Office/' + soId + '/ServiceSelected');
    return seq;
  }
  
  updateOffice(soId, data) {
    let seq = this.api.patch('Office/' + soId, data);
    return seq;
  }

  updateOfficeCountry(soId, data) {
    let seq = this.api.patch('Office/' + soId + '/UpdateCountry', data);
    return seq;
  }

  updateXero(xeroInfo) {
    let seq = this.api.post('XeroAdmin', xeroInfo);
    return seq;
  }

  refreshToken(soId) {
    let seq = this.api.get('XeroAdmin/Office/Refresh');
    return seq;
  }
}

