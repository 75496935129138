/// <reference path="../../../../../node_modules/@types/googlemaps/index.d.ts"/>
import { Component, OnInit, ViewChild, Input,EventEmitter } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { FormBuilder, FormControl, FormGroup, Validators, FormControlName } from '@angular/forms';
import { StaffService } from '../../../services/staff.service';
import { ServicesListService } from '../../../services/services.service';
import { EventsService } from '../../../services/events.service';
import {ToastrService} from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { XeroService } from 'src/app/services/xero.service';
import { Router } from '@angular/router';



@Component({
  selector: 'app-xero-tenants',
  templateUrl: './xero-tenants.component.html',
  styleUrls: ['./xero-tenants.component.css']
})
export class XeroTenantsComponent implements OnInit {
  public titles;
  public header : string;
  public services: any =  [];

  eventInfo: any = {};
  public Id: number;

  
  public event: EventEmitter<any> = new EventEmitter();

  eventForm: FormGroup;
  isRepeatChecked : boolean = false;
  closeBtnName: string = "Close Planner"
  meetingLocation: string;
  //services: string[] = ["2018 Tax return", "In-office appointment", "30 minutes phone call", "Introductory Tax Preparation Phone Call"]
  isEditable: boolean = false;
  selectedDetailType: string;
  meetingAddress:string;
  staffList: any = [];
  serviceList: any = [];
  clientInfo: any = {};
  serviceRequest: string;
  startEventDate: string;
  endEventDate: string;
  startDateTime: string = "";
  endDateTime: string;
  staff: string;
  description: string;
  showAddress: boolean = false;
  serviceFeeType: string;
  servicePrice: string;
  formValid:boolean = true;
  occurenceType: string = "Daily";
  //seasons: string[] = ['Days', 'Weeks', 'Monthly'];
  seasons: string[] = ['Daily', 'Weekly'];
  hours: string[];
  places: string[] = ['First', 'Second', 'Third', 'Fourth'];
  weekdays: string[] = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  startTimeList: any = [];
  endTimeList: any = [];
  dailyInterval:number;
  isWeekDays:number;
  recurWeek:number;
  occurenceCount:number;
  tenant: any = {};
  cServiceName:string;
  staffIds:string;
  eventStatus:string;
  participantInfo: any = {};
  modalRef: BsModalRef;
  paymentList: any = [];
  invoiceAmount: string;

  xeroTenaants: any = [];

  constructor(private _serviceList: ServicesListService, private datePipe: DatePipe, private toast: ToastrService,
    private _eventsService: EventsService, private _router: Router,
     private _xeroService: XeroService,
      public bsModalRef: BsModalRef,
       private fb: FormBuilder ) { }

  ngOnInit() {
   // this.staff = "50";

    this.eventForm = new FormGroup({
      staff: new FormControl('', [Validators.required]),
    });
  }

  showOptions(event, value) {
  }

  editDetails() {
    this.isEditable = false;
  }

  saveDetails() {
    this.isEditable = true;
  }

  discardChanges() {
    this.eventForm.reset();
    this.isEditable = true;
  }

  getAddress(event) {
    this.meetingAddress =  event.name;
  }

  updateXero() {
    this._xeroService.updateXeroTenant(localStorage.getItem("XeroRequestOfficeID"), this.tenant).subscribe((res: any) => {
      if (res && res.Status == 'SUCCESS') {
        this.closeModal();
        this._router.navigateByUrl("/office/" + localStorage.getItem("XeroRequestOfficeID"));
      }


      if (res != null && res.Accounts) {        
        localStorage.setItem("Accounts", res.Accounts);
      } else {
        localStorage.removeItem("Accounts");
      }


      if (res != null && res.TaxRates) {        
        localStorage.setItem("XeroTaxRates", res.TaxRates);
      } else {
        localStorage.removeItem("XeroTaxRates");
      }
    });
  }


  closeModal() {
    this.bsModalRef.hide();

  }

  triggerEvent() {
    this.event.emit();
  }

  getStaffInfo(loginId: String) {
    return this.staffList.filter(proj => proj.PersonSoid === loginId);
  }

  typeChange() {
    var staffInfo = this.getStaffInfo(this.staff);
  }

  endEventDateChange() {
    this.endEventDate =  this.startEventDate;
  }



}

