import { Injectable } from '@angular/core';
import { Api } from '../services/api';

@Injectable({
  providedIn: 'root'
})
export class ClientService {

  constructor(public api: Api) { }

  getClientList() {   
    var officeSoid = localStorage.getItem("OfficeSoid");
    let seq = this.api.get('Person/' + officeSoid + '/Clients/Active');
    return seq;
  }

  getBusinessInfoByOffice() {   
    var officeSoid = localStorage.getItem("OfficeSoid");
    let seq = this.api.get('Person/' + officeSoid + '/BusinessInfoList');
    return seq;
  }

  getClientListFilterBy(filterBy) {   
    var officeSoid = localStorage.getItem("OfficeSoid");
    let seq = this.api.get('Person/' + officeSoid + '/Clients/' + filterBy);
    return seq;
  }

  getClientByOffice(officeSoid, filterBy) {
    let seq = this.api.get('Person/' + officeSoid + '/Clients/' + filterBy);
    return seq;
  }

  

  saveClientInfo(clientInfo) {
    let seq = this.api.post('User/Register', clientInfo);
    return seq;
  }
  updateClientInfo(clientId, clientInfo) {
    let seq = this.api.patch('Person/' + clientId, clientInfo);
    return seq;
  }

  updateBusinessInfo(clientId, businessInfoId, businessInfo) {
    let seq = this.api.patch('Person/' + clientId + '/UpdateBusinessInfo/' + businessInfoId, businessInfo);
    return seq;
  }

  getClientBusinessInfo(clientId) {
    let seq = this.api.get('Person/' + clientId + '/BusinessInfo');
    return seq;
  }

  getClientDependentInfo(clientId) {
    let seq = this.api.get('Person/' + clientId + '/DependentInfo');
    return seq;
  }

  getClientInsuranceInfo(clientId) {
    let seq = this.api.get('Person/' + clientId + '/InsuranceInfo');
    return seq;
  }

  updateClientHomeCountry(clientId, clientInfo) {
    let seq = this.api.patch('Person/' + clientId + '/UpdateHomeCountry', clientInfo);
    return seq;
  }

  updateClientBusinessCountry(clientId, clientInfo, businessSoid) {
    let seq = this.api.patch('Person/' + clientId + '/UpdateBusinessCountry/' + businessSoid, clientInfo);
    return seq;
  }

  updateDependent(clientId, clientInfo, dependentSoid) {
    let seq = this.api.patch('Person/' + clientId + '/UpdateDependent/' + dependentSoid, clientInfo);
    return seq;
  }

  updateInsuranceInfo(clientId, insuranceInfo, insuranceSoid) {
    let seq = this.api.patch('Person/' + clientId + '/UpdateInsuranceInfo/' + insuranceSoid, insuranceInfo);
    return seq;
  }

  updatePassportBusinessCountry(clientId, clientInfo) {
    let seq = this.api.patch('Person/' + clientId + '/UpdatePassportCountry', clientInfo);
    return seq;
  }

  getClientInfo(clientId) {
    let seq = this.api.get('Person/' + clientId);
    return seq;
  }

  getCustomerInfo(clientId) {
    let seq = this.api.get('Customer/' + clientId);
    return seq;
  }

  saveBusinessInfo(clientId, business) {
    let seq = this.api.post('Person/' + clientId + '/AddBusinessInfo', business);
    return seq;
  }

  saveEmployment(clientId, employment) {
    let seq = this.api.post('Person/' + clientId + '/AddEmployment', employment);
    return seq;
  }

  saveDependentInfo(clientId, dependent) {
    let seq = this.api.post('Person/' + clientId + '/AddDependent', dependent);
    return seq;
  }

  saveInsuranceInfo(clientId, insuranceInfo) {
    let seq = this.api.post('Person/' + clientId + '/AddInsurance', insuranceInfo);
    return seq;
  }

  saveNotes(clientId, notes) {
    let seq = this.api.post('Person/' + clientId + '/AddNotes', notes);
    return seq;
  }

  getNotes(clientId) {
    let seq = this.api.get('Person/' + clientId + '/Notes');
    return seq;
  }

  updatePersonNotes(clientId, noteSoid, noteInfo) {
    let seq = this.api.patch('Person/' + clientId + '/UpdateNotes/' + noteSoid, noteInfo);
    return seq;
  }

  deleteNote(clientId, noteSoid, noteInfo) {
    let seq = this.api.post('Person/' + clientId + '/DeleteNote/' + noteSoid, noteInfo);
    return seq;
  }

  cloneNotes(clientId, noteSoid, noteInfo) {
    let seq = this.api.post('Person/' + clientId + '/Notes/' + noteSoid + '/Clone', noteInfo);
    return seq;
  }
}
