import { FormBuilder, FormGroup } from '@angular/forms';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
//import {PageEvent} from '@angular/material/paginator/api/pageevent';
import { InvoiceService } from '../../../services/invoice.service';
import { USStatesService } from '../../../services/us-states.service';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { EventPlannerComponent } from '../../../base/eventplanner/eventplanner.component';
import _ from 'lodash';
import { SearchclientComponent } from '../../modal/searchclient/searchclient.component';
import { Router, ActivatedRoute } from '@angular/router';

import { Country } from '@angular-material-extensions/select-country';
import { PageEvent } from '@angular/material/paginator';
import { OfficeService } from 'src/app/services/office.service';
@Component({
  selector: 'app-invoice-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.css']
})
export class PaymentsComponent implements OnInit {
  defaultValue: Country
  searchText: string;
  myForm: FormGroup;
  clientSubmitForm: FormGroup;
  disabled = false;
  ShowFilter = false;
  limitSelection = false;
  selectedItems: any = [];
  dropdownSettings: any = {};
  lowValue: number = 0;
  highValue: number = 5;
  clientRequest: any = {};
  firstName: string;
  middleName: string;
  lastName: string;
  personalEmail: string;
  businessEmail: string;
  formValid: boolean = true;
  subscriptionServices: any = [];
  agelimit: Date;
  sortBy: string = "CreatedOn";
  clientRequestInfo: any = {};
  idTypes: string[] = ['Driving License', 'SSN', 'ITIN Number'];
  idType: string;
  countries: string[] = [
    'Canada', 'Mexico', 'United States'
  ];
  martialStatus: string[] = ['Married', 'Widowed', 'Separated', 'Divorced', 'Single'];
  languagePreference: string[] = [
    'English', 'Spanish'
  ];
  hearAboutUs: string[] = [
    'Web', 'Facebook', 'Web', 'Facebook', 'Instagram', 'YouTube', 'Twitter', 'LinkedIn', 'Friend', 'Other'
  ];
  toppingsControl = new FormControl('', Validators.compose([
    Validators.required
  ]));
  toppingList: string[] = ['Extra cheese', 'Mushroom', 'Onion', 'Pepperoni', 'Sausage', 'Tomato'];
  currentDate = new Date();
  hearAboutUsSelect: string;
  businessMobile: string;
  personalMobile: string;
  

  phoneForm = new FormGroup({
    mobilePhone: new FormControl('')

 });

 officeList: any = [];
 selectedOffice: string;
 hiddenSSN: string;
  @ViewChild('viewReciept')
  public viewReciept;

  @ViewChild('OfficeView')
  public officeView;

  @ViewChild('businessphoneField')
  public businessphoneField;

  invoiceList: any = [];
  transactionList: any = [];
  usStates: any = [];

  // MatPaginator Inputs
  invoicesLength = Number;
  pageIndex: number = 0;
  invoicePageSize = 5;
  invoicePageSizeOptions: number[] = [5, 10, 25, 100];
  customPatterns = { '0': { pattern: new RegExp('([A-Za-z0-9_-]+)') } };


  modalRef: BsModalRef;
  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);
  
  overDueAmount: number;
  dueAmount: number;
  filteredTotal: string;
  separateDialCode = false;
  
  duePayment: any = [];
  overDuePayment: any = [];
  allInvoices: any = [];
  selectedReceiptId: string;

  constructor(private usStatesService: USStatesService, private toast: ToastrService, private datePipe: DatePipe, private _officeService : OfficeService, 
    private _invoiceService: InvoiceService, private modalService: BsModalService, private _router: Router,
     private fb: FormBuilder) { }

  ngOnInit() {
    var date = new Date();
    date.setFullYear(date.getFullYear() - 18);
    this.agelimit = date;
    this.currentDate = new Date();
    this.usStates = this.usStatesService.getAllStates();
    
    this.defaultValue = {
      name: 'United States of America',
      alpha2Code: 'US',
      alpha3Code: 'USA',
      numericCode: '840',
      callingCode: '+1'
    };
    this.selectedOffice = localStorage.getItem("OfficeSoid");
    
    this.getOfficeList();
  
    this.getInvoiceList();

    this.myForm = this.fb.group({
      city: [this.selectedItems]
    });
   
  }

  private getInvoiceList() {
    this._invoiceService.getPayments(this.selectedOffice).subscribe((transactionList: any) => {
      if (transactionList) {            
        this.invoicesLength = transactionList.length;
       
        this.transactionList = transactionList;
        this.transactionList = _.sortBy(this.transactionList, this.sortBy).reverse();
        this.overDueAmount = _.sumBy(this.transactionList, 'Amount');

        this.dueAmount = _.sumBy(this.transactionList, 'Amount');

        if (this.transactionList.length > 0) {
          this.showReceipt(this.transactionList[0].Soid,
            this.transactionList[0].CustomerSoid, 
            this.transactionList[0].InvoiceSoid, this.transactionList[0]) 
        } else {
          this.viewReciept.clearView();
        }
       
        
      }
    }
  );
  }


  private getOfficeList() {    
    this._officeService.getAccessOfficeList(localStorage.getItem("OfficeSoid")).subscribe((res: any) => {
      if (res) {
        this.officeList = res.Office;        
      }
    });
  }

  public getinvoicePaginatorData(event: PageEvent): PageEvent {
    this.lowValue = event.pageIndex * event.pageSize;
    this.highValue = this.lowValue + event.pageSize;
    return event;
  }

  openModal(template: TemplateRef<any>) {
	  this.clientSubmitForm.reset();
    this.modalRef = this.modalService.show(template);
  }

  showReceipt(receiptSoid, clientSoid, invoiceSoid, paymentInfo) {
    this.selectedReceiptId = receiptSoid;
   // this._router.navigate(['client/' + clientSoid + '/invoice/' + invoiceSoid + '/receipt/' + receiptSoid]);
    this.viewReciept.loadView(receiptSoid, clientSoid, invoiceSoid, paymentInfo) 
  }

  loadClientSearch(type) {


    const initialState = {
      title: "Client search",
      backdrop: true,
      ignoreBackdropClick: true,
      closeBtnName: "Close",      
      nextStep: type
  };
    this.modalRef = this.modalService.show(SearchclientComponent, { initialState });
      this.modalRef.content.event.subscribe(data => {
       this.getInvoiceList();
     }); 
  }

  addClient() {

  }

  sortFieldChange(event) {
    this.sortBy = event.srcElement.value;
    this.invoiceList = _.sortBy(this.invoiceList, this.sortBy);
  }

  onItemSelect(item: any) { }
  onSelectAll(items: any) {
  }
  toogleShowFilter() {
    this.ShowFilter = !this.ShowFilter;
    this.dropdownSettings = Object.assign({}, this.dropdownSettings, { allowSearchFilter: this.ShowFilter });
  }

  handleLimitSelection() {
    if (this.limitSelection) {
      this.dropdownSettings = Object.assign({}, this.dropdownSettings, { limitSelection: 2 });
    } else {
      this.dropdownSettings = Object.assign({}, this.dropdownSettings, { limitSelection: null });
    }
  }

  getShortName(fullName) {
    return fullName.split(' ').map(n => n[0]).join('');
  }

  convertToArray(valueText) {
    return valueText.split(",");
  }

  saveClient() {
   
  }


  transformDateFormat(dateValue) {
    return this.datePipe.transform(new Date(dateValue), 'MM/dd/yyyy');
  }

  addNewClient() {

  }

  onToppingRemoved(topping: string) {
    const toppings = this.toppingsControl.value as string[];
    this.removeFirst(toppings, topping);
    this.toppingsControl.setValue(toppings); // To trigger change detection
  }

  private removeFirst<T>(array: T[], toRemove: T): void {
    const index = array.indexOf(toRemove);
    if (index !== -1) {
      array.splice(index, 1);
    }
  }


  
  updateValidators() {
    const ssn = this.clientSubmitForm.controls.ssn;
    const itinNumber = this.clientSubmitForm.controls.itinNumber;
    const dlNumber = this.clientSubmitForm.controls.dlNumber;
    const dlState = this.clientSubmitForm.controls.dlState;
    const dlStartDate = this.clientSubmitForm.controls.dlStartDate;
    const dlExpirationDate = this.clientSubmitForm.controls.dlExpirationDate;

    ssn.setValidators(null);
    itinNumber.setValidators(null);
    dlNumber.setValidators(null);
    dlState.setValidators(null);
    dlStartDate.setValidators(null);
    dlExpirationDate.setValidators(null);
    if (this.idType == "SSN") {      
      ssn.setValidators([Validators.required]);
    } else if (this.idType == "ITIN Number") {      
      itinNumber.setValidators([Validators.required]);
    } else if (this.idType == "Driving License") {      
      dlNumber.setValidators([Validators.required, Validators.pattern('([A-Za-z0-9-]+)')]);
      dlState.setValidators([Validators.required]);
      dlStartDate.setValidators([Validators.required]);
      dlExpirationDate.setValidators([Validators.required]);
    }
  }


  compareDate(dueDate) {
    if (this.currentDate > new Date(dueDate)) {
      return "Over Due";
    } else if (this.currentDate <= new Date(dueDate)) {
      return "Due";
    }
  }

  showFilter(filter) {
    if (filter == "OverDue") {
      this.invoiceList = this.overDuePayment;      
    } else if (filter == "Due") {
      this.invoiceList = this.duePayment; 
    } else if (filter == "All") {
      this.invoiceList = this.allInvoices;
    }
  }

}
