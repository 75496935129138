
<div class="modal-header">
  <h4 class="modal-title pull-left">{{header}}</h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body contentScroll">
    
  <form class="client-modal" [formGroup]="eventForm">
      <div class="form-row">
        <div style="float: left; width: 50px; height:50px;">
            <ngx-avatar size="40" name="{{clientInfo.NameFirst}} {{clientInfo.NameLast}}"></ngx-avatar>  
        </div>
        <div style="float: left; width: 80%;">
            <div class="client-list-left">
              <h6 class="mt-0 mb-1">{{clientInfo.NameFirst | titlecase}} {{clientInfo.NameLast | titlecase}}</h6>
              <span><i class="fas fa-at"></i>{{clientInfo.Email}}</span>
            </div>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-12">
          <mat-form-field>            
            <mat-select (selectionChange)="serviceChange()" placeholder="Service" formControlName="serviceRequest" [(ngModel)]="serviceRequest">
              <!--<mat-option>None</mat-option>-->
              <mat-option *ngFor="let service of serviceList" [value]="service.Soid">{{service.Item | titlecase}} </mat-option>
              <!--<mat-option value="Custom">Custom</mat-option>-->
            </mat-select>
            <mat-error *ngIf="eventForm.controls.serviceRequest.errors != null && !formValid">
                Please select Service
              </mat-error>
          </mat-form-field>
        </div>
      <!--  <div class="form-group col-md-12" *ngIf="serviceRequest == 'Custom'">-->
       <!--  <div class="form-group col-md-12" >
          <mat-form-field class="example-full-width"> 
            <input type="input" [(ngModel)]="cServiceName" formControlName="customServiceName" matInput placeholder="Service Name" [disabled]="isEditable">            
            <mat-error *ngIf="eventForm.controls.customServiceName.errors != null && !formValid">
                Please enter custom service name
              </mat-error>
          </mat-form-field>
          
        </div>-->
    </div>
    <div class="form-row">
        <div class="form-group col-md-3">
          <mat-form-field>
            <input matInput readonly  autocomplete="off" (dateChange)="endEventDateChange()" formControlName="startEventDate" [(ngModel)]="startEventDate" [matDatepicker]="startTime" placeholder="Start Date">
            <mat-datepicker-toggle matSuffix [for]="startTime"></mat-datepicker-toggle>
            <mat-datepicker #startTime></mat-datepicker>
            <mat-error *ngIf="eventForm.controls.startEventDate.errors != null && !formValid">
                Please select start date
              </mat-error>
          </mat-form-field>
        </div>
        <div class="form-group col-md-3">
            <mat-form-field>
              <mat-select (selectionChange)="startDateTimeChange()" placeholder="Start Time" formControlName="startDateTime" [(value)]="startDateTime">
                <mat-option>None</mat-option>
                <mat-option *ngFor="let hour of startTimeList" [value]="hour">{{hour}}</mat-option>
              </mat-select>
              <mat-error *ngIf="eventForm.controls.startDateTime.errors != null && !formValid">
                  Please select end time
                </mat-error>
            </mat-form-field>
        </div>
        <div class="form-group col-md-3">
          <mat-form-field>
            <input matInput  readonly autocomplete="off" formControlName="endEventDate" [(ngModel)]="endEventDate" [matDatepicker]="endTime" placeholder="End Date">
            <mat-datepicker-toggle matSuffix [for]="endTime"></mat-datepicker-toggle>
            <mat-datepicker #endTime></mat-datepicker>
            <mat-error *ngIf="eventForm.controls.endEventDate.errors != null && !formValid">
                Please select end date
              </mat-error>
          </mat-form-field>
        </div>
        <div class="form-group col-md-3">
            <mat-form-field>
              <mat-select placeholder="End Time" formControlName="endDateTime" [(value)]="endDateTime">
                <mat-option>None</mat-option>
                <mat-option *ngFor="let hour of endTimeList" [value]="hour">{{hour}}</mat-option>
              </mat-select>
              <mat-error *ngIf="eventForm.controls.endDateTime.errors != null && !formValid">
                  Please select end time
                </mat-error>
            </mat-form-field>
        </div>
    </div>
    <!--<div class="form-row">
      <div class="form-group">
        <div class="form-check">
          <input class="form-check-input" type="checkbox" id="gridCheck" [(ngModel)]="isRepeatChecked" formControlName="repeatChecked">
          <label class="form-check-label" for="gridCheck">
            Repeats...
          </label>
        </div>
      </div>
    </div>-->
    <div *ngIf="isRepeatChecked" class="client-recur">
      <div class="form-row">
          <div class="form-group col-md-3">
              <mat-radio-group
              aria-labelledby="recurrence-radio-group-label"
              class="recurrence-radio-group"
              [(ngModel)]="occurenceType" formControlName="occurenceType">
              <mat-radio-button class="recurrence-radio-button" *ngFor="let season of seasons" [value]="season">
                {{season}}
              </mat-radio-button>
            </mat-radio-group>
          </div>
          <div class="form-group col-md-9">
              <div *ngIf="occurenceType === 'Daily'" class="schedule-cards">
                <div class="form-row">
                  <div class="form-group col-md-3">
                    <mat-radio-group [(ngModel)]="isWeekDays" formControlName="isWeekDays">
                        <mat-radio-button value="1">Every</mat-radio-button>
                        <br/>
                        <mat-radio-button value="2">Every weekday</mat-radio-button>
                   </mat-radio-group>
                   <br/>
                   
                  </div>
                  <div class="form-group col-md-1">
                    <!-- <mat-form-field class="example-full-width">
                      <input matInput [(ngModel)]="dailyInterval" formControlName="dailyInterval" placeholder="" value="">
                    </mat-form-field> -->
                    <input [(ngModel)]="dailyInterval" formControlName="dailyInterval" type="text" class="input-days" />
                  </div>
                  <div class="form-group col-md-7">
                    <span>day(s)</span>
                  </div>
                </div>
                <!-- <div class="form-row">
                    <div class="form-group col-md-5">
                      <mat-radio-group [(ngModel)]="isWeekDays" formControlName="isWeekDays">
                          <mat-radio-button value="2">Every weekday</mat-radio-button>
                      </mat-radio-group>
                    </div>
                  </div> -->
              </div>
              <div *ngIf="occurenceType === 'Weekly'" class="schedule-cards">
                <div class="form-row">
                  <div class="form-group col-md-3">
                    <span>Recur every</span>
                  </div>
                  <div class="form-group col-md-1">
                    <!-- <mat-form-field class="example-full-width">
                      <input matInput placeholder="" value="">
                    </mat-form-field> -->
                    <input [(ngModel)]="recurWeek" formControlName="recurWeek" type="text" class="input-days" />
                  </div>
                  <div class="form-group col-md-7">
                    <span>weeks(s) on.</span>
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group">
                      <section class="recur-weekly-section form-row">
                        <div class="col-md-3" *ngFor="let day of weekdays"><mat-checkbox (change)="showOptions($event , day)">{{day}}</mat-checkbox></div>
                      </section>
                  </div>
                </div>
              </div>
              <div *ngIf="occurenceType === 'Monthly'" class="schedule-cards">
                <div class="form-row">
                  <div class="form-group col-md-3">
                    <mat-radio-group>
                        <mat-radio-button value="3">Day</mat-radio-button>
                    </mat-radio-group>
                  </div>
                  <div class="form-group col-md-1">
                    <mat-form-field class="example-full-width">
                      <input matInput placeholder="" value="">
                    </mat-form-field>
                  </div>
                  <div class="form-group col-md-2">
                    <span>of every</span>
                  </div>
                  <div class="form-group col-md-1">
                    <mat-form-field class="example-full-width">
                      <input matInput placeholder="" value="">
                    </mat-form-field>
                  </div>
                  <div class="form-group col-md-2">
                    <span>month(s).</span>
                  </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-md-2">
                      <mat-radio-group>
                          <mat-radio-button value="4">The</mat-radio-button>
                      </mat-radio-group>
                    </div>
                    <div class="form-group col-md-2">
                      <mat-select>
                          <mat-option *ngFor="let place of places" [value]="place">
                            {{place}}
                          </mat-option>
                        </mat-select>
                    </div>
                    <div class="form-group col-md-2">
                      <mat-select>
                          <mat-option *ngFor="let food of weekdays" [value]="day">
                            {{day}}
                          </mat-option>
                        </mat-select>
                    </div>
                    <div class="form-group col-md-2">
                      <span>of every</span>
                    </div>
                    <div class="form-group col-md-1">
                      <mat-form-field class="example-full-width">
                        <input matInput placeholder="" value="">
                      </mat-form-field>
                    </div>
                    <div class="form-group col-md-2">
                      <span>month(s).</span>
                    </div>
                  </div>
              </div>
          </div>
      </div>
      <div class="form-row">
          <div class="form-group col-md-3">
            <mat-form-field>
                <mat-select placeholder="Ends">
                  <mat-option value="After">After</mat-option>
                  <mat-option value="Date">Date</mat-option>
                </mat-select>
              </mat-form-field>
          </div>
          <div class="form-group col-md-3">
            <mat-form-field>
                <mat-select placeholder="Occurance" formControlName="occurenceCount" [(value)]="occurenceCount">
                  <mat-option value="">None</mat-option>
                  <mat-option value="1">1</mat-option>
                  <mat-option value="3">3</mat-option>
                  <mat-option value="5">5</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
        </div>
    </div>
    <div class="form-row">
        <div class="form-group col-md-12">
            <mat-form-field>
              <mat-label>Please select the staff</mat-label>
              <mat-select formControlName="staff" (selectionChange)="typeChange()" [(ngModel)]="staff">
                <mat-option *ngFor="let staff of staffList" [value]="staff.PersonSoid">({{staff.NameFirst | titlecase}} {{staff.NameLast | titlecase}} ) {{staff.EmailAddress}}</mat-option>
              </mat-select>
              <mat-error *ngIf="eventForm.controls.staff.errors != null && !formValid">
                  Please select end time
                </mat-error>
            </mat-form-field>
        </div>
    </div>
    <div class="form-row">
      <div class="form-group col-md-12">
          <mat-form-field>
              <input maxlength="50" autocomplete="off" type="text" formControlName="description" [(ngModel)] = "description" matInput placeholder="Message to client">
              <mat-error *ngIf="eventForm.controls.description.errors != null && !formValid">
                  Please enter message to client
                </mat-error>
          </mat-form-field>
      </div>
    </div>
  <div class="client-details-and-information">
      <div class="form-group row">
       <!--  <label for="staticEmail" class="col-sm-4 col-form-label">Type</label> -->
        <div [ngClass]="selectedDetailType ? 'col-md-6' : 'col-md-12'">
          <mat-form-field>
            <mat-select placeholder="Type" (selectionChange)="typeChange()" [disabled]="isEditable" formControlName="detailsType" [(value)]="selectedDetailType">              
              <mat-option [value]="eventTypes.PhoneClientCalls">{{eventTypes.PhoneClientCalls}}</mat-option>
              <mat-option [value]="eventTypes.InPersonAtYours">{{eventTypes.InPersonAtYours}}</mat-option>
              <mat-option [value]="eventTypes.Skype">{{eventTypes.Skype}}</mat-option>
              <mat-option [value]="eventTypes.PhoneYouCall">{{eventTypes.PhoneYouCall}}</mat-option>
              <mat-option [value]="eventTypes.InPersonAtClients">{{eventTypes.InPersonAtClients}}</mat-option>
              <mat-option [value]="eventTypes.OnlineOther">{{eventTypes.OnlineOther}}</mat-option>
            </mat-select>
            <mat-error *ngIf="eventForm.controls.detailsType.errors != null && !formValid">
                Please select type
              </mat-error>
          </mat-form-field>
        </div>
        <div class="col-sm-6" *ngIf="selectedDetailType">
            <mat-form-field class="example-full-width">
              <span *ngIf="showCountryCode" matPrefix>+1 &nbsp;</span>            
              <input *ngIf="!showLMSAddress && !showBusinessAddresses" type="input" autocomplete="off" [(ngModel)]="meetingLocation" #meetingLoc  formControlName="meetingLocation" matInput [placeholder]="Where" [disabled]="isEditable">
              <mat-select *ngIf="showLMSAddress && !showBusinessAddresses" formControlName="meetingLocation" [(ngModel)]="meetingLocation">
                <mat-option *ngFor="let address of officeAddress" [value]="meetingLocation">{{address.Address}}</mat-option>
              </mat-select>
              <mat-select *ngIf="showBusinessAddresses && !showLMSAddress" formControlName="meetingLocation" [(ngModel)]="meetingLocation">
                <mat-option *ngFor="let address of businessAddresses" [value]="address.Address">{{address.Address}}</mat-option>
              </mat-select>

              <mat-error *ngIf="eventForm.controls.meetingLocation.errors != null && !formValid">
                  Please enter contact number
                </mat-error>
            </mat-form-field>
            
        </div>
        <div class="col-sm-6" *ngIf="showAddress">
          
          <app-google-places (setAddress)="getAddress($event)" adressType="address"></app-google-places>
        </div>
      </div>
      
      <div class="form-group row">
          <div class="form-group col-md-6" [ngClass]="showUSDPrice ? 'col-md-6' : 'col-md-12'">
            <mat-form-field>
              <mat-select (selectionChange)="serviceFeeChange()" placeholder="Service Fee Option" formControlName="serviceFeeType" [(value)]="serviceFeeType">              
                <mat-option value="Free - Don't display a fee">Free - Don't display a fee</mat-option>
                <mat-option value="Price Varies - Display as 'For a fee'">Price Varies - Display as 'For a fee'</mat-option>
                <mat-option value="Free - Display as 'Free'">Free - Display as 'Free'</mat-option>
                <mat-option value="Paid - Display Fee only(No online payment)">Paid - Display Fee only(No online payment)</mat-option>
                <mat-option value="Paid - Suggest to pay at booking">Paid - Suggest to pay at booking</mat-option>
                <mat-option value="Paid - Require to pay at booking">Paid - Require to pay at booking</mat-option>
              </mat-select>
              <mat-error *ngIf="eventForm.controls.serviceFeeType.errors != null && !formValid">
                  Please select service fee option
                </mat-error>
            </mat-form-field>
          </div>
          <div class="form-group col-md-6" *ngIf="showUSDPrice">
              <mat-form-field class="example-full-width">  
                <input placeholder="Service price(USD)" min="0" value="0"
                 step=".01" autocomplete="off" type="number" [(ngModel)]="servicePriceUSD"  formControlName="servicePrice" matInput >
                <mat-error *ngIf="eventForm.controls.servicePrice.errors != null && !formValid">
                    Please enter service price
                  </mat-error>
              </mat-form-field>
              
            </div>
        </div>
        <div class="form-group row">
            <label class="col-md-12 col-form-label">The price stated should be the price for appointment instance (and not for the whole series)</label>

        </div>

      <!--<h4>Information</h4>
      <div class="form-group row">
        <label for="bookingConfirmation" class="col-sm-4 col-form-label">Booking Confirmation</label>
        <div class="col-sm-8">
          <mat-form-field>
            <mat-select [disabled]="isEditable">
              <mat-option>None</mat-option>
              <mat-option *ngFor="let service of services" [value]="service">{{service}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="form-group row">
        <label for="inputPassword" class="col-sm-4 col-form-label">First remainder</label>
        <div class="col-sm-8">
          <mat-form-field>
            <mat-select [disabled]="isEditable">
              <mat-option>None</mat-option>
              <mat-option *ngFor="let service of services" [value]="service">{{service}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="form-group row">
        <label for="inputPassword" class="col-sm-4 col-form-label">Second remainder</label>
        <div class="col-sm-8">
          <mat-form-field>
            <mat-select [disabled]="isEditable">
              <mat-option>None</mat-option>
              <mat-option *ngFor="let service of services" [value]="service">{{service}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="form-group row">
        <label for="inputPassword" class="col-sm-4 col-form-label">Follow up note</label>
        <div class="col-sm-8">
          <mat-form-field class="example-full-width">
            <input matInput placeholder="First Remainder" type="text" [disabled]="isEditable">
          </mat-form-field>
        </div>
      </div> -->
    </div>
  </form> 
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary" (click)="createEvent()">Create Schedule</button>
</div>