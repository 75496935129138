import { Injectable } from '@angular/core';
import { Api } from './api';

@Injectable({
  providedIn: 'root'
})
export class MasterService {

  public masterList: any = [];

  constructor(public api: Api) { }

  getMasterList() {   
    let seq = this.api.get('Master/List');
    return seq;
  }

  fetchMasterList(){
    this.getMasterList().subscribe((res: any) => {
      if (res) {        
        this.masterList = res;
      }
    });
  }

  getMasterItems(category){
    var master = this.masterList.filter(e=> (e.category === category));
    if (master && master[0]) {
      return master[0].items;
    } else {
      return [];
    }
    
  }
}
