<div class="modal-header">
  <h4 class="modal-title pull-left">{{title}}</h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form class="client-modal" [formGroup]="recordPaymentDocumentForm">
    <div class="form-row">
      <div style="float: left; width: 50px; height:50px;">
        <ngx-avatar size="40" name="{{clientInfo.NameFirst}} {{clientInfo.NameLast}}"></ngx-avatar>
      </div>
      <div style="float: left; width: 80%;">
        <div class="client-list-left">
          <h6 class="mt-0 mb-1">{{clientInfo.NameFirst | titlecase}} {{clientInfo.NameLast | titlecase}}</h6>
          <span><i class="fas fa-at"></i>{{clientInfo.Email}}</span>
        </div>
      </div>
    </div>
    <!-- <div class="form-row">
      <div class="col-md-12">        
        <h5>Payment For: {{invoiceInfo.Label}} #{{invoiceInfo.InvoiceNumber}}</h5>
      </div>
    </div> -->
    <div class="form-row">
      <div class="col-md-6">
        <mat-form-field>            
            <input type="number" autocomplete="off" formControlName="amount" matInput placeholder="Amount (USD)" >                    
            <mat-error *ngIf="recordPaymentDocumentForm.controls.amount.errors != null && !formValid">
                Please enter amount
            </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-6">
        <label style="padding-top: 18px;font-weight: 500;"> out of ${{invoiceBalance}} USD </label>
      </div>
    </div>
    <div class="form-row">
      <!-- <div class="col-md-6">
        <mat-form-field>            
            <input type="text" autocomplete="off" [(ngModel)]="serviceName" formControlName="serviceText" matInput placeholder="Service or Product" >            
            <mat-error *ngIf="recordPaymentDocumentForm.controls.serviceText.errors != null && !formValid">
                Please enter service name
            </mat-error>
        </mat-form-field>
      </div> -->
      <div class="col-md-6">
        <mat-form-field>            
            <input type="text" [(ngModel)]="receiptNo" readonly autocomplete="off" formControlName="receiptNumber" matInput placeholder="Receipt Number" >            
            <mat-error *ngIf="recordPaymentDocumentForm.controls.receiptNumber.errors != null && !formValid">
                Please enter receipt number
            </mat-error>
        </mat-form-field>
      </div>
      <div class="form-group col-md-6">
        <mat-form-field>
          <input matInput autocomplete="off" formControlName="paymentDate" [matDatepicker]="paymentDatePicker" placeholder="Paid On">
          <mat-datepicker-toggle matSuffix [for]="paymentDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #paymentDatePicker></mat-datepicker>
          <mat-error *ngIf="recordPaymentDocumentForm.controls.paymentDate.errors != null && !formValid">
              Please select payment date
            </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="form-row">
      <div class="col-md-6">
        <mat-form-field>
          <mat-label>Payment Method</mat-label>
          <mat-select [(ngModel)]="module" formControlName="paymentMethod">
            <mat-option *ngFor="let payment of paymentMethods" [value]="payment">
              {{payment}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="recordPaymentDocumentForm.controls.paymentMethod.errors != null && !formValid">
            Please select payment method
          </mat-error>
        </mat-form-field>
      </div>
    </div>

  <div class="card-payment-checkbox">
    <mat-checkbox formControlName="sendReceipt" >Send Receipt to client</mat-checkbox>
  </div>

  <!-- <div class="card-payment-checkbox">
    <mat-checkbox formControlName="issueAnInvoice" >Issue an invoice for this payment</mat-checkbox>
  </div> -->


  </form>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">{{closeBtnName}}</button>
  <button type="button" class="btn btn-primary" (click)="recordPayment()">Save</button>
</div>