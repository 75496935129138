<div class="section-container">
  <div class="subheader">
      <h2>Services</h2>
  </div>
  <div class="white-bg rounded border-0">
    <div class="row top-menu bg-light bg-grey no-gutters">
      <div class="col-md-6">
          <div class="top-menu-container">
            <a (click)="openServiceModal()" class="btn btn-primary">Create Service</a>
            </div>
      </div>
      <div class="col-md-6">
          <form class="search-card">
              <div class="card-body row no-gutters align-items-center">
                  <div class="col search-card-field">
                      <i class="fas fa-search icon-serach"></i>
                      <input [(ngModel)]="searchText" name="searchText" class="form-control  form-control-borderless" type="search" placeholder="Search">
                  </div>
                 
              </div>
          </form>
      </div>
    </div>
    <div class="row form-page-header">
      <div class="col-md-4">
        <form class="form-inline">
          <label for="inlineFormInputName2" class="mrg-rt-5">Sort By</label>
          <mat-form-field>
              <select matNativeControl required  (change)="sortFieldChange($event)">
                  <option value="CreatedOn">Created On</option>
                <option value="ModuleName">Module Name</option>
                <option value="UnitPrice">Service Fee</option>
              </select>
            </mat-form-field>
        </form>
      </div>
      <div class="col-md-6" *ngIf="officeList && officeList.length > 0">
        <mat-form-field>
          <select  matNativeControl placeholder="Office" (change)="getOfficeFilter($event)" [(ngModel)]="selectedOffice">
            <!--<mat-option>None</mat-option>-->
            <option value="All">All</option>
            <option *ngFor="let service of officeList" [value]="service.Soid">{{service.CompanyName}} </option>
            <!--<mat-option value="Custom">Custom</mat-option>-->
          </select>
        </mat-form-field>
      </div>
      <div class="col-md-4 text-right">
          <span class="mrg-rt-5 search-results">Showing Services(filtered)</span>
      </div>
    </div>
    <div class="client-lists">
      <div class="row service-list">
          <div class="col-md-12">
              <ul class="list-unstyled">                 
                  <li class="media" *ngFor="let services of serviceList | 
                  simpleSearch : 'Description,ModuleName,UnitPrice': searchText | slice: lowValue : highValue" (click)="editService(services.OfficeSoid, services.Soid)">                     
                    
                        <div class="media-body">
                            <div class="row no-gutters">
                                <div class="client-list-left col-md-4">
                                  <h6 class="mt-0 mb-1">Service Name - {{services.Item | titlecase}}</h6>
                                  <span>Module - {{services.ModuleName}}</span>
                                  <div><span>Price - {{services.UnitPrice}} USD</span></div>
                                </div>
                                <div class="col-md-3 result-date text-right">
                                 <span> {{services.CreatedOn | date:"dd MMM yyyy"}}</span>
                                </div>
                            </div>
                        </div>
                      </li>
                </ul>
          </div>
      </div>
      <div class="row">
        <div class="col-md-4 offset-md-8">
        <mat-paginator class="pagination" [length]="length"
            [pageSize]="pageSize"
            [pageSizeOptions]="pageSizeOptions" (page)="getPaginatorData($event)"
            >
        </mat-paginator>
        </div>
      </div>
    </div>
  </div>
</div>