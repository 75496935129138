<mat-card>
    <div class="form-row">
        <div class="form-group col-md-12">
            <span class="label">Subject:</span>&nbsp;<span class="data">{{data.Subject}}</span>
        </div>       
    </div>
    <div class="form-row">
        <div class="form-group col-md-4">
            <span class="label">Created On:</span>&nbsp;<span class="data">{{data.CreatedOn | date:'MM-dd-yyyy hh:mm a'}}</span>
        </div>
        <div class="form-group col-md-4">
            <span class="label">By:</span>&nbsp;<span class="data">{{data.CreatedBy}}</span>
        </div>
        <div class="form-group col-md-4">
            <span class="label">At:</span>&nbsp;<span class="data">{{data.CreatedAt}}</span>
        </div>        
    </div>
    <div class="form-row">
        <div class="form-group col-md-4">
            <span class="label">Office:</span>&nbsp;<span class="data">{{data.OfficeName}}</span>
        </div>
        <div class="form-group col-md-4">
            <span class="label">Originator:</span>&nbsp;<span class="data">{{data.OriginatorName}}</span>
        </div>
        <div class="form-group col-md-4">
            <span class="label">Assignee:</span>&nbsp;<span class="data">{{data.AssigneeName}}</span>
        </div>        
    </div>
    <div class="form-row">
        <div class="form-group col-md-12">
            <div class="label">Collaborators:</div>
            <div *ngFor="let item of data.Collaborators">
                <div class="data" style="padding-left: 20px;"><span>{{item.UserName}}</span>&nbsp;<span>[{{item.Role}}]</span></div>
            </div>            
        </div>       
    </div>
    <div class="form-row">
        <div class="form-group col-md-4">
            <span class="label">Next Step:</span>&nbsp;<span class="data">{{data.NextStep}}</span>
        </div>
        <div class="form-group col-md-4">
            <span class="label">On:</span>&nbsp;<span class="data">{{data.NextStepOn}}</span>
        </div>
        <div class="form-group col-md-4">
            <span class="label">By:</span>&nbsp;<span class="data">{{nextStepBy}}</span>
        </div>        
    </div>
    <div class="form-row">
        <div class="form-group col-md-4">
            <span class="label">Status:</span>&nbsp;<span class="data">{{data.Status}}</span>
        </div>
        <div class="form-group col-md-4">
            <span class="label">Priority:</span>&nbsp;<span class="data" [ngClass]="setPriorityColor(data)">{{data.Priority}}</span>
        </div>
        <div class="form-group col-md-4">
            <span class="label">Due On:</span>&nbsp;<span class="data">{{data.DueOn | date:'MM-dd-yyyy hh:mm a'}}</span>
        </div>        
    </div>
    <div class="form-row">
        <div class="form-group col-md-4">
            <span class="label">Version Found:</span>&nbsp;<span class="data">{{data.VersionFound}}</span>
        </div>
        <div class="form-group col-md-4">
            <span class="label">Version Fixed:</span>&nbsp;<span class="data">{{data.VersionFixed}}</span>
        </div>
        <div class="form-group col-md-4">
            <span class="label">Category:</span>&nbsp;<span class="data">{{data.Category}}</span>
        </div>        
    </div>
    <div class="form-row">
        <div class="form-group col-md-12">
            <span class="label">Description:</span>&nbsp;<span class="data">{{data.Description}}</span>
        </div>       
    </div>
    <div class="form-row">
        <div class="form-group col-md-12">
            <span class="label">Resolution:</span>&nbsp;<span class="data">{{data.Resolution}}</span>
        </div>       
    </div>
    <div class="form-row">
        <div class="form-group col-md-12">
            <div class="label">Comments:</div>
            <div *ngFor="let item of data.Comments">
                <div class="form-row" style="padding-left: 20px;">
                    <div class="form-group col-md-3">
                        <span class="label">CreatedBy:</span>&nbsp;<span class="data">{{item.CreatedBy}}</span>
                    </div>
                    <div class="form-group col-md-3">
                        <span class="label">CreatedOn:</span>&nbsp;<span class="data">{{item.CreatedOn | date:'MM-dd-yyyy hh:mm a'}}</span>
                    </div>
                    <div class="form-group col-md-6">
                        <span class="label">Content:</span>&nbsp;<span class="data">{{item.Content}}</span>
                    </div>        
                </div>
            </div> 
        </div>       
    </div>
</mat-card>

