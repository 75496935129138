import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LayoutComponent } from '../base/layout/layout.component';
import { StaffComponent } from './staff/staff.component';
import { ClientComponent } from './client/client.component';
import { AdminComponent } from './admin/admin.component';
import { HomeComponent } from './home/home.component';
import { EditComponent } from './client/edit/edit.component'; 
import { StaffEditComponent } from './staff/edit/staffedit.component';
import { RouterGuard } from '../guards/router-guard.service';
import { RoleGuard } from '../guards/role-guard.service';
import { ScheduleComponent } from './schedule/schedule.component';
import { ServiceComponent } from './service/service.component';
import { AppoinmentComponent } from './schedule/appoinment/appoinment.component';
import { OfficeComponent } from './office/office.component';
import { OfficeViewComponent } from './office/view/view.component';
import { DocumentsComponent } from './documents/documents.component';
import { ViewDocumentComponent } from './documents/view-document/view-document.component';
import { ViewInvoiceComponent } from './invoice/view-invoice/view-invoice.component';
import { ViewEstimatesComponent } from './invoice/view-estimates/view-estimates.component';
import { ServiceViewComponent } from './service/view/view.component';
import { TaskComponent } from './tasks/tasks.component';
import { TaskEditComponent } from './tasks/edit/taskedit.component';
import { InvoiceListComponent } from './invoice/list/invoice-list.component';
import { PaymentsComponent } from './invoice/payments/payments.component';
import { ModulesListComponent } from './modules/modules-list.component';
import {DashboardComponent} from './dashboard/dashboard.component';
import { ViewRecieptComponent } from './invoice/view-reciept/view-reciept.component';
import { InvoicesComponent } from './xero/invoices/invoices.component';
import { XeroCodeComponent } from './xero/xero-code/xero-code.component';

const featureRoutes: Routes = [
  {
    path: '',
    component: LayoutComponent,  
    canActivate: [RouterGuard],
    children: [
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      { path: 'dashboard', component: DashboardComponent},      
      { path: 'client', component: ClientComponent},
      { path: 'client/:loginId', component: EditComponent},
      { path: 'client/:loginId/invoice/:invoiceId', component: ViewInvoiceComponent},      
      { path: 'client/:loginId/invoice/:invoiceId/receipt/:recieptId', component: ViewRecieptComponent},      
      { path: 'client/:loginId/estimate/:estimateId', component: ViewEstimatesComponent},
      { path: 'invoices', component: InvoiceListComponent},
      { path: 'payments', component: PaymentsComponent},
      { path: 'client/:loginId/invoice/:invoiceId', component: ViewInvoiceComponent},
	    { path: 'staff', component: StaffComponent},
      { path: 'staff/:loginId', component: StaffEditComponent},
      { path: 'home', component: HomeComponent},
      { path: 'schedule', component: ScheduleComponent},    
      { path: 'appointment/:eventId', component: AppoinmentComponent},
      { path: 'appointment', component: AppoinmentComponent},
      { path: 'documents', component: DocumentsComponent},
      { path: 'document/:soId', component: ViewDocumentComponent},
	    { path: 'service', component: ServiceComponent},
      { path: 'office/:officeSoid/service/:soId', component: ServiceViewComponent},
      { path: 'office/:soId', component: OfficeViewComponent},
      { path: 'office', component: OfficeComponent},     
      { path: 'tasks', component: TaskComponent},
      { path: 'modules', component: ModulesListComponent},
      { path: 'xero', component: InvoicesComponent},
      { path: 'task/:taskId', component: TaskEditComponent},
      { path: 'xero-code', component: XeroCodeComponent},
      { path: 'admin', component: AdminComponent, 
      data: {role: 'User'},
      canActivate: [RoleGuard]}

      
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(featureRoutes)],
  exports: [RouterModule]
})
export class FeatureRoutingModule { }
