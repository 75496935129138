
<div class="modal-header">
  <h4 class="modal-title pull-left">{{header}}</h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body contentScroll" style="height: 450px; overflow-y: auto; overflow-x: hidden;">
    
  <form class="client-modal" [formGroup]="eventForm">
      <div class="form-row" *ngIf="participantInfo[0]">
        <div style="float: left; width: 50px; height:50px;">
            <ngx-avatar size="40"  name="{{participantInfo[0].NameFirst}} {{participantInfo[0].NameLast}}"></ngx-avatar>  
        </div>
        <div style="float: left; width: 80%;">
            <div class="client-list-left">
              <h6 class="mt-0 mb-1">{{participantInfo[0].NameFirst}} {{participantInfo[0].NameLast}}</h6>
              <span><i class="fas fa-at"></i>{{participantInfo[0].EmailAddress}}</span>
            </div>
        </div>
      </div>
      
    


    <div class="form-row">
        <div class="form-group col-md-12">
            <mat-form-field>
              <mat-label>Please select the staff</mat-label>
              <mat-select formControlName="staff" [(ngModel)]="this.staff">
                <mat-option *ngFor="let staff of staffList" [value]="staff.PersonSoid">({{staff.NameFirst | titlecase}} {{staff.NameLast | titlecase}} ) {{staff.EmailAddress}}</mat-option>
              </mat-select>
              <mat-error *ngIf="eventForm.controls.staff.errors != null && !formValid">
                  Please select staff
                </mat-error>
            </mat-form-field>
        </div>
    </div>


  </form> 
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary" (click)="createEvent()">Assign</button>
</div>