import { Component, OnInit, ViewChild } from '@angular/core';

import { CalendarOptions, DateSelectArg, EventClickArg, EventApi } from '@fullcalendar/angular';
import { INITIAL_EVENTS, createEventId } from './event-utils';
import { DashboardService } from '../../services/dashboard.service';
import { Router } from '@angular/router';
import { EventsService } from '../../services/events.service';
import { SearchclientComponent } from '../modal/searchclient/searchclient.component';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
  templateUrl: 'dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  
  modalRef: BsModalRef;
  todayAmount: string;
  thisMonthAmount: string;
  currentDate = new Date();
  thisWeekAmount: string;
  thisYearAmount: string;
  calendarVisible = true;
  overDueAmount:string;
  dueAmount: string;
  notYetDueAmount: string;
  upcomingEvents: any = [];
  calendarOptions: CalendarOptions = {
    timeZone: 'UTC',
    height: 400,
    contentHeight: 400,
    aspectRatio: 2,
    headerToolbar: {
      left: 'prev,next today',
      center: 'title',
      right: 'dayGridMonth,dayGridWeek,listWeek'
      //dayGridWeek, dayGridMonth,timeGridWeek,listWeek
    },
    initialView: 'dayGridWeek',
    initialEvents: INITIAL_EVENTS, // alternatively, use the `events` setting to fetch from a feed
    weekends: true,
    editable: true,
    selectable: true,
    selectMirror: false,
    dayMaxEvents: false,
    select: this.handleDateSelect.bind(this),
    eventClick: this.handleEventClick.bind(this),
    buttonText: {
      today:    'Today',
      month:    'Month',
      week:     'Week',
      day:      'Day',
      list:     'List'
    },
    events:[{}]
    /* you can update a remote database when these fire:
    eventAdd:
    eventChange:
    eventRemove:
    */
  };
  currentEvents: EventApi[] = [];

  constructor(private _dashboardService: DashboardService,  private modalService: BsModalService, 
    private _eventsService: EventsService, 
    private _router: Router, ) { }

  ngOnInit() {
    this.getDashboardStatusCount();

    this.getEvents();

    this.getUpComingEvents();
  }

  private getDashboardStatusCount() {
    this._dashboardService.getStatusCount().subscribe((res: any) => {
      if (res) {
        this.overDueAmount = res.overDuePayment;
        this.dueAmount= res.duePayment;
        this.notYetDueAmount= res.notYetDue;
        this.todayAmount= res.todayAmount;
        this.thisWeekAmount= res.thisWeekAmount;
        this.thisMonthAmount= res.thisMonthAmount;
        this.thisYearAmount= res.thisYearAmount;
        
      }
    });

    
  }

  handleDateClick(arg) {
    alert('date click! ' + arg)
  }

  handleDateSelect(selectInfo: DateSelectArg) {
    const calendarApi = selectInfo.view.calendar;

    calendarApi.unselect(); // clear date selection

    const initialState = {
      title: "Client search",
      backdrop: true,
      ignoreBackdropClick: true
      //,    selectedDate: date.getDate
    };
    this.modalRef = this.modalService.show(SearchclientComponent, { initialState });
  }

  handleEventClick(clickInfo: EventClickArg) {
    this._router.navigate(['/appointment/'+ clickInfo.event.id]);
  }

  handleEvents(events: EventApi[]) {
    this.currentEvents = events;
  }
  
  private getUpComingEvents() {
    var event: any = {};
   // this.events = [];
    
    this._eventsService.getUpComingEvents().subscribe((res: any) => {
      if (res) {
        this.upcomingEvents = res;
      }
    });
  }

  private getEvents() {
    var event: any = {};
   // this.events = [];
    
    this._eventsService.getEvents().subscribe((res: any) => {
      if (res) {
          this.refreshCalendarView(res);
      }
    });
  }

  appointmentPage(appointmentSoid): void {
    this._router.navigate(['/appointment/'+ appointmentSoid]);
    //this.modalService.show(this.modalContent);
  }


  private refreshCalendarView(res: any) {
    var event: any = [];
    for (var index = 0; index < res.length; index++) {
      var eventInfo = res[index];


      var partInfo = eventInfo.Participants.filter(e => e.ParticipantType == "Customer");

      event.push({
        title: eventInfo.Subject + ' (' + partInfo[0].ParticipantName + ')',
        start: new Date(eventInfo.Time.Start),
        end: new Date(eventInfo.Time.End),
        id: eventInfo.Soid,
        
      eventBackgroundColor: 'blue',
      });
    }
    this.calendarOptions = {
      height: 400,
      contentHeight: 400,
      aspectRatio: 2,
      headerToolbar: {
        left: 'prev,next today',
        center: 'title',
        right: 'dayGridMonth,dayGridWeek,listWeek'
        //dayGridWeek, dayGridMonth,timeGridWeek,listWeek
      },
      initialView: 'dayGridWeek',
      initialEvents: INITIAL_EVENTS, // alternatively, use the `events` setting to fetch from a feed
      weekends: true,
      editable: true,
      selectable: true,
      selectMirror: false,
      dayMaxEvents: false,
      select: this.handleDateSelect.bind(this),
      eventClick: this.handleEventClick.bind(this),
      buttonText: {
        today:    'Today',
        month:    'Month',
        week:     'Week',
        day:      'Day',
        list:     'List'
      },
      eventColor: '#378006',
      eventBackgroundColor: 'blue',
      events:event,
      eventTimeFormat :{
        hour: 'numeric',
        minute: '2-digit',
        meridiem: 'short'
      },
      displayEventEnd: true
      /* you can update a remote database when these fire:
      eventAdd:
      eventChange:
      eventRemove:
      */
    };
  }

    

}
