<div class="modal-header">
    <h4 class="modal-title pull-left">{{header}}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form class="client-modal" [formGroup]="newTimeForAppoinmentForm">
      <div class="form-row">
          <div class="col-md-3">
              <mat-form-field>
                <input matInput autocomplete="off" formControlName="startEventDate" [(ngModel)]="startEventDate" [matDatepicker]="startDate" placeholder="Start Date">
                                                    
         
                <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
                <mat-datepicker #startDate></mat-datepicker>
              </mat-form-field>
          </div>
          <div class="col-md-3">
              <mat-form-field>
                <mat-select placeholder="Start Time"  formControlName="startDateTime" [(ngModel)]="startDateTime">
                  <mat-option>None</mat-option>
                  <mat-option *ngFor="let hour of startTimeList" [value]="hour">{{hour}}</mat-option>
                </mat-select>
              </mat-form-field>
          </div>
          <div class="col-md-3">
              <mat-form-field>
                <input matInput formControlName="endEventDate" [(ngModel)]="endEventDate" [matDatepicker]="endTime" placeholder="End Date">
                <mat-datepicker-toggle matSuffix [for]="endTime"></mat-datepicker-toggle>
                <mat-datepicker #endTime></mat-datepicker>
              </mat-form-field>
          </div>
          <div class="col-md-3">
              <mat-form-field>
                <mat-select placeholder="End Time" formControlName="endDateTime" [(ngModel)]="endDateTime">
                  <mat-option>None</mat-option>
                  <mat-option *ngFor="let hour of endTimeList" [value]="hour">{{hour}}</mat-option>
                </mat-select>
              </mat-form-field>
          </div>
      </div>
      <div class="form-row">
          <mat-form-field>
              <input type="text" formControlName="message" matInput placeholder="Message">
          </mat-form-field>
      </div>
     </form> 
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="save()">{{closeBtnName}}</button>
  </div>