import {NgModule, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {MatMenuModule} from '@angular/material/menu';
import {MatInputModule} from '@angular/material/input';
import {OwlDateTimeModule, OwlNativeDateTimeModule} from 'ng-pick-datetime';
import {MatButtonModule} from '@angular/material/button';
import {MatSelectModule} from '@angular/material/select';
import {MatIconModule} from '@angular/material/icon';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import {MatTabsModule} from '@angular/material/tabs';
import {MatTableModule} from '@angular/material/table';
import {MatSortModule} from '@angular/material/sort';
import {MatCardModule} from '@angular/material/card';
import {MatDividerModule} from '@angular/material/divider';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatRadioModule} from '@angular/material/radio';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {LayoutComponent} from '../base/layout/layout.component';
import {SimpleSearchPipe} from './pipes/simple-search.pipe';
import {ModulesValuesPipe} from './pipes/modules-value.pipe';
import {PhonePipe} from './pipes/phone.pipe';
import {ReversePipe} from './pipes/reverse.pipe';
import {ClientComponent} from './client/client.component';
import {AdminComponent} from './admin/admin.component';
import {HomeComponent} from './home/home.component';
import {EditComponent} from './client/edit/edit.component';
import {StaffEditComponent} from './staff/edit/staffedit.component';
import {RouterGuard} from '../guards/router-guard.service';
import {RoleGuard} from '../guards/role-guard.service';
import {FeatureRoutingModule} from './feature-routing.module';
import {TabsModule} from 'ngx-bootstrap/tabs';
import {ModalModule} from 'ngx-bootstrap/modal';
import {NgMultiSelectDropDownModule} from 'ng-multiselect-dropdown';
import {MatPaginatorModule} from '@angular/material/paginator';
import {ToastrModule} from 'ngx-toastr';
import {EventPlannerComponent} from '../base/eventplanner/eventplanner.component';
import {TooltipModule} from 'ngx-bootstrap/tooltip';

import {BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import {AvatarModule} from 'ngx-avatar';
import {GooglePlacesComponent} from '../base/googleplaces/googleplaces.component';
import {ScheduleComponent} from './schedule/schedule.component';
import {CalendarModule, DateAdapter} from 'angular-calendar';
import {adapterFactory} from 'angular-calendar/date-adapters/date-fns';
import {FlatpickrModule} from 'angularx-flatpickr';
import {SidebarModule} from 'ng-sidebar';
import {ServiceComponent} from './service/service.component';
import {AddServiceComponent} from './modal/addservice/add-service.component';
import {AssignStaffComponent} from './modal/assignstaff/assignstaff.component';
import {AddOfficeComponent} from './modal/addoffice/addoffice.component';
import {StaffComponent} from './staff/staff.component';
import {AppoinmentComponent} from './schedule/appoinment/appoinment.component';
import {SearchclientComponent} from './modal/searchclient/searchclient.component';
import {OfficeComponent} from './office/office.component';
import {RescheduleAppoinmentComponent} from './modal/reschedule-appoinment/reschedule-appoinment.component';
import {NewTimeForAppoinmentComponent} from './modal/new-time-for-appoinment/new-time-for-appoinment.component';
import {ResheduleAppoinmentInfoComponent} from './modal/reshedule-appoinment-info/reshedule-appoinment-info.component';
import {CancelAppoinmentComponent} from './modal/cancel-appoinment/cancel-appoinment.component';
import {OfficeViewComponent} from './office/view/view.component';
import {DocumentsComponent} from './documents/documents.component';
import {AddMyDocumentsComponent} from './modal/add-my-documents/add-my-documents.component';
import {AddClientDocumentsComponent} from './modal/add-client-documents/add-client-documents.component';

import {AddClientComponent} from './modal/add-client/add-client.component';
import {NgBootstrapFileuploadAngularModule} from 'ng-bootstrap-fileupload-angular';
import {EditDocumentComponent} from './modal/edit-document/edit-document.component';
import {ViewDocumentComponent} from './documents/view-document/view-document.component';
import {AccordionModule} from 'ngx-bootstrap/accordion';
import {RecordPaymentComponent} from './modal/record-payment/record-payment.component';
import {AddProductComponent} from './modal/add-product/add-product.component';
import {ServiceInvoiceComponent} from './modal/service-invoice/service-invoice.component';
import {ServiceEstimateComponent} from './modal/service-estimate/service-estimate.component';
import {ViewInvoiceComponent} from './invoice/view-invoice/view-invoice.component';
import {ViewRecieptComponent} from './invoice/view-reciept/view-reciept.component';
import {ViewEstimatesComponent} from './invoice/view-estimates/view-estimates.component';
import {NgxMaskModule} from 'ngx-mask';
import {NgxMatIntlTelInputModule} from 'ngx-mat-intl-tel-input';
import {MatChipsModule} from '@angular/material/chips';
import {MatSelectCountryModule} from '@angular-material-extensions/select-country';
import {ServiceViewComponent} from './service/view/view.component';
import {TaskComponent} from './tasks/tasks.component';
import {TaskViewComponent} from './tasks/view/taskview.component';
import {TaskEditComponent} from './tasks/edit/taskedit.component';
import {TaskAddComponent} from './tasks/add/taskadd.component';
import {MatListModule} from '@angular/material/list';
import {PaymentsComponent} from './invoice/payments/payments.component';
import {InvoiceListComponent} from './invoice/list/invoice-list.component';
import {RefundConfirmationComponent} from './modal/refund-confirmation/refund-confirmation.component';
import {NgxMatSelectSearchModule} from 'ngx-mat-select-search';
import {BusinessInfoComponent} from './modal/business-info/business-info.component';
import {PersonNoteComponent} from './modal/person-note/person-note.component';
import {MatExpansionModule} from '@angular/material/expansion';
import {AddDependentComponent} from './modal/adddependent/adddependent.component';
import {ConfirmationDialog} from './modal/confirmation-dialog/confirmation-dialog.component';
import {ModulesListComponent} from './modules/modules-list.component';
import {AddModulesComponent} from './modal/add-modules/add-modules.component';
import {AddClientInsuranceComponent} from './modal/add-insurance-info/add-insurance-info.component';
import {DashboardComponent} from './dashboard/dashboard.component';
import {FullCalendarModule} from '@fullcalendar/angular'; // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!
import interactionPlugin from '@fullcalendar/interaction'; // a plugin!
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import {ChangePasswordComponent} from './modal/change-password/change-password.component';
import {TakePaymentComponent} from './modal/take-payment/take-payment.component';
import {ArrayFilterPipe} from './pipes/filter-array.pipe';
import {InvoicesComponent} from './xero/invoices/invoices.component';
import {XeroCodeComponent} from './xero/xero-code/xero-code.component';
import {ListPaymentComponent} from './modal/list-payments/list-payments.component';

import {ListInvoiceComponent} from './modal/list-invoice/list-invoice.component';

import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {ForgotPasswordComponent} from './modal/forgot-password/forgot-password.component';
import { EstimateToInvoiceComponent } from './modal/estimate-to-invoice/estimate-to-invoice.component';
import {XeroTenantsComponent} from './modal/xero-tenants/xero-tenants.component';


FullCalendarModule.registerPlugins([ // register FullCalendar plugins
  dayGridPlugin,
  interactionPlugin,
  listPlugin,
  timeGridPlugin
]);

@NgModule({
  declarations: [

    TakePaymentComponent,
    ViewRecieptComponent,
    DashboardComponent,
    AddClientInsuranceComponent,
    AddModulesComponent,
    ConfirmationDialog,
    AddDependentComponent,
    PersonNoteComponent,
    BusinessInfoComponent,
    RefundConfirmationComponent,
    InvoiceListComponent,
    PaymentsComponent,
    ScheduleComponent,
    SimpleSearchPipe,
    ModulesValuesPipe,
    PhonePipe,
    ReversePipe,
    ArrayFilterPipe,
    StaffEditComponent,
    StaffComponent,
    LayoutComponent,
    ClientComponent,
    AdminComponent,
    HomeComponent,
    EditComponent,
    EventPlannerComponent,
    GooglePlacesComponent,
    ServiceComponent,
    AddServiceComponent,
    AssignStaffComponent,
    AppoinmentComponent,
    SearchclientComponent,
    RescheduleAppoinmentComponent,
    NewTimeForAppoinmentComponent,
    ResheduleAppoinmentInfoComponent,
    CancelAppoinmentComponent,
    OfficeComponent,
    AddOfficeComponent,
    AddMyDocumentsComponent,
    EstimateToInvoiceComponent,
    AddClientDocumentsComponent,
    AddClientComponent,
    OfficeViewComponent,
    DocumentsComponent,
    EditDocumentComponent,
    ViewDocumentComponent,
    RecordPaymentComponent,
    AddProductComponent,
    ServiceInvoiceComponent,
    ServiceEstimateComponent,
    ViewInvoiceComponent,
    ViewEstimatesComponent,
    ServiceViewComponent,
    TaskComponent,
    TaskViewComponent,
    TaskEditComponent,
    ModulesListComponent,
    ChangePasswordComponent,
    ListPaymentComponent,
    InvoicesComponent,
    TaskAddComponent,
    ForgotPasswordComponent,
    XeroCodeComponent,
    XeroTenantsComponent,
    ListInvoiceComponent
  ],
  imports: [
    MatSlideToggleModule,
    FullCalendarModule,
    MatExpansionModule,
    MatSelectCountryModule.forRoot('en'),
    NgxMatSelectSearchModule,
    MatChipsModule,
    NgxMatIntlTelInputModule,
    NgxMaskModule.forRoot(),
    AccordionModule.forRoot(),
    NgBootstrapFileuploadAngularModule,
    AvatarModule.forRoot({}),
    CommonModule,
    FormsModule,
    SidebarModule.forRoot(),
    ReactiveFormsModule,
    FeatureRoutingModule,
    BrowserAnimationsModule,
    BsDropdownModule,
    MatMenuModule,
    MatInputModule,
    MatButtonModule,
    MatSelectModule,
    MatIconModule,
    MatDatepickerModule,
    MatTabsModule,
    MatTableModule,
    MatSortModule,
    MatCardModule,
    MatDividerModule,
    MatAutocompleteModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    MatFormFieldModule,
    MatListModule,
    MatNativeDateModule,
    MatRadioModule,
    MatCheckboxModule,
    TabsModule.forRoot(),
    ModalModule.forRoot(),
    TooltipModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),
    MatPaginatorModule,
    ToastrModule.forRoot({
      positionClass: 'toast-top-center'
    }),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
    BsDatepickerModule.forRoot(),
    FlatpickrModule.forRoot(),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [RouterGuard, RoleGuard, MatDatepickerModule],
  exports: [FormsModule, ReactiveFormsModule],
  entryComponents: [ChangePasswordComponent, PersonNoteComponent, EventPlannerComponent,
    AddServiceComponent, AssignStaffComponent,
    SearchclientComponent, RescheduleAppoinmentComponent,
    NewTimeForAppoinmentComponent, ResheduleAppoinmentInfoComponent,
    CancelAppoinmentComponent, AddOfficeComponent, AddMyDocumentsComponent,
    AddClientDocumentsComponent, EditDocumentComponent, AddClientComponent,
    RecordPaymentComponent, AddProductComponent,EstimateToInvoiceComponent,
    ServiceInvoiceComponent, AddModulesComponent,
    ServiceEstimateComponent, RefundConfirmationComponent,XeroTenantsComponent,
    BusinessInfoComponent, AddClientInsuranceComponent,ListInvoiceComponent,
    AddDependentComponent, ConfirmationDialog,
    TakePaymentComponent, ListPaymentComponent, ViewRecieptComponent
  ]
})
export class FeatureModule {
}
