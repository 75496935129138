import { FormBuilder, FormGroup } from '@angular/forms';
import { Component, OnInit, TemplateRef } from '@angular/core';
import {FormControl, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import { XeroService } from '../../../services/xero.service';
import { DatePipe } from '@angular/common';
import {ToastrService} from 'ngx-toastr';
import {PageEvent} from '@angular/material/paginator'; 
import * as _ from 'underscore';

@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.css']
})
export class InvoicesComponent implements OnInit {

  myForm:FormGroup;
  clientSubmitForm: FormGroup;
  disabled = false;
  ShowFilter = false;
  limitSelection = false;
  selectedItems: any = [];
  searchText: string;
  dropdownSettings: any = {};
  lowValue:number = 0;
  highValue:number = 5; 
  clientRequest: any = {};
  firstName:string;
  lastName:string;
  formValid:boolean = true;
  subscriptionServices: any = [];
  agelimit:Date;
  sortBy:string = "";


  countries: string[] = [
    'Canada', 'Mexico', 'United States'
  ];

  invoices: any = [];

  // MatPaginator Inputs
  length = Number;
  pageIndex:number = 0;
  pageSize = 5;
  pageSizeOptions: number[] = [5, 10, 25, 100];  

  modalRef: BsModalRef;
  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);
  constructor(private _invoices: XeroService, private toast: ToastrService,
     private datePipe: DatePipe, private modalService: BsModalService,
      private _router: Router, private fb: FormBuilder, ) {}

  ngOnInit() {
    var date = new Date();
    date.setFullYear(date.getFullYear() - 18);
    this.agelimit = date;

    this.clientSubmitForm = new FormGroup({
      addressLine1: new FormControl('', Validators.compose([
        Validators.required
    ])),
      addressLine2: new FormControl('', Validators.compose([
    ])),
    email: new FormControl('', Validators.compose([
      Validators.required, Validators.email 
  ]))
      , firstName: new FormControl('', Validators.compose([
        Validators.required
      ])), lastName: new FormControl('', Validators.compose([
        Validators.required
      ])), state: new FormControl('', Validators.compose([
        Validators.required
      ]))
      , city: new FormControl('', Validators.compose([
        Validators.required
      ]))
      , dob: new FormControl('', Validators.compose([
        Validators.required
      ])), tag: new FormControl('', Validators.compose([
        Validators.required
      ])), status: new FormControl('', Validators.compose([
        Validators.required
      ])),country: new FormControl('', Validators.compose([
        Validators.required
      ])),phoneNo: new FormControl('', Validators.compose([
        Validators.required
      ])),pincode: new FormControl('', Validators.compose([
        Validators.required
      ]))
  });

  if (localStorage.getItem("Modules") && localStorage.getItem("Modules") != "undefined") {
    var services : any = [];
      services = JSON.parse(localStorage.getItem("Modules"));
      var service: any = {};

      for (var key in services) {
        service = {};
        service.itemId = key;
        service.itemText = services[key];
        this.subscriptionServices.push(service);
      }
  }

    this.getinvoices();
    
    
    this.myForm = this.fb.group({
        city: [this.selectedItems]
    });
    
  }

  
  private getinvoices() {
    this._invoices.getInvoices().subscribe((res: any) => {
      if (res && res.Invoices) {
        this.length = res.Invoices.length;
        this.invoices = res.Invoices
        ;
        this.invoices =_.sortBy(this.invoices, "CreatedOn");
      }
    });
  } 

  

  public getPaginatorData(event: PageEvent): PageEvent {
    this.lowValue = event.pageIndex * event.pageSize;
    this.highValue = this.lowValue + event.pageSize;
    return event;
  }

openServiceModal() {
  const initialState = {
    header: "New Service",
    services: "",
    clientInfo: "",
    backdrop: true,
  ignoreBackdropClick: true
};
 /** this.modalRef = this.modalService.show(AddserviceComponent);
  this.modalRef.content.event.subscribe(data => {
    this.getinvoices();
 }); */

}
 
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  editService(soId) {
    this._router.navigate(['service/' + soId]);
  }

  openPlannerModal() {
    /**this.modalRef = this.modalService.show(EventPlannerComponent);**/
  }

  addClient(){
    
  }

  sortFieldChange(event) {
    this.sortBy = event.srcElement.value;
    this.invoices =_.sortBy(this.invoices, this.sortBy);
  }

  onItemSelect(item: any) {
  }
  onSelectAll(items: any) {
  }
  toogleShowFilter() {
      this.ShowFilter = !this.ShowFilter;
      this.dropdownSettings = Object.assign({}, this.dropdownSettings, { allowSearchFilter: this.ShowFilter });
  }

  handleLimitSelection() {
      if (this.limitSelection) {
          this.dropdownSettings = Object.assign({}, this.dropdownSettings, { limitSelection: 2 });
      } else {
          this.dropdownSettings = Object.assign({}, this.dropdownSettings, { limitSelection: null });
      }
  }

  getShortName(fullName) { 
    return fullName.split(' ').map(n => n[0]).join('');
  }

  convertToArray(valueText){
    return valueText.split(",");
  }

  saveClient() {

    if (!this.clientSubmitForm.valid){
      for (let inner in this.clientSubmitForm.controls) {
        this.clientSubmitForm.get(inner).markAsTouched();
        this.clientSubmitForm.get(inner).updateValueAndValidity();
      }
      this.formValid = false;
      this.toast.warning("Fill the required fields.", "Warning!");
      return;
    }
    
    this.clientRequest.firstName = this.clientSubmitForm.controls.firstName.value;
    this.clientRequest.lastName = this.clientSubmitForm.controls.lastName.value;
    this.clientRequest.email = this.clientSubmitForm.controls.email.value;
    this.clientRequest.type = this.clientSubmitForm.controls.status.value;
    
    this.clientRequest.birthday = this.datePipe.transform(this.clientSubmitForm.controls.dob.value, 'MM/dd/yyyy');
    this.clientRequest.country = this.clientSubmitForm.controls.country.value;
    this.clientRequest.phoneNo = this.clientSubmitForm.controls.phoneNo.value;
    this.clientRequest.addressLine1 = this.clientSubmitForm.controls.addressLine1.value;
    this.clientRequest.addressLine2 = this.clientSubmitForm.controls.addressLine2.value;
    this.clientRequest.state = this.clientSubmitForm.controls.state.value;
    this.clientRequest.city = this.clientSubmitForm.controls.city.value;
    this.clientRequest.pincode = this.clientSubmitForm.controls.pincode.value;
    var tag = "";
    var tags = this.clientSubmitForm.controls.tag.value;
    tags.forEach(function(element) {
      if (tag === "") {
        tag = element;
      } else {
        tag = tag + "," + element;
      }
    });
    this.clientRequest.tag = tag;

    
  }

  transformDateFormat(dateValue) {
    return this.datePipe.transform(new Date(dateValue), 'MM/dd/yyyy');
  }

}
