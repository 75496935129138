<div class="section-container">
    <div class="subheader">
        <h2>Task</h2>
    </div>
    <div class="white-bg rounded border-0" *ngIf="showTask">
        <div class="row top-menu bg-light bg-grey no-gutters">
            <div class="col-md-6">
                <div class="top-menu-container">
                    <a class="btn btn-secondary">Notify All (not implemented)</a>
                    <a *ngIf="task.Status == 'New' || task.Status == 'Rejected'" (click)="onChangeStatusButtonClick('In Progress')" class="btn btn-primary">Inprogress</a>
                    <a *ngIf="task.Status == 'In Progress'" (click)="onChangeStatusButtonClick('Complete')" class="btn btn-success">Complete</a>
                    <a *ngIf="task.Status == 'Complete' && task.OriginatorSoid == userSoid" (click)="onChangeStatusButtonClick('Accepted')" class="btn btn-success">Accepted</a>
                    <a *ngIf="task.Status == 'Complete' && task.OriginatorSoid == userSoid" (click)="onChangeStatusButtonClick('Rejected')" class="btn btn-primary">Rejected</a>
                    <a *ngIf="task.Status == 'Accepted'" (click)="onChangeStatusButtonClick('Closed')" class="btn btn-success">Close</a>
                    <a *ngIf="task.Status != 'Cancel'" (click)="onChangeStatusButtonClick('Cancel')" class="btn btn-danger">Cancel</a>
                </div>
            </div>
        </div>
        <mat-card>
            <mat-card-title>{{task.Subject}}</mat-card-title>
            <mat-divider></mat-divider>
            <mat-card-content>
                <div class="form-row">
                    <div class="form-group col-md-1">
                        <span class="label">Created On:</span>
                    </div>   
                    <div class="form-group col-md-11">
                        <span class="data">{{task.CreatedOn | date:'MM-dd-yyyy hh:mm a'}}</span>
                    </div>    
                </div>
                <div class="form-row">
                    <div class="form-group col-md-1">
                        <span class="label">Created By:</span>
                    </div>   
                    <div class="form-group col-md-11">
                        <span class="data">{{task.CreatedBy}}</span>
                    </div>    
                </div>
                <div class="form-row">
                    <div class="form-group col-md-1">
                        <span class="label">Task Number:</span>
                    </div>   
                    <div class="form-group col-md-11">
                        <span class="data">{{task.TaskNumber}}</span>
                    </div>    
                </div>
                <div class="form-row">
                    <div class="form-group col-md-1">
                        <span class="label">Office:</span>
                    </div>   
                    <div class="form-group col-md-11">
                        <span class="data">{{task.OfficeName}}</span>
                    </div>    
                </div>
                <div class="form-row">
                    <div class="form-group col-md-1">
                        <span class="label">Originator:</span>
                    </div>   
                    <div class="form-group col-md-11">
                        <span class="data">{{task.OriginatorName}}</span>
                    </div>    
                </div>
                <div class="form-row">
                    <div class="form-group col-md-1">
                        <span class="label">Assignee:</span>
                    </div>   
                    <div class="form-group col-md-11">
                        <span class="data">{{task.AssigneeName}}</span>
                    </div>    
                </div>
                <div class="form-row">
                    <div class="form-group col-md-1">
                        <span class="label">Status:</span>
                    </div>   
                    <div class="form-group col-md-11">
                        <span class="data">{{task.Status}}</span>
                    </div> 
                </div>
                <div class="form-row">
                    <div class="form-group col-md-1">
                        <span class="label">Collaborators:</span>
                    </div>   
                    <div class="form-group col-md-11">
                        <div class="form-row">
                            <div class="form-group col-md-6">
                                <form class="example-form">
                                    <mat-form-field style="width: 100%;">
                                        <input class="data" type="text" [(ngModel)]="collaborator"  placeholder="Select Collaborator" aria-label="Number" matInput [formControl]="myControl" [matAutocomplete]="auto">
                                        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="OnCollabsSelected($event.option)">
                                            <mat-option  *ngFor="let option of filteredOptions | async" [value]="option.PersonName">
                                            {{option.PersonName}}
                                            </mat-option>
                                        </mat-autocomplete>
                                    </mat-form-field>
                                </form>
                            </div>
                            <div class="form-group col-md-6">
                                <a class="btn btn-primary" (click)="onAddCollaboratorButtonClick()">Add Collaborator</a>
                            </div>
                        </div>                  
                    </div>    
                </div>
                <div class="form-row">
                    <div class="form-group col-md-1"></div>
                    <div class="form-group col-md-11">
                        <div class="form-row" *ngFor="let item of task.Collaborators">
                            <div class="form-group col-md-4">
                                <span><a class="btn btn-secondary" (click)="onChangeToAssigneeButtonClick(item)">Change to Assignee</a></span>&nbsp;
                                <span><a class="btn btn-danger" (click)="openModal(template, item)">Delete Collaborator</a></span>
                            </div>
                            <div class="form-group col-md-8">
                                <div class="data"><span>{{item.UserName}}</span></div>
                            </div>                          
                        </div> 
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-md-1">
                        <span class="form-label">Priority:</span>
                    </div>   
                    <div class="form-group col-md-3">
                        <mat-form-field>
                            <mat-select class="data" id="Priority" (focus)="onSelectNumberFocusOut($event)" (selectionChange)="onSelectFocusOut($event)" [(value)]="task.Priority">              
                              <mat-option [value]="0">Low</mat-option>
                              <mat-option [value]="1">Normal</mat-option>
                              <mat-option [value]="2">High</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div> 
                    <div class="form-group col-md-1">
                        <span class="form-label">Due On:</span>
                    </div>   
                    <div class="form-group col-md-3">
                        <mat-form-field>
                            <!-- <input matInput class="data" id="DueOn" [matDatepicker]="picker" [(value)]="task.DueOn" (dateChange)="dateChange($event)">
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker> -->
                            <input matInput class="data" [owlDateTimeTrigger]="dt" [owlDateTime]="dt" id="DueOn" [(value)]="task.DueOn" (dateTimeChange)="dateTimeChange($event)">
                            <owl-date-time #dt [hour12Timer]="true"></owl-date-time>
                        </mat-form-field>
                    </div> 
                    <div class="form-group col-md-1">
                        <span class="form-label">Category:</span>
                    </div>   
                    <div class="form-group col-md-3">
                        <mat-form-field>
                            <mat-select class="data" id="Category" (focus)="onSelectFocus($event)" (selectionChange)="onSelectFocusOut($event)" [(value)]="task.Category">              
                                <mat-option [value]="'Customer'">Customer</mat-option>
                                <mat-option [value]="'Agency'">Agency</mat-option>
                                <mat-option [value]="'Ticket'">Ticket</mat-option>
                                <mat-option [value]="'To Do'">To Do</mat-option>
                                <mat-option [value]="'Bug'">Bug</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div> 
                </div>
                <div class="form-row">
                    <div class="form-group col-md-1">
                        <span class="form-label">Subject:</span>
                    </div>   
                    <div class="form-group col-md-11">
                        <mat-form-field style="width: 100%;">
                            <input matInput class="data" [(value)]="task.Subject" id="Subject" (focus)="inputFocus($event)" (focusout)="onInputFocusOut($event)">
                        </mat-form-field>
                    </div> 
                </div>
                <div class="form-row">
                    <div class="form-group col-md-1">
                        <span class="form-label">Decription:</span>
                    </div>   
                    <div class="form-group col-md-11">
                        <mat-form-field style="width: 100%;">
                            <textarea matInput class="data" [(value)]="task.Description" id="Description" (focus)="inputFocus($event)" (focusout)="onInputFocusOut($event)"></textarea>
                        </mat-form-field>
                    </div> 
                </div>
                <div class="form-row">
                    <div class="form-group col-md-1">
                        <span class="form-label">Resolution:</span>
                    </div>   
                    <div class="form-group col-md-11">
                        <mat-form-field style="width: 100%;">
                            <textarea matInput class="data" id="Resolution" (focus)="inputFocus($event)" (focusout)="onInputFocusOut($event)" [(value)]="task.Resolution"></textarea>
                        </mat-form-field>
                    </div> 
                </div>
                <div class="form-row">
                    <div class="form-group col-md-1">
                        <span class="form-label">Version Found:</span>
                    </div>   
                    <div class="form-group col-md-3">
                        <mat-form-field>
                            <input matInput class="data" id="VersionFound" (focus)="inputFocus($event)" (focusout)="onInputFocusOut($event)" [(value)]="task.VersionFound">
                        </mat-form-field>
                    </div> 
                    <div class="form-group col-md-1">
                        <span class="form-label">Version Fixed:</span>
                    </div>   
                    <div class="form-group col-md-3">
                        <mat-form-field>
                            <input matInput class="data" id="VersionFixed" (focus)="inputFocus($event)" (focusout)="onInputFocusOut($event)" [(value)]="task.VersionFixed">
                        </mat-form-field>
                    </div>                     
                </div>
                <div class="form-row">
                    <div class="form-group col-md-1">
                        <span class="form-label">Attachments:</span>
                    </div>  
                    <div class="form-group col-md-5">
                        <div class="custom-file">
                            <input type="file" class="custom-file-input" multiple  id="importFile" (change)="onFileChange($event.target.files)">
                                          <label class="custom-file-label" #labelImport for="importFile"><i class="fas fa-search"></i> Choose file</label>
                          </div>
                    </div> 
                    <div class="form-group col-md-2">
                        <a class="btn btn-success" (click)="OnUploadButtonClick()">Upload</a>
                    </div>                   
                </div>
                <div class="form-row" *ngIf="task.Attachments.length > 0">
                    <div class="form-group col-md-1">
                    </div> 
                    <div class="form-group col-md-11">
                        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" style="width: 100%;">
                          
                            <!-- Position Column -->
                            <ng-container matColumnDef="FileName">
                              <th mat-header-cell *matHeaderCellDef>File Name</th>
                              <td mat-cell *matCellDef="let element"> 
                                <!-- <a target="_new" [attr.href]="'/Documents/Tasks/'+element.Soid+'/'+element.FileName">{{element.FileName}}</a> -->
                                <a class="file-name" (click)="viewFile(element)">{{element.FileName}}</a>
                              </td>
                            </ng-container>
                          
                            <!-- Name Column -->
                            <ng-container matColumnDef="FileType">
                              <th mat-header-cell *matHeaderCellDef> File Type </th>
                              <td mat-cell *matCellDef="let element"> {{element.FileType}} </td>
                            </ng-container>
                          
                            <!-- Weight Column -->
                            <ng-container matColumnDef="PostedByName">
                              <th mat-header-cell *matHeaderCellDef> Uploaded By </th>
                              <td mat-cell *matCellDef="let element"> {{element.PostedByName}} </td>
                            </ng-container>                           
                          
                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                          </table>
                    </div> 
                </div>
                <div class="form-row">
                    <div class="form-group col-md-1">
                        <span class="form-label">Next Step:</span>
                    </div> 
                    <div class="form-group col-md-11">
                        <span class="form-label next-step">By</span>
                        <span class="next-step">
                            <mat-form-field>
                                <mat-select class="data" id="NextStepBy" [(value)]="task.NextStepBy" (focus)="onSelectFocus($event)" (selectionChange)="onSelectFocusOut($event)">              
                                    <mat-option [value]="task.OriginatorSoid">{{task.OriginatorName}}</mat-option>
                                    <mat-option [value]="task.AssigneeSoid">{{task.AssigneeName}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </span>
                        <span class="form-label next-step">On</span>
                        <span class="next-step">
                            <mat-form-field>
                                <input matInput class="data" id="NextStepOn" [matDatepicker]="picker1" [(value)]="task.NextStepOn" (dateChange)="dateChange($event)">
                                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                <mat-datepicker #picker1></mat-datepicker>
                            </mat-form-field>
                        </span>

                    </div> 
                    <div class="form-group col-md-1"></div>
                    <div class="form-group col-md-11">
                        <span class="form-label">Description</span>
                        <span>
                            <mat-form-field style="width: 100%;">
                                <input matInput class="data"  id="NextStep" (focus)="inputFocus($event)" (focusout)="onInputFocusOut($event)" [(value)]="task.NextStep">
                            </mat-form-field>
                        </span>
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-md-1">
                        <span class="form-label">Comments:</span>
                    </div>   
                    <div class="form-group col-md-10">
                        <mat-form-field style="width: 100%;">
                            <input matInput class="data" [(ngModel)]="comment">
                        </mat-form-field>
                    </div> 
                    <div class="form-group col-md-1">
                        <a class="btn btn-success" (click)="onAddCommentButtonClick()">Add Comment</a>
                    </div> 
                </div>
                <div class="form-row" *ngIf="task.Comments.length > 0">
                    <div class="form-group col-md-1">
                    </div> 
                    <div class="form-group col-md-11">
                        <table mat-table [dataSource]="commentDataSource" class="mat-elevation-z8" style="width: 100%;">
                          
                            <!-- Position Column -->
                            <ng-container matColumnDef="CreatedOn">
                              <th mat-header-cell *matHeaderCellDef>Created On</th>
                              <td mat-cell *matCellDef="let element"> {{element.CreatedOn | date:'MM-dd-yyyy hh:mm a'}} </td>
                            </ng-container>
                          
                            <!-- Name Column -->
                            <ng-container matColumnDef="AuthorName">
                              <th mat-header-cell *matHeaderCellDef>Created By </th>
                              <td mat-cell *matCellDef="let element"> {{element.AuthorName}} </td>
                            </ng-container>
                          
                            <!-- Weight Column -->
                            <ng-container matColumnDef="Content">
                              <th mat-header-cell *matHeaderCellDef>Content</th>
                              <td mat-cell *matCellDef="let element"> {{element.Content}} </td>
                            </ng-container>                           
                          
                            <tr mat-header-row *matHeaderRowDef="displayedCommentsColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedCommentsColumns;"></tr>
                          </table>
                    </div> 
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>



<ng-template #template>
    <div class="modal-header">
       <h4 class="modal-title pull-left">Delete Collaborator</h4>
       <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
          <span aria-hidden="true">×</span>
       </button>
    </div>
    <div class="modal-body">
        Are you sure you want to delete collaborator {{modalCollaborator.UserName}} ?
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-danger" (click)="OnOkDeleteButtonClick()">Ok</button>
        <button type="button" class="btn btn-secondary" (click)="modalRef.hide()">Cancel</button>
    </div>
 </ng-template>