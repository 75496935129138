import {Component, OnInit, Output, EventEmitter, TemplateRef} from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { NewTimeForAppoinmentComponent } from '../../modal/new-time-for-appoinment/new-time-for-appoinment.component';
import { ResheduleAppoinmentInfoComponent } from '../../modal/reshedule-appoinment-info/reshedule-appoinment-info.component';
import { CancelAppoinmentComponent } from '../../modal/cancel-appoinment/cancel-appoinment.component';
import { DatePipe, Location } from '@angular/common';
import { ClientService } from '../../../services/client.service';
import { DocumentsService } from '../../../services/documents.service';
import { AuthService } from '../../../services/auth.service';
import { ConfirmationDialog } from '../../modal/confirmation-dialog/confirmation-dialog.component';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-view-document',
  templateUrl: './view-document.component.html',
  styleUrls: ['./view-document.component.css']
})
export class ViewDocumentComponent implements OnInit {

  clientList: any = {};
  myForm: FormGroup;
  contactForm: FormGroup;
  tagForm: FormGroup;
  statusForm: FormGroup;
  infoForm: FormGroup;
  clientForm: FormGroup;
  disabled = false;
  ShowFilter = false;
  limitSelection = false;
  cities: any = [];
  selectedItems: any = [];
  dropdownSettings: any = {};
  modalRef: BsModalRef;
  documentInfo: any = {};
  fileUploadSoid: number;
  eventStatus: string;
  selected: string;
  actualValue: string;
  birthDate: Date;
  appoinmentClient: any = {};
  agelimit: 18;
  lmsStartDate: Date;
  expirationDate: Date;
  sendDocumentConfirmationPopup: BsModalRef;

  subscriptionServices: any = [];

  staffInfo: any = {};
  clientInfo: any = {};
  isAdmin: boolean = false;

  constructor(
    private modalService: BsModalService, private _router: Router, private datePipe: DatePipe,
    private _clientService: ClientService,
    private _authService: AuthService,
    private activatedRoute: ActivatedRoute, private location: Location,
    private _documentService: DocumentsService,
    private fb: FormBuilder,
    private readonly toast: ToastrService,
  )
  { }

  ngOnInit() {
    this._authService.getUserRole().subscribe( (res: string) => {
      if (res) {
        const permissions = this._authService.getPermissions(res);
        this.isAdmin = permissions.isAdmin;
      }
    }, (err) => {
      this.toast.error(err.message)
    });

    this.fileUploadSoid = this.activatedRoute.snapshot.params.soId;

    this.getDocumentInfo();

    //debugger;
    this.selectedItems = [{ item_id: 2, item_text: 'Staff' }, { item_id: 6, item_text: 'Documents' }];
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 5,
      allowSearchFilter: this.ShowFilter
    };
    this.myForm = this.fb.group({
      city: [this.selectedItems]
    });

    this.tagForm = this.fb.group({
      tag: new FormControl(this.selectedItems, Validators.compose([
        Validators.required
      ]))
    });
    this.statusForm = this.fb.group({
      status: new FormControl('', Validators.compose([
        Validators.required
      ]))
    });

    this.contactForm = this.fb.group({
      addressLine1: new FormControl('', Validators.compose([
        Validators.required
      ])),
      addressLine2: new FormControl('', Validators.compose([
      ])), state: new FormControl('', Validators.compose([
        Validators.required
      ]))
      , city: new FormControl('', Validators.compose([
        Validators.required
      ])), country: new FormControl('', Validators.compose([
        Validators.required
      ])), phoneNo: new FormControl('', Validators.compose([
        Validators.required
      ])), pincode: new FormControl('', Validators.compose([
        Validators.required
      ])), homeAddress: new FormControl('', Validators.compose([
        Validators.required
      ])), personalPhoneCountryCode: new FormControl('', Validators.compose([
        Validators.required
      ])), personalMobile: new FormControl('', Validators.compose([
        Validators.required
      ])), businessAddress: new FormControl('', Validators.compose([
        Validators.required
      ])), businessCountryCode: new FormControl('', Validators.compose([
        Validators.required
      ])), businessMobileNo: new FormControl('', Validators.compose([
        Validators.required
      ])), businessEmail: new FormControl('', Validators.compose([
        Validators.required
      ])), zipCode: new FormControl('', Validators.compose([
        Validators.required
      ]))

    });

    this.infoForm = this.fb.group({
      ssn: new FormControl('', Validators.compose([
        Validators.required
      ])),
      dlNumber: new FormControl('', Validators.compose([
        Validators.required
      ])),
      dlState: new FormControl('', Validators.compose([
        Validators.required
      ])),
      dlExpirationDate: new FormControl('', Validators.compose([
        Validators.required
      ])),
      dlStartDate: new FormControl('', Validators.compose([
        Validators.required
      ])),
      ITINNumber: new FormControl('', Validators.compose([
        Validators.required
      ])),
    });
    this.clientForm = this.fb.group({
      email: new FormControl('', Validators.compose([
        Validators.required
      ])),
      firstName: new FormControl('', Validators.compose([
        Validators.required
      ])),
      lastName: new FormControl('', Validators.compose([
        Validators.required
      ])),
      birthday: new FormControl('', Validators.compose([
        Validators.required
      ])),
      companyName: new FormControl('', Validators.compose([
        Validators.required
      ])),
      websiteURL: new FormControl('', Validators.compose([
        Validators.required
      ])),
      lmsStartDate: new FormControl('', Validators.compose([
        Validators.required
      ])),
      languagePreference: new FormControl('', Validators.compose([
        Validators.required
      ])),
      hearAboutUs: new FormControl('', Validators.compose([
        Validators.required
      ])),
      couple: new FormControl('', Validators.compose([
        Validators.required
      ])),
      CompanyName: new FormControl('', Validators.compose([
        Validators.required
      ])),
      EINNumber: new FormControl('', Validators.compose([
        Validators.required
      ]))

    });
  }

  private getDocumentInfo() {
    this._documentService.getDocumentBySoid(this.fileUploadSoid).subscribe((res: any) => {
      if (res) {
        this.documentInfo = res;
        this.getClientInfo(res.OwnerSoid);
      }
    });
  }

  printFile() {

    if (this.documentInfo.ContentType == 'application/pdf') {
      var winparams = 'dependent=yes,locationbar=no,scrollbars=yes,menubar=yes,' +
        'resizable,screenX=50,screenY=50,width=850,height=1050';

      var htmlPop = '<embed width=100% height=100%'
        + ' type="application/pdf"'
        + ' src="'
        + this.documentInfo.FileContent
        + '"></embed>';

      var printWindow = window.open("", "PDF", winparams);
      printWindow.document.write(htmlPop);

      // printWindow.print();
      //printWindow.focus();

    }



  }

  inActiveDocument() {
    this._documentService.inactiveFileUpload(this.fileUploadSoid).subscribe((res: any) => {
      if (res) {
        //this.toast.success("Document InActive successfully.", "Success!");
        this._router.navigate(['documents']);
      } else {
        //this.toast.warning("Error Creating service", "Error!");
      }
    });

  }

  downloadFile() {
    //  const mimType = this.documentInfo;
    // const mimType = 'application/pdf';
    //   const url = `data:${mimType};base64,` + res.data.response;
    // download the file
    const url = this.documentInfo.FileContent;
    const a = document.createElement('a');
    a.href = url;
    a.download = this.documentInfo.FileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);

    var request: any = {};
    request.fileId = this.fileUploadSoid;
    /** *this._documentService.downloadFile(request).subscribe((res: any) => {
       if (res) {
         const mimType = this.documentInfo.ContentType === 'pdf' ? 'application/pdf' : this.documentInfo.ContentType === 'xlsx' ? 'application/xlsx' :
         this.documentInfo.ContentType === 'pptx' ? 'application/pptx' : this.documentInfo.ContentType === 'csv' ? 'application/csv' : this.documentInfo.ContentType === 'docx' ? 'application/docx' :
         this.documentInfo.ContentType === 'jpg' ? 'application/jpg' : this.documentInfo.ContentType === 'png' ? 'application/png' : '';
        // const mimType = 'application/pdf';
         const url = `data:${mimType};base64,` + res.data.response;
         // download the file
           const a = document.createElement('a');
           a.href = url;
           a.download = this.documentInfo.FileName;
           document.body.appendChild(a);
           a.click();
           document.body.removeChild(a);
           window.URL.revokeObjectURL(url);
       }
     });**/

  }

  convertBase64ToBlob(base64Image: string, mimType) {


    // Decode Base64 string
    const decodedData = window.atob(base64Image);

    // Create UNIT8ARRAY of size same as row data length
    const uInt8Array = new Uint8Array(decodedData.length);

    // Insert all character code into uInt8Array
    for (let i = 0; i < decodedData.length; ++i) {
      uInt8Array[i] = decodedData.charCodeAt(i);
    }

    // Return BLOB image after conversion
    return new Blob([uInt8Array], { type: mimType });
  }

  /**
 * Convert BASE64 to BLOB
 * @param base64Image Pass Base64 image data to convert into the BLOB
 */
  private convertBase64(base64Image: string) {
    // Split into two parts
    const parts = base64Image.split(';base64,');
    // Hold the content type
    const imageType = parts[0].split(':')[1];

    // Decode Base64 string
    const decodedData = window.atob(parts[1]);

    // Create UNIT8ARRAY of size same as row data length
    const uInt8Array = new Uint8Array(decodedData.length);

    // Insert all character code into uInt8Array
    for (let i = 0; i < decodedData.length; ++i) {
      uInt8Array[i] = decodedData.charCodeAt(i);
    }

    // Return BLOB image after conversion
    return new Blob([uInt8Array], { type: imageType });
  }

  viewFile() {

    if (this.documentInfo.ContentType == 'application/pdf') {
      this.printFile();
    } else  {


      const url = this.convertBase64(this.documentInfo.FileContent);

      const a = document.createElement('a');
      a.href = URL.createObjectURL(url);
      a.target = "_blank"
      //  a.download = this.documentInfo.FileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }


  }


  //New Time For Appoinment
  newTimeForAppoinment() {
    const initialState = {
      header: "Reschedule Appoinment",
      closeBtnName: "Save",
      backdrop: true,
      ignoreBackdropClick: true
    };
    this.modalRef = this.modalService.show(ResheduleAppoinmentInfoComponent);
  }

  //New Time For Appoinment Info
  resceduleAppoinmentInfo() {
    const initialState = {
      header: "Reschedule Appoinment",
      //  eventId: this.eventId,
      closeBtnName: "Cancel",
      backdrop: true,
      ignoreBackdropClick: true
    };
    this.modalRef = this.modalService.show(ResheduleAppoinmentInfoComponent, { initialState });
  }
  //Cancel Appoinment
  cancelAppoinment(action) {
    const initialState = {
      header: action == 'Completed' ? "Complete Appointment" : 'Cancel Appointment',
      closeBtnName: "Save",
      //eventId: this.eventId,
      status: action,
      backdrop: true,
      ignoreBackdropClick: true
    };
    this.modalRef = this.modalService.show(CancelAppoinmentComponent, { initialState });
    this.modalRef.content.event.subscribe(data => {
      // this.getAppointmentInfo();
    });
  }

  onItemSelect(test) {

  }

  onInputFocusOut(event) {
    if (event.srcElement.value.trim() != "" && this.actualValue != event.srcElement.value.trim()) {
      this.invokeClientSave(event.srcElement.id, event.srcElement.value);
    }

  }

  private invokeClientSave(selector, value) {
    var requestInfo: any = {};
    requestInfo.FieldName = selector;
    requestInfo.Data = value
    requestInfo.UserName = localStorage.getItem("UserName");
    requestInfo.UserSoid = localStorage.getItem("UserSoid");
    /**  this._clientService.updateClientInfo(this.clientInfo.Soid, requestInfo).subscribe((res: any) => {
       if (res && res.Changed === true) {
         if (res.response == "EMAIL_EXISTS") {
           this.toast.warning("Email Id already exists.", "Warning!");
         } else {
           this.toast.success("Updated Successfully.", "Success!");
         }
       } else {
         this.toast.error("Error Updating Details", "Success!");
       }
     });*/
  }


  private getClientInfo(Soid) {
    this._clientService.getClientInfo(Soid).subscribe((res: any) => {
      if (res) {
        this.appoinmentClient = res;
        this.birthDate = this.appoinmentClient.DateOfBirth ? new Date(this.appoinmentClient.DateOfBirth) : null;
        this.lmsStartDate = this.appoinmentClient.LmsMemberDate ? new Date(this.appoinmentClient.LmsMemberDate) : null;
        this.expirationDate = this.clientInfo.DLExpirationDate ? new Date(this.appoinmentClient.DLExpirationDate) : null;
      }
    });
  }


  onSelectFocus(event) {
    this.actualValue = event.srcElement.value;
  }

  onSelectFocusOut(event) {
    if (event.source._value.trim() != "" && this.actualValue != event.source._value.trim().trim()) {
      this.invokeClientSave(event.source._id, event.source._value.trim());
    }
  }

  onFocusChange(event) {

  }

  onChange(event) {

  }

  dateChange(event) {
    this.invokeClientSave(event.target._elementRef.nativeElement.id, this.datePipe.transform(event.value, 'MM/dd/yyyy'));
  }


  inputFocus(event) {
    this.actualValue = event.srcElement.value;
  }

  goBack() {
    if (window.history.length > 1) {
      this.location.back()
    }
  }


  openConfirmationDialog() {
    const initialState = {
      confirmMessage: "Are you sure to delete the document?",
      backdrop: true,
      ignoreBackdropClick: true
    };
    this.modalRef = this.modalService.show(ConfirmationDialog, { initialState });
    this.modalRef.content.event.subscribe(data => {
      if (data) {
        this.inActiveDocument();
      }
    });

  }

  /**
   * Sends the document to the customer via email.
   */
  sendDocumentByEmail(): void {
    this._documentService.sendCustomerDocumentByEmail(this.documentInfo.Soid)
      .subscribe({
        next: () => this.toast.success('Email send'),
        error: (err) => this.toast.error('There was an error sending email'),
        complete: () => this.sendDocumentConfirmationPopup.hide(),
      });
  }

  showSendDocumentConfirmationPopup(template: TemplateRef<any>): void {
    this.sendDocumentConfirmationPopup = this.modalService.show(template);
  }
}
