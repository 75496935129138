import { Component, OnInit, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ServicesListService } from '../../../services/services.service';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject } from 'rxjs';
import { StaffService } from '../../../services/staff.service';
import { InvoiceService } from '../../../services/invoice.service';
import { Router, ActivatedRoute } from '@angular/router';
import _ from 'lodash';

@Component({
  selector: 'app-take-payment',
  templateUrl: './take-payment.component.html',
  styleUrls: ['./take-payment.component.css']
})
export class TakePaymentComponent implements OnInit {


  title: string = "Take Payment";
  selectCallType: string;
  closeBtnName: string = "Close";
  selectedTab: string = "tab1"; //Initial tab active
  paymentTypes: Array<string> = ['USD', 'POUNDS', 'TINAR', 'BHAT'];
  paymentMethods: Array<string> = ['Cash', 'Credit Card', 'Bank Transfer', 'ACH', 'Check', 'Paypal', 'Other'];
  recordPaymentDocumentForm: FormGroup;
  feeField: boolean = true;
  serviceName: string;
  uploadType: string = "MY DOCUMENTS";
  phoneType: string;
  phoneValue: string;
  onlineType: string;
  onlineValue: string;
  faceType: string;
  faceValue: string;
  durationHour: string;
  durationMinutes: string;
  servicePrice: string;
  currency: string;
  module: string;
  formValid: boolean = true;
  locationModeObserver: Subject<string> = new Subject<string>();
  priceChangeObserver: Subject<string> = new Subject<string>();
  subscriptionServices: any = [];
  public event: EventEmitter<any> = new EventEmitter();
  public clientInfo: any = [];
  staffList: any = [];
  myDocumentsList: any = [];
  @ViewChild('labelImport')
  labelImport: ElementRef;
  agelimit: number = 10;

  formImport: FormGroup;
  fileToUpload: File = null;
  invoiceInfo: any = {};
  customerInfo: any = {};
  invoiceBalance: number  = 0;
  receiptNo: string;



  


  constructor(private toast: ToastrService, private _service: ServicesListService,
    private _invoiceService: InvoiceService,
    public bsModalRef: BsModalRef, sb: FormBuilder,private _router: Router,
    private _staffService: StaffService,) {
  }



  ngOnInit() {
    this.getInvoiceNumber();
    this.recordPaymentDocumentForm = new FormGroup({
      //serviceText: new FormControl('', [Validators.required]),
      amount: new FormControl('',[Validators.required]),
      paymentMethod: new FormControl('',[Validators.required]),
      paymentDate: new FormControl('',[Validators.required]),
      sendReceipt: new FormControl(),
     // issueAnInvoice: new FormControl(),
      receiptNumber: new FormControl('',[Validators.required]),
    });
    //console.log(this.customerInfo.Invoices);
    this.invoiceBalance = _.sumBy(this.customerInfo.Invoices, 'Balance');
    
  }


  recordPayment() {
    for (let inner in this.recordPaymentDocumentForm.controls) {
      this.recordPaymentDocumentForm.get(inner).markAsTouched();
      this.recordPaymentDocumentForm.get(inner).updateValueAndValidity();
    }
    if (!this.recordPaymentDocumentForm.valid) {
      this.formValid = false;
      this.toast.warning("Fill the required fields.", "Warning!");
      return;
    }

    //var balance = this.invoiceInfo.Balance;
    var pay = this.recordPaymentDocumentForm.controls.amount.value;
    if (pay > this.invoiceBalance) {
      this.toast.warning("Amount should be less than invoice balance " +  this.invoiceBalance, "Warning!");
      return;
    }
    var testData : any = {};


    let userFullName = localStorage.getItem("NameFirst") + " " + localStorage.getItem("NameLast");
    testData.StaffSoid = localStorage.getItem("UserSoid");
    testData.StaffName =  userFullName;
    testData.ReceiptNumber = this.recordPaymentDocumentForm.controls.receiptNumber.value;
    testData.PaidOn = this.recordPaymentDocumentForm.controls.paymentDate.value;
    testData.Amount = this.recordPaymentDocumentForm.controls.amount.value;
    testData.PaymentType = this.recordPaymentDocumentForm.controls.paymentMethod.value;
    //testData.PaymentInfo = this.recordPaymentDocumentForm.controls.serviceText.value;

    
    
    this._invoiceService.recordPayment(this.clientInfo.Soid, testData).subscribe((res: any) => {
      if (res) {
        this.toast.success("Payment recorded successfully.", "Success!");
        this.triggerEvent();
        this.bsModalRef.hide();
        this._router.navigate(['client/' + res.PersonSoid + '/reciept/' + res.Soid]);
      } else {
        this.toast.warning("Error Creating service", "Error!");
      }
    });

  }

  triggerEvent() {
    this.event.emit();
  }

  getInvoiceNumber(){
    this._invoiceService.getReceiptNumber().subscribe((res: any) => {
      if (res) {
        this.receiptNo = res;
      }

    });
  }

 
}
