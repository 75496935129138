<div class="modal-header">
  <h4 class="modal-title pull-left">{{header}}</h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form class="client-modal" [formGroup]="notesForm">

    <div class="form-row">
      <div class="form-group col-md-12">
        <mat-form-field>
          <textarea matInput [(ngModel)]="clientNote"
             placeholder="Comment" rows="10"
             formControlName="notes">
          </textarea>
          <mat-error *ngIf="notesForm.controls.notes.errors != null">
            Please enter notes
          </mat-error>
        </mat-form-field> 
      </div>
     
    </div>
  </form>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">{{closeBtnName}}</button>
  <button type="button" class="btn btn-primary" (click)="createNewDocument()">{{editNote && editNote.Soid ?  'Save' : 'Create'}}</button>
</div>