<div class="section-container">
  <div class="subheader">
    <h2>Estimate</h2>
    <!-- <h5>Plans and events</h5> -->
  </div>
  <div class="white-bg rounded border-0">
    <div class="row top-menu bg-light bg-grey no-gutters">
      <div class="col-md-6">
        <div class="top-menu-container">
          <a class="btn-link btn-icon-back" (click)="goBack()"><i class="fa fa-chevron-left"></i></a>
        </div>
      </div>
      <div class="col-md-6 text-right">
        <div class="top-menu-container">
          <a class="btn btn-warning" [matMenuTriggerFor]="menu"><i class="fas fa-ellipsis-v"></i></a>

          <mat-menu #menu="matMenu">
            <button mat-menu-item
              (click)="loadEstimateToInvoice()"
              *ngIf="invoiceInfo.Status == 'ISSUED'"
            >
              <mat-icon>receipt_long</mat-icon>
              Convert to Invoice
              <span></span>
            </button>
            <button mat-menu-item
              (click)="showInvoice()"
              *ngIf="invoiceInfo.Status == 'INVOICED'"
            >
              <mat-icon>receipt_long</mat-icon>
              <span>View Invoice</span>
            </button>
            <button mat-menu-item (click)="generatePDF('print')">
              <mat-icon>print</mat-icon>
              <span>Print</span>
            </button>
            <button mat-menu-item
              *ngIf="invoiceInfo.Status == 'ISSUED'"
              (click)="deleteEstimate()"
            >
              <mat-icon>delete</mat-icon>
              <span>Delete</span>
            </button>
            <button mat-menu-item (click)="openSendEmailConfirmationPopup(template)">
              <mat-icon>email</mat-icon>
              <span>Send email</span>
            </button>
          </mat-menu>
          <a class="btn btn-primary" (click)="generatePDF('download')" >Download</a>
        </div>
      </div>
    </div>
    <div class="padding-10">
      <!-- <div class="container"> -->
      <div class="row">
        <div class="col-md-9">
          <div class="row">
            <div class="date-container">
              <div class="date"><i class="far fa-file-image fa-5x"></i> <!--
                <span class="binds"></span>
                <span class="month">{{documentInfo.Time.Start | date:"EE" | uppercase}}</span>
                <h1 class="day">{{documentInfo.Time.Start | date:"dd"}}
                  <div class="month-text">{{documentInfo.Time.Start | date:"MMM"}}</div>
                </h1>
               -->

              </div>
            </div>
            <div class="col-md-9">
              <h5>{{invoiceInfo.Label}} #{{invoiceInfo.EstimateNumber}}</h5>
              <h2>${{invoiceInfo.Total}} USD</h2>
              <ul class="list-group list-group-flush list-no-borders">
                <li class="list-group-item"><i class="fa fa-user-tie  mr-2"></i> {{clientInfo.NameLast}} {{clientInfo.NameFirst}} </li>
                <li class="list-group-item"><i class="fa fa-mobile-alt  mr-2"></i>{{clientInfo.PersonalMobile}}  </li>
                <li class="list-group-item"><i class="fa fa-email-alt  mr-2"></i>{{clientInfo.Email}}  </li>
                <li class="list-group-item">
                  <span [ngClass]="{'custom-chip-red': invoiceInfo.Status === 'DELETED',
                  'custom-chip-issued': invoiceInfo.Status === 'ISSUED',
                  'custom-chip-invoiced': invoiceInfo.Status === 'INVOICED'}">
   {{invoiceInfo.Status}}
 </span>
              </li>
              </ul>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <div class="card">
                <div class="card-header">
                  Details
                </div>
                <div class="card-body card-info">
                  <label>Staff</label>
                  <!-- <button>{{eventInfo.staffFName}} {{eventInfo.staffLName}} <button *ngIf="eventStatus == 'Scheduled'" (click)="assignStaff()" class="btn btn-link">Assign</button></p> -->
                  <p>{{invoiceInfo.StaffName}}</p>
                  <label>Created On</label>
                  <!-- <button>{{eventInfo.staffFName}} {{eventInfo.staffLName}} <button *ngIf="eventStatus == 'Scheduled'" (click)="assignStaff()" class="btn btn-link">Assign</button></p> -->
                  <p>{{invoiceInfo.CreatedOn | date:"dd MMM yyyy"}}</p>
                  <label>Issue Date</label>
                  <!-- <button>{{eventInfo.staffFName}} {{eventInfo.staffLName}} <button *ngIf="eventStatus == 'Scheduled'" (click)="assignStaff()" class="btn btn-link">Assign</button></p> -->
                  <p>{{invoiceInfo.EstimateDate | date:"dd MMM yyyy"}}</p>
                  <label>Expiration Date</label>
                  <!-- <button>{{eventInfo.staffFName}} {{eventInfo.staffLName}} <button *ngIf="eventStatus == 'Scheduled'" (click)="assignStaff()" class="btn btn-link">Assign</button></p> -->
                  <p>{{invoiceInfo.ExpirationDate | date:"dd MMM yyyy"}}</p>

                  <label>Status Summary</label>
                  <!-- <button>{{eventInfo.staffFName}} {{eventInfo.staffLName}} <button *ngIf="eventStatus == 'Scheduled'" (click)="assignStaff()" class="btn btn-link">Assign</button></p> -->
                  <p>{{invoiceInfo.Notes}}</p>
                </div>
              </div>
              <br>

            </div>
            <div class="col-md-8">
              <div class="card">
                <div class="card-header">
                  <div class="row">
                    <div class="col-md-7">
                      Items
                    </div>
                    <div class="col-md-2">
                    </div>
                    <div class="col-md-3">
                      Total
                    </div>
                  </div>
                </div>
                <div class="card-body card-info">
                  <div class="row" *ngFor="let row of invoiceInfo.Items">
                    <div class="col-md-7">
                      {{row.Item}} <br>
                      {{row.Quantity}} x {{row.UnitPrice}} USD
                    </div>
                    <div class="col-md-2">
                    </div>
                    <div class="col-md-3">
                      ${{row.Price}} USD
                    </div>
                  </div>
                  <div class="row" style="padding: 5px 0;
                  border-bottom: 1px solid blue"></div>
                  <div class="row" style="
                  font-weight: 500;">
                    <div class="col-md-7">
                      Subtotal
                    </div>
                    <div class="col-md-2">
                    </div>
                    <div class="col-md-3">
                      ${{invoiceInfo.SubTotal}} USD
                    </div>
                  </div>
                  <br>
                  <div class="row" style="color:brown;
                  font-weight: 500;">
                    <div class="col-md-7">
                      Tax
                    </div>
                    <div class="col-md-2">
                    </div>
                    <div class="col-md-3">
                      ${{invoiceInfo.Taxes}} USD
                    </div>
                  </div>
                  <br>
                  <div class="row">
                    <div class="col-md-7" style="font-weight: 500;">
                      <strong>Total Amount</strong>
                    </div>
                    <div class="col-md-2">
                    </div>
                    <div class="col-md-3" style="font-weight: 500;">
                      <strong>${{invoiceInfo.Total}} USD</strong>
                    </div>
                  </div>
                  <br>
                  <div class="row" *ngFor="let payment of invoiceInfo.Payments">
                    <div class="col-md-4">

                    </div>
                    <div class="col-md-5">
                      <strong>Less
                        <a class="" (click)="loadReceipt(clientId,invoiceInfo.Soid,payment.Soid)"
                        style="color: blue;cursor:pointer; display: inline-block;">Payment</a>
                      </strong><br>
                      {{payment.CreatedOn | date:"dd MMM yyyy"}}
                    </div>
                    <div class="col-md-3">
                      <strong>${{payment.Amount}} USD</strong>
                      <a class="" *ngIf="payment.XeroRecieptReference != null"
                      target="_blank" href="{{receiptURL}}{{payment.XeroRecieptReference}}"

                        style="color: blue;cursor:pointer; display: inline-block;margin-left: 5px;">
                        <img src="assets/images/xero-icon.png" height="24px" width="24px" class="rounded" /></a>
                    </div>
                  </div>

                  <br>
                </div>
              </div>
              <br />

            </div>


          </div>
        </div>
        <!--  <div class="col-md-3">
            <app-edit (sendMessage)="showDataFromParent($event)"></app-edit>
          </div> -->

      </div>

      <!-- </div> -->
    </div>
  </div>
</div>

<ng-template #template>
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title">Send estimate by email</h5>
      <button type="button" class="close" aria-label="Close" (click)="confirmationPopup.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      Do you want to send <b>{{ invoiceInfo.EstimateNumber }}</b> estimate by email?
    </div>
    <div class="modal-footer">
      <button class="btn btn-danger" (click)="confirmationPopup.hide()">Cancel</button>
      <button class="btn btn-info" (click)="sendEstimateByEmail()" >Send</button>
    </div>
  </div>
</ng-template>

