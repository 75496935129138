<div class="modal-header">
  <h4 class="modal-title pull-left">{{header}}</h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form class="client-modal" [formGroup]="addMyDocumentForm">

    <div class="form-row">
      <div class="form-group selectType col-md-10">
       


       

        <div>

           
  <div class="custom-file">
    <input type="file" class="custom-file-input" multiple formControlName="importFile" id="importFile" (change)="onFileChange($event.target.files)">
                  <label class="custom-file-label" #labelImport for="importFile"><i class="fas fa-search"></i> Choose file</label>
  </div>
          
        </div>
        <mat-form-field>
          <input type="text" autocomplete="off" formControlName="documentTitle" matInput
            placeholder="Document Title">

        </mat-form-field>
        <mat-form-field>
          <input type="text" autocomplete="off" formControlName="documentDescription" matInput
            placeholder="Document Description (Optional)">
        </mat-form-field>

      </div>

    </div>
  </form>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">{{closeBtnName}}</button>
  <button type="button" class="btn btn-primary" (click)="createNewDocument()">Create</button>
</div>