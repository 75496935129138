import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { LoginComponent } from './login/login.component';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { RouterModule } from '@angular/router';
import {APP_ROUTES} from './app.routes'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { Api } from './services/api';
import { DatePipe} from '@angular/common';
import { HttpClientModule} from '@angular/common/http';
import { FeatureModule } from './feature/feature.module';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { MasterDataProvider } from './provider/MasterDataProvider';
import { LostPasswordComponent } from './lost-password/lost-password.component';

export function masterDataProviderFactory(provider: MasterDataProvider) {
  return () => provider.load();
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    PageNotFoundComponent,
    LostPasswordComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(APP_ROUTES),
    FormsModule,
    ReactiveFormsModule,
    BsDropdownModule.forRoot(),
    ModalModule.forRoot(),
    TabsModule.forRoot(),
    BsDatepickerModule.forRoot(),
    HttpClientModule,
    FeatureModule
  ],
  providers: [
    Api,DatePipe, BsModalRef,
    MasterDataProvider, 
    { provide: APP_INITIALIZER, useFactory: masterDataProviderFactory, deps: [MasterDataProvider], multi: true }
  ],
  bootstrap: [AppComponent,]
})
export class AppModule { }
