import { Injectable } from '@angular/core';
import { Api } from '../services/api';

@Injectable({
  providedIn: 'root'
})
export class StaffService {

  constructor(public api: Api) { }

  saveStaffInfo(staffInfo) {
    let seq = this.api.post('User/Register', staffInfo);
    return seq;
  }
  
  getStaffsListFilterBy(filterBy) {   
    return this.api.get('OfficeEmployee/' + localStorage.getItem("OfficeSoid") + '/Staffs/' + filterBy);
  }

  getSelectedOfficeFilterBy(filterBy) {   
    return this.api.get('OfficeEmployee/' + localStorage.getItem("OfficeSoid") + '/Staffs/' + filterBy);
  }

  getStaffByOffice(OfficeSoid, filterBy) {   
    return this.api.get('OfficeEmployee/' + OfficeSoid + '/Staffs/' + filterBy);
  }

  getActiveStaffs() {   
    return this.api.get('OfficeEmployee/' + localStorage.getItem("OfficeSoid") + '/Staffs/Active');
  }

  getStaffOptions() {   
    return this.api.get('OfficeEmployee/' + localStorage.getItem("OfficeSoid") + '/ActiveStaffs');
  }

  getStaffInfo(StaffId) {
    let seq = this.api.get('OfficeEmployee/' + StaffId + '/Person');
    return seq;
  }

  validateEmailAddress(userEmail) {
    let seq = this.api.get('User/' + userEmail + '/validateEmailAddress');
    return seq;
  }

  saveEmployment(staffId, employment) {
    let seq = this.api.post('OfficeEmployee/' + staffId + '/AddOrUpdate', employment);
    return seq;
  }

  resetPassword(staffId) {
    let seq = this.api.patch('Credentials/' + staffId + '/ResetPassword', {});
    return seq;
  }
}
