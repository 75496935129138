<div class="section-container">
    <div class="subheader">
        <h2>Tasks</h2>

    </div>
    <div class="white-bg rounded border-0">
        <div class="row top-menu bg-light bg-grey no-gutters">
            <div class="col-md-4">
                <div class="top-menu-container">
                    <a class="btn btn-success" (click)="OnAddTaskButtonClick()">Add Task</a>
                    <a class="btn btn-primary" *ngIf="!viewAll" (click)="OnViewAllButtonClick()">View All Tasks</a>
                    <a class="btn btn-primary" *ngIf="viewAll" (click)="OnBackButtonClick()">Back</a>
                </div>
            </div>
            <div class="col-md-5" *ngIf="officeList && officeList.length > 0">
                <mat-form-field>
                  <select  matNativeControl placeholder="Office" (change)="getSelectedStaffList($event)"  [(ngModel)]="selectedOffice">
                    <option value="All">All</option>
                    <!--<mat-option>None</mat-option>-->
                    <option *ngFor="let service of officeList" [value]="service.Soid">{{service.CompanyName}} </option>
                    <!--<mat-option value="Custom">Custom</mat-option>-->
                  </select>
                </mat-form-field>
              </div>
            <div class="col-md-3">
                <div class="filter-header">
                    <mat-form-field>
                      <input class="data" matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
                    </mat-form-field>
                  </div>
            </div>
        </div>

        <table #table mat-table matSort [dataSource]="dataSource" multiTemplateDataRows style="width: 100%;" *ngIf="showData">
            <ng-container matColumnDef="Edit">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element">
                    <button type="button" class="btn btn-primary" (click)="OnEditButtonClick(element)">Edit</button>
                </td>
            </ng-container>

            <ng-container matColumnDef="Delete">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element">
                    <button type="button" class="btn btn-danger" (click)="openModal(template, element)">Delete</button>
                </td>
            </ng-container>

            <ng-container matColumnDef="OfficeName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Office</th>
                <td mat-cell *matCellDef="let element">{{element.OfficeName}}</td>
            </ng-container>

            <ng-container matColumnDef="OriginatorName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Originator</th>
                <td mat-cell *matCellDef="let element">{{element.OriginatorName}}</td>
            </ng-container>

            <ng-container matColumnDef="AssigneeName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Assignee</th>
                <td mat-cell *matCellDef="let element">{{element.AssigneeName}}</td>
            </ng-container>

            <ng-container matColumnDef="Status">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
                <td mat-cell *matCellDef="let element">{{element.Status}}</td>
            </ng-container>

            <ng-container matColumnDef="Priority">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Priority</th>
                <td mat-cell *matCellDef="let element" [ngClass]="setPriorityColor(element)">{{element.Priority}}</td>
            </ng-container>

            <ng-container matColumnDef="DueOn">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Due Date</th>
                <td mat-cell *matCellDef="let element">{{element.DueOn | date:'MM-dd-yyyy hh:mm a'}}</td>
            </ng-container>

            <ng-container matColumnDef="Category">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Category</th>
                <td mat-cell *matCellDef="let element">{{element.Category}}</td>
            </ng-container>

            <ng-container matColumnDef="Subject">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Subject</th>
                <td mat-cell *matCellDef="let element">{{element.Subject}}</td>
            </ng-container>

            <ng-container matColumnDef="DueStatus">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Due Status</th>
                <td mat-cell *matCellDef="let element" [ngClass]="isOverDue(element)">{{isOverDue(element)}}</td>
            </ng-container>

            <ng-container matColumnDef="expandedDetail">
                <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
                    <div class="element-detail" [@detailExpand]="expandedElement ? element == expandedElement ? 'expanded' : 'collapsed':'collapsed'">
                        <task-view [data]="element" class="task-view"></task-view>
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="noRecord">
                <td mat-footer-cell *matFooterCellDef colspan="11">No Tasks Found</td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let element; columns: displayedColumns;" class="element-row"
            [class.expanded-row]="expandedElement === element" (click)="toggleRow(element)" [ngClass]="setColor(element)"></tr>
            <tr mat-row *matRowDef="let row; columns['expandedDetail']" class="detail-row"></tr>
            <tr [ngClass]="{'hide': !(dataSource?.data!=null && dataSource?.data.length==0)}" mat-footer-row
            *matFooterRowDef="['noRecord'];sticky:false"></tr>
        </table>

        <mat-paginator #paginator *ngIf="dataSource" [length]="dataSource?.data.length" [pageIndex]="0" [pageSize]="20"
            [pageSizeOptions]="[20, 50,100, 150]">

        </mat-paginator>


    </div>
</div>

<ng-template #template>
    <div class="modal-header">
       <h4 class="modal-title pull-left">Delete Task</h4>
       <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
          <span aria-hidden="true">×</span>
       </button>
    </div>
    <div class="modal-body">
        Are you sure you want to delete the task ?
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-danger" (click)="OnOkDeleteButtonClick()">Ok</button>
        <button type="button" class="btn btn-secondary" (click)="modalRef.hide()">Cancel</button>
    </div>
 </ng-template>