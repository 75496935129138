import { Component, OnInit, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import {FormBuilder, FormGroup, FormControl, Validators} from '@angular/forms';
import { ServicesListService } from '../../../services/services.service';
import {ToastrService} from 'ngx-toastr';
import { Observable, Subject } from 'rxjs';
import { MasterService } from '../../../services/master.service';
import { AuthService } from 'src/app/services/auth.service';
import { ConfirmedValidator } from './confirm-validator.validator';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {

  title: string = "New Service";
  selectCallType: string;
  closeBtnName: string = "Close";
  changePasswordForm: FormGroup;
  formValid: boolean = true;
  hide:boolean = true;
  public event: EventEmitter<any> = new EventEmitter();

  

  constructor(private masterService: MasterService, private toast: ToastrService, private _authService: AuthService,
     public bsModalRef: BsModalRef,
     private fb: FormBuilder) {
    
  /*  this.addService = sb.group({
      hideRequired: false,
      floatLabel: 'auto',
    });*/
  }

  ngOnInit() {

    this.changePasswordForm = this.fb.group({
      password: ['', [
        Validators.required, Validators.minLength(8),
        Validators.pattern(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\^$*.\[\]{}()\?\-"!@#%&\/\\,><':;\|_~`\+\=]).{8,}/)
      ]],
      confirmPassword: ['', [Validators.required]]
    }, { 
      validator: ConfirmedValidator('password', 'confirmPassword')
    });
  }

  createService(){
    for (let inner in this.changePasswordForm.controls) {
      this.changePasswordForm.get(inner).markAsTouched();
      this.changePasswordForm.get(inner).updateValueAndValidity();
    }
    if (!this.changePasswordForm.valid){
      this.formValid = false;
      this.toast.warning("Fill the required fields.", "Warning!");
      return;
    }

    
    var password = btoa(this.changePasswordForm.controls.password.value)

    this._authService.changePassword(password).subscribe((res: any) => {
      if (res === 'SUCCESS') {
        this.toast.success("Password Changed Successfully.", "Success!");
        this.triggerEvent();
        this.bsModalRef.hide();
      } else {
       this.toast.warning("Error Changing Password", "Error!");
      }
    });

  }

  triggerEvent() {
    this.event.emit();
  }

  

}
   