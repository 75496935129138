<div class="section-container" >

  <div class="row" style="margin-right: 10px;">

    <div class="col-lg-6">
      <div class="subheader">
        <h2>Office</h2>

        <!-- <h5>Plans and events</h5> -->
      </div>
      <div class="white-bg rounded border-0">
        <div class="row top-menu bg-light bg-grey no-gutters">
          <div class="col-md-6">
            <div class="top-menu-container">
              <button *ngIf="isSuperAdmin" mat-flat-button (click)="openAddOfficeModal()" color="primary">Add New <i
                  class="fa fa-plus"></i></button>
            </div>
          </div>
          <div class="col-md-6">
            <form class="search-card">
              <div class="card-body row no-gutters align-items-center">
                <div class="col search-card-field">
                  <i class="fas fa-search icon-serach"></i>
                  <input [(ngModel)]="searchText" name="searchText" class="form-control  form-control-borderless"
                    type="search" placeholder="Search topics or keywords">
                </div>

              </div>
            </form>
          </div>
        </div>
        <div class="row form-page-header">
          <div class="col-md-4">
            <form class="form-inline">
              <label for="inlineFormInputName2" class="mrg-rt-5">Sort By</label>
              <!-- <input type="text" class="form-control mb-2 mr-sm-2" /> -->
              <mat-form-field>
                <!--  <mat-label>Cars</mat-label> -->
                <select matNativeControl required (change)="sortFieldChange($event)">
                  <option value="CreatedOn">Default</option>
                  <option value="CompanyName">Company Name</option>
                  <option value="EmailAddress">Email Address</option>
                </select>
              </mat-form-field>
            </form>
          </div>
          <div class="col-md-8 text-right">
            <span class="mrg-rt-5 search-results">Showing office(filtered)</span>
          </div>
        </div>
        <div class="client-lists">
          <div class="row">
            <div class="col-md-12">
              <ul class="list-unstyled">

                <li [ngClass]="(office.Soid == selectedOfficeSoid )   ? 'selected-row' : ''"  class="media" *ngFor="let office of officeList |
                    simpleSearch : 'Company Name,EmailAddress': searchText | slice: lowValue : highValue"
                  (click)=editOffice(office.Soid)>

                  <ngx-avatar *ngIf="office.Logo; else NoLogo" size="40" [src]="office.Logo" > </ngx-avatar>
                  <ng-template #NoLogo>
                    <ngx-avatar size="40" name="{{office.CompanyName}}"></ngx-avatar>
                  </ng-template>
                  <!-- <div class="rounded mr-3 bg-success initial-text">{{getShortName(client.firstName) | uppercase}}{{getShortName(client.lastName) | uppercase}}</div> -->
                  <div class="media-body">
                    <div class="row no-gutters">
                      <div class="client-list-left col-md-4">
                        <h6 class="mt-0 mb-1">{{office.CompanyName | titlecase}}</h6>
                        <span><i class="fas fa-at"></i> {{office.EmailAddress}}</span>
                        <div><span><i class="fas fa-mobile-alt"></i> {{office.PhoneNumber}}</span></div>
                      </div>
                      <!--<div class="col-md-5 result-date text-right" *ngIf="client.tag != null" >
                                      <span *ngFor="let tagName of convertToArray(client.tag), let i of index" class="lable-tag">{{tagName | titlecase}}</span>
                                    </div>-->
                      <div class="col-md-3 result-date text-right">
                        <span> {{office.CreatedOn | date:"dd MMM yyyy"}}</span>
                      </div>

                      <div class="col-md-2 result-date text-right" >
                        
                      </div>


                      <div class="col-md-3 result-date text-right" >
                        <a *ngIf="office.XeroUrl" href="{{office.XeroUrl}}" class="m-3">Generate Xero Token</a>
                      </div>


                     
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <!-- <div  [ngClass]="{'right-search-panel opened': show, 'right-search-panel col-md-4': show }">
              <div class="right-panel-opener right-search-arrow" (click)="show = !show" style="position: absolute; left: 0">Click me </div>
              <div class="right-panel">
                Lorem ipsum dolor sit amet,
              </div>
            </div> -->
          </div>
          <div class="row">
            <div class="col-md-4 offset-md-8">
              <mat-paginator class="pagination" [length]="length" [pageSize]="pageSize"
                [pageSizeOptions]="pageSizeOptions" (page)="getPaginatorData($event)">
              </mat-paginator>
            </div>
          </div>
        </div>
      </div>

    </div>




    <div class="col" style="    overflow-y: auto;    overflow-x: hidden;">

      <app-office-view #OfficeView></app-office-view>

    </div>


  </div>
</div>
