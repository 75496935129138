import { Component, OnInit, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ServicesListService } from '../../../services/services.service';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject } from 'rxjs';
import { ClientService } from '../../../services/client.service';
import { DocumentsService } from '../../../services/documents.service';
import { MasterService } from '../../../services/master.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-adddependent',
  templateUrl: './adddependent.component.html',
  styleUrls: ['./adddependent.component.css']
})
export class AddDependentComponent implements OnInit {


  header: string;
  selectCallType: string;
  closeBtnName: string = "Close";
  selectedTab: string = "tab1";
  addDependentForm: FormGroup;
  feeField: boolean = true;
  serviceName: string;
  uploadType: string = "MY DOCUMENTS";
  phoneType: string;
  phoneValue: string;
  onlineType: string;
  onlineValue: string;
  faceType: string;
  faceValue: string;
  durationHour: string;
  durationMinutes: string;
  servicePrice: string;
  currency: string;
  module: string;
  formValid: boolean = true;
  locationModeObserver: Subject<string> = new Subject<string>();
  priceChangeObserver: Subject<string> = new Subject<string>();
  subscriptionServices: any = [];
  public event: EventEmitter<any> = new EventEmitter();
  public clientInfo: any = [];
  staffList: any = [];
  myDocumentsList: any = [];
  @ViewChild('labelImport')
  labelImport: ElementRef;
  staffAssigned: string;

  formImport: FormGroup;
  fileToUpload: File = null;
  dependentRelationship: any = [];
  dependentIDType: string[];
  birthDate: string = "";
  agelimit: Date;
  personalMobileCountryCode: string = "";
  relationShip: string = "";
  hiddenSSN: string = "";
  personalMobile: string = "";
  gender: any = [];
  dependentIDTypeVal: string = "";


  constructor(private masterService: MasterService, private toast: ToastrService, private datePipe: DatePipe,
    private _service: ServicesListService,
    private _documentService: DocumentsService,
    public bsModalRef: BsModalRef, sb: FormBuilder,
    private _clientService: ClientService,) {
  }

  ngOnInit() {

    this.dependentRelationship = this.masterService.getMasterItems("RELATIONSHIP");

    this.dependentIDType = this.masterService.getMasterItems("DEPENDENTIDTYPE");
    this.gender = this.masterService.getMasterItems("GENDER");

    

    this.addDependentForm = new FormGroup({
      relationShip: new FormControl('', [Validators.required]),
      idType: new FormControl('', [Validators.required]),
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl('', [Validators.required]),
      phone: new FormControl(''),
      email: new FormControl('', [
        Validators.email,
      ]),
      birthday: new FormControl('', [Validators.required]),
      ssn: new FormControl(),
      marriageAnniversaryDate: new FormControl(),
      actualSSN: new FormControl(),
      gender: new FormControl('', [Validators.required]),
      itinNumber: new FormControl('', [Validators.required])
    });

    this.staffAssigned = localStorage.getItem("UserSoid");

    var date = new Date();
    date.setFullYear(date.getFullYear() - 18);
    this.agelimit = date;

  }

  addDependent() {
    for (let inner in this.addDependentForm.controls) {
      this.addDependentForm.get(inner).markAsTouched();
      this.addDependentForm.get(inner).updateValueAndValidity();
    }
    if (!this.addDependentForm.valid) {
      this.formValid = false;
      this.toast.warning("Fill the required fields.", "Warning!");
      return;
    }


    var dependentInfo: any = {};
    dependentInfo.FirstName = this.addDependentForm.controls.firstName.value;
    dependentInfo.LastName = this.addDependentForm.controls.lastName.value;
    dependentInfo.DOB = this.datePipe.transform(this.addDependentForm.controls.birthday.value, 'MM/dd/yyyy');
    dependentInfo.Email = this.addDependentForm.controls.email.value;
    
    dependentInfo.IDType = this.addDependentForm.controls.idType.value;

    if (dependentInfo.IDType == "SSN") {
      dependentInfo.SSN =  btoa(this.hiddenSSN);
    } else {
      dependentInfo.ITINNumber = this.addDependentForm.controls.itinNumber.value;
    }
    
    
    var countryCode = this.personalMobileCountryCode ? '+' + this.personalMobileCountryCode : '+1';
    dependentInfo.MobileNumber = countryCode + this.personalMobile;
    dependentInfo.MarriageAnniversaryDate = this.datePipe.transform(this.addDependentForm.controls.marriageAnniversaryDate.value, 'MM/dd/yyyy');

    dependentInfo.DependentType = this.addDependentForm.controls.relationShip.value;
    dependentInfo.Gender = this.addDependentForm.controls.gender.value;
    dependentInfo.Status = 'Active';

    this._clientService.saveDependentInfo(this.clientInfo.Soid, dependentInfo).subscribe((res: any) => {
      if (res) {
        this.toast.success("Dependent added successfully.", "Success!");
        this.triggerEvent(res);
        this.bsModalRef.hide();
      } else {
        this.toast.warning("Error Creating service", "Error!");
      }
    });


  }


  triggerEvent(res) {
    this.event.emit(res);
  }

  updateIDValidators() {
    const ssn = this.addDependentForm.controls.ssn;
    const itinNumber = this.addDependentForm.controls.itinNumber;

    ssn.setValidators(null);
    itinNumber.setValidators(null);
    if (this.dependentIDTypeVal === 'SSN') {
      ssn.setValidators([Validators.required]);
    } else {
      itinNumber.setValidators([Validators.required]);
    }
  }


  updateValidators() {
    const marriageAnniversaryDate = this.addDependentForm.controls.marriageAnniversaryDate;

    marriageAnniversaryDate.setValidators(null);
    if (this.relationShip === 'Spouse') {
      marriageAnniversaryDate.setValidators([Validators.required]);
    }
  }

  phoneNumberFieldFocusOut(event) {
    if (event.srcElement.value.trim() != "") {
      this.personalMobile = event.srcElement.value;
    }
  }

  countryChange(event) {

    this.personalMobileCountryCode = event.dialCode;
  }
}
