<div *ngIf="showView && showView == 'POPUP'" class="modal-header">
  <h4 class="modal-title pull-left">{{title}}</h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div [ngClass]="(showView && showView != 'POPUP')  ? 'section-container' : ''" >
  <div class="subheader" *ngIf="showView && showView != 'POPUP'">
    <h2>Receipt</h2>
    <!-- <h5>Plans and events</h5> -->
  </div>
  <div class="white-bg rounded border-0">
    <div class="row top-menu bg-light bg-grey no-gutters"  >
      <div class="col-md-6" *ngIf="showView && showView != 'POPUP'">

        <div class="top-menu-container" *ngIf="showView && showView != 'POPUP'" >
          <a class="btn-link btn-icon-back" *ngIf="showView && showView != 'POPUP'" (click)="goBack()"><i class="fa fa-chevron-left"></i></a>
          <!-- <a class="btn btn-primary"  >Charge</a>-->
          <!-- <a class="btn btn-primary" (click)="recordPayment()">View Receipt</a>
         <a class="btn btn-primary" (click)="recordPayment()">Resend Receipt</a>
          <a class="btn btn-primary" *ngIf="invoiceInfo.Balance > 0" (click)="recordPayment()">Record Payment</a>-->
          <a class="btn btn-primary" *ngIf="showView && showView != 'POPUP'" (click)="showInvoice()">View Invoice</a>
          <a class="btn btn-primary" (click)="pushToXero()" *ngIf="paymentInfo.XeroRecieptReference == null" >Push to Xero</a>
          <a class="btn btn-warning" [matMenuTriggerFor]="menu" *ngIf="showView && showView != 'POPUP'" ><i class="fas fa-ellipsis-v"></i></a>
       
          <mat-menu #menu="matMenu">
            <button mat-menu-item>
              <mat-icon>receipt_long</mat-icon>
              <span>Cancel Payment</span>
            </button>
            <button mat-menu-item (click)="generatePDF('download')">
              <mat-icon>cloud_download</mat-icon>
              <span>Download Receipt</span>
            </button>
            <button mat-menu-item (click)="generatePDF('print')">
              <mat-icon>print</mat-icon>
              <span>Print Receipt</span>
            </button>
            <button mat-menu-item (click)="openSendConfirmationPopup(template)">
              <mat-icon>email</mat-icon>
              <span>Send email</span>
            </button>
          </mat-menu>

        </div>
      </div>
      <div class="col-md-12 text-right" *ngIf="showView && showView == 'POPUP'">
        <div class="top-menu-container">
          <a class="btn btn-primary"  (click)="generatePDF('download')"><mat-icon>cloud_download</mat-icon></a>
          <a class="btn btn-primary" (click)="generatePDF('print')" *ngIf="showView && showView ==  'POPUP'" ><mat-icon>print</mat-icon></a>
          <!-- <a class="btn btn-secondary">View on calender</a>
          <a class="btn btn-secondary">View conversation</a>
          <a class="btn btn-secondary" (click)="newTimeForAppoinment()">New Time</a>
          <a class="btn btn-secondary" (click)="resceduleAppoinmentInfo()">Info</a> -->
        </div>
      </div>
    </div>
    <div class="padding-10">
      <!-- <div class="container"> -->
      <div class="row">
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-2">
              <div class="date-container">
                <div class="date"><i class="far fa-file-image fa-5x"></i> <!--
                  <span class="binds"></span>
                  <span class="month">{{documentInfo.Time.Start | date:"EE" | uppercase}}</span>
                  <h1 class="day">{{documentInfo.Time.Start | date:"dd"}}
                    <div class="month-text">{{documentInfo.Time.Start | date:"MMM"}}</div>
                  </h1>
                 -->
                </div>
              </div>

            </div>

            <div class="col-md-7">
              <h5>Payment For {{invoiceInfo.Label}} #{{invoiceInfo.InvoiceNumber}}</h5>
              <h2 *ngIf="balanceInfo">{{balanceInfo}}</h2>
              <ul class="list-group list-group-flush list-no-borders">
                <li class="list-group-item"><i class="fa fa-clock mr-2"></i>Paid</li>
                <li class="list-group-item"><i class="fa fa-user-tie  mr-2"></i> {{invoiceInfo.BusinessName ?
                  invoiceInfo.BusinessName : '' | titlecase}}{{invoiceInfo.BusinessName ? ' / ' : '' |
                  titlecase}}{{invoiceInfo.BillingToFirstName | titlecase}} {{invoiceInfo.BillingToLastName |
                  titlecase}}</li>
                <li class="list-group-item"><i class="fa fa-mobile-alt  mr-2"></i>{{clientInfo.PersonalMobile}} </li>
                <li class="list-group-item"><i class="fa fa-email-alt  mr-2"></i>{{clientInfo.Email}} </li>

              </ul>
            </div>
            <div class="col-md-3" >
              <img src="assets/images/xero-logo.png" height="50px" width="50px" class="rounded" />
              <span style="font-weight:500;font-size: 16px;padding-left: 10px;">{{paymentInfo.XeroStatus}}</span>
            </div>
          </div>
          <div class="row">
            <div class="col-md-5">
              <div class="card">
                <div class="card-header">
                  Details
                </div>
                <div class="card-body card-info">
                  <label>Staff</label>
                  <!-- <button>{{eventInfo.staffFName}} {{eventInfo.staffLName}} <button *ngIf="eventStatus == 'Scheduled'" (click)="assignStaff()" class="btn btn-link">Assign</button></p> -->
                  <p>{{invoiceInfo.StaffName}}</p>
                  <label>Created On</label>
                  <!-- <button>{{eventInfo.staffFName}} {{eventInfo.staffLName}} <button *ngIf="eventStatus == 'Scheduled'" (click)="assignStaff()" class="btn btn-link">Assign</button></p> -->
                  <p>{{invoiceInfo.CreatedOn | date:"dd MMM yyyy"}}</p>
                  <label>Paid On</label>
                  <!-- <button>{{eventInfo.staffFName}} {{eventInfo.staffLName}} <button *ngIf="eventStatus == 'Scheduled'" (click)="assignStaff()" class="btn btn-link">Assign</button></p> -->
                  <p>{{paymentInfo.PaidOn | date:"dd MMM yyyy"}}</p>
                  <!-- <label>Due Date</label> -->
                  <!-- <button>{{eventInfo.staffFName}} {{eventInfo.staffLName}} <button *ngIf="eventStatus == 'Scheduled'" (click)="assignStaff()" class="btn btn-link">Assign</button></p> -->
                  <!-- <p>{{invoiceInfo.DueOn | date:"dd MMM yyyy"}}</p> -->
                  <!-- <label>Payment Details</label> -->
                  <!-- <button>{{eventInfo.staffFName}} {{eventInfo.staffLName}} <button *ngIf="eventStatus == 'Scheduled'" (click)="assignStaff()" class="btn btn-link">Assign</button></p> -->
                  <!-- <p>Mar 31</p> -->
                  <label>Status Summary</label>
                  <!-- <button>{{eventInfo.staffFName}} {{eventInfo.staffLName}} <button *ngIf="eventStatus == 'Scheduled'" (click)="assignStaff()" class="btn btn-link">Assign</button></p> -->
                  <p>{{invoiceInfo.Notes}}</p>
                  <ng-container *ngIf="paymentInfo.XeroRecieptReference != null">
                    <label style="display: inline-block;padding-right: 5px;">Xero Receipt Reference</label>(<a class=""
                      target="_blank" href="{{xeroRecieptURL}}"
                      style="color: blue;cursor:pointer; display: inline-block;">Link</a>)
                    <!-- <button>{{eventInfo.staffFName}} {{eventInfo.staffLName}} <button *ngIf="eventStatus == 'Scheduled'" (click)="assignStaff()" class="btn btn-link">Assign</button></p> -->
                    <p>{{paymentInfo.XeroRecieptReference}}</p>
                  </ng-container>
                </div>
              </div>
              <br>

            </div>
            <div class="col-md-7">
              <div class="card">
                <div class="card-header">
                  <div class="row">
                    <div class="col-md-7">
                      Items
                    </div>
                    <div class="col-md-2">
                    </div>
                    <div class="col-md-3">
                      Total
                    </div>
                  </div>
                </div>
                <div class="card-body card-info">
                  <div class="row" *ngFor="let row of invoiceInfo.Items">
                    <div class="col-md-7">
                      {{row.Item}} <br>
                      {{row.Quantity}} x {{row.UnitPrice}} USD
                    </div>
                    <div class="col-md-2">
                    </div>
                    <div class="col-md-3">
                      ${{row.Price}} USD
                    </div>
                  </div>
                  <div class="row" style="padding: 5px 0;
                  border-bottom: 1px solid blue"></div>
                  <div class="row">
                    <div class="col-md-7">
                      Subtotal
                    </div>
                    <div class="col-md-2">
                    </div>
                    <div class="col-md-3">
                      ${{invoiceInfo.SubTotal}} USD
                    </div>
                  </div>
                  <div class="row" style="color: brown;
                  font-weight: 500;">
                    <div class="col-md-7">
                      Tax
                    </div>
                    <div class="col-md-2">
                    </div>
                    <div class="col-md-3">
                      ${{invoiceInfo.Taxes}} USD
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-7" style="font-weight: 500;">
                      <strong>Total Amount</strong>
                    </div>
                    <div class="col-md-2">
                    </div>
                    <div class="col-md-3" style="font-weight: 500;">
                      <strong>${{invoiceInfo.Total}} USD</strong>
                    </div>
                  </div>
                  <br>
                  <!-- <div class="row"  >
                    <div class="col">
                      Total Amount
                    </div>
                    <div class="col">
                    </div>
                    <div class="col">
                      ${{invoiceInfo.Invoice.Total}} USD
                    </div>
                  </div>
                  <br>-->
                  <div class="row">
                    <div class="col-md-7" style="font-weight: 500;">
                      <strong>Total Paid</strong>
                    </div>
                    <div class="col-md-2">
                    </div>
                    <div class="col-md-3" style="font-weight: 500;">
                      <strong>${{invoiceInfo.Total - paymentInfo.Balance}} USD</strong>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-7" style="font-weight: 500;">
                      <strong>Receipt Amount</strong>
                    </div>
                    <div class="col-md-2">
                    </div>
                    <div class="col-md-3" style="font-weight: 500;">
                      <strong>${{paymentInfo.Amount}} USD</strong>
                    </div>
                  </div>

                  <div class="row" style="color: red;">
                    <div class="col-md-7" style="font-weight: 500;">
                      <strong>Balance</strong>
                    </div>
                    <div class="col-md-2">
                    </div>
                    <div class="col-md-3" style="font-weight: 500;">
                      <strong>${{paymentInfo.Balance}} USD</strong>
                    </div>
                  </div>

                  <!-- <br>
                  <div class="row"  *ngIf="invoiceInfo.Invoice.Balance > 0">
                    <div class="col">
                      Balance Due
                    </div>
                    <div class="col">
                    </div>
                    <div class="col">
                      ${{invoiceInfo.Invoice.Balance}} USD
                    </div>
                  </div>
                  <br> -->
                </div>
              </div>
              <br />

            </div>

          </div>
        </div>
        <!--  <div class="col-md-3">
            <app-edit (sendMessage)="showDataFromParent($event)"></app-edit>
          </div> -->
      </div>

      <!-- </div> -->
    </div>
  </div>
</div>

<ng-template #template>
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title">Send payment by email</h5>
      <button type="button" class="close" aria-label="Close" (click)="sendPaymentConfirmationPopup.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      Do you want to send <b>{{ invoiceInfo.InvoiceNumber }}</b> invoice by email?
    </div>
    <div class="modal-footer">
      <button class="btn btn-danger" (click)="sendPaymentConfirmationPopup.hide()">Cancel</button>
      <button class="btn btn-info" (click)="sendPaymentByEmail()" >Send</button>
    </div>
  </div>
</ng-template>
