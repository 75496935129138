<div class="modal-header">
  <h4 class="modal-title pull-left">{{title}}</h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form class="client-modal" [formGroup]="serviceInvoiceForm">
    <!--<div class="form-row">
      <div style="float: left; width: 50px; height:50px;">
        <ngx-avatar size="40" name="{{clientInfo.NameFirst}} {{clientInfo.NameLast}}"></ngx-avatar>
      </div>
      <div style="float: left; width: 80%;">
        <div class="client-list-left">
          <h6 class="mt-0 mb-1">{{clientInfo.NameFirst | titlecase}} {{clientInfo.NameLast | titlecase}}</h6>
          <span><i class="fas fa-at"></i>{{clientInfo.Email}}</span>
        </div>
      </div>
    </div>-->


    <accordion >
      <accordion-group [isOpen]="false" #billingFrom>
        <span accordion-heading>
          <i class="fas mr-2" [ngClass]="{'fa-angle-down': billingFrom?.isOpen, 'fa-angle-up': !billingFrom?.isOpen}"></i>
          <strong>From: </strong>{{officeName}}
        </span>
        <br>

        <mat-form-field appearance="fill">
          <mat-label>Business Address</mat-label>
          <textarea matInput formControlName="billingFromAddress" [(ngModel)]="billingFromAddress" disabled
                  ></textarea>
        </mat-form-field>

      </accordion-group>

      
      <accordion-group [isOpen]="false" #billingTo>
        <span accordion-heading>
          <i class="fas  mr-2" [ngClass]="{'fa-angle-down': billingTo?.isOpen, 'fa-angle-up': !billingTo?.isOpen}"></i>
          <strong>To: </strong>{{billToName}}          
        </span>
        <mat-form-field appearance="fill">
          <mat-label>Contact: {{billToName}}</mat-label>
          <textarea matInput formControlName="billingToAddress" [(ngModel)]="billingToAddress" disabled
                  ></textarea>
        </mat-form-field>

      </accordion-group>


      <accordion-group [isOpen]="true" #invoiceDetails>
        <span accordion-heading>
          <i class="fas  mr-2" [ngClass]="{'fa-angle-down': invoiceDetails?.isOpen, 'fa-angle-up': !invoiceDetails?.isOpen}"></i>
          <strong>Invoice Details</strong>
        </span>

        <div class="form-row">
          <div class="col-md-6">
            <mat-form-field>
              <input type="input" autocomplete="off" formControlName="invoiceLabel" matInput
                placeholder="Invoice Label">
              <mat-error *ngIf="serviceInvoiceForm.controls.invoiceLabel.errors != null && !formValid">
                Please enter invoice label
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <mat-form-field>
              <input readonly  [(ngModel)]="invoiceNo" autocomplete="off" formControlName="invoiceNumber" 
              matInput placeholder="Invoice Number">
              <mat-error *ngIf="serviceInvoiceForm.controls.invoiceNumber.errors != null && !formValid">
                Please enter invoice number
              </mat-error>
            </mat-form-field>
          </div>

        </div>

        <div class="form-row">
          <div class="col-md-6">
            <mat-form-field>
              <input matInput autocomplete="off" formControlName="issueDate" [matDatepicker]="picker"
                placeholder="Issue Date">
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
              <mat-error *ngIf="serviceInvoiceForm.controls.issueDate.errors != null && !formValid">
                Please select issue date
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <mat-form-field>
              <input matInput autocomplete="off" formControlName="dueDate" [matDatepicker]="dueDatePicker"
                placeholder="Due Date">
              <mat-datepicker-toggle matSuffix [for]="dueDatePicker"></mat-datepicker-toggle>
              <mat-datepicker #dueDatePicker></mat-datepicker>
              <mat-error *ngIf="serviceInvoiceForm.controls.dueDate.errors != null && !formValid">
                Please select due date
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-12">
            <mat-form-field>
              <input type="input" [(ngModel)]="purchaseOrder" readonly autocomplete="off" formControlName="purchaseOrder" matInput
                placeholder="Purchase Order">
              <mat-error *ngIf="serviceInvoiceForm.controls.purchaseOrder.errors != null && !formValid">
                Please enter service name
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="col-md-12">

          <div class="invoice">
            <div class="header-row">
              <div class="item">Item</div>
              <div class="quantity">Quantity</div>
              <div class="unit-price">Price</div>
              <div class="unit-price">Tax</div>
              <div class="price" style="text-align: right;">Amount (USD)</div>
            </div>
            <div class="items">
              <!-- Angular ngFor loop here to generate item rows -->
              <div class="item-row" *ngFor="let row of invoiceInfo.Items">
                <div class="item">{{row.Item}}</div>
                <div class="quantity">{{row.Quantity}}</div>
                <div class="unit-price">{{row.UnitPrice}}</div>
                <div class="unit-price">{{row.TaxAmount}}</div>
                <div class="price" style="text-align: right;">{{row.Price}}</div>
              </div>
            </div>
            <!--  <div class="subtotal-row">
              <div class="subtotal-label">Sub Total</div>
              <div class="subtotal">{{invoiceSubTotal}}</div>
            </div> -->
            <div class="total-row">
              <div class="total-label">Sub Total</div>
              <div class="total">{{invoiceInfo.SubTotal}}</div>
            </div>
            <div class="item-row" style="color:brown">
              <div class="total-label">Tax</div>
              <div class="total">{{invoiceInfo.Taxes}}</div>
            </div>
            <div class="total-row">
              <div class="total-label">Total (USD)</div>
              <div class="total">{{invoiceInfo.Total}}</div>
            </div>
          </div>

          
          
        </div>
      </accordion-group>
      <accordion-group [isOpen]="false" #invoiceNotes>
        <span accordion-heading>
          <i class="fas  mr-2" [ngClass]="{'fa-angle-down': invoiceNotes?.isOpen, 'fa-angle-up': !invoiceNotes?.isOpen}"></i>
          Notes
        </span>

        <div class="form-row">
          <div class="col-md-12">
            <mat-form-field>
              <input type="input" autocomplete="off" formControlName="notes" matInput placeholder="Notes">
              <mat-error *ngIf="serviceInvoiceForm.controls.notes.errors != null && !formValid">
                Please enter notes
              </mat-error>
            </mat-form-field>
          </div>
        </div>

      </accordion-group>
    </accordion>
  
  </form>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">{{closeBtnName}}</button>
  <button type="button" class="btn btn-primary" (click)="createNewDocument()">Create</button>
</div>