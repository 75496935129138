import { Pipe, PipeTransform } from '@angular/core';

import * as lodash from 'lodash';

@Pipe({ name: 'modulesNamePipe' })
export class ModulesValuesPipe implements PipeTransform {

  public transform(value) {
    var  subscriptionServices:any = [];
    var  modules = [];
    if (localStorage.getItem("Modules") && value && localStorage.getItem("Modules") != "undefined") {            
      var services: any = [];
      var services = JSON.parse(localStorage.getItem("Modules"));
      let tagList = value.split(",");
      tagList.forEach(element => {
        if (services[element]) {
          modules.push(services[element]);
        }                       
      });
    }
    return modules;
  }
}