import {
  Component,
  ChangeDetectionStrategy,
  ViewChild,
  TemplateRef
} from '@angular/core';
import { CalendarOptions, DateSelectArg, EventClickArg, EventApi } from '@fullcalendar/angular';
import { INITIAL_EVENTS, createEventId } from './event-utils';
import {
  startOfDay,
  endOfDay,
  subDays,
  addDays,
  endOfMonth,
  isSameDay,
  isSameMonth,
  addHours
} from 'date-fns';
import { Subject } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BsModalService } from 'ngx-bootstrap/modal';
import {
  CalendarEvent,
  CalendarEventAction,
  CalendarEventTimesChangedEvent,
  CalendarView
} from 'angular-calendar';

import { EventsService } from '../../services/events.service';
import { EventColor } from 'calendar-utils';
import { StaffService } from '../../services/staff.service';
import { AddServiceComponent } from '../modal/addservice/add-service.component';
import { ServicesListService } from '../../services/services.service';
import { Router } from '@angular/router';
import { SearchclientComponent } from '../modal/searchclient/searchclient.component';

const colors: any = {
  red: {
    primary: '#ad2121',
    secondary: '#FAE3E3'
  },
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF'
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA'
  }
};

@Component({
  selector: 'mwl-demo-component',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.css']
})
export class ScheduleComponent {
  // @ViewChild('modalContent') modalContent: TemplateRef<any>;

  calendarOptions: CalendarOptions = {
    timeZone: 'UTC',
    height: 650,
    contentHeight: 650,
    aspectRatio: 2,
    headerToolbar: {
      left: 'prev,next today',
      center: 'title',
      right: 'dayGridMonth,dayGridWeek,timeGridWeek,listWeek'
      //dayGridWeek, dayGridMonth,timeGridWeek,listWeek
    },
    initialView: 'dayGridMonth',
    initialEvents: INITIAL_EVENTS, // alternatively, use the `events` setting to fetch from a feed
    weekends: true,
    editable: true,
    selectable: true,
    selectMirror: false,
    dayMaxEvents: false,
    select: this.handleDateSelect.bind(this),
    eventClick: this.handleEventClick.bind(this),
    buttonText: {
      today: 'Today',
      month: 'Month',
      week: 'Week',
      day: 'Day',
      list: 'List'
    },
    events: [{}]
    /* you can update a remote database when these fire:
    eventAdd:
    eventChange:
    eventRemove:
    */
  };

  view: CalendarView = CalendarView.Month;
  isAllChecked: boolean;
  checklist: any;

  serviceList: any = [
  ];

  CalendarView = CalendarView;
  viewDate: Date = new Date();
  color?: EventColor;
  modalRef: BsModalRef;
  rule: any = {};
  modalData: {
    action: string;
    event: CalendarEvent;
  };
  staffList: any = [];
  staffIds: any = [];



  refresh: Subject<any> = new Subject();

  events: CalendarEvent[];

  activeDayIsOpen: boolean = false;
  _opened: boolean = false;

  close() {
  }

  ngOnInit() {
    this.getEvents();

    this.getStaffList();

    this.getServiceList();

  }

  private getEvents() {
    var event: any = {};
    this.events = [];

    this._eventsService.getEvents().subscribe((res: any) => {
      if (res) {
        this.refreshCalendarView(res);
      }
    });
  }

  constructor(private services: ServicesListService, private _router: Router, private _staffService: StaffService,
    private _eventsService: EventsService, private modalService: BsModalService) {
    this.isAllChecked = false;
  }

  private refreshCalendarView(res: any) {
    var event: any = [];
    for (var index = 0; index < res.length; index++) {
      var eventInfo = res[index];
      var partInfo = eventInfo.Participants.filter(e => e.ParticipantType == "Customer");
      event.push({
        title: eventInfo.Subject  + ' (' + partInfo[0].ParticipantName + ')',
        start: new Date(eventInfo.Time.Start),
        end: new Date(eventInfo.Time.End),
        id: eventInfo.Soid,

        eventBackgroundColor: 'blue',
      });
    }
    this.calendarOptions = {
      height: 650,
      contentHeight: 650,
      aspectRatio: 2,
      headerToolbar: {
        left: 'prev,next today',
        center: 'title',
        right: 'dayGridMonth,dayGridWeek,listWeek'
        //dayGridWeek, dayGridMonth,timeGridWeek,listWeek
      },
      initialView: 'dayGridMonth',
      initialEvents: INITIAL_EVENTS, // alternatively, use the `events` setting to fetch from a feed
      weekends: true,
      editable: true,
      selectable: true,
      selectMirror: false,
      dayMaxEvents: false,
      select: this.handleDateSelect.bind(this),
      eventClick: this.handleEventClick.bind(this),
      buttonText: {
        today: 'Today',
        month: 'Month',
        week: 'Week',
        day: 'Day',
        list: 'List'
      },
      eventColor: '#378006',
      eventBackgroundColor: 'blue',
      events: event,
      eventTimeFormat: {
        hour: 'numeric',
        minute: '2-digit',
        meridiem: 'short'
      },
      displayEventEnd: true
      /* you can update a remote database when these fire:
      eventAdd:
      eventChange:
      eventRemove:
      */
    };
  }

  /**  private refreshCalendarView(res: any) {
     this.events = [];
     for (var index = 0; index < res.response.eventList.length; index++) {
       var eventInfo = res.response.eventList[index];
       if (eventInfo.occurenceType === "Weekly") {
         this.color = colors.red;
         this.rule = new RRule({
           freq: RRule.WEEKLY,
           interval: eventInfo.recurWeek == "" ? 1 : parseInt(eventInfo.recurWeek),
           count: eventInfo.occurenceCount == "" ? 1 : parseInt(eventInfo.occurenceCount),
           tzid: "Asia/Kolkata",
           dtstart: new Date(eventInfo.startTime),
           until: eventInfo.recurWeek == "" ? new Date(eventInfo.endTime) : null,
         });
       }
       else {
         if (eventInfo.isWeekDays == "2") {
           this.color = colors.blue;
           this.rule = new RRule({
             freq: RRule.DAILY,
             interval: 1,
             tzid: "Asia/Kolkata",
             count: eventInfo.occurenceCount == "" ? 1 : parseInt(eventInfo.occurenceCount),
             dtstart: new Date(eventInfo.startTime),
             until: null,
             byweekday: [RRule.MO, RRule.TU, RRule.WE, RRule.TH, RRule.FR]
           });
         }
         else {
           this.color = colors.blue;
           this.rule = new RRule({
             freq: RRule.DAILY,
             tzid: "Asia/Kolkata",
             interval: eventInfo.dailyInterval == "" ? 1 : parseInt(eventInfo.dailyInterval),
             count: eventInfo.occurenceCount == "" ? 1 : parseInt(eventInfo.occurenceCount),
             dtstart: new Date(eventInfo.startTime),
             until: eventInfo.dailyInterval == "" ? new Date(eventInfo.endTime) : null
           });
         }
       }
       this.rule.all().forEach(date => {
         this.events.push({
           title: eventInfo.serviceRequest,
           color: this.color,
           start: new Date(date),
           id: eventInfo.eventId
         });
       });
     }
   }
   */

  selectedService(serviceId) {
    const initialState = {
      title: "Client search",
      backdrop: true,
      ignoreBackdropClick: true,
      selectedService: serviceId
    };
    this.modalRef = this.modalService.show(SearchclientComponent, { initialState });
  }

  selectedStaff(staffId) {
    const initialState = {
      title: "Client search",
      backdrop: true,
      ignoreBackdropClick: true,
      selectedStaff: staffId
    };
    this.modalRef = this.modalService.show(SearchclientComponent, { initialState });
  }

  hourSegmentClicked(date) {
    const initialState = {
      title: "Client search",
      backdrop: true,
      ignoreBackdropClick: true,
      selectedDate: date
    };
    this.modalRef = this.modalService.show(SearchclientComponent, { initialState });

  }

  _toggleSidebar() {
    this._opened = !this._opened;
  }

  deleteEvent(eventToDelete: CalendarEvent) {
    this.events = this.events.filter(event => event !== eventToDelete);
  }

  setView(view: CalendarView) {
    this.view = view;
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }


  checkUncheckAll() {
    for (var i = 0; i < this.staffList.length; i++) {
      this.staffList[i].isSelected = this.isAllChecked;
    }
    this.getEventsByStaffId();
  }

  isAllSelected(loginId) {
    var staff = this.staffList.filter(staffInfo => staffInfo.loginId == loginId);
    if (staff[0] != null) {
      staff[0].isSelected = !staff[0].isSelected;
    }
    this.getEventsByStaffId();
  }

  staffSelection(event) {
    if (event.target.checked) {
      this.staffIds.push(parseInt(event.target.value));
      // this.getEventsByStaffId(this.staffIds);
    } else {
      this.staffIds.remove(parseInt(event.target.value));
      //  this.getEventsByStaffId(this.staffIds);
    }
  }

  private getStaffList() {
    this._staffService.getActiveStaffs().subscribe((res: any) => {
      if (res) {
        this.staffList = res;
      } else {
        this.staffList = [];
      }
    });
  }


  openSearchForClient() {
    const initialState = {
      title: "Client search",
      backdrop: true,
      ignoreBackdropClick: true
    };
    this.modalRef = this.modalService.show(SearchclientComponent);

  }

  private getServiceList() {
    this.services.getServicesList(localStorage.getItem("OfficeSoid")).subscribe((res: any) => {
      if (res) {
        this.serviceList = res;
      }
    });
  }

  private getEventsByStaffId() {
    this.staffIds = [];

    for (var i = 0; i < this.staffList.length; i++) {
      if (this.staffList[i].isSelected) {
        this.staffIds.push(this.staffList[i].Soid);
      }

    }
    this._eventsService.getEventsByStaffId(this.staffIds).subscribe((res: any) => {
      this.events = [];
      if (res) {
        this.refreshCalendarView(res);
      }
    });
  }

  openServiceModal() {
    const initialState = {
      header: "New Service",
      services: "",
      clientInfo: "",
      backdrop: true,
      ignoreBackdropClick: true
    };
    this.modalRef = this.modalService.show(AddServiceComponent);

  }

  viewServiceList() {
    this._router.navigate(['/service']);
  }

  handleDateSelect(selectInfo: DateSelectArg) {
    const calendarApi = selectInfo.view.calendar;

    calendarApi.unselect(); // clear date selection

    const initialState = {
      title: "Client search",
      backdrop: true,
      ignoreBackdropClick: true
      //,    selectedDate: date.getDate
    };
    this.modalRef = this.modalService.show(SearchclientComponent, { initialState });
  }

  handleEventClick(clickInfo: EventClickArg) {
    this._router.navigate(['/appointment/' + clickInfo.event.id]);
  }

}

