<div class="modal-header">
  <h4 class="modal-title pull-left">{{header}}</h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form class="client-modal" [formGroup]="editDocument">

    <div class="form-row">
      <div class="form-group selectType col-md-10">

        <mat-form-field>
          <input type="text" autocomplete="off" [(ngModel)]="documentTitle" formControlName="documentTitle" matInput
            placeholder="Document Title">

        </mat-form-field>
        <mat-form-field>
          <input type="text" autocomplete="off" [(ngModel)]="documentDescription" formControlName="documentDescription" matInput
            placeholder="Document Description (Optional)">
        </mat-form-field>

      </div>

    </div>
  </form>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">{{closeBtnName}}</button>
  <button type="button" class="btn btn-primary" (click)="createNewDocument()">Create</button>
</div>