<div class="section-container">
  <div class="subheader">
      <h2>Planner</h2>
      <h5>Plans and events</h5>
  </div>
  <div class="white-bg rounded border-0">
      <button type="button" class="btn btn-primary"
        tooltip="Vivamus sagittis lacus vel augue laoreet rutrum faucibus." placement="bottom">
  Simple demo
  </button>
    <!-- <div class="dropdown show">
      <a class="btn btn-secondary dropdown-toggle" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        Dropdown link
      </a>
    
      <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
        <a class="dropdown-item" href="#">Action</a>
        <a class="dropdown-item" href="#">Another action</a>
        <a class="dropdown-item" href="#">Something else here</a>
      </div>
    </div> -->
    <div class="btn-group" dropdown container="body">
      <button id="button-container" dropdownToggle type="button" class="btn btn-primary dropdown-toggle"
              aria-controls="dropdown-container">
        Dropdown on Body <span class="caret"></span>
      </button>
      <ul id="dropdown-container" *dropdownMenu class="dropdown-menu"
          role="menu" aria-labelledby="button-container">
        <li role="menuitem"><a class="dropdown-item" href="#">Action</a></li>
        <li role="menuitem"><a class="dropdown-item" href="#">Another action</a></li>
        <li role="menuitem"><a class="dropdown-item" href="#">Something else here</a></li>
        <li class="divider dropdown-divider"></li>
        <li role="menuitem"><a class="dropdown-item" href="#">Separated link</a>
        </li>
      </ul>
    </div>
    <div class="pull-right" dropdown container="body">
      <i id="button-container" dropdownToggle class="fa fa-plus" aria-controls="dropdown-container"></i>
      <ul id="dropdown-container" *dropdownMenu class="dropdown-menu"
          role="menu" aria-labelledby="button-container">
        <li role="menuitem"><a class="dropdown-item" href="#">Action</a></li>
        <li role="menuitem"><a class="dropdown-item" href="#">Another action</a></li>
        <li role="menuitem"><a class="dropdown-item" href="#">Something else here</a></li>
        <li class="divider dropdown-divider"></li>
        <li role="menuitem"><a class="dropdown-item" href="#">Separated link</a>
        </li>
      </ul>
    </div>
    <div>  
      <tabset>  
        <tab heading="Name" id="tab1">Satyaprakash Samantaray</tab>  
        <tab heading="About">I am a Software Developer and MVP Winner</tab>  
        <tab heading="Contact">satyatohome@gmail.com</tab>  
        <tab heading="My Book">https://www.dotnettricks.com/mentors/satyaprakash-samantaray</tab>  
      </tabset>  
    </div>  
    <p tooltip="Vivamus sagittis lacus vel augue laoreet rutrum faucibus."
    placement="right">Tooltip</p>
  </div>
</div>