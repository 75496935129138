import { Component, OnInit, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ServicesListService } from '../../../services/services.service';
import { ClientService } from '../../../services/client.service';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject } from 'rxjs';
import { StaffService } from '../../../services/staff.service';
import { InvoiceService } from '../../../services/invoice.service';
import { Router } from '@angular/router';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';


@Component({
  selector: 'app-estimate-to-invoice',
  templateUrl: './estimate-to-invoice.component.html',
  styleUrls: ['./estimate-to-invoice.component.css']
})
export class EstimateToInvoiceComponent implements OnInit {

  billingToList: any = [];
  title: string = "Invoice";
  selectCallType: string;
  closeBtnName: string = "Close";
  selectedTab: string = "tab1"; //Initial tab active
  paymentTypes: Array<string> = ['USD', 'POUNDS', 'TINAR', 'BHAT'];
  serviceInvoiceForm: FormGroup;
  feeField: boolean = true;
  serviceName: string;
  uploadType: string = "MY DOCUMENTS";
  phoneType: string;
  phoneValue: string;
  onlineType: string;
  onlineValue: string;
  faceType: string;
  faceValue: string;
  durationHour: string;
  durationMinutes: string;
  servicePrice: string;
  currency: string;
  module: string;
  formValid: boolean = true;
  locationModeObserver: Subject<string> = new Subject<string>();
  priceChangeObserver: Subject<string> = new Subject<string>();
  subscriptionServices: any = [];
  public event: EventEmitter<any> = new EventEmitter();
  public clientInfo: any = {};
  public invoiceInfo: any = {};
  staffList: any = [];
  serviceList: any = [];
  invoiceNo: string;
  myDocumentsList: any = [];
  @ViewChild('labelImport')
  labelImport: ElementRef;
  agelimit: number = 10;
  invoicePreListItems: any = {};
  formImport: FormGroup;
  fileToUpload: File = null;
  InvoicePriceListItemQuantity: number = 0;
  invoiceServiceItems: any = [];
  invoiceSubTotal: number = 0.00;
  invoiceTotal: number = 0.00;
  invoiceQuantity: number = 1;
  officeName: string;
  purchaseOrder: string;
  billingFromAddress: string;
  billingToAddress: string;

  billingToSelected: any = {};
  invoiceServiceItem: any = {};
  clientBusiness: any = {};
  clientDependent: any = {};
  invoicePrice: number;
  changeUnitPrice: boolean = false;
  billToName: string;
  disableInvoice: boolean = true;
  totalTax: number = 0.00;
  subTotal: number = 0.00;

  constructor(private toast: ToastrService, private _service: ServicesListService,
    private _invoiceService: InvoiceService,
    private _router: Router,
    private _clientService: ClientService,
    public bsModalRef: BsModalRef, sb: FormBuilder,
    private _staffService: StaffService,) {
  }


  ngOnInit() {

    this.serviceInvoiceForm = new FormGroup({
      invoiceLabel: new FormControl('', [Validators.required]),
      //currency: new FormControl('',[Validators.required]),
      invoiceNumber: new FormControl('', [Validators.required]),
      purchaseOrder: new FormControl(),
      estimateNumber: new FormControl(),
      estimateLabel: new FormControl(),
      issueDate: new FormControl('', [Validators.required]),
      dueDate: new FormControl('', [Validators.required]),
      notes: new FormControl(),
      serviceItem: new FormControl(),
      invoiceQuantity: new FormControl(),
      serviceUnitPrice: new FormControl(),
      servicePrice: new FormControl(),
      billingToAddress: new FormControl(),
      billingFromAddress: new FormControl()
    });

    
    this.calculateSubTotal();

    this.billToName = this.invoiceInfo.BillingToFirstName + ' ' + this.invoiceInfo.BillingToLastName;

    this.billingFromAddress = this.invoiceInfo.BillingFromAddress + ',' + this.invoiceInfo.BillingFromState + ',' + this.invoiceInfo.BillingFromCountry  + ',' + this.invoiceInfo.BillingFromZip; 
    

    this.billingToAddress = "Address: " + (this.invoiceInfo.BillingToAddress ? this.invoiceInfo.BillingToAddress + ', ' : '')
          + (this.invoiceInfo.BillingToCity ? this.invoiceInfo.BillingToCity + ', ' : '')
          + (this.invoiceInfo.BillingToState ? this.invoiceInfo.BillingToState + ', ' : '')
          + (this.invoiceInfo.BillingToZip ? this.invoiceInfo.BillingToZip + ', ' : '')        
          + (this.invoiceInfo.BillingToCountry ? this.invoiceInfo.BillingToCountry + ', ' : '') ;
          
    //this.getStaffList();
    
    this.getInvoiceNumber();
    this.officeName = localStorage.getItem("OfficeName");
   
    this.purchaseOrder = this.invoiceInfo.Label + '-' + this.invoiceInfo.EstimateNumber;
    this.invoiceInfo.PurchaseOrder = this.purchaseOrder;
  }


  createNewDocument() {
    for (let inner in this.serviceInvoiceForm.controls) {
      this.serviceInvoiceForm.get(inner).markAsTouched();
      this.serviceInvoiceForm.get(inner).updateValueAndValidity();
    }
    if (!this.serviceInvoiceForm.valid) {
      this.formValid = false;
      this.toast.warning("Fill the required fields.", "Warning!");
      return;
    }
    if (this.invoiceInfo.items && this.invoiceInfo.items.length == 0 ) {
      this.toast.warning("Please add service items.", "Warning!");
      return;
    }


    var requestInfo: any = {};
    requestInfo = this.invoiceInfo;
    requestInfo.EstimateSoid = this.invoiceInfo.Soid;
    requestInfo.StaffSoid = localStorage.getItem("UserSoid");
    requestInfo.StaffName = localStorage.getItem("NameFirst") + " " + localStorage.getItem("NameLast");
    requestInfo.Soid = null;
    requestInfo.Label = this.serviceInvoiceForm.controls.invoiceLabel.value;
    requestInfo.InvoiceNumber = this.serviceInvoiceForm.controls.invoiceNumber.value;
    requestInfo.InvoicedOn = this.serviceInvoiceForm.controls.issueDate.value;
    requestInfo.DueOn = this.serviceInvoiceForm.controls.dueDate.value;
    requestInfo.Notes = this.serviceInvoiceForm.controls.notes.value;
  
  
    requestInfo.Status = "ISSUED";
    requestInfo.BillingToSoid = this.invoiceInfo.CustomerSoid;
   
    this._invoiceService.createInvoice(this.clientInfo.Soid, requestInfo).subscribe((res: any) => {
      if (res && res.Soid != null) {
        this.toast.success("Invoice created successfully, Click 'View Invoice' to view.", "Success!");
        this.invoiceInfo.Status = "INVOICED";
        this.invoiceInfo.InvoiceSoid = res.Soid;
        //this._router.navigate(['/client/' + this.clientInfo.Soid + '/invoice/' + res.Soid]);
        this._router.navigate(['/client/' + this.clientInfo.Soid + '/invoice/' + res.Soid]);
        this.triggerEvent();

        this.bsModalRef.hide();
      } else {
        this.toast.warning("Error Creating service", "Error!");
      }
    });

  }


  private getInvoiceNumber() {
    this._invoiceService.getInvoiceNumber().subscribe((res: any) => {
      if (res) {
        this.invoiceNo = res;
      }
    });
  }

 

  calculateLineTotal(e) {
    var tr = e.closest('tr');
    var price = tr.find('input.UnitPrice').val().replace(/,/g, '') * 100;
    //	if (price === "") price = 0;
    var quantity = tr.find('input.Quantity').val();
    if (quantity == "") quantity = 0;
    var total = parseFloat("0.9") * parseFloat(quantity);
    tr.find('span.LineTotal').text(this.formatDecimal(total));
    this.calculateSubTotal();
  }

  formatDecimal(d, n = 2, x = 3) {
    var re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\.' : '$') + ')';
    try {
      var decimal = parseFloat(d) / 100;
      return decimal.toFixed(Math.max(0, ~~n)).replace(new RegExp(re, 'g'), '$&,');
    } catch {
      return '0.00';
    }
  }

  calculateSubTotal() {
    var subTotal = 0;
    this.invoiceServiceItems.forEach(element => {
      if (subTotal == 0) {
        subTotal = element.Price;
      } else {
        subTotal += element.Price;
      }
    });
    this.subTotal = subTotal;
    this.totalTax = Number((subTotal * .0825).toFixed(2));
    this.invoiceTotal = this.totalTax + this.subTotal;
  }

  triggerEvent() {
    this.event.emit();
  }

 

  serviceChange() {
    this.invoicePrice = this.invoiceServiceItem.UnitPrice * this.invoiceQuantity;
    this.changeUnitPrice = this.invoiceServiceItem.PriceType == 'Flexi Fee';
  }




}
