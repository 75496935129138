import {Component, OnInit, Output, EventEmitter, TemplateRef} from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { NewTimeForAppoinmentComponent } from '../../modal/new-time-for-appoinment/new-time-for-appoinment.component';
import { ResheduleAppoinmentInfoComponent } from '../../modal/reshedule-appoinment-info/reshedule-appoinment-info.component';
import { CancelAppoinmentComponent } from '../../modal/cancel-appoinment/cancel-appoinment.component';
import { DatePipe } from '@angular/common';
import { ClientService } from '../../../services/client.service';
import { InvoiceService } from '../../../services/invoice.service';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { EstimateToInvoiceComponent } from '../../modal/estimate-to-invoice/estimate-to-invoice.component';
import { ConfirmationDialog } from '../../modal/confirmation-dialog/confirmation-dialog.component';
import { ToastrService } from 'ngx-toastr';
import { Location} from '@angular/common';

@Component({
  selector: 'app-view-estimates',
  templateUrl: './view-estimates.component.html',
  styleUrls: ['./view-estimates.component.css']
})
export class ViewEstimatesComponent implements OnInit {
  eventInfo: any = {};
  clientList: any = {};
  myForm:FormGroup;
  contactForm: FormGroup;
  tagForm: FormGroup;
  statusForm: FormGroup;
  infoForm: FormGroup;
  clientForm:FormGroup;
  disabled = false;
  ShowFilter = false;
  limitSelection = false;
  cities: any = [];
  selectedItems: any = [];
  dropdownSettings: any = {};
  modalRef: BsModalRef;
  invoiceInfo: any = {};
  estimateId:string;
  clientId:number;
  eventStatus:string;
  selected: string;
  actualValue: string;
  birthDate: Date;
  appoinmentClient: any = {};
  agelimit: 18;
  lmsStartDate: Date;
  expirationDate: Date;
  invoiceStatus: string;
  subscriptionServices:any = [];
  confirmationPopup: BsModalRef;

  staffInfo : any = {};
  clientInfo : any = {};

  countries: string[] = [
    'Canada', 'Mexico', 'United States'
  ];

  constructor(private modalService: BsModalService,  private _router: Router, private datePipe: DatePipe,
     private _clientService: ClientService,private toast: ToastrService, private location: Location,
    private activatedRoute: ActivatedRoute,
     private _invoiceService: InvoiceService,
      private fb: FormBuilder) { }

  ngOnInit() {

    this.estimateId = this.activatedRoute.snapshot.params.estimateId;
    this.clientId       = this.activatedRoute.snapshot.params.loginId;

    this.getInvoice();
    this.getClientInfo();


    //debugger;
    this.selectedItems = [{ item_id: 2, item_text: 'Staff' }, { item_id: 6, item_text: 'Documents' }];
    this.dropdownSettings = {
        singleSelection: false,
        idField: 'item_id',
        textField: 'item_text',
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        itemsShowLimit: 5,
        allowSearchFilter: this.ShowFilter
    };
    this.myForm = this.fb.group({
        city: [this.selectedItems]
    });

    this.tagForm = this.fb.group({
      tag: new FormControl(this.selectedItems, Validators.compose([
        Validators.required
      ]))
    });
    this.statusForm = this.fb.group({
      status: new FormControl('', Validators.compose([
        Validators.required
      ]))
    });

    this.contactForm  = this.fb.group({
      addressLine1: new FormControl('', Validators.compose([
        Validators.required
    ])),
      addressLine2: new FormControl('', Validators.compose([
    ])),state: new FormControl('', Validators.compose([
      Validators.required
    ]))
    , city: new FormControl('', Validators.compose([
      Validators.required
    ])),country: new FormControl('', Validators.compose([
      Validators.required
    ])),phoneNo: new FormControl('', Validators.compose([
      Validators.required
    ])),pincode: new FormControl('', Validators.compose([
      Validators.required
    ])),homeAddress: new FormControl('', Validators.compose([
      Validators.required
    ])),personalPhoneCountryCode: new FormControl('', Validators.compose([
      Validators.required
    ])),personalMobile: new FormControl('', Validators.compose([
      Validators.required
    ])),businessAddress: new FormControl('', Validators.compose([
      Validators.required
    ])),businessCountryCode: new FormControl('', Validators.compose([
      Validators.required
    ])),businessMobileNo: new FormControl('', Validators.compose([
      Validators.required
    ])),businessEmail: new FormControl('', Validators.compose([
      Validators.required
    ])),zipCode: new FormControl('', Validators.compose([
      Validators.required
    ]))

    });

    this.infoForm = this.fb.group({
      ssn: new FormControl('', Validators.compose([
        Validators.required
      ])),
      dlNumber: new FormControl('', Validators.compose([
        Validators.required
      ])),
      dlState: new FormControl('', Validators.compose([
        Validators.required
      ])),
      dlExpirationDate: new FormControl('', Validators.compose([
        Validators.required
      ])),
      dlStartDate: new FormControl('', Validators.compose([
        Validators.required
      ])),
      ITINNumber: new FormControl('', Validators.compose([
        Validators.required
      ])),
    });
    this.clientForm = this.fb.group({
      email: new FormControl('', Validators.compose([
        Validators.required
      ])),
      firstName: new FormControl('', Validators.compose([
        Validators.required
      ])),
       lastName: new FormControl('', Validators.compose([
        Validators.required
      ])),
      birthday: new FormControl('', Validators.compose([
        Validators.required
      ])),
      companyName: new FormControl('', Validators.compose([
        Validators.required
      ])),
      websiteURL: new FormControl('', Validators.compose([
        Validators.required
      ])),
      lmsStartDate: new FormControl('', Validators.compose([
        Validators.required
      ])),
      languagePreference: new FormControl('', Validators.compose([
        Validators.required
      ])),
      hearAboutUs: new FormControl('', Validators.compose([
        Validators.required
      ])),
      couple: new FormControl('', Validators.compose([
        Validators.required
      ])),
      CompanyName: new FormControl('', Validators.compose([
        Validators.required
      ])),
      EINNumber: new FormControl('', Validators.compose([
        Validators.required
      ]))

    });
  }

  private getInvoice() {
    this._invoiceService.getEstimates(this.clientId, this.estimateId).subscribe((res: any) => {
      if (res) {
        this.invoiceInfo = res;
      }
    });
  }

  showInvoice() {
    this._router.navigate(['client/' + this.clientId + '/invoice/' + this.invoiceInfo.InvoiceSoid]);
  }

  inActiveDocument() {


  }

  downloadFile() {

  }

  convertBase64ToBlob(base64Image: string, mimType) {


    // Decode Base64 string
    const decodedData = window.atob(base64Image);

    // Create UNIT8ARRAY of size same as row data length
    const uInt8Array = new Uint8Array(decodedData.length);

    // Insert all character code into uInt8Array
    for (let i = 0; i < decodedData.length; ++i) {
      uInt8Array[i] = decodedData.charCodeAt(i);
    }

    // Return BLOB image after conversion
    return new Blob([uInt8Array], { type: mimType });
  }

  viewFile() {

  }


  //New Time For Appoinment
  newTimeForAppoinment() {
    const initialState = {
      header: "Reschedule Appoinment",
      closeBtnName: "Save",
      backdrop: true,
      ignoreBackdropClick: true
    };
    this.modalRef = this.modalService.show(ResheduleAppoinmentInfoComponent);
  }



  loadEstimateToInvoice() {
    const initialState = {
      header: "Estimate Invoice",
      closeBtnName: "Save",
      backdrop: true,
      ignoreBackdropClick: true,
      clientInfo: this.clientInfo,
      invoiceInfo: this.invoiceInfo
    };
    this.modalRef = this.modalService.show(EstimateToInvoiceComponent, {initialState});
    this.modalRef.setClass('invoice-dialog');
    this.modalRef.content.event.subscribe(data => {
        this.getInvoice();
    });
  }

  //New Time For Appoinment Info
  resceduleAppoinmentInfo() {
    const initialState = {
      header: "Reschedule Appoinment",
    //  eventId: this.eventId,
      closeBtnName: "Cancel",
      backdrop: true,
      ignoreBackdropClick: true
    };
    this.modalRef = this.modalService.show(ResheduleAppoinmentInfoComponent, {initialState});
  }
  //Cancel Appoinment
  cancelAppoinment(action) {
    const initialState = {
      header: action == 'Completed' ? "Complete Appointment" : 'Cancel Appointment',
      closeBtnName: "Save",
      //eventId: this.eventId,
      status: action,
      backdrop: true,
      ignoreBackdropClick: true
    };
    this.modalRef = this.modalService.show(CancelAppoinmentComponent, {initialState});
    this.modalRef.content.event.subscribe(data => {
     // this.getAppointmentInfo();
   });
  }

  onItemSelect(test) {

  }

  onInputFocusOut(event) {
    if (event.srcElement.value.trim() != "" && this.actualValue != event.srcElement.value.trim()) {
      this.invokeClientSave(event.srcElement.id, event.srcElement.value);
    }

  }

  private invokeClientSave(selector, value) {
    var requestInfo : any= {};
    requestInfo.FieldName = selector;
    requestInfo.Data = value
    requestInfo.UserName = localStorage.getItem("UserName");
    requestInfo.UserSoid =  localStorage.getItem("UserSoid");
   /**  this._clientService.updateClientInfo(this.clientInfo.Soid, requestInfo).subscribe((res: any) => {
      if (res && res.Changed === true) {
        if (res.response == "EMAIL_EXISTS") {
          this.toast.warning("Email Id already exists.", "Warning!");
        } else {
          this.toast.success("Updated Successfully.", "Success!");
        }
      } else {
        this.toast.error("Error Updating Details", "Success!");
      }
    });*/
  }


  private getClientInfo() {
    this._clientService.getClientInfo(this.clientId).subscribe((res: any) => {
      if (res) {
        this.clientInfo = res;
      }
    });

  }


  onSelectFocus(event) {
    this.actualValue = event.srcElement.value;
  }

  onSelectFocusOut(event) {
       if (event.source._value.trim() != "" && this.actualValue != event.source._value.trim().trim()) {
      this.invokeClientSave(event.source._id, event.source._value.trim());
    }
  }

  onFocusChange(event) {

  }

  onChange(event) {

  }

  dateChange(event) {
    this.invokeClientSave(event.target._elementRef.nativeElement.id, this.datePipe.transform(event.value, 'MM/dd/yyyy'));
  }


  inputFocus(event){
    this.actualValue = event.srcElement.value;
  }

  goBack() {
    if (window.history.length > 1) {
      this.location.back()
    }
  }

  generatePDF(action = 'open') {
    var billTo = (this.invoiceInfo.BusinessName ? this.invoiceInfo.BusinessName : '') + (this.invoiceInfo.BusinessName ? ' / ' : '') + (this.invoiceInfo.BillingToFirstName + ' ' + this.invoiceInfo.BillingToLastName);

    let docDefinition = {
      content: [
        {
          text: 'Estimate',
          fontSize: 25,
          bold: true,
          alignment: 'center',
          color: 'black',
          decoration: 'underline'
        },

        {
          text: this.invoiceInfo.Label,
          fontSize: 20,
          bold: true,
          alignment: 'right',
          color: 'skyblue',
          width: 50
        },
        {
          text: "#" + this.invoiceInfo.EstimateNumber,
          fontSize: 20,
          bold: true,
          alignment: 'right',
        },
        {
          columns: [
            [
              {
                text: 'From: ' + this.invoiceInfo.StaffName,
                bold: true
              },
              {text: this.invoiceInfo.CreatedBy},
              { text: this.invoiceInfo.BillingFromAddress && this.invoiceInfo.BillingFromAddress != 'null' ? this.invoiceInfo.BillingFromAddress : '' },
              { text: this.invoiceInfo.BillingFromState && this.invoiceInfo.BillingFromState != 'null' ? this.invoiceInfo.BillingFromState : '' },
              { text: this.invoiceInfo.BillingFromCountry && this.invoiceInfo.BillingFromCountry != 'null' ? this.invoiceInfo.BillingFromCountry : '' },
              { text: this.invoiceInfo.BillingFromZip && this.invoiceInfo.BillingFromZip != 'null' ? this.invoiceInfo.BillingFromZip : '' },
            ],

            [
              {
                text: `Date: ${new Date().toLocaleString()}`,
                alignment: 'right',
                fontSize: 7,
              },
            ]
          ]

        },
        {

        },
        {
          style: 'lineSpace',
          columns: [
            [
              {
                text: 'To: ' + this.clientInfo.NameLast + ' ' + this.clientInfo.NameFirst,
                bold: true
              },
              {text : this.clientInfo.PersonNumber},
              { text: this.invoiceInfo.BillingToAddress ? this.invoiceInfo.BillingToAddress : '' },
              { text: this.invoiceInfo.BillingToState ? this.invoiceInfo.BillingToState : '' },
              { text: this.invoiceInfo.BillingToCountry ? this.invoiceInfo.BillingToCountry : '' },
              { text: this.invoiceInfo.BillingToZip ? this.invoiceInfo.BillingToZip : '' },
            ],
            [
              {
                text: 'Date Of Issue',
                alignment: 'right',
                fontSize: 10,
                bold: true
              },
              {
                text: this.datePipe.transform(this.invoiceInfo.EstimateDate, 'MM/dd/yyyy'),
                alignment: 'right',
                fontSize: 10,

              },
              {
                text: 'Expires On',
                alignment: 'right',
                fontSize: 10,
                bold: true,
              },
              {
                text: this.datePipe.transform(this.invoiceInfo.ExpirationDate, 'MM/dd/yyyy'),
                alignment: 'right',
                fontSize: 10,
              }
            ]
          ]

        },
        {
          table: {
            headerRows: 1,
            widths: ['*', 'auto', 'auto', 'auto', 'auto'],
            body: [
              ['Product', 'Price', 'Quantity', 'Tax', 'Total'],
              ...this.invoiceInfo.Items.map(p => ([p.Item, '$' + p.UnitPrice, p.Quantity, p.UnitOfMeasure, '$' + (p.UnitPrice * p.Quantity).toFixed(2) + ' USD'])),
              [{ text: 'Sub Total', colSpan: 4 }, {}, {}, {}, '$' + this.invoiceInfo.Items.reduce((sum, p) => sum + (p.Quantity * p.UnitPrice), 0).toFixed(2) + ' USD'],
              [{ text: 'Grand Total', colSpan: 4 }, {}, {}, {}, '$' + this.invoiceInfo.Items.reduce((sum, p) => sum + (p.Quantity * p.UnitPrice), 0).toFixed(2) + ' USD']
            ]
          }
        },
        {
          text: 'Notes:',
          style: 'notes'
        },
        {
          text: this.invoiceInfo.Notes
        },
        {
          style: 'lineSpace',
          columns: [
            [{ text: 'Powered by Love My Systems', alignment: 'right', italics: true, fontSize: 5 }],
          ]
        },
      ],
      styles: {
        tableHeader: {
          bold: true
      },
        sectionHeader: {
          bold: true,
          decoration: 'underline',
          fontSize: 14,
          margin: [0, 15, 0, 15]
        },
        lineSpace: {
          margin: [0, 15, 0, 15]
        },
        notes: {
          bold: true,
          decoration: 'underline',
          fontSize: 10,
          margin: [0, 15, 0, 15]
        },

      }
    };

    const pdfCreator = pdfMake.createPdf(docDefinition);
    if (action === 'download') {
      pdfCreator.download();
    } else if (action === 'print') {
      pdfCreator.print();
    } else if (action === 'pdfGenerator') {
      return pdfCreator;
    } else {
      pdfCreator.open();
    }

  }

  deleteEstimate() {
    const initialState = {
      confirmMessage: "Are you sure to delete this estimate?",
      backdrop: true,
      ignoreBackdropClick: true
    };
    this.modalRef = this.modalService.show(ConfirmationDialog, { initialState });
    this.modalRef.content.event.subscribe(data => {
      if (data) {

        this._invoiceService.deleteEstimate(this.invoiceInfo.Soid).subscribe((res: any) => {
          if (res) {
            this.invoiceInfo = res;
            if (this.invoiceInfo.Status == "DELETED") {
              this.toast.success("Successfully Deleted", "Info!");
            }
          }
        });

      }
    });
  }

  sendEstimateByEmail(): void {
    this.generatePDF('pdfGenerator').getBlob((file: Blob) => {
      this._invoiceService.sendEstimateByEmail(file, this.invoiceInfo.Soid)
        .subscribe({
          next: (res) => this.toast.success('Estimate successfully sent'),
          error: (err) => {
            this.toast.error('There was an error sending estimate');
            console.error(err);
          },
          complete: () => this.confirmationPopup.hide(),
        });
    });
  }

  openSendEmailConfirmationPopup(template: TemplateRef<any>): void {
    this.confirmationPopup = this.modalService.show(template);
  }
}
