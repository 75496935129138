import { Component, OnInit, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import {FormBuilder, FormGroup, FormControl, Validators} from '@angular/forms';
import { ServicesListService } from '../../../services/services.service';
import {ToastrService} from 'ngx-toastr';
import { Observable, Subject } from 'rxjs';
import { MasterService } from '../../../services/master.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {
  public event: EventEmitter<any> = new EventEmitter();

  constructor(public bsModalRef: BsModalRef) {}

  ngOnInit() {
  }

  close() {
    this.event.emit();
    this.bsModalRef.hide();
  }
}
