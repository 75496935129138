
<div class="modal-header">
  <h4 class="modal-title pull-left">{{header}} - Invoice ${{invoiceAmount}} USD</h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body contentScroll" style="height: 450px; overflow-y: auto; overflow-x: hidden;">
    
  <form class="client-modal" [formGroup]="eventForm">
    <div class="container" *ngIf="paymentList.length > 0">
      <div class="row col-heading-payments">
      <div class="col ">
        Receipt 
            </div>
      <div class="col">
        Paid On
      </div>
      <div class="col">
        Paid
      </div>
      <div class="col">
        Balance
      </div>
    </div>
      <div class="row" *ngFor="let row of paymentList" >
        <div class="col">
          #{{row.ReceiptNumber}}
        </div>
        <div class="col">
          {{row.PaidOn | date:"dd MMM yyyy"}}
        </div>
        <div class="col">
          ${{row.Amount}} USD
        </div>
        <div class="col">
          ${{row.Balance}} USD
        </div>
      </div>
    </div>


  </form> 
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary" (click)="closeModal()">Close</button>
</div>